import TURN_STATE from '../config/estados';

const TURN_STATE_OBJECT = {
    'Default': {
        'id': parseInt(TURN_STATE.NOT_CONFIRMED),
        'display': 'Sin Confirmar',
        'color': 'spanFinalizado',
        'dataColor': 2,
        'botones': {
            ver: false,
            confirmar: false,
            derivar: false,
            atender: false,
            cancelar: false,
            finalizar: false,
            editar: false,
            reactivar: false,
        }
    },
    'Sin Confirmar': {
        'id': parseInt(TURN_STATE.NOT_CONFIRMED),
        'display': 'Sin Confirmar',
        'color': 'spanFinalizado',
        'dataColor': 2,
        'botones': {
            ver: true,
            confirmar: true,
            derivar: false,
            atender: false,
            cancelar: true,
            finalizar: false,
            editar: true,
            reactivar: false,
        }
    },
    'Confirmado': {
        'id': parseInt(TURN_STATE.CONFIRMED),
        'display': 'Confirmado',
        'color': 'spanAtendido',
        'dataColor': 2,
        'botones': {
            ver: true,
            confirmar: true,
            derivar: false,
            atender: false,
            cancelar: true,
            finalizar: false,
            editar: true,
            reactivar: false,
        }
    },
    'Pendiente de atención': {
        'id': parseInt(TURN_STATE.PENDING_ATTENTION),
        'display': 'Pendiente de atención',
        'color': 'spanPendienteAtencion',
        'dataColor': 1,
        'botones': {
            ver: true,
            confirmar: false,
            derivar: true,
            atender: true,
            cancelar: true,
            finalizar: true,
            editar: true,
            reactivar: false,
        }
    },
    'En atencion': {
        'id': parseInt(TURN_STATE.ATTENDED),
        'display': 'En atencion',
        'color': 'spanAtendido',
        'dataColor': 2,
        'botones': {
            ver: true,
            confirmar: true,
            derivar: true,
            atender: true,
            cancelar: true,
            finalizar: true,
            editar: true,
            reactivar: false,
        }
    },
    'Finalizado': {
        'id': parseInt(TURN_STATE.FINALIZED),
        'display': 'Finalizado',
        'color': 'spanAtendido',
        'dataColor': 3,
        'botones': {
            ver: true,
            confirmar: false,
            derivar: false,
            atender: false,
            cancelar: false,
            finalizar: false,
            editar: false,
            reactivar: false,
        }
    },
    'Cancelado': {
        'id': parseInt(TURN_STATE.CANCELLED),
        'display': 'Cancelado',
        'color': 'spanCancelado',
        'dataColor': 1,
        'botones': {
            ver: true,
            confirmar: false,
            derivar: false,
            atender: false,
            cancelar: false,
            finalizar: false,
            editar: false,
            reactivar: true,
        }
    },
    'Transferido': {
        'id': parseInt(TURN_STATE.TRANSFERRED),
        'display': 'Transferido',
        'color': 'spanPendienteAtencion',
        'dataColor': 3,
        'botones': {
            ver: true,
            confirmar: false,
            derivar: false,
            atender: false,
            cancelar: false,
            finalizar: false,
            editar: false,
            reactivar: false,
        }
    },
    'Derivado': {
        'id': parseInt(TURN_STATE.TRANSFERRED),
        'display': 'Derivado',
        'color': 'spanPendienteAtencion',
        'dataColor': 2,
        'botones': {
            ver: true,
            confirmar: false,
            derivar: true,
            atender: true,
            cancelar: true,
            finalizar: true,
            editar: false,
            reactivar: false,
        }
    }
};

export default TURN_STATE_OBJECT