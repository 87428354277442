import { formatarFecha, isEmpty } from "../../../utils/functions";
import '../../datosPersona/_datosPersonaTurno.scss';
import profileImage from '../../../images/imagen-card1.png';
import TURN_STATE_OBJECT from "../../../utils/turn_state";

export function ModalVerDatosTurno(props) {
    const { turno_id, open_modal, setOpenModal, datosTurno } = props;
    const cerrarPopup = () => {
        setOpenModal(false);
    }

    return (
        <>
            <div className={`modal ${!open_modal ? 'fade' : 'show d-block'}`} id="modalAgregarMotivoTurno" tabIndex="-1" aria-labelledby="modalAgregarMotivoTurnoLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Detalles de un turno</h6>
                            <button type="button" className="btn-close" id="modalDerivacionClose" onClick={cerrarPopup} aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            {!isEmpty(turno_id) &&
                                <div className="h-100 container d-flex flex-column p-0" id="show">
                                    <div className="col-12 d-flex">
                                        <div className="col-3 d-flex justify-content-center align-items-center">
                                            <img src={profileImage} alt="imagen perfil" className="img-fluid" />
                                        </div>
                                        <div className="col-9">
                                            <h3 className="titlegray">{`${datosTurno.persona.name} ${datosTurno.persona.surname}`}</h3>
                                            <div className="mt-5 container__main__datos">
                                                <div className="container__historial__card">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div>
                                                                <h6>#{datosTurno.motivo_turno_abreviacion} - {datosTurno.turno_id}</h6>
                                                            </div>
                                                            <div>
                                                                <p><b>Email:</b> <span>{datosTurno.persona.email}</span></p>
                                                            </div>
                                                            <div>
                                                                <p><b>Legajo:</b> <span>{datosTurno.persona.legajo}</span></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div>
                                                                <p><b>Empresa:</b> <span>{datosTurno.persona.company}</span></p>
                                                            </div>
                                                            <div>
                                                                <p><b>{datosTurno.persona.document_type}:</b> <span>{datosTurno.persona.document_number}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        Estado: <span className="infoTurno" data-color={TURN_STATE_OBJECT[datosTurno.estado_turno].dataColor - 1}>{TURN_STATE_OBJECT[datosTurno.estado_turno].display}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 container__main__datos">
                                        <div className="container__historial__card bordersCustom my-5 py-5">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div>
                                                        <p><b>Sucursal:</b> <span>{datosTurno.sucursal.name}</span></p>
                                                    </div>

                                                    <div>
                                                        <p><b>Fecha:</b> <span>{`${formatarFecha(datosTurno.fecha)}`}</span></p>
                                                    </div>

                                                    <div>
                                                        <p><b>Hora:</b> <span>{datosTurno.hora}</span></p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <b>Observaciones:</b>
                                                        </div>
                                                        <div className="card-body my-3">
                                                            <span>{datosTurno.observacion}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 container__main__datos">
                                        <div className="container__historial__card my-3 py-2">
                                            <h5 className="text-center"><b>Creado por:</b></h5>
                                            <div>
                                                <p><b>Nombre y apellido:</b> <span>{datosTurno.ADP_nombre} {datosTurno.ADP_apellido}</span></p>
                                            </div>
                                            <div>
                                                <p><b>Email:</b> <span>{datosTurno.ADP_email}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}