import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import agregarMotivoTurnoATurno from "../../../services/adp/turn/agregar-motivo-turno";
import getmotivoTurnosData from "../../../services/adp/turn_reason/get";
import Message from "../../../utils/message";
import Select from "../../Select/select";


function AgregarMotivoTurnoATurno(props) {
    const { id, ver_agregar_motivo_turno, motivos_actuales, setMotivosturno, cerrarAgregarMotivoTurnoTurno, TOKEN, motivos_turno, setLoader, cargarLista, obtenerDatosTurno } = props;
    const motivos_actuales_aux = motivos_actuales;
    const [validated, setValidated] = useState(false);
    const [formValues, setValues] = useState({
        turno_id: '',
        motivo_turno_id: ''
    });

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    const guardarMotivoTurnoTurno = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            setLoader(true);
            const motivoTurnoID = formValues.motivo_turno_id;
            const found = motivos_actuales.find(element => element.idNitro4 === parseInt(motivoTurnoID));

            if (found === undefined) {
                agregarMotivoTurnoATurno({
                    params: formValues, token: TOKEN, callbacks: response => {
                        if (response.estado > 0) {
                            getmotivoTurnosData({
                                id: motivoTurnoID, token: TOKEN, callbacks: async (response) => {
                                    const motivoDevuelto = response.resultados[0];
                                    motivos_actuales_aux.push({
                                        idNitro4: motivoDevuelto.id,
                                        Nombre: motivoDevuelto.name,
                                        Documentacion: motivoDevuelto.documentation,
                                        Abreviacion: motivoDevuelto.abbreviation
                                    });
                                    setMotivosturno(motivos_actuales_aux);
                                    await obtenerDatosTurno(id);
                                    cargarLista();
                                    await cerrarAgregarMotivoTurnoTurno();
                                    await setLoader(false);
                                }
                            })
                        }
                    }
                });
            } else {
                Message({ message:'Este motivo ya se encuentra agregado', type: 'error' })
                setLoader(false);
            }

        }
    }
    useEffect(() => {
        setValues({
            turno_id: id,
            motivo_turno_id: '',
        });
    }, [TOKEN, id]);


    return (
        <div id="agregarMotivoTurno" className={`${!ver_agregar_motivo_turno ? 'd-none' : 'd-block'}`}>
            <div className="card my-5">
                <div className="card-body">
                    <Form className='glassDatos' noValidate validated={validated} onSubmit={guardarMotivoTurnoTurno}>
                        <div className="row">
                            <div className="col-md-12 mt-1">
                                <Form.Group>
                                    <Form.Label>Motivo de turno</Form.Label>
                                    <Select
                                        value={motivos_turno}
                                        name="motivo_turno_id"
                                        id="motivo_turno_id"
                                        placeholder="Seleccione el motivo de turno"
                                        onChange={handleInputChange}
                                    />
                                    <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="my-2 mt-4 d-flex justify-content-center">
                                <button type="button" className="btn-dangergg mx-4" onClick={cerrarAgregarMotivoTurnoTurno}>Cerrar</button>
                                <button type="submit" className="btn-orange mx-4">Guardar</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
export default AgregarMotivoTurnoATurno;