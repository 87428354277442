
import './_Metricas.scss';
import Title from '../../components/Title';
import { useEffect, useState } from 'react';
import getListBranchs from '../../services/adp/branch/list';
import Select from '../../components/Select/select';
import metricasDuracionDeTurnos from '../../services/adp/metricas/duracion-turnos';
import { formatarFecha, isEmpty, filterObjectKeysFromArray, downloadExcelfromClient, restablecerFechaDeDB } from '../../utils/functions';
import TURN_STATE_OBJECT from '../../utils/turn_state';
import { ANIO, MESES } from '../../utils/config';
import { Pinwheel } from '@uiball/loaders';
import { obtenerExcel } from '../../services/adp/download-excel/get-file';
import { useAuth } from '../../hooks/auth';

export default function MetricasDuracionDeTurnos(props) {
    const { token: TOKEN } = useAuth();
    const [sucursales, setSucursales] = useState([]);
    const [filter, setFilter] = useState(false);
    const mes = MESES.find(item => Number(item.id) === new Date().getMonth())?.id;
    const year = ANIO.find(item => Number(item.id) === new Date().getFullYear())?.id;
    const [formValues, setValues] = useState({
        mes: mes,
        anio: year,
        sucursal_id: null,
    });
    const [total_tiempo_de_atencion, setTotalTiempoAtencion] = useState('...');
    const [tiempo_de_atencion_promedio, setTiempoDeAtencionPromedio] = useState('...');
    const [turnos, setTurnos] = useState({});

    const [loading_table, setLoadingTable] = useState(true);
    const [mensaje, setMensaje] = useState('Buscando resultados...');
    const [tipo_mensaje, setMensajeType] = useState('warning');
    const [filterParameters, setFilterParameters] = useState([
        "estado_turno",
        "current_fecha_alta",
        "Nombre_completo",
        "DNI",
        "motivo_turno",
        "empresa",
        "tiempo_de_atencion",
        "id"
    ])

    useEffect(() => {
        const mes_select = document.getElementById("mes");
        if (!mes_select) throw new Error("No se encontró el elemento select mes");
        mes_select.value = mes
        const anio_select = document.getElementById("anio");
        if (!anio_select) throw new Error("No se encontró el elemento select anio");
        anio_select.value = year;

        getListBranchs({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setSucursales(response.resultados);
                }
            }
        });

        metricasDuracionDeTurnos({
            params: filter ? formValues : { mes: mes, anio: year }, token: TOKEN, callbacks: response => {
                if (response.estado && response.resultados.length > 0) {
                    setMensaje(null);
                    setMensajeType(null);
                    setTotalTiempoAtencion(response.total_tiempo_de_atencion ?? '');
                    setTiempoDeAtencionPromedio(response.tiempo_de_atencion_promedio ?? '');
                    setTurnos(response.resultados.sort(function (a, b) { return b.turno_id - a.turno_id }));
                    setLoadingTable(false)
                } else {
                    setTotalTiempoAtencion('');
                    setTiempoDeAtencionPromedio('');
                    setTurnos([])
                    setMensaje(response.mensaje);
                    setMensajeType('danger');
                    setLoadingTable(false)
                }
            }
        });

    }, [TOKEN, filter, formValues, mes, year]);


    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setFilter(true)
        setLoadingTable(true)
        setMensaje('Buscando...');
        setMensajeType('info');
        if (!isEmpty(value)) {
            setValues({
                ...formValues,
                [name]: value,
            });
        } else {
            setValues({
                ...formValues,
                [name]: null,
            });
        }
    }

    async function handleDownloadExcel() {

        const descargarBtn = document.querySelector(".descargar");

        if (descargarBtn) {
            descargarBtn.classList.add("fa", "fa-spinner", "fa-spin");
        }

        const nombre = "Metricas duracion turnos"

        const cabecera = [
            "Fecha Alta",
            "Estado Turno",
            "Motivo Turno",
            "Empresa",
            "Dni",
            "Colaborador",
            "Tiempo de atención (SEG)",
            "ID"
        ]

        const datosExcel = [cabecera, ...await filterObjectKeysFromArray(turnos, filterParameters, filterParameters)];

        const file = await obtenerExcel({ token: TOKEN, params: datosExcel })

        const response = await downloadExcelfromClient(file, nombre)

        if (response) descargarBtn.classList.remove("fa", "fa-spinner", "fa-spin");

    }
    return (
        <>
            <main>
                <div className="container-fluid ps-0">
                    <div className="glassDatos row">
                        <div className="my-5 col-9">
                            <Title title='Duracion de turnos' class="titlegray" />
                        </div>
                        <div className="col-2 my-5">
                            <button onClick={handleDownloadExcel} className="btn-greengg d-block"><i className='descargar'></i>Exportar datos</button>
                        </div>
                        <div className="row divTotalTurnos my-3 mb-5">
                            <div className='col-12'>
                                <div className="selectViewTurnos">
                                    <div className="divSelect">
                                        <div className='row'>
                                            <div className="col-3">
                                                <p className="m-0 me-1">Mes:</p>
                                                <Select
                                                    id="mes"
                                                    name="mes"
                                                    placeholder="Seleccione un mes"
                                                    onChange={handleInputChange}
                                                    value={MESES}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <p className="m-0 me-1">Año:</p>
                                                <Select
                                                    id="anio"
                                                    name="anio"
                                                    placeholder="Seleccione el año"
                                                    onChange={handleInputChange}
                                                    value={ANIO}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <p className="m-0 me-1">Sucursal:</p>
                                                <Select
                                                    id="sucursal_id"
                                                    name="sucursal_id"
                                                    placeholder="Seleccione una sucursal"
                                                    onChange={handleInputChange}
                                                    value={sucursales}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`alert alert-${tipo_mensaje}`}>
                            {mensaje}
                        </div>
                        {
                            turnos.length > 0 &&
                            <div>
                                {
                                    loading_table ?
                                        <div className="loadingTableMetricas">
                                            <Pinwheel
                                                size={100}
                                                lineWeight={5.5}
                                                speed={1}
                                                color="orange"
                                            />
                                        </div> :
                                        <>
                                            <div className="row mb-5">
                                                <div className='col-6 d-flex'>
                                                    <div className="containerNumberTurnos">
                                                        <h2 className="m-0">Tiempo total de atencion</h2>
                                                        <p>{total_tiempo_de_atencion}</p>
                                                    </div>
                                                </div>
                                                <div className='col-6 d-flex'>
                                                    <div className="containerNumberTurnos">
                                                        <h2 className="m-0">Tiempo promedio de atencion</h2>
                                                        <p>{tiempo_de_atencion_promedio}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <table className="table table-hover table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Colaborador</th>
                                                            <th>DNI</th>
                                                            <th>Fecha de alta</th>
                                                            <th>Hora de alta</th>
                                                            <th>Motivo de turno</th>
                                                            <th>Empresa</th>
                                                            <th>Tiempo de atención</th>
                                                            <th>Estado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            turnos.map(item => {
                                                                let fecha_total = item.current_fecha_alta.split('T');
                                                                const fecha = fecha_total[0];
                                                                const hora = restablecerFechaDeDB({ hora: fecha_total[1].split('.') });
                                                                return (
                                                                    <tr key={item.turno_id}>
                                                                        <td>#{item.abreviacion}-{item.turno_id}</td>
                                                                        <td>{item.Nombre_completo}</td>
                                                                        <td>{item.DNI}</td>
                                                                        <td>{formatarFecha(fecha)}</td>
                                                                        <td>{hora}</td>
                                                                        <td>{item.motivo_turno}</td>
                                                                        <td>{item.empresa ?? 'SD'}</td>
                                                                        <td>{item.tiempo_de_atencion} seg</td>
                                                                        <td><span className="infoTurno" data-color={TURN_STATE_OBJECT[item.estado_turno].dataColor - 1}>{TURN_STATE_OBJECT[item.estado_turno].display}</span></td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </main>
        </>
    );
}