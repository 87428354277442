import { useEffect, useState } from "react";
import './cardTurnoPendiente.scss';
import Title from "../../components/Title";
import confirmarTurnoADP from "../../services/adp/turn/confirm";
import DatosPersonaTurno from "../../components/datosPersona";
import { formatarFecha, isEmpty } from "../../utils/functions";
import { Spinner } from "react-bootstrap";
import ListadoTurnos from "../../components/listadoTurnos";
import FiltrosLista from "../../components/listadoTurnos/filtros";
import obtenerTurnoParaAtender from "../../services/adp/turn/obtain-to-attend";
import AgregarTurnoSelectora from "../../components/AgregarTurnoSelectora";
import listaMotivosReasignacion from "../../services/adp/motivo_reasignacion/list";
import listaMotivosDerivacion from "../../services/adp/motivo_derivacion/list";
import getListTurnReasons from "../../services/adp/turn_reason/list";
import getListBranchs from "../../services/collaborator/branch/list";
import getListPersonsByBranch from "../../services/adp/person/list-by-branch";
import ModalCancelarTurno from "../../components/Modals/Cancelar";
import ModalDerivacionTurno from "../../components/Modals/Derivacion";
import ModalEditarTurno from "../../components/Modals/Editar/Index";
import listaMotivosCancelacion from "../../services/adp/motivo_cancelacion/list";
import AtencionMultiple from "../../components/atencionMultiple";
import obtenerListaDeTurnoParaAtender from "../../services/adp/turn/obtener-lista-turnos";
import confirmarListaTurno from "../../services/adp/turn/confirmar-lista-turnos";
import TURN_STATE from "../../config/estados";
import Message from "../../utils/message";
import ModalCancelarMultiple from "../../components/Modals/CancelarMultiple";
import ModalEditarMultiple from "../../components/Modals/EditarMultple";
import ModalDerivacionMultiple from "../../components/Modals/DerivarMultiple";
import { useListaTurnos } from "../../hooks/useListaTurnos";
import listaTurnos from "../../services/adp/turn/lista-turnos";
import { CURRENT_DATE } from "../../utils/config";
import { useCallback } from "react";
import AgregarPresentacionEspontanea from "../../components/AgregarPresentacionEspontanea";
import { ESTADOS_TURNO } from "../../config/estados_turno";
import { useSucursal } from "../../hooks/sucursal";
import { useSocket } from "../../hooks/socket";
import { useAuth } from "../../hooks/auth";
import Loading from "../../components/Loading";
import { useHotkeys } from "react-hotkeys-hook";


export default function Turnos() {
    const { sucursal: sucursalAsignada } = useSucursal();
    const { token: TOKEN, loading: loading_auth } = useAuth();
    const { socket } = useSocket();
    const turnosPorPagina = 16;
    const [fecha, setFecha] = useState(CURRENT_DATE);
    const { loading, setLoading, turnos, setTurnos } = useListaTurnos({ token: TOKEN, branch: sucursalAsignada, fecha })
    const [idTurnoSeleccionado, setIdTurnoSeleccionado] = useState(0);
    const [turnos_finalizar, setTurnosFinalizar] = useState([])
    const [datosTurno, setTurnData] = useState({
        estado: 0,
        estado_turno: 'Default',
        sucursal: {
            Nombre: ''
        },
        persona: {
            name: '',
            surname: '',
            document_type: '',
            document_number: '',
        },
        motivos_turno: [],
        historial: [],
        fecha: '',
        hora: ''
    });
    const [ver_opciones, setVerOpciones] = useState(false);
    const [ultimaPagina, setUltimaPagina] = useState(() => parseInt(Math.ceil(turnos / turnosPorPagina)));

    const [loader, setLoader] = useState(true);
    const [abrir_atencion_multiple, setAtencionMultiple] = useState(false);
    const [loader_atencion_multiple, setLoaderAtencionMultiple] = useState(false);

    const [datosTurnosSeleccionados, setDatosTurnosSeleccionados] = useState([]);

    let [turnos_seleccionados, setTurnosSeleccionados] = useState([])

    const [estadosTurno, setEstadosTurno] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);

    const [filtroEstadoTurno, setFiltroEstadoTurno] = useState(0);
    const [search, setSearch] = useState('')

    const containerDatosPersona = document.querySelector('.containerDatosPersonaCard');
    const containerShadowClose = document.querySelector('.shadowClose');

    const closeContainerDatosPersona = () => {
        containerDatosPersona.setAttribute('data-swap', 'off');
        containerShadowClose.setAttribute('data-swap', 'off');
    }
    const openContainerDatosPersona = () => {
        containerDatosPersona.setAttribute('data-swap', 'on');
        containerShadowClose.setAttribute('data-swap', 'on');
    }

    const [isAllChecked, setIsAllChecked] = useState(false);
    const [permitir_finalizar, setPermitirFinalizar] = useState(false);


    useHotkeys('esc', async function (event) {
        setLoading(false);
        setLoader(false);
    });

    const cargarLista = useCallback(async () => {
        listaTurnos({
            branch_id: sucursalAsignada.branchID, fecha: fecha, token: TOKEN, callbacks: async (response) => {
                if (response.estado === 1 && response.resultados && response.resultados.length > 0) {
                    setTurnos(response.resultados ?? []);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setTurnos([]);
                }
            }
        })
    }, [TOKEN, sucursalAsignada, fecha, setTurnos, setLoading]);


    const restablecerCheckboxs = () => {
        setTurnosSeleccionados([]);
        setIsAllChecked(false);
        setDatosTurnosSeleccionados([]);
    }

    const volverEstadoEnAtencion = () => {
        setLoading(true);
        closeContainerDatosPersona();
        setVerOpciones(false);
        confirmarTurnoADP({
            id: idTurnoSeleccionado, token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0) {
                    setIdTurnoSeleccionado(0);
                    cargarLista();
                    closeContainerDatosPersona();
                    setLoading(false);
                } else {
                    setLoading(false)
                }
            }
        });
    }
    const paginate = (array, page_size, page_number) => {
        if (array instanceof Array) {
            return array.slice((page_number - 1) * page_size, page_number * page_size);
        } else {
            return false;
        }
    }


    const obtenerDatosTurno = async (id) => {
        setLoader(true);
        setIdTurnoSeleccionado(id);
        setPermitirFinalizar(false);
        await obtenerTurnoParaAtender({
            token: TOKEN, id: id, callbacks: (result) => {
                let response = result.resultados;
                setTurnData({ ...response, estado: 1 });
                setLoader(false);
            }
        });
    }


    const abrirAtencionMultiple = async () => {
        if (turnos_seleccionados.length > 0) {
            setLoading(true);
            setAtencionMultiple(true)
            setLoaderAtencionMultiple(true);
            obtenerListaDeTurnoParaAtender({
                token: TOKEN, params: { lista: turnos_seleccionados, update: 'yes', action: 'atender' }, callbacks: async (response) => {
                    setTurnosFinalizar(response.resultados.filter(item => item.estado !== 0))
                    setDatosTurnosSeleccionados(response.resultados);
                    setLoaderAtencionMultiple(false);
                    cargarLista();
                }
            });
        } else {
            Message({ message: 'Debes seleccionar al menos un turno', type: 'error' })
        }
    }

    const cerrarAtencionMultiple = () => {
        const turnos_regresar = [];
        setLoading(true);
        setLoaderAtencionMultiple(true);
        datosTurnosSeleccionados.forEach(item => {
            if (Number(item.estado_turno_id) === Number(TURN_STATE.ATTENDED)) {
                turnos_regresar.push(item.turno_id);
            }
        })
        confirmarListaTurno({
            params: { lista: turnos_regresar }, token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0) {
                    cargarLista();
                    setAtencionMultiple(false);
                    setLoaderAtencionMultiple(false);
                } else {
                    setLoading(false)
                }
            }
        });
    }




    const [ver_modal_editar_multiple, setAbrirModalEditarMultiple] = useState(false);
    const [ver_modal_derivar_multiple, setAbrirModalDerivarMultiple] = useState(false);
    const [ver_modal_cancelar_multiple, setAbrirModalCancelarMultiple] = useState(false);

    const abrirCancelarMultiple = async () => {
        if (turnos_seleccionados.length > 0) {
            setLoading(true);
            await listaMotivosCancelacion({
                token: TOKEN, callbacks: response => {
                    if (response.estado > 0 && response.resultados.length > 0) {
                        setMotivoCancelacion(response.resultados);
                    }
                }
            });
            await obtenerListaDeTurnoParaAtender({
                token: TOKEN, params: { lista: turnos_seleccionados, update: 'not', action: 'cancelar' }, callbacks: async (response) => {
                    setTurnosFinalizar(response.resultados.filter(item => item.estado !== 0))
                    setDatosTurnosSeleccionados(response.resultados);
                }
            })
            setAbrirModalEditarMultiple(false);
            setAbrirModalDerivarMultiple(false);
            setAbrirModalCancelarMultiple(true);
            setLoading(false);
        } else {
            Message({ message: 'Debes seleccionar al menos un turno', type: 'error' })
        }
    }

    const cerrarCancelarMultiple = () => {
        setAbrirModalCancelarMultiple(false);
    }


    const abrirDerivarMultiple = async () => {
        if (turnos_seleccionados.length > 0) {
            setLoading(true);
            await listaMotivosDerivacion({
                token: TOKEN, callbacks: function (result) {
                    let motivosDerivacionSelect = [];
                    if (result.estado > 0) {
                        result.resultados.forEach(item => {
                            motivosDerivacionSelect.push({
                                id: item.idNitro4,
                                name: `${item.Nombre}`
                            });
                        });
                    }
                    setMotivosDerivacion(motivosDerivacionSelect);
                }
            });

            await getListTurnReasons({
                token: TOKEN, callbacks: response => {
                    if (response.estado > 0 && response.resultados.length > 0) {
                        let motivosTurnoArray = [];
                        response.resultados.forEach(item => {
                            let label = `${item.abbreviation} - ${item.name}`;
                            motivosTurnoArray.push({
                                value: item.id,
                                label: label.toUpperCase(),
                            });
                        });
                        setMotivosTurno(motivosTurnoArray);
                    }
                }
            });

            await getListBranchs({
                token: TOKEN, callbacks: response => {
                    if (response.estado > 0 && response.resultados.length > 0) {
                        setSucursales(response.resultados);
                    }
                }
            });

            await getListPersonsByBranch({
                sucursal_id: sucursalAsignada.branchID, token: TOKEN, callbacks: function (result) {
                    let personasSelect = [];
                    if (result.estado > 0) {
                        result.resultados.forEach(item => {
                            personasSelect.push({
                                id: item.id,
                                name: `${item.name} ${item.surname} - ${item.email}`
                            });
                        });
                    }
                    const unique = [...new Map(personasSelect.map((m) => [m.id, m])).values()];
                    setPersonas(unique);
                }
            });
            await obtenerListaDeTurnoParaAtender({
                token: TOKEN, params: { lista: turnos_seleccionados, update: 'not', action: 'derivar' }, callbacks: async (response) => {
                    setTurnosFinalizar(response.resultados.filter(item => item.estado !== 0))
                    setDatosTurnosSeleccionados(response.resultados);
                }
            });
            setAbrirModalEditarMultiple(false);
            setAbrirModalDerivarMultiple(true);
            setAbrirModalCancelarMultiple(false);
            setLoading(false);
        } else {
            Message({ message: 'Debes seleccionar al menos un turno', type: 'error' })
        }
    }

    const cerrarDerivarMultiple = () => {
        setAbrirModalDerivarMultiple(false);
    }


    const abrirEditarMultiple = async () => {
        if (turnos_seleccionados.length > 0) {
            setLoading(true);
            await listaMotivosReasignacion({
                token: TOKEN, callbacks: response => {
                    let motivosReasignacionSelect = [];
                    if (response.estado > 0) {
                        response.resultados.forEach(item => {
                            motivosReasignacionSelect.push({
                                id: item.idNitro4,
                                name: `${item.Nombre}`
                            });
                        });
                    } else {
                        setLoading(false)
                    }
                    setMotivosReasignacion(motivosReasignacionSelect);
                }
            });
            await obtenerListaDeTurnoParaAtender({
                token: TOKEN, params: { lista: turnos_seleccionados, update: 'not', action: 'editar' }, callbacks: async (response) => {
                    setTurnosFinalizar(response.resultados.filter(item => item.estado !== 0))
                    setDatosTurnosSeleccionados(response.resultados);
                }
            });
            setAbrirModalEditarMultiple(true);
            setAbrirModalDerivarMultiple(false);
            setAbrirModalCancelarMultiple(false);
            setLoading(false);
        } else {
            Message({ message: 'Debes seleccionar al menos un turno', type: 'error' })
        }
    }

    const cerrarEditarMultiple = () => {
        setAbrirModalEditarMultiple(false);
    }

    useEffect(() => {
        socket.on("TURNO_ESTADO_CHANGE", function (data) {
            try {
                // const index = turnos.findIndex(item => {
                //     return Number(item.Turno.id) === Number(data.turno_id)
                // });
                // turnos[index]['Turno']['estado'] = data.estado_id;
                // cargarLista();
            } catch (e) {
                // console.error("error:", e);
            }
        });
        setEstadosTurno(ESTADOS_TURNO);

    }, [TOKEN, socket, cargarLista]);

    let result = [];
    let resultTemp = [];
    if (isEmpty(search)) {
        if (isEmpty(filtroEstadoTurno) || filtroEstadoTurno === "Todos") {
            resultTemp = turnos;
        } else {
            resultTemp = turnos.filter(item => item.Turno.estado.toLowerCase().includes(filtroEstadoTurno.toLocaleLowerCase()))
        }
    } else {
        if (isEmpty(filtroEstadoTurno) || filtroEstadoTurno === "Todos") {
            resultTemp = turnos.filter(item => {
                const nombreYApelldo = `${item.Persona.nombre} ${item.Persona.apellido}`;
                const MotivoIDSH = `${item.Turno.motivo.abreviacion}-${item.Turno.id}`;
                const MotivoID = `#${item.Turno.motivo.abreviacion}-${item.Turno.id}`;
                const IDSH = `${item.Turno.id}`;
                const ID = `#${item.Turno.id}`;
                return nombreYApelldo.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    MotivoID.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    MotivoIDSH.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    IDSH.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    ID.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Persona.dni.nro.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Persona.nombre.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Persona.apellido.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Persona.empresa.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Persona.legajo.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Turno.motivo.abreviacion.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Turno.empresa.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Turno.motivo.nombre.toLowerCase().includes(search.toLocaleLowerCase())
            });
        } else {
            resultTemp = turnos.filter(item => {
                const nombreYApelldo = `${item.Persona.nombre} ${item.Persona.apellido}`;
                const MotivoIDSH = `${item.Turno.motivo.abreviacion} ${item.Turno.id}`;
                const MotivoID = `#${item.Turno.motivo.abreviacion} ${item.Turno.id}`;
                const IDSH = `${item.Turno.id}`;
                const ID = `#${item.Turno.id}`;
                return nombreYApelldo.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    MotivoID.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    MotivoIDSH.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    IDSH.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    ID.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Persona.dni.nro.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Persona.nombre.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Persona.apellido.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Persona.empresa.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Persona.legajo.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Turno.motivo.abreviacion.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Turno.empresa.toLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.Turno.motivo.nombre.toLowerCase().includes(search.toLocaleLowerCase())
            });
        }
    }
    let lastPage = parseInt(Math.ceil(resultTemp.length / turnosPorPagina));
    if (ultimaPagina !== lastPage)
        setUltimaPagina(lastPage);
    result = paginate(resultTemp, turnosPorPagina, paginaActual);






    const [motivos_derivacion, setMotivosDerivacion] = useState([]);
    const [motivos_turno, setMotivosTurno] = useState([]);
    const [motivos_cancelacion, setMotivoCancelacion] = useState([]);
    const [motivos_reasignacion, setMotivosReasignacion] = useState([]);


    const [sucursales, setSucursales] = useState([]);
    const [personas, setPersonas] = useState([]);


    const cargarModalDerivar = async (id) => {
        setLoading(true);
        setIdTurnoSeleccionado(id);
        await listaMotivosDerivacion({
            token: TOKEN, callbacks: function (result) {
                let motivosDerivacionSelect = [];
                if (result.estado > 0) {
                    result.resultados.forEach(item => {
                        motivosDerivacionSelect.push({
                            id: item.idNitro4,
                            name: `${item.Nombre}`
                        });
                    });
                }
                setMotivosDerivacion(motivosDerivacionSelect);
            }
        });

        await getListTurnReasons({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    let motivosTurnoArray = [];
                    response.resultados.forEach(item => {
                        let label = `${item.abbreviation} - ${item.name}`;
                        motivosTurnoArray.push({
                            value: item.id,
                            label: label.toUpperCase(),
                        });
                    });
                    setMotivosTurno(motivosTurnoArray);
                }
            }
        });

        await getListBranchs({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setSucursales(response.resultados);
                }
            }
        });

        await getListPersonsByBranch({
            sucursal_id: sucursalAsignada.branchID, token: TOKEN, callbacks: function (result) {
                let personasSelect = [];
                if (result.estado > 0) {
                    result.resultados.forEach(item => {
                        personasSelect.push({
                            id: item.id,
                            name: `${item.name} ${item.surname} - ${item.email}`
                        });
                    });
                }
                const unique = [...new Map(personasSelect.map((m) => [m.id, m])).values()];
                setPersonas(unique);
            }
        });
        setAbrirModalEditar(false);
        setAbrirModalCancelar(false);

        setAbrirModalDerivar(true);
        setLoading(false);
    }






    const cargarModalEditar = async () => {
        if (datosTurno.estado > 0) {
            setLoading(true);
            await listaMotivosReasignacion({
                token: TOKEN, callbacks: response => {
                    let motivosReasignacionSelect = [];
                    if (response.estado > 0) {
                        response.resultados.forEach(item => {
                            motivosReasignacionSelect.push({
                                id: item.idNitro4,
                                name: `${item.Nombre}`
                            });
                        });
                    } else {
                        setLoading(false)
                    }
                    setMotivosReasignacion(motivosReasignacionSelect);
                }
            });
            setAbrirModalDerivar(false);
            setAbrirModalCancelar(false);
            setAbrirModalEditar(true);
            setLoading(false);
        }
    }



    const cargarModalCancelar = async () => {
        setLoading(true);
        await listaMotivosCancelacion({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setMotivoCancelacion(response.resultados);
                }
            }
        });
        setAbrirModalDerivar(false);
        setAbrirModalEditar(false);
        setAbrirModalCancelar(true);
        setLoading(false);
    }
    const [ver_modal_editar, setAbrirModalEditar] = useState(false);
    const [ver_modal_derivar, setAbrirModalDerivar] = useState(false);
    const [ver_modal_cancelar, setAbrirModalCancelar] = useState(false);

    return (
        <>
            {
                loading_auth ?
                    <Loading /> :
                    (<main>
                        <div className="container-fluid ps-0">
                            <div className="glassDatos">
                                <div className="pb-4 d-flex align-items-center justify-content-between">
                                    <Title title={`Turnos Pendientes para ${sucursalAsignada.branchName} - ${formatarFecha(fecha)}`} class="titlegray" />
                                    <div className="d-flex">
                                        <AgregarTurnoSelectora
                                            setLoading={setLoading}
                                            cargarLista={cargarLista}
                                            TOKEN={TOKEN}
                                        />
                                        <AgregarPresentacionEspontanea
                                            cargarLista={cargarLista}
                                            setLoading={setLoading}
                                            TOKEN={TOKEN}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <FiltrosLista
                                        setLoading={setLoading}
                                        paginaActual={paginaActual}
                                        ultimaPagina={ultimaPagina}
                                        restablecerCheckboxs={restablecerCheckboxs}
                                        setPaginaActual={setPaginaActual}
                                        fecha={fecha}
                                        setFecha={setFecha}
                                        setFiltroEstadoTurno={setFiltroEstadoTurno}
                                        estadosTurno={estadosTurno}
                                        search={search}
                                        setSearch={setSearch}
                                        cargarLista={cargarLista}
                                        TOKEN={TOKEN}
                                    />
                                </div>
                                <div className='col-12'>
                                    <div className="col-12 my-5">
                                        <div id="DatosPersonaTurno">
                                            <DatosPersonaTurno
                                                id={idTurnoSeleccionado}
                                                loader={loader}
                                                setLoader={setLoader}
                                                setLoading={setLoading}
                                                obtenerDatosTurno={obtenerDatosTurno}

                                                setIdTurnoSeleccionado={setIdTurnoSeleccionado}

                                                datosTurno={datosTurno}

                                                sucursal={sucursalAsignada}
                                                ver_opciones={ver_opciones}
                                                closeModalPersona={closeContainerDatosPersona}
                                                volverAtras={volverEstadoEnAtencion}
                                                cargarLista={cargarLista}


                                                cargarModalDerivar={cargarModalDerivar}
                                                cargarModalEditar={cargarModalEditar}
                                                cargarModalCancelar={cargarModalCancelar}

                                                restablecerCheckboxs={restablecerCheckboxs}

                                                TOKEN={TOKEN}

                                                permitir_finalizar={permitir_finalizar}
                                                setPermitirFinalizar={setPermitirFinalizar}
                                            />
                                        </div>
                                        <div id="AtencionMultiple">
                                            <AtencionMultiple
                                                datosTurnosSeleccionados={datosTurnosSeleccionados}
                                                turnos_seleccionados={turnos_seleccionados}
                                                turnos_finalizar={turnos_finalizar}

                                                restablecerCheckboxs={restablecerCheckboxs}

                                                abrir_atencion_multiple={abrir_atencion_multiple}
                                                setLoaderAtencionMultiple={setLoaderAtencionMultiple}
                                                loader_atencion_multiple={loader_atencion_multiple}

                                                sucursal={sucursalAsignada}
                                                closeModal={cerrarAtencionMultiple}
                                                obtenerDatosTurno={obtenerDatosTurno}
                                                cargarLista={cargarLista}
                                                setLoader={setLoader}

                                                TOKEN={TOKEN}
                                            />
                                        </div>
                                        <ListadoTurnos
                                            lista={result}
                                            setLoading={setLoading}
                                            setIdTurnoSeleccionado={setIdTurnoSeleccionado}
                                            obtenerDatosTurno={obtenerDatosTurno}
                                            openContainerDatosPersona={openContainerDatosPersona}
                                            closeContainerDatosPersona={closeContainerDatosPersona}
                                            setVerOpciones={setVerOpciones}
                                            cargarLista={cargarLista}

                                            abrirAtencionMultiple={abrirAtencionMultiple}
                                            abrirCancelarMultiple={abrirCancelarMultiple}
                                            abrirDerivarMultiple={abrirDerivarMultiple}
                                            abrirEditarMultiple={abrirEditarMultiple}

                                            turnos_seleccionados={turnos_seleccionados}
                                            setTurnosSeleccionados={setTurnosSeleccionados}

                                            cargarModalDerivar={cargarModalDerivar}
                                            cargarModalEditar={cargarModalEditar}
                                            cargarModalCancelar={cargarModalCancelar}
                                            isAllChecked={isAllChecked}
                                            setIsAllChecked={setIsAllChecked}
                                            restablecerCheckboxs={restablecerCheckboxs}

                                            TOKEN={TOKEN}
                                        />
                                    </div>
                                </div>
                                {
                                    loading &&
                                    <div className="containerLoader">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Cargando...</span>
                                        </Spinner>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            !isEmpty(idTurnoSeleccionado) &&
                            <>
                                <ModalCancelarTurno
                                    setLoading={setLoading}
                                    ver_modal_cancelar={ver_modal_cancelar}
                                    setAbrirModalCancelar={setAbrirModalCancelar}
                                    motivos_cancelacion={motivos_cancelacion}
                                    turno_id={idTurnoSeleccionado}
                                    datosTurno={datosTurno}
                                    volverAtras={volverEstadoEnAtencion}
                                    cargarLista={cargarLista}
                                    closeModalPersona={closeContainerDatosPersona}
                                    restablecerCheckboxs={restablecerCheckboxs}
                                    TOKEN={TOKEN}
                                />
                                <ModalEditarTurno
                                    setLoading={setLoading}
                                    ver_modal_editar={ver_modal_editar}
                                    setAbrirModalEditar={setAbrirModalEditar}
                                    motivosReasignacion={motivos_reasignacion}
                                    turno_id={idTurnoSeleccionado}
                                    datosTurno={datosTurno}
                                    volverAtras={volverEstadoEnAtencion}
                                    cargarLista={cargarLista}
                                    closeModalPersona={closeContainerDatosPersona}
                                    restablecerCheckboxs={restablecerCheckboxs}
                                    TOKEN={TOKEN}
                                />
                                <ModalDerivacionTurno
                                    setLoading={setLoading}
                                    ver_modal_derivar={ver_modal_derivar}
                                    setAbrirModalDerivar={setAbrirModalDerivar}
                                    motivos_derivacion={motivos_derivacion}
                                    motivos_turno={motivos_turno}
                                    sucursales={sucursales}
                                    personas={personas}
                                    sucursal={sucursalAsignada}

                                    turno_id={idTurnoSeleccionado}
                                    datosTurno={datosTurno}
                                    volverAtras={volverEstadoEnAtencion}
                                    cargarLista={cargarLista}
                                    closeModalPersona={closeContainerDatosPersona}
                                    restablecerCheckboxs={restablecerCheckboxs}
                                    TOKEN={TOKEN}
                                />
                            </>
                        }
                        {
                            turnos_seleccionados.length > 0 &&
                            <>
                                <ModalCancelarMultiple
                                    setLoading={setLoading}
                                    ver_modal_cancelar={ver_modal_cancelar_multiple}
                                    motivos_cancelacion={motivos_cancelacion}

                                    turnos_seleccionados={turnos_seleccionados}
                                    datosTurnosSeleccionados={datosTurnosSeleccionados}
                                    turnos_finalizar={turnos_finalizar}
                                    cargarLista={cargarLista}
                                    closeModal={cerrarCancelarMultiple}

                                    restablecerCheckboxs={restablecerCheckboxs}
                                    TOKEN={TOKEN}
                                />
                                <ModalEditarMultiple
                                    setLoading={setLoading}
                                    ver_modal_editar={ver_modal_editar_multiple}
                                    setAbrirModalEditar={setAbrirModalEditarMultiple}
                                    motivosReasignacion={motivos_reasignacion}

                                    turnos_seleccionados={turnos_seleccionados}
                                    datosTurnosSeleccionados={datosTurnosSeleccionados}
                                    turnos_finalizar={turnos_finalizar}
                                    cargarLista={cargarLista}
                                    closeModal={cerrarEditarMultiple}

                                    restablecerCheckboxs={restablecerCheckboxs}
                                    TOKEN={TOKEN}
                                />
                                <ModalDerivacionMultiple
                                    setLoading={setLoading}
                                    ver_modal_derivar={ver_modal_derivar_multiple}
                                    setAbrirModalDerivar={setAbrirModalDerivarMultiple}
                                    motivos_derivacion={motivos_derivacion}
                                    motivos_turno={motivos_turno}
                                    sucursales={sucursales}
                                    personas={personas}
                                    sucursal={sucursalAsignada}

                                    turnos_seleccionados={turnos_seleccionados}
                                    datosTurnosSeleccionados={datosTurnosSeleccionados}
                                    turnos_finalizar={turnos_finalizar}
                                    cargarLista={cargarLista}
                                    closeModal={cerrarDerivarMultiple}

                                    restablecerCheckboxs={restablecerCheckboxs}
                                    TOKEN={TOKEN}
                                />
                            </>
                        }


                    </main>
                    )
            }
        </>
    )
}