import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import addPerson from '../../../services/adp/person/add';



import Button from '../../Button';
import Message from '../../../utils/message';
import Select from '../../Select/select';
import { MAIN_URL } from '../../../utils/config';
import updatePerson from '../../../services/adp/person/update_person';
import useUnload from '../../../hooks/beforUnload';


function FormAgregarPersona(props) {
    const { document_type_list, profile_type_list, TOKEN, TYPE_PERSON, ID_USER, id } = props;
    const [validated, setValidated] = useState(false);

    const [formValues, setValues] = useState({
        document_number: "",
        type_profile: "",
        email: "",
        name: "",
        surname: "",
        document_type: "",
        previous_profile: TYPE_PERSON ?? "",
        user_id: ID_USER ?? "",
        idNitro4: id ?? ""
    });

    useEffect(() => {
        setValues({
            document_number: props.dni ?? "",
            type_profile: props.TYPE_PERSON ?? "",
            email: props.email ?? "",
            name: props.name ?? "",
            surname: props.surname ?? "",
            document_type: props.document_type ?? "",
            previous_profile: TYPE_PERSON ?? "",
            user_id: ID_USER ?? "",
            idNitro4: id ?? ""
        })
    }, [props, TYPE_PERSON, ID_USER, id]);



    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    const handleOnCancel = () => {
        document.getElementById("agregarPersonaForm").reset()
        setValues({
            document_number: "",
            type_profile: "",
            email: "",
            name: "",
            surname: "",
            document_type: "",
        });

        if (TYPE_PERSON === 'NOT_REGISTRY') {
            sessionStorage.clear()
            document.location.href = `${MAIN_URL}/`
        }

        document.location.href = `${MAIN_URL}/lista-personas`

    }

    useUnload(e => {
        e.preventDefault();
        handleOnCancel()
    });


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            const action = form.getAttribute("actionsubmit");
            if (action === 'add') {
                addPerson({
                    params: formValues, token: TOKEN, callbacks: response => {
                        if (response.estado > 0) {
                            form.reset();
                            setValues({
                                document_number: "",
                                type_profile: "",
                                email: "",
                                name: "",
                                surname: "",
                                document_type: "",
                            });
                            Message({ message: response.mensaje, type: 'success' })
                            // document.location.href = `${MAIN_URL}/`
                            document.location.href = `${MAIN_URL}/lista-personas`
                        } else {
                            Message({ message: response.mensaje, type: 'error' })
                        }
                    }
                });
            }
            if (action === 'edit') {
                updatePerson({
                    params: formValues, token: TOKEN, callbacks: response => {
                        if (response.estado > 0) {
                            form.reset();
                            setValues({
                                document_number: "",
                                type_profile: "",
                                email: "",
                                name: "",
                                surname: "",
                                document_type: "",
                            });
                            Message({ message: response.mensaje, type: 'success' })
                            document.location.href = `${MAIN_URL}/lista-personas`
                        } else {
                            Message({ message: response.mensaje, type: 'error' })
                        }
                    }
                });
            }

        }
    };

    return (
        <Form noValidate validated={validated} actionsubmit={(props.update && props.update === 'yes') ? 'edit' : 'add'} onSubmit={handleFormSubmit} id='agregarPersonaForm'>
            <div className="row my-3">
                <div className="col-md-6">
                    <div className="form-group my-3">
                        <label htmlFor="name" className='m-0 parrafoGray'>Nombre</label>
                        <Form.Control
                            required
                            type="text"
                            name="name"
                            id="name"
                            className='input-gg'
                            defaultValue={props.name ?? ''}
                            placeholder="Ingresar nombre"
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group my-3">
                        <label htmlFor="surname" className='m-0 parrafoGray'>Apellido</label>
                        <Form.Control
                            required
                            type="text"
                            name="surname"
                            className='input-gg'
                            id="surname"
                            defaultValue={props.surname ?? ''}
                            placeholder="Ingresar apellido"
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group my-3">
                        <label htmlFor="email" className='m-0 parrafoGray'>Email</label>
                        <Form.Control
                            required
                            type="email"
                            name="email"
                            className='input-gg'
                            id="email"
                            defaultValue={props.email ?? ''}
                            placeholder="Ingresar email"
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group my-3">
                        <label htmlFor="document_type" className='m-0 parrafoGray'>Tipo de documento</label>
                        <Select
                            required
                            value={document_type_list}
                            name="document_type"
                            id="document_type"
                            placeholder="Seleccione tipo de documento"
                            className='input-gg'
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group my-3">
                        <label htmlFor="document_number" className='m-0 parrafoGray'>Numero de documento</label>
                        <Form.Control
                            required
                            type="text"
                            name="document_number"
                            id="document_number"
                            className='input-gg'
                            defaultValue={props.dni ?? ''}
                            placeholder="Ingresar DNI"
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group my-3">
                        <label htmlFor="type_profile" className='m-0 parrafoGray'>Perfil</label>
                        <Select
                            required
                            value={profile_type_list}
                            name="type_profile"
                            id="type_profile"
                            placeholder="Seleccione el perfil"
                            defaultValue={props.TYPE_PERSON ?? 'Seleccione el perfil'}
                            className='input-gg'
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                </div>
            </div>
            <div className='mt-5 d-flex justify-content-center'>
                {
                    (props.update && props.update === 'yes') ?
                        <Button type="submit" className="btn-orange d-block mx-5" text="Editar" />
                        :
                        <Button type="submit" className="btn-greengg d-block mx-5" text="Agregar" />
                }
                <button type="button" className="btn-dangergg d-block mx-5" text="Cancelar" onClick={handleOnCancel}> Cancelar </button>
            </div>
        </Form>
    );
}

export default FormAgregarPersona;