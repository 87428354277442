import React from "react";
import { NavLink } from 'react-router-dom';
import Button from "../../components/Button";
import Title from "../../components/Title";
import './_SplashConfirm.scss'

function SplashConfirm() {
    return (
        <main className="container__main__splashConfirm">
            <div className="glassDatos container__splashConfirm text-center">
                <div>
                    <Title title="Su turno ha sido confirmado" class="titlegraySplash fw-500"></Title>
                </div>

                <div className="my-5">
                    <h1 className="titlegraySplash">Muchas gracias por elegirnos!</h1>
                </div>

                <div className="my-5">
                    <h3 className="parrafoGray fw-500">Ante cualquier duda o consulta no dude en escribirnos</h3>
                </div>

                <div className="my-5">
                    <p className="parrafoGray fw-400">atencionalcliente@grupo-gestion.com.ar</p>
                </div>
                <div className="my-5 d-flex justify-content-center">
                    <NavLink to="/mis-turnos"><Button className="btn-orange" text="Volver"/></NavLink>
                </div>
            </div>
        </main>
    );
}

export default SplashConfirm;