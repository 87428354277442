import React, { useState } from 'react';
import { Container, Row, Form, Col } from 'react-bootstrap';
import Title from '../../components/Title';
import LogoGG from '../../images/logo-corporativo.svg'
import './_Login.scss'
import { useAuth } from '../../hooks/auth';
import { Navigate, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { isEmpty } from '../../utils/functions';
import { useUser } from '../../hooks/user';
import getListDocumentTypes from '../../services/adp/document_type/list';
import getListProfiles from '../../services/adp/profile/list';
import PERSON_TYPE from "../../config/person_type";
import Select from '../../components/Select/select';
import validateToken from '../../services/auth/validate-token';
// import setDefaultProfile from "../../services/adp/profile/setDefault";
import signupService from '../../services/auth/signup';
import Message from '../../utils/message';

const FormLogin = ({ email, setEmail, password, setPassword, provider, setCompleteSignup, setDniType, setProfileType, setToken_aux }) => {
    const { login, setToken, removeAll, setMessageError, setIsLogin, setLoading } = useAuth();
    const { saveUser, setUser } = useUser();
    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        let params = {
            provider,
            email,
            password
        };
        const result = await login(params);
        if (result.estado > 0) {
            const TOKEN = result.token;
            if (result.es_correcto) {
                await validateToken({
                    token: TOKEN, callbacks: async (response) => {
                        if (response.estado && response.resultados.length > 0) {
                            const userData = response.resultados[0];
                            if (PERSON_TYPE[userData.person_type]) {
                                // document.querySelector('.btn-greengg').dataset.disabled = 'on';
                                setToken(TOKEN);
                                localStorage.setItem('auth_token', TOKEN);
                                saveUser(userData);
                                setIsLogin(true);
                                // document.location.href = `${PERSON_TYPE[userData.person_type].redireccion_login}`;
                                // document.location.href = '/';
                            } else {
                                // ToastMesage({ message: 'No tienes permiso para acceder', type: 'error' })
                                setMessageError('No tienes permiso para acceder');
                                setIsLogin(false);
                                setToken(null);
                                setLoading(false);
                            }
                            // } else if (result.provider === 'ktna' && TOKEN) {
                            //     // document.querySelector('.btn-greengg').dataset.disabled = 'on';
                            //     await setDefaultProfile({
                            //         token: TOKEN, params: result.userId, callbacks: async (response) => {
                            //             if (response.estado > 0) {
                            //                 setToken(TOKEN);
                            //                 localStorage.setItem('auth_token', TOKEN);
                            //                 saveUser({ name: response.userData.name, surname: '', person_type: 'NOT_REGISTRY', user_id: response.userData.id });
                            //                 setIsLogin(true);
                            //                 // document.location.href = `${PERSON_TYPE['NOT_REGISTRY_PERSON'].redireccion_login}`;
                            //                 // document.location.href = '/';
                            //             } else {
                            //                 setMessageError('Algo salio mal, por favor vuelva a intentarlo');
                            //                 setIsLogin(false);
                            //                 setToken(null);
                            //                 setLoading(false);
                            //             }
                            //         }
                            //     })
                            // } else {
                            //     // ToastMesage({ message: response.mensaje ?? 'Ocurrio un error al ingresar', type: 'error' })
                            //     setIsLogin(false);
                            //     setToken(null);
                            //     setLoading(false);
                            // }
                        }
                        else {
                            // ToastMesage({ message: response.mensaje ?? 'Ocurrio un error al ingresar', type: 'error' })
                            setMessageError(response.mensaje ?? 'Algo salio mal, por favor vuelva a intentarlo');
                            setIsLogin(false);
                            setToken(null);
                            setLoading(false);
                        }
                    }
                });
            } else {
                await getListDocumentTypes({
                    token: TOKEN, callbacks: async (response) => {
                        setDniType(response.resultados ?? []);
                        await getListProfiles({
                            token: TOKEN, callbacks: response => {
                                if (response.estado > 0 && response.resultados.length > 0) {
                                    let listita = response.resultados.filter(item => PERSON_TYPE[item.name].it_is_allowed_to_add);
                                    setProfileType(listita.filter(item => item.id !== 1));
                                }
                                setToken_aux(TOKEN);
                                setMessageError('Completa el siguiente formulario para poder acceder');
                                setCompleteSignup(true)
                                setUser({ id: result.userId, name: result.userFullName });
                                setIsLogin(false);
                                setLoading(false);
                            }
                        });
                    }
                });
            }
        } else {
            setMessageError(result.mensaje ?? 'Ocurrio un error al ingresar login');
            setIsLogin(false);
            setToken(null);
            setLoading(false);
            removeAll();
        }
    }


    function olvideMiPassword(e) {
        e.preventDefault();
        if (provider === 'local') {
            navigate("/restablecer-password");
        } else {
            Message({ message: 'Al ser un usuario de KTNA deberas ponerte en contacto con el administrador para restablecer tu password', type: 'error' });
        }
    }
    const viewPasswordInput = (e) => {
        const input = document.querySelector('.inputTypePassword');
        const { target: eye } = e;
        console.log(input)
        // const eye = document.querySelector('.ojito');
        if (input.type === "text") {
            input.type = 'password';
            eye.classList.add('fa-eye-slash');
            eye.classList.remove('fa-eye');
            return;
        }
        input.type = 'text';
        eye.classList.remove('fa-eye-slash');
        eye.classList.add('fa-eye');
    }

    return (
        <Form onSubmit={handleSubmit}>
            <div className='containerEmail-Password'>
                <Form.Group as={Row} className="mb-5" controlId="formHorizontalEmail">
                    <Form.Label className='textLabel'>
                        E-mail:
                    </Form.Label>
                    <Col>
                        <input className='inputCustom px-3 py-2' type="email" placeholder="Ingresar E-mail" defaultValue={email} value={email} onChange={e => setEmail(e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalPassword">
                    <Form.Label className='textLabel'>
                        Contraseña:
                    </Form.Label>
                    <Col className='relativePassword'>
                        <input className='inputCustom inputTypePassword px-3 py-2' id='Password1' type="password" placeholder="Ingresar Contraseña" defaultValue={password} value={password} onChange={e => setPassword(e.target.value)} />
                        <div className='viewPassword' input-id='Password1' onClick={e => viewPasswordInput(e)}><i className="ojito fas fa-eye-slash"></i></div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalForgotPassword" className='my-3'>
                    <button type="button" className="btn link" onClick={olvideMiPassword}>Olvide mi password</button>
                </Form.Group>
            </div>

            <Form.Group as={Row}>
                <Col className='d-flex justify-content-center my-5'>
                    <button type="submit" className="btn-greengg" data-disabled="off">Ingresar</button>
                </Col>
            </Form.Group>
        </Form>
    );
}


const FormSignup = ({ documentTypeList, profilesTypeList, setCompleteSignup, token_aux, provider }) => {
    const { user, setUser } = useUser();
    const { setMessageError, setLoading } = useAuth();
    const [formValues, setValues] = useState({
        provider: provider,
        document_number: "",
        type_profile: "",
        document_type: "",
        user_id: user.id
    });

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        const response = await signupService({ token: token_aux, params: formValues });
        if (response.estado > 0) {
            setLoading(false);
            setUser(null);
            setMessageError('');
            setCompleteSignup(false)
        } else {
            setLoading(false);
            setMessageError(response.mensaje);
        }
    }


    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    return (
        <Form onSubmit={handleSubmit}>
            <div className='containerEmail-Password'>
                <div className="row my-3">
                    <div className="col-md-6">
                        <div className="form-group my-3">
                            <label htmlFor="document_type" className='m-0 parrafoGray'>Tipo de documento</label>
                            <Select
                                required
                                value={documentTypeList}
                                name="document_type"
                                id="document_type"
                                placeholder="Seleccione tipo de documento"
                                className='input-gg'
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group my-3">
                            <label htmlFor="document_number" className='m-0 parrafoGray'>Numero de documento</label>
                            <Form.Control
                                required
                                type="text"
                                name="document_number"
                                id="document_number"
                                className='input-gg'
                                placeholder="Ingresar DNI"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group my-3">
                            <label htmlFor="type_profile" className='m-0 parrafoGray'>Perfil</label>
                            <Select
                                required
                                value={profilesTypeList}
                                name="type_profile"
                                id="type_profile"
                                placeholder="Seleccione el perfil"
                                className='input-gg'
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>
                </div>
            </div>

            <Form.Group as={Row}>
                <Col className='d-flex justify-content-center my-5'>
                    <button type="submit" className="btn-greengg" data-disabled="off">Finalizar</button>
                </Col>
                <Col className='d-flex justify-content-center my-5'>
                    <button type="button" className="btn-orange" data-disabled="off" onClick={() => {
                        setUser(null);
                        setMessageError('');
                        setCompleteSignup(false)
                    }}
                    >Volver</button>
                </Col>
            </Form.Group>
        </Form>
    );
}

export default function Login() {
    const { isLogin, loading, messageError } = useAuth();
    const { user } = useUser();
    // const [provider, setProvider] = useState('local');
    const provider = 'local';
    const [complete_signup, setCompleteSignup] = useState(false);
    const [documentTypeList, setDniType] = useState([]);
    const [profilesTypeList, setProfileType] = useState([]);
    const [token_aux, setToken_aux] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    if (loading) {
        return <Loading />
    }
    if (isLogin) {
        return <Navigate to='/' />
    }

    // async function changeTabs(e) {
    //     const { target } = e;
    //     const providerkey = target.getAttribute('providerkey');
    //     setEmail('');
    //     setPassword('');
    //     setProvider(providerkey);
    // }
    return (
        <Container className='containerLogin'>
            <div className='col-12 containerGlassLogin'>
                <div className="col-6">
                    <img src={LogoGG} alt="logo grupo gestion" className='img-fluid resetWidth' />
                </div>
                <div className="col-6 containerPrintDatosLogin">
                    {complete_signup ?
                        <>
                            <div className='py-5 d-flex justify-content-center'>
                                <Title className='titleLogin w-100 text-center m-0' title={`Bienvenido ${user.name}`} />
                            </div>
                            {!isEmpty(messageError) &&
                                <div className='alert alert-danger'>{messageError}</div>
                            }
                            <FormSignup
                                documentTypeList={documentTypeList}
                                profilesTypeList={profilesTypeList}
                                setCompleteSignup={setCompleteSignup}
                                token_aux={token_aux}
                                setToken_aux={setToken_aux}
                                provider={provider}
                            />
                        </>
                        :
                        <>
                            <div className='py-5 d-flex justify-content-center'>
                                <Title className='titleLogin w-100 text-center m-0' title="Ingresar" />
                            </div>
                            {!isEmpty(messageError) &&
                                <div className='alert alert-danger'>{messageError}</div>
                            }
                            {/* <ul className="mb-3 nav nav-tabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button type="button" id="controlled-local" role="tab" providerkey="local" onClick={changeTabs} aria-selected={provider === 'local' ? 'true' : 'false'} className={`nav-link ${provider === "local" ? "active" : ""}`}>CDT</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" id="controlled-ktna" role="tab" providerkey="ktna" onClick={changeTabs} aria-selected={provider === 'ktna' ? 'true' : 'false'} className={`nav-link ${provider === "ktna" ? "active" : ""}`}>KTNA</button>
                                </li>
                            </ul> */}
                            <FormLogin
                                provider={provider}
                                setCompleteSignup={setCompleteSignup}
                                setDniType={setDniType}
                                setProfileType={setProfileType}
                                token_aux={token_aux}
                                setToken_aux={setToken_aux}
                                email={email}
                                password={password}
                                setEmail={setEmail}
                                setPassword={setPassword}
                            />
                        </>
                    }
                </div>
            </div>
        </Container>
    )
}