import { connection } from "../../utils/connection";
import { AUTH_URL } from "../../config/config";


const cambiarPassword = async ({ params, token, callbacks }) => {
    const response = await connection({ method: 'POST', url: `${AUTH_URL.CHANGE_PASSWORD}`, extraHeaders: { token: token }, params: params });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default cambiarPassword;