import { connection } from "../../utils/connection";
import { AUTH_URL } from "../../config/config";


const activateAccount = async ({ params, callbacks }) => {
    const response = await connection({ method: 'POST', url: `${AUTH_URL.ACTIVATE_ACCOUNT}`, params: params });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default activateAccount;