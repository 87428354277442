import { Form } from "react-bootstrap";
import HORARIOS from "../../config/horarios";
import comprobarFeriado from "../../services/adp/feriados/get";
import { addDisabled, comprobarFechaMayor, formatarFecha, isEmpty, removeDisabled } from "../../utils/functions";
import ToastMesage from "../../utils/toast";

export function Calendario(props) {
    const { setDateSelected, horary_select, TOKEN, horarios_disponibles, setHorariosDisponibles } = props;

    const DATE_ACTUALLY = new Date();
    const MAX_DATE = new Date();
    MAX_DATE.setMonth(DATE_ACTUALLY.getMonth() + 13)



    async function handleDatePickerChange(evt) {
        const { target } = evt;
        const { value } = target;
        addDisabled({ element: horary_select });
        target.classList.remove('is-valid');
        target.classList.remove('is-invalid');
        setHorariosDisponibles(HORARIOS);
        if (!isEmpty(value)) {
            const is_week = new Date(value).getUTCDay();
            if ([6, 0].includes(is_week)) {
                ToastMesage({ message: `El ${formatarFecha(value)} es ${is_week === 6 ? 'Sabado' : 'Domingo'}`, type: 'error' });
                addDisabled({ element: horary_select });
                target.value = null;
                target.classList.remove('is-valid');
                target.classList.add('is-invalid');
                setDateSelected(null);
                setHorariosDisponibles(HORARIOS);
            } else {
                comprobarFeriado({
                    feriado: value, token: TOKEN, callbacks: response => {
                        if (response.estado > 0) {
                            if (response.resultados.length > 0) {
                                const feriado = response.resultados[0];
                                ToastMesage({ message: `El ${formatarFecha(value)} feriado por motivos ${feriado.motivo_feriado}`, type: 'error' });
                                addDisabled({ element: horary_select });
                                target.value = null;
                                target.classList.remove('is-valid');
                                target.classList.add('is-invalid');
                                setDateSelected(null);
                                setHorariosDisponibles(HORARIOS);
                            } else {
                                comprobarFechaMayor({ valor: value, horarios: horarios_disponibles, setValue: setHorariosDisponibles });
                                removeDisabled({ element: horary_select });
                                setDateSelected(value);
                                target.classList.remove('is-invalid');
                                target.classList.add('is-valid');
                            }
                        } else {
                            ToastMesage({ message: `No se pudo verificar si ${formatarFecha(value)} es feriado`, type: 'error' });
                            addDisabled({ element: horary_select });
                            target.value = null;
                            target.classList.remove('is-valid');
                            target.classList.add('is-invalid');
                            setDateSelected(null);
                            setHorariosDisponibles(HORARIOS);

                        }
                    }
                })
            }
        } else {
            addDisabled({ element: horary_select });
        }
    }

    return (
        <Form.Control
            required
            name="date"
            id="date"
            type="date"
            min={DATE_ACTUALLY.toISOString().split("T")[0]}
            max={MAX_DATE.toISOString().split("T")[0]}
            maxLength="6"
            defaultValue={props.date ?? ''}
            onChange={handleDatePickerChange}
            placeholder="Seleccionar fecha"
            className='my-2'
        />
    );
}