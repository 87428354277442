import React, { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from "../../../components/Select/select";
import cancelTurn from "../../../services/adp/turn/cancel";
import { isEmpty, mostrarOtros, } from "../../../utils/functions";
import Message from "../../../utils/message";
import TURN_STATE_OBJECT from "../../../utils/turn_state";
import { SERVER_URL } from "../../../utils/config";
import Swal from "sweetalert2";


function FormCancelarTurnoADP(props) {
    const { id, setLoading, datosTurno, cerrar_modal, closeModalPersona, cargarLista, TOKEN, motivos_cancelacion, restablecerCheckboxs } = props;
    const [messageUpload, setMessageUpload] = useState('');
    const [messageUploadType, setMessageUploadType] = useState('');
    const [validated, setValidated] = useState(false);
    const [formValues, setValues] = useState({
        observaciones: '',
        motivo_cancelacion: '',
    });
    const id_estado = TURN_STATE_OBJECT[datosTurno.estado_turno].id;

    const fileDefault = {
        filename: ''
    };
    const [file, setFile] = useState(fileDefault);

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            // if (isEmpty(file.filename)) {
            //     Swal.fire({
            //         title: 'No agregaste un archivo o foto a la cancelacion del turno',
            //         showDenyButton: true,
            //         showCancelButton: false,
            //         icon: 'info',
            //         confirmButtonColor: "var(--color-orange)",
            //         confirmButtonText: 'Aceptar',
            //         denyButtonColor: "var(--color-danger)",
            //         denyButtonText: 'Cancelar',
            //     }).then((result) => {
            //         if (result.isConfirmed) {
            setLoading(true);
            const newValue = {
                ...formValues,
                id_estado: id_estado,
                file: file
            }
            cancelTurn({
                id: id, params: newValue, token: TOKEN, callbacks: async (response) => {
                    if (response.estado > 0) {
                        restablecerCheckboxs();
                        cerrar_modal();
                        closeModalPersona();
                        cargarLista();
                        setLoading(false);
                    } else {
                        Message({ message: response.mensaje, type: 'error' })
                        setLoading(false);
                    }
                }
            });
            //         } else {
            //             setLoading(false);
            //             setValidated(false);
            //             event.stopPropagation();
            //         }
            //     });
            // } else {
            //     setLoading(true);
            //     const newValue = {
            //         ...formValues,
            //         id_estado: id_estado,
            //         file: file
            //     }
            //     cancelTurn({
            //         id: id, params: newValue, token: TOKEN, callbacks: async (response) => {
            //             if (response.estado > 0) {
            //                 restablecerCheckboxs();
            //                 cerrar_modal();
            //                 closeModalPersona();
            //                 cargarLista();
            //                 setLoading(false);
            //             } else {
            //                 Message({ message: response.mensaje, type: 'error' })
            //                 setLoading(false);
            //             }
            //         }
            //     });
            // }

        }
    };


    const cambiarMotivoDeCancelacion = async (event) => {
        handleInputChange(event);
        const { target } = event;
        mostrarOtros(target, document.getElementById('motivo_cancelacion_otros'));
    }

    async function subirImagen(event) {
        if (event.target.files) {
            const files = event.target.files
            if (event.target.files.length > 0) {
                let file = files[0];
                const ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
                if (ext === "gif" || ext === "png" || ext === "jpeg" || ext === "jpg" || ext === "pdf") {
                    setLoading(true);
                    let formdata = new FormData();
                    formdata.append("file", file, file.name);
                    let myHeaders = new Headers();
                    myHeaders.append("token", TOKEN);
                    const requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };
                    fetch(`${SERVER_URL}/transactional/api/files/upload`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.filename) {
                                setFile(result);
                                setMessageUpload('El archivo se guardo correctamente');
                                setMessageUploadType('success');
                            } else {
                                setMessageUpload('Ocurrio un error al intentar subir el archivo');
                                setMessageUploadType('danger');
                                setFile(fileDefault);
                            }
                            setLoading(false);
                        })
                        .catch(error => {
                            setMessageUpload(error);
                            setMessageUploadType('danger');
                            setLoading(false);
                            setFile(fileDefault);
                        });
                } else {
                    setMessageUpload(`La extension ${ext} no esta permitida`);
                    setMessageUploadType('danger');
                    setFile(fileDefault);
                }
            } else {
                setMessageUpload('Selecciona al menos un archivo');
                setMessageUploadType('danger');
                setFile(fileDefault);
            }
        } else {
            setMessageUpload('Selecciona al menos un archivo');
            setMessageUploadType('danger');
            setFile(fileDefault);
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
            <div className="row my-3 px-5">
                <div className="col-md-6">
                    <div className="form-group">
                        <Form.Label htmlFor="motivo_cancelacion">Motivo de cancelacion</Form.Label>
                        <Select
                            value={motivos_cancelacion}
                            name="motivo_cancelacion"
                            id="motivo_cancelacion"
                            placeholder="Seleccione el motivo por el que cancela el turno"
                            onChange={cambiarMotivoDeCancelacion}
                            other="yes"
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                    <div className="form-group d-none" id="motivo_cancelacion_otros">
                        <Form.Label>Motivo de cancelacion</Form.Label>
                        <Form.Control
                            name="motivo_cancelacion_otros"
                            id="motivo_cancelacion_otros"
                            type="text"
                            onChange={handleInputChange}
                            placeholder="Indica el motivo de cancelacion"
                        />
                    </div>
                </div>
                {/* <div className="col-md-6">
                    <Form.Group controlId="fileDocsCancelarTurno">
                        <Form.Label>Adjuntar documentos</Form.Label>
                        <Form.Control type="file" size="md" className="my-1" accept="image/*, application/pdf" onChange={subirImagen} />
                    </Form.Group>
                    {!isEmpty(messageUpload) &&
                        <div className={`alert alert-${messageUploadType} my-5`}>
                            {messageUpload}
                        </div>
                    }
                </div> */}
                <div className="col-md-12 my-3">
                    <div className="form-group">
                        <Form.Label>Observaciones</Form.Label>
                        <FloatingLabel
                            controlId="observaciones"
                            label="Observaciones"
                            className="mb-3"
                        >
                            <Form.Control required as="textarea" placeholder="Indique observaciones" className="customArea" name="observaciones" onChange={handleInputChange}
                                style={{ height: '200px' }} />
                        </FloatingLabel>
                    </div>
                </div>
            </div>
            <div className="my-5 d-flex justify-content-center">
                <button type="submit" className="btn-orange">Confirmar</button>
            </div>
        </Form>
    );
}

export default FormCancelarTurnoADP;