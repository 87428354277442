import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";


const listaTurnos = async ({ branch_id, token, callbacks, fecha }) => {
    const response = await connection({ method: 'GET', url: `${ENTITY_URL.ADP.TURNOS.TURNOS_LISTA}${branch_id}?fecha=${fecha}`, extraHeaders: { token: token } });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default listaTurnos;