import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import NuevaEncuesta from '../../../services/admin/nueva-encuesta';
import Message from '../../../utils/message';
import { useAuth } from '../../../hooks/auth';


export default function FormAgregarPregunta() {
    const { token: TOKEN } = useAuth();
    const [preguntas, setPreguntas] = useState("");
    const [respuestas, setRespuestas] = useState([]);
    const [validated, setValidated] = useState(false);
    const [campos, setCampos] = useState([]);
    const [formulario, setFormulario] = useState({
        nombre: '',
        descripcion: ''
    });

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setFormulario({
            ...formulario,
            [name]: value,
        });
    }


    const guardarPregunta = () => {
        if (preguntas !== "") {
            if (respuestas.length > 0) {
                for (let respuesta of respuestas) {
                    if (respuesta === '' || respuesta === null || respuesta === undefined) {
                        Message({ message: 'Por favor verifique sus respuestas', type: 'warning' })
                        return;
                    }
                }
                const data = {
                    nombre: preguntas,
                    valores: JSON.stringify(respuestas)
                }
                campos.push(data);
                setCampos(campos);
                setPreguntas("");
                setRespuestas([]);
            }
        } else {
            Message({ message: 'Por favor ingrese una pregunta', type: 'warning' })
        }
    }

    const imprimirRespuesta = () => {
        setRespuestas([...respuestas, []]);
    }

    const asignarValorRespuesta = (changeValue, i) => {
        const inputData = [...respuestas];
        inputData[i] = changeValue.target.value;
        setRespuestas(inputData)
    }

    const eliminarRespuesta = (i) => {
        const deleteVal = [...respuestas];
        deleteVal.splice(i, 1);
        setRespuestas(deleteVal);
    }

    const eliminarPregunta = (i) => {
        const deleteVal = [...campos];
        deleteVal.splice(i, 1);
        setCampos(deleteVal);
    }

    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            const data = {
                formulario: formulario,
                campos: campos
            }
            const verificarCampos = data.campos;
            if (verificarCampos.length > 0) {
                NuevaEncuesta({
                    params: data, token: TOKEN, callbacks: response => {
                        console.log(response);
                        if (response.estado > 0) {
                            Message({ message: '¡Encuesta cargada!', type: 'success' })
                            setFormulario({
                                nombre: '',
                                descripcion: ''
                            });
                            setPreguntas("");
                            setRespuestas([]);
                            setCampos([])
                        } else {
                            Message({ message: response.mensaje, type: 'error' })
                        }
                    }
                });
            } else {
                Message({ message: 'Ingrese una o mas preguntas para continuar', type: 'warning' })
            }

        }
    };

    return (
        <div className="container-fluid pb-5 ps-0">
            <Form className='mb-5' noValidate validated={validated} onSubmit={handleFormSubmit}>
                <Row className="mb-3 flex-column">
                    <Form.Group>
                        <Form.Control
                            required
                            type="text"
                            name="nombre"
                            id="nombre"
                            placeholder="Nombre"
                            className='input-gg'
                            onChange={handleInputChange}
                            defaultValue={formulario.nombre}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Control
                            required
                            type="text"
                            name="descripcion"
                            id="descripcion"
                            placeholder="Descripcion"
                            className='input-gg'
                            onChange={handleInputChange}
                            defaultValue={formulario.descripcion}
                        />
                    </Form.Group>
                </Row>

                <div className="accordion containerQuestionForm" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Nueva Pregunta
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <Row className="p-4 mb-3 flex-column">
                                <Col>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Pregunta"
                                            className='input-gg'
                                            onChange={(e) => setPreguntas(e.target.value)}
                                            value={preguntas}
                                        />
                                    </Form.Group>

                                    <h3 className='mb-4'>Respuestas</h3>
                                    <fieldset className='gridQuestions'>
                                        {
                                            respuestas.map((data, i) => {
                                                return <div className="relativeQuestion" key={i}>
                                                    <label htmlFor={`valor${i}`}>{`Respuesta`}</label>
                                                    <input value={data} onChange={e => asignarValorRespuesta(e, i)} className="input-gg" type="text" id={`valor${i}`} />
                                                    <div onClick={() => eliminarRespuesta(i)} className='btnCancelRespuesta'>X</div>
                                                </div>
                                            })
                                        }
                                    </fieldset>
                                </Col>

                                <div className='d-flex justify-content-center '>
                                    <div className='d-flex justify-content-center mx-2'>
                                        <div className="btn-orange mx-auto my-3" onClick={() => imprimirRespuesta()}>Agregar Respuestas</div>
                                    </div>

                                    <div className='d-flex justify-content-center mx-2 '>
                                        <div className="btn-greengg mx-auto my-3" onClick={() => guardarPregunta()}>Guardar pregunta</div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>

                    {
                        campos.map((data, i) => {
                            return <div className="accordion-item" key={i}>
                                <h2 className="accordion-header" id={`heading${i}`}>
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#item${i}`} aria-expanded="false" aria-controls="collapseTwo">
                                        <h3 className='h6 m-0'>Pregunta: <span className='parrafoGray'>{data.nombre}</span></h3>
                                    </button>
                                </h2>
                                <div id={`item${i}`} className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <h3 className='h6 m-0'>Respuestas:</h3>
                                        <ol className='p-3'>
                                            {
                                                JSON.parse(data.valores).map((res, i2) => {
                                                    return <li className='mb-2' key={i2}>{res}</li>
                                                })
                                            }
                                        </ol>
                                    </div>
                                    <div className='d-flex justify-content-center mx-2 '>
                                        <div className="btn-dangergg mx-auto my-3" onClick={() => eliminarPregunta(i)}>Eliminar Pregunta</div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className='d-flex justify-content-center'>
                    <button className="btn-greengg mx-auto my-3" type="submit">Guardar formulario</button>
                </div>
            </Form>
        </div>
    );
}