import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";
import { debounce } from "../../../utils/functions";


const graficoCantidadTurnos = debounce(async ({ params, token, callbacks }) => {
    const response = await connection({ method: 'POST', url: `${ENTITY_URL.SUPERVISOR.GRAFICOS.CANTIDAD_TURNOS}`, extraHeaders: { token: token }, params: params });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}, 5000)


export default graficoCantidadTurnos;