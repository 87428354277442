import React from "react";
import { MultiSelect } from "react-multi-select-component";
import './_Multiselect.scss'

function Multiselect(props) {
    const customValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.map(({ label }) => `${label}, `)
            : `${props.label}` ?? "";
    };

    const overrideStrings = {
        "allItemsAreSelected": "Todos los elementos están seleccionados.",
        "clearSearch": "Borrar búsqueda",
        "clearSelected": "Borrar seleccionado",
        "noOptions": props.noOptions ?? "Sin opciones",
        "search": "Búsqueda",
        "selectAll": "Seleccionar todo",
        "selectAllFiltered": "Seleccionar todo (filtrado)",
        "selectSomeItems": "Seleccione...",
        "create": "Crear",
    };
    return (
        <MultiSelect
            className={props.className ? props.className : 'multiSelectStyles'}
            options={props.options}
            value={props.value}
            onChange={props.onChange}
            labelledBy={props.label}
            valueRenderer={customValueRenderer}
            overrideStrings={overrideStrings}
            hasSelectAll={false}
            filterOptions={props.filterOptions ?? null}
            closeOnChangedValue={props.closeOnChangedValue ?? false}
            isLoading={props.isLoading ?? false}
        />
    );
}

export default Multiselect;