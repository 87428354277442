import { Spinner } from "react-bootstrap";

export default function Loading() {
    return (
        <div className="containerLoader">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
            </Spinner>
        </div>
    );
}