

import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";


const eliminarMotivoCancelacion = async ({ id, token, callbacks }) => {
    const response = await connection({ method: 'DELETE', url: `${ENTITY_URL.MOTIVO_DERIVACION.DELETE}${id}`, extraHeaders: { token: token } });;
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default eliminarMotivoCancelacion;