
import FormAsignarSucursal from "../../components/Forms/AsignarSucursal";
import Title from "../../components/Title";

export default function AsignarSucursal() {
    return (
        <main>
            <div className="container-fluid ps-0 my-5">
                <div className="glassDatos">
                    <div className="pb-4 d-flex align-items-center justify-content-between">
                        <Title title="Asignar sucursal" class="titlegray" />
                    </div>
                    <div className="col-12">
                        <FormAsignarSucursal />
                    </div>
                </div>
            </div>
        </main>
    )
}