import React, { useEffect, useState } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { addDisabled, isEmpty, removeDisabled } from '../../../utils/functions';
import Select from '../../Select/select.jsx';
import Button from '../../Button';
import Multiselect from '../../Multiselect/Multiselect';
import addMassTurn from '../../../services/adp/turn/add';
import Message from '../../../utils/message';
import getListPersons from '../../../services/adp/person/list';
import ToastMesage from '../../../utils/toast';
import ModalAgregarPersona from '../../Modals/AgregarPersona';
import { Calendario } from '../../Calendar/calendar';
import HORARIOS from '../../../config/horarios';
import ModalEditarPersonaMultiple from '../../Modals/EditarPersonaMultiple';


export default function FormAgregarTurnoSelectora(props) {
    const { TOKEN, setLoading, branchs, turnReasons, cargarLista, closeModal } = props;
    const [personSelected, setPersonSelected] = useState([]);
    const [ver_modal, setModalAgregar] = useState(false);
    const [ver_modal_editar, setModalEditar] = useState(false);
    const [message, setMessage] = useState('');

    const [validated, setValidated] = useState(false);
    const [motivoTurnoSelected, setMotivoTurnoSelected] = useState([]);
    const [resultadoBusqueda, setResultadoBusqueda] = useState("Ingrese 3 carácteres");

    const [horarios_disponibles, setHorariosDisponibles] = useState(HORARIOS);

    const [date_selected, setDateSelected] = useState(null);
    const [formValues, setValues] = useState({
        branch_id: '',
        // date: '',
        observaciones: '',
        horary: ''
    });

    const turn_reason_select = document.getElementById('turn_reason_id');
    const horary_select = document.getElementById('horary');
    const date_input = document.getElementById('date');

    const date_max = new Date();
    const months = 1;

    date_max.setMonth(date_max.getMonth() + months);

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    function handleSelectBranchChange(evt) {
        handleInputChange(evt);
        const { target } = evt;
        const { value } = target;
        if (!isEmpty(value)) {
            removeDisabled({ element: turn_reason_select });
            removeDisabled({ element: date_input });
        } else {
            addDisabled({ element: turn_reason_select });
        }
    }



    function alwaysTwoDigitsDay(date) {
        let dateStrings = date;
        const [year, month, day] = dateStrings.split('-');
        dateStrings = `${year}-${month}-${day.padStart(2, '0')}`

        return dateStrings
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            const newValue = {
                ...formValues,
                persons: personSelected,
                date: alwaysTwoDigitsDay(date_selected),
                turn_reasons: motivoTurnoSelected,
            }
            if (date_selected === null) {
                ToastMesage({ message: `Debes seleccionar una fecha`, type: 'error' });
            } else {
                if (personSelected.length > 0) {
                    if (motivoTurnoSelected.length > 0) {
                        if (motivoTurnoSelected.some(e => e.label.includes('INDUMENTARIA')) && formValues.observaciones === '') {
                            ToastMesage({ message: `Observaciones son requeridas en los motivos de indumentaria`, type: 'error' });
                        } else {
                            setLoading(true);
                            addMassTurn({
                                params: newValue, token: TOKEN, callbacks: async (response) => {
                                    if (response.estado > 0) {
                                        response.resultados.sort((a, b) => a.estado - b.estado).forEach(message => {
                                            if (message.estado > 0) {
                                                ToastMesage({ message: `El turno #${message.resultado.idNitro4} fue creado con exito`, type: 'success' });
                                            } else {
                                                ToastMesage({ message: `${message.mensaje || 'Ocurrio un error al generar el turno'}`, type: 'error' });
                                            }
                                        });
                                        form.reset();
                                        setValues({
                                            branch_id: '',
                                            // date: '',
                                            observaciones: '',
                                            horary: ''
                                        });
                                        // setDateSelected(null);
                                        // date_input.change();
                                        const event = new Event('change');
                                        date_input.dispatchEvent(event);
                                        date_input.classList.remove('is-valid');
                                        document.querySelectorAll('.clear-selected-button').forEach(function (e) {
                                            e.click();
                                        })
                                        document.getElementById(closeModal).click();
                                        cargarLista();
                                    } else {
                                        Message({ message: response.mensaje, type: 'error' })
                                        if (response.hasOwnProperty('errores')) {
                                            if (response.errores.length > 0) {
                                                response.errores.forEach(message => {
                                                    if (message.estado > 0) {
                                                        ToastMesage({ message: `${message.mensaje}`, type: 'success' });
                                                    } else {
                                                        ToastMesage({ message: `${message.mensaje}`, type: 'error' });
                                                    }
                                                });
                                            }
                                        }
                                        setLoading(false);
                                    }
                                }
                            });
                        }
                    } else {
                        ToastMesage({ message: `Debes seleccionar al menos un motivo de turno`, type: 'error' });
                    }
                } else {
                    ToastMesage({ message: `Debes seleccionar al menos una persona`, type: 'error' });
                }
            }
        }
    };

    const abrirModal = () => {
        setModalAgregar(true);
    }

    const abrirModalEditar = () => {
        setMessage('')
        setModalEditar(true);
    }

    const [isLoading, setIsLoading] = useState(false);

    async function filtrarTotal(options, filter) {
        options = [];
        if (!filter) {
            setResultadoBusqueda("Ingrese 3 carácteres")
        }
        if (filter.length >= 3) {
            // setIsLoading(true);
            await getListPersons({
                filtro: filter, token: TOKEN, callbacks: async (response) => {
                    if (response.estado > 0) {
                        const lista = response.resultados;
                        if (lista.length > 0) {
                            options = lista.map((persona) => ({
                                label: `${persona.name.toUpperCase()} ${persona.surname.toUpperCase()} - DNI: ${persona.document_number.toUpperCase()} - ${persona.Empresa.toUpperCase()} - #${response.origin}`,
                                value: persona.id
                            }));
                        } else {
                            setResultadoBusqueda("No hay resultados");
                        }
                    } else {
                        setResultadoBusqueda("No hay resultados");
                    }
                }
            });
            // setIsLoading(false);
        } else {
            setResultadoBusqueda("Ingrese 3 carácteres");
        }
        return options;
    }
    useEffect(() => {
        setIsLoading(false);
    }, [isLoading]);

    return (
        <>
            <div className="container">
                <Form noValidate validated={validated} onSubmit={handleSubmit} id="formAgregarTurno">
                    <div className="mb-3 row">
                        <div className="col-md-11 my-3">
                            <div className="form-group">
                                <Multiselect
                                    label="Seleccione colaboradores"
                                    value={personSelected}
                                    onChange={setPersonSelected}
                                    options={[]}
                                    noOptions={resultadoBusqueda}
                                    // isLoading={isLoading}
                                    filterOptions={async (options, filter) => await filtrarTotal(options, filter)}
                                />
                            </div>
                        </div>
                        <div className="col-1 my-3 d-flex justify-content-center align-items-center">
                            <button type="button" className="btn btn-success btn-sm btn-rounded d-block mx-auto w-100" onClick={abrirModal}>+</button>
                            {personSelected.length > 0 && <button type="button" className="btn btn-warning btn-sm btn-rounded d-block mx-auto w-100 mx-2" onClick={abrirModalEditar}><i className="fas fa-pencil-alt"></i></button>}
                        </div>
                        {!isEmpty(message) &&
                            <div className="col-md-12 my-3">
                                <div className="alert alert-success">{message}</div>
                            </div>
                        }
                        <div className="col-md-12 my-3">
                            <div className="form-group">
                                <Form.Label>Sucursal</Form.Label>
                                <Select
                                    value={branchs}
                                    name="branch_id"
                                    id="branch_id"
                                    placeholder="Seleccione la sucursal"
                                    defaultValue={props.branch_id ?? ''}
                                    onChange={handleSelectBranchChange}
                                />
                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="col-md-12 my-3">
                            <div className="form-group">
                                <Form.Label>Motivo de turno</Form.Label>
                                <Multiselect
                                    label="Seleccione motivos de turno"
                                    value={motivoTurnoSelected}
                                    onChange={setMotivoTurnoSelected}
                                    options={turnReasons}
                                />
                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="col-md-8 my-3">
                            <div className="form-group">
                                <Form.Label>Fecha</Form.Label>
                                <Calendario
                                    setDateSelected={setDateSelected}
                                    horary_select={horary_select}
                                    TOKEN={TOKEN}
                                    horarios_disponibles={horarios_disponibles}
                                    setHorariosDisponibles={setHorariosDisponibles}
                                />
                                <Form.Control.Feedback>Fecha correcta</Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="col-md-4 my-3">
                            <div className="form-group">
                                <Form.Label>Horario</Form.Label>
                                <Select
                                    value={horarios_disponibles}
                                    name="horary"
                                    id="horary"
                                    placeholder="Seleccione el horario"
                                    defaultValue={props.horary ?? ''}
                                    onChange={handleInputChange}
                                />
                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="col-md-12 my-3">
                            <div className="form-group">
                                <div className="container__form__observaciones">
                                    <Form.Label>Cargar observaciones</Form.Label>
                                    <FloatingLabel controlId="observaciones" label="Observaciones" className="my-3" >
                                        <Form.Control as="textarea" placeholder="Indique observaciones" className="customArea" name="observaciones" onChange={handleInputChange} />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button type="submit" className="btn-greengg d-block mx-auto" text="Agregar" />
                </Form>
            </div>
            <ModalAgregarPersona
                ver_modal={ver_modal}
                setAbrir={setModalAgregar}
                TOKEN={TOKEN}
            />
            <ModalEditarPersonaMultiple
                setMessage={setMessage}
                setPersonSelected={setPersonSelected}
                personSelected={personSelected}
                ver_modal={ver_modal_editar}
                setAbrir={setModalEditar}
                TOKEN={TOKEN}
            />
        </>
    );
}