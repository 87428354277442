import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Alert } from 'react-bootstrap';
import getListBranchs from '../../../services/adp/branch/list';
import Select from '../../Select/select';
import { removeDisabled } from '../../../utils/functions';
import updateQuantityTurnPerBranch from '../../../services/adp/branch/update-quantity-turnos';
import Button from '../../Button';
import { useAuth } from '../../../hooks/auth';


export default function FormActualizarCantidadDeTurnos(props) {
    const { token: TOKEN } = useAuth();
    const [validated, setValidated] = useState(false);
    const [formValues, setValues] = useState({
        cantidad_turnos: 1,
        sucursal_id: ""
    });
    const [branchs, setBranchs] = useState([]);


    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    const sucursal = document.getElementById('sucursal_id');


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            updateQuantityTurnPerBranch({
                params: formValues, token: TOKEN, callbacks: response => {
                    if (response.estado) {
                        document.getElementById('updateQuantityTurnsAlertError').classList.add('d-none');
                        document.getElementById('updateQuantityTurnsAlert').classList.remove('d-none');
                        document.getElementById('updateQuantityTurnsAlert').innerHTML = 'Se modifico correctamente';
                    } else {
                        document.getElementById('updateQuantityTurnsAlert').classList.add('d-none');
                        document.getElementById('updateQuantityTurnsAlertError').classList.remove('d-none');
                        document.getElementById('updateQuantityTurnsAlertError').innerHTML = response.mensaje ?? '';

                    }
                }
            });
        }
    };

    useEffect(() => {
        getListBranchs({
            token: TOKEN, callbacks: response => {
                setBranchs(response.resultados ?? []);
                if (response.estado > 0) removeDisabled({ element: sucursal });
            }
        });
    }, [TOKEN, sucursal]);

    return (
            <div className="container-fluid ps-0">
            <Alert id="updateQuantityTurnsAlert" variant="success" className='d-none my-3'></Alert>
            <Alert id="updateQuantityTurnsAlertError" variant="danger" className='d-none my-3'></Alert>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 flex-column justify-content-center">
                    <Col className='my-3'>
                        <Form.Group>
                            <Form.Label>Sucursal</Form.Label>
                            <Select
                                value={branchs}
                                name="Seleccione la sucursal"
                                id="sucursal_id"
                                placeholder="Seleccione la sucursal"
                                defaultValue={props.sucursal_id ?? ''}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className='my-3'>
                        <Form.Group>
                            <Form.Label>Cantidad de Turnos</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                name="cantidad_turnos"
                                id="cantidad_turnos"
                                placeholder="Seleccione la cantidad de Turnos"
                                defaultValue="1"
                                min="0"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3 flex-column justify-content-center">
                    <Button type="submit" className="d-block btn-greengg mx-auto my-1" text="Actualizar" />
                </Row>
            </Form>
            </div>
    );
}