import { API_URL, API_URLBOT } from "../utils/config";

export const AUTH_URL = {
    LOGIN: `${API_URL}/personas/custom/login`,
    VALIDATE_TOKEN: `${API_URL}/personas/custom/get-person-by-token`,
    VALIDATE_PERSON: `${API_URL}/personas/custom/get-perfil-segun-id`,
    SIGNUP: `${API_URL}/personas/custom/signup`,
    ACTIVATE_ACCOUNT: `${API_URL}/personas/custom/activate-account`,
    CHANGE_PASSWORD: `${API_URL}/personas/custom/change-password`,
    COMPLETE_SIGNUP: `${API_URL}/personas/custom/complete-signup`,
    FORGOT_PASSWORD: `${API_URL}/personas/custom/forgot-password`,
    RESET_PASSWORD: `${API_URL}/personas/custom/reset-password`,
    FETCH_USERS: `${API_URL}/personas/custom/auth-users`,
    SIGNUP_EXIST: `${API_URL}/personas/custom/signup-exist`,
}

export const ENTITY_URL = {
    ADP: {
        TURNOS: {
            AGREGAR: `${API_URL}/turnos/custom/adp-agregar-turno`,
            TURNOS_BOT: `${API_URLBOT}/adpbot/getPersonas`,
            REASIGNAR: `${API_URL}/turnos/custom/reasignar-turno/`,
            REASIGNAR_LISTA_TURNOS: `${API_URL}/turnos/custom/adp-lista-turnos-reasignados/`,
            CONFIRMAR_TURNO: `${API_URL}/turnos/custom/adp-turno-confirmado/`,
            CONFIRMAR_LISTA_TURNOS: `${API_URL}/turnos/custom/adp-lista-turnos-confirmado/`,
            FINALIZAR_TURNO: `${API_URL}/turnos/custom/finalizar-turno/`,
            FINALIZAR_LISTA_TURNOS: `${API_URL}/turnos/custom/adp-finalizar-lista-turnos/`,
            ATENDER_TURNO: `${API_URL}/turnos/custom/atencion-de-turno/`,
            CANCELAR_TURNO: `${API_URL}/turnos/custom/turno-cancelado/`,
            CANCELAR_LISTA_TURNOS: `${API_URL}/turnos/custom/adp-lista-turnos-cancelados/`,

            OBTENER_TURNO: `${API_URL}/turnos/custom/adp-obtener-detalles/`,
            OBTENER_PARA_ATENDER: `${API_URL}/turnos/custom/adp-obtener-para-atender/`,
            OBTENER_LISTA_PARA_ATENDER: `${API_URL}/turnos/custom/adp-obtener-lista-para-atender/`,
            OBTENER_HISTORIAL_ATENCION: `${API_URL}/turnos/custom/adp-obtener-historial-de-atencion/`,


            TURNOS_EN_ESPERA: `${API_URL}/turnos/custom/adp-turnos-en-espera/`,
            TURNOS_EN_ATENCION: `${API_URL}/turnos/custom/adp-turnos-en-atencion/`,
            TURNOS_HISTORIAL_LISTA: `${API_URL}/turnos/custom/adp-historial-turnos`,
            TURNOS_LISTA: `${API_URL}/turnos/custom/adp-turnos-lista/`,

            MODIFICAR_OBSERVACION: `${API_URL}/turnos/custom/modificar-observacion-turno/`,
        },

        EMPRESAS: {
            LISTADO: `${API_URL}/turnos/custom/empresas`,
            LISTADO_KTNA: `${API_URL}/turnos/custom/empresatKtna`,
        },
        PERSONAS: {
            AGREGAR_COLABORADOR: `${API_URL}/personas/custom/agregar-colaborador`,
        },
        FERIADOS: {
            OBTENER: `${API_URL}/calendario_laboral/custom/comprobar-dia/`,
        }
    },
    COLABORADOR: {
        TURNOS: {
            AGREGAR: `${API_URL}/turnos/custom/colaborador-agregar-turno`,
            COMPROBAR_DIAS: `${API_URL}/turnos/custom/comprobar-disponibilidad-fecha`,
            COMPROBAR_HORARIO: `${API_URL}/turnos/custom/comprobar-disponibilidad-horario`,
            REASIGNAR: `${API_URL}/turnos/custom/colaborador-reasignar-turno/`,
            MIS_TURNOS: `${API_URL}/turnos/custom/colaborador-mis-turnos`,
            DETALLES_TURNO: `${API_URL}/turnos/custom/colaborador-obtener-detalles/`,
            CONFIRMAR_TURNO: `${API_URL}/turnos/custom/turno-confirmado/`,
            CANCELAR_TURNO: `${API_URL}/turnos/custom/turno-cancelado/`,
        }
    },
    SUPERVISOR: {
        TURNOS: {
            LISTA_TURNOS: `${API_URL}/turnos/custom/supervisor-historial-turnos`,
        },
        METRICAS: {
            CANTIDAD_TURNOS: `${API_URL}/turnos_historico/custom/metrica/cantidad-de-turnos-filtered`,
            DURACION_TURNOS: `${API_URL}/turnos_historico/custom/metrica/duracion-de-turnos-filtered`,
            TIEMPO_EN_ATENCION: `${API_URL}/turnos_historico/custom/metrica/tiempo-de-espera-en-atencion-filtered`,
            TURNOS_CANCELADOS: `${API_URL}/turnos_historico/custom/metrica/turnos-cancelados-filtered`,
            TURNOS_POR_OPERADOR: `${API_URL}/turnos_historico/custom/metrica/turnos-por-operador-filtered`,
            MOTIVOS_TURNO: `${API_URL}/turnos_historico/custom/metrica/turnos-por-motivos-turnos-filtered`,
            PRESENTACIONES_ESPONTANEAS: `${API_URL}/turnos_historico/custom/metrica/presentaciones-espontaneas-filtered`,
            CLIENTES: `${API_URL}/turnos_historico/custom/metrica/turnos-por-clientes-filtered`,
            CLIENTES_MOTIVOS_TURNO: `${API_URL}/turnos_historico/custom/metrica/turnos-motivos-turno-por-clientes-filtered`,
        },
        GRAFICOS: {
            CANTIDAD_TURNOS: `${API_URL}/turnos_historico/custom/grafico/cantidad-turnos`,
            CANTIDAD_TURNOS_TOTAL: `${API_URL}/turnos_historico/custom/grafico/cantidad-turnos-total`,
            TURNOS_CANCELADOS: `${API_URL}/turnos_historico/custom/grafico/turnos-cancelados`,
            TURNOS_CANCELADOS_TOTAL: `${API_URL}/turnos_historico/custom/grafico/turnos-cancelados-total`,
            MOTIVOS_TURNO: `${API_URL}/turnos_historico/custom/grafico/turnos-por-motivos-turnos`,
            MOTIVOS_TURNO_TOTAL: `${API_URL}/turnos_historico/custom/grafico/turnos-por-motivos-turnos-total`,
            PRESENTACION_ESPONTANEA_TOTAL: `${API_URL}/turnos_historico/custom/grafico/presentaciones-espontaneas-total`,
            PRESENTACION_ESPONTANEA: `${API_URL}/turnos_historico/custom/grafico/presentaciones-espontaneas`,
            CLIENTES: `${API_URL}/turnos_historico/custom/grafico/turnos-por-clientes`,
        },
        FERIADOS: {
            LISTA: `${API_URL}/calendario_laboral`,
            AGREGAR: `${API_URL}/calendario_laboral`,
            ELIMINAR: `${API_URL}/calendario_laboral/`,
        }
    },
    ADMIN: {
        ENCUESTA: {
            AGREGAR: `${API_URL}/encuesta_formulario/custom/agregar`,
            LISTAR: `${API_URL}/encuesta_formulario/custom/listar`,
            OBTENER: `${API_URL}/encuesta_formulario/custom/detalle/`,
        }
    },
    RECEPCIONISTA: {
        TURNOS: {
            LISTA_TURNOS: `${API_URL}/turnos/custom/recepcionista-historial-turnos`,
            CONFIRMAR_ASISTENCIA: `${API_URL}/turnos/custom/recepcionista-confirmar-turno`,
        }
    },
    ENCUESTA: {
        ADD: `${API_URL}/encuesta/custom/add`,
        LIST: `${API_URL}/encuesta/custom/list`,
        GET: `${API_URL}/encuesta/custom/get`,
        GETFORM: `${API_URL}/encuesta/custom/get-encuesta-colaborador`,
        CONFIRM: `${API_URL}/encuesta/custom/agregar-resultado`,
    },
    DOCUMENTACION: {
        ADD: `${API_URL}/documentacion/custom/add`,
        LIST: `${API_URL}/documentacion/custom/list`,
        GET: `${API_URL}/documentacion/custom/get`,
        UPDATE: `${API_URL}/documentacion/custom/update`,
        DELETE: `${API_URL}/documentacion/custom/delete`,
    },
    TIPO_DNI: {
        LIST: `${API_URL}/tipo_dni/custom/list`
    },
    PERSONA: {
        COLLABORATORS: `${API_URL}/personas/custom/colaboradores`,
        LIST_INTERNAL: `${API_URL}/personas/custom/persons/list`,
        GET_PERSON: `${API_URL}/personas/custom/get/`,
        UPDATE_PERSON: `${API_URL}/personas/custom/update/`,
        LIST: `${API_URL}/personas/external/persons/list`,
        SET_DEFAULT_PROFILE: `${API_URL}/personas/custom/setDefaultProfile`,
        ASSIGN_BRANCH_TO_PERSON: `${API_URL}/persona_sucursal/custom/asignar-sucursal`,
        GET_BRANCH_ASSIGNED_TO_ID_PERSON: `${API_URL}/persona_sucursal/custom/obtener-sucursal-por-id-persona`,
        GET_BRANCH_ASSIGNED: `${API_URL}/persona_sucursal/custom/obtener-sucursal-asignada`,
        LISTA_POR_SUCURSAL: `${API_URL}/persona_sucursal/custom/lista-por-sucursal/`,
        LISTA_POR_SUCURSAL_FILTRADO: `${API_URL}/persona_sucursal/custom/lista-por-sucursal-filtrado/`,
        ASSIGN_BRANCH_AND_BOX_TO_PERSON: `${API_URL}/persona_sucursal/custom/asignar-sucursal-y-box`,
        GET_BOX_ASSIGNED: `${API_URL}/persona_box/custom/obtener-box-asignado`,
        UPDATE_SUCURSAL: `${API_URL}/persona_sucursal/custom/modificar-mi-sucursal`,
        UPDATE_BOX: `${API_URL}/persona_box/custom/modificar-mi-box`,
        TURNOS: `${API_URL}/turnos/custom/obtener-listado-turnos-colaborador`,
        EDITAR_MULTIPLE: `${API_URL}/personas/custom/update-multiple`,
        EDITAR_COLABORADOR: `${API_URL}/personas/custom/editar-colaborador`,
        EDITAR_EMAIL: `${API_URL}/personas/custom/editar-email`,
        ELIMINAR: `${API_URL}/personas/custom/eliminar`,
        GET_BY_USER_ID: `${API_URL}/personas/custom/get-person-by-userid`
    },
    TIPO_PERSONA: {
        LIST: `${API_URL}/tipo_persona/custom/list`,
    },
    SUCURSAL: {
        ADD: `${API_URL}/sucursal/custom/add`,
        LIST: `${API_URL}/sucursal/custom/list`,
        GET: `${API_URL}/sucursal/custom/get/`,
        UPDATE: `${API_URL}/sucursal/custom/update/`,
        DELETE: `${API_URL}/sucursal/custom/delete/`,
        UPDATE_QUANTITY_TURNS: `${API_URL}/sucursal_configuracion/`
    },
    ESTADOS_TURNO: {
        LIST: `${API_URL}/estado_turno/`,
    },
    MOTIVO_TURNO: {
        ADD: `${API_URL}/motivo_turno/custom/add`,
        LIST: `${API_URL}/motivo_turno/custom/list`,
        GET: `${API_URL}/motivo_turno/custom/get/`,
        UPDATE: `${API_URL}/motivo_turno/custom/update/`,
        DELETE: `${API_URL}/motivo_turno/custom/delete/`
    },
    TIPO_TURNO: {
        LIST: `${API_URL}/tipo_turno/custom/list`,
    },
    TURNOS_MOTIVO: {
        AGREGAR: `${API_URL}/turnos_motivo_turno/`,
        ELIMINAR: `${API_URL}/turnos_motivo_turno/`,
    },
    MOTIVO_CANCELACION: {
        ADD: `${API_URL}/motivo_cancelacion/custom/add`,
        LIST: `${API_URL}/motivo_cancelacion/custom/list`,
        GET: `${API_URL}/motivo_cancelacion/custom/get/`,
        UPDATE: `${API_URL}/motivo_cancelacion/custom/update/`,
        DELETE: `${API_URL}/motivo_cancelacion/custom/delete/`
    },
    PRESENTACION_ESPONTANEA: {
        ADD: `${API_URL}/presentacion_espontanea/custom/agregar`,
        LIST: `${API_URL}/presentacion_espontanea/custom/lista/`,
        FINALIZE: `${API_URL}/presentacion_espontanea/custom/finalizar/`,
    },
    MOTIVO_DERIVACION: {
        ADD: `${API_URL}/motivo_derivacion/`,
        LIST: `${API_URL}/motivo_derivacion/`,
        GET: `${API_URL}/motivo_derivacion/`,
        UPDATE: `${API_URL}/motivo_derivacion/`,
        DELETE: `${API_URL}/motivo_derivacion/`
    },
    MOTIVO_REASIGNACION: {
        ADD: `${API_URL}/motivo_reasignacion/`,
        LIST: `${API_URL}/motivo_reasignacion/`,
        GET: `${API_URL}/motivo_reasignacion/`,
        UPDATE: `${API_URL}/motivo_reasignacion/`,
        DELETE: `${API_URL}/motivo_reasignacion/`
    },
    SETTINGS: {
        URL_YOUTUBE: `${API_URL}/configuracion/custom/obtener-video`,
        UPDATE_VIDEO_YOUTUBE: `${API_URL}/configuracion/custom/modificar-video`,
    },
    NOTIFICACIONES: {
        ASIGNAR_DISPOSITIVO: `${API_URL}/notificaciones_push/custom/asignar-dispositivo-a-usuario`,
        OBTENER_LISTADO: `${API_URL}/notificaciones/custom/get-by-userid`,
    },
    DERIVACION: {
        OTRA_SUCURSAL: `${API_URL}/derivaciones/custom/derivacion-a-otra-sucursal`,
        INTERNA: `${API_URL}/derivaciones/custom/derivacion-en-sucursal`,
        MASIVA_SUCURSAL: `${API_URL}/derivaciones/custom/derivacion-masiva-sucursal`,
        MASIVA_INTERNA: `${API_URL}/derivaciones/custom/derivacion-masiva-interna`,
    },
    FERIADOS: {
        GET_FERIADOS: `${API_URL}/calendario/custom/feriados`
    },
    EXCEL: {
        GET_REPORT: `${API_URL}/turnos_historico/custom/get-report`
    }
}

export const maximoNotificaciones = 5;