import { RaceBy } from "@uiball/loaders";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Message from "../../../utils/message";
import modificarEmailPersona from "../../../services/adp/person/update-email";

export function ModalEditarEmail(props) {
    const { datosTurno, loader, openModalEmail, setOpenModalEmail, setLoader, setLoading, TOKEN, obtenerDatosTurno, setPermitirFinalizar } = props;
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = {
            email: email,
            persona_id: datosTurno.persona.id
        }
        setLoader(true);
        setLoading(true);
        await modificarEmailPersona({
            params: params, token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0) {
                    await obtenerDatosTurno(datosTurno.turno_id);
                    cerrarPopup();
                    setLoading(false);
                } else {
                    Message({ message: response.mensaje, type: 'error' })
                    setLoader(false);
                    setLoading(false);
                }
            }
        })
    }
    const cerrarPopup = async () => {
        setEmail('');
        await setOpenModalEmail(false);
    }


    const finalizarSinAgregarMail = () => {
        setPermitirFinalizar(true);
        cerrarPopup();
    }

    return (
        <>
            <div className={`modal ${!openModalEmail ? 'fade' : 'show d-block'}`} id="modalModificarMail" tabIndex="-1" aria-labelledby="modalModificarMailLabel" aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Modificar el email al colaborador del turno #{datosTurno.motivo_turno_abreviacion} - {datosTurno.turno_id}</h6>
                            <button type="button" className="btn-close" id="modalModificarMailClose" onClick={cerrarPopup} aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            {
                                loader &&
                                <RaceBy
                                    size={280}
                                    lineWeight={15}
                                    speed={1.4}
                                    color="black"
                                    className="d-flex"
                                />
                            }
                            {
                                !loader &&
                                <form onSubmit={handleSubmit}>
                                    <div className="card">
                                        <div className="card-header">Datos del turno</div>
                                        <div className="card-body">
                                            <div className="form-group my-1">
                                                <b>Turno:</b> #{datosTurno.motivo_turno_abreviacion} - {datosTurno.turno_id}
                                            </div>
                                            <div className="form-group my-1">
                                                <b>Fecha:</b> {datosTurno.fecha}
                                            </div>
                                            <div className="form-group my-1">
                                                <b>Hora:</b> {datosTurno.hora}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card my-5">
                                        <div className="card-header">Datos del colaborador</div>
                                        <div className="card-body">
                                            <div className="form-group my-1">
                                                <b>Nombre y apellido:</b> {datosTurno.persona.name} - {datosTurno.persona.surname}
                                            </div>
                                            <div className="form-group my-1">
                                                <b>{datosTurno.persona.document_type}:</b> {datosTurno.persona.document_number}
                                            </div>
                                            <div className="form-group my-1">
                                                <b>Empresa:</b> {datosTurno.persona.company}
                                            </div>
                                        </div>
                                    </div>
                                    <Form.Group>
                                        <Form.Control
                                            required
                                            name="email"
                                            id="email"
                                            type="text"
                                            className='input-gg'
                                            defaultValue={email ?? ''}
                                            placeholder="Ingresar Email"
                                            onChange={({ target }) => setEmail(target.value)}
                                        />
                                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                    </Form.Group>
                                    <div className="form-group d-block mx-auto text-center">
                                        <button type="submit" className="btn btn-primary mx-1 my-2">Editar</button>
                                        <button type="button" className="btn btn-warning mx-1 my-2" onClick={finalizarSinAgregarMail}>No agregar email</button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}