import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";

const confirmTurn = async ({ id, latlng,token, callbacks }) => {
    const response = await connection({ method: 'POST', url: `${ENTITY_URL.COLABORADOR.TURNOS.CONFIRMAR_TURNO}${id}`, extraHeaders: { token: token }, params: latlng });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default confirmTurn;