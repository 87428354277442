import { useState, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import AgregarPresentacionEspontanea from "../../components/AgregarPresentacionEspontanea";
import Title from "../../components/Title";
import { useListaPresentacionesEspontaneas } from "../../hooks/useListaPresentacionesEspontaneas";
import finalizarPresentacionEspontanea from "../../services/adp/presentaciones-espontaneas/finalizar";
import listaPresentacionEspontanea from "../../services/adp/presentaciones-espontaneas/lista";
import { CURRENT_DATE } from "../../utils/config";
import { formatarFecha } from "../../utils/functions";
import Message from "../../utils/message";
import TURN_STATE_OBJECT from "../../utils/turn_state";
import { useSucursal } from "../../hooks/sucursal";
import { useAuth } from "../../hooks/auth";
import Loading from "../../components/Loading";

export default function PresentacionesEspontaneas(props) {
    const { sucursal: sucursalAsignada } = useSucursal();
    const { token: TOKEN, loading: loading_auth } = useAuth();
    const [fecha, setFecha] = useState(CURRENT_DATE);

    const { loading, setLoading, presentaciones, setPresentaciones, message, message_type } = useListaPresentacionesEspontaneas({ token: TOKEN, branch: sucursalAsignada, fecha });

    const cargarLista = useCallback(async () => {
        listaPresentacionEspontanea({
            sucursal_id: sucursalAsignada.branchID, fecha: fecha, token: TOKEN, callbacks: async (response) => {
                if (response.estado === 1 && response.resultados && response.resultados.length > 0) {
                    setPresentaciones(response.resultados ?? []);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setPresentaciones([]);
                }
            }
        })
    }, [TOKEN, sucursalAsignada, fecha, setPresentaciones, setLoading]);

    const finalizarPresentacion = (id) => {
        const new_horary = new Date();
        const hours = new_horary.getHours() < 10 ? `0${new_horary.getHours()}` : new_horary.getHours();
        const minutes = new_horary.getMinutes() < 10 ? `0${new_horary.getMinutes()}` : new_horary.getMinutes();
        const second = new_horary.getSeconds() < 10 ? `0${new_horary.getSeconds()}` : new_horary.getSeconds();

        const horary_finalize = `${hours}:${minutes}:${second}`;

        const params = {
            horario: horary_finalize,
            horario_completo: new_horary
        }
        setLoading(true);
        finalizarPresentacionEspontanea({
            id: id, params: params, token: TOKEN, callbacks: response => {
                setLoading(false);
                if (response.estado > 0) {
                    cargarLista();
                } else {
                    Message({ message: response.mensaje, type: 'error' });
                }
            }
        });
    }


    async function filtrarPorFecha(evt) {
        const { target } = evt;
        const { value } = target;
        setLoading(true);
        setFecha(value)
    }
    return (
        <>
            {
                loading_auth ?
                    <Loading /> :
                    <main>
                        <div className="container-fluid ps-0">
                            <div className="glassDatos">
                                <div className="pb-4 d-flex align-items-center justify-content-between">
                                    <Title title={`Tus entregas de CV en ${sucursalAsignada.branchName} - ${formatarFecha(fecha)}`} class="titlegray" />
                                    <div className="d-flex">
                                        <AgregarPresentacionEspontanea
                                            cargarLista={cargarLista}
                                            setLoading={setLoading}
                                            TOKEN={TOKEN}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mt-4 container-fluid d-flex justify-content-between align-items-center p-0">
                                        <div className="col-5 d-flex">
                                            <div>
                                                <input className="formatInputs me-4" type="date" defaultValue={CURRENT_DATE} onChange={filtrarPorFecha} />
                                            </div>
                                        </div>


                                        <div className="col-3 d-flex justify-content-end">
                                            <button className="m-0 btnPaginationAtras" onClick={() => cargarLista()}>
                                                <i className="fas fa-sync-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                loading &&
                                <div className="containerLoader">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </Spinner>
                                </div>
                            }
                            {
                                presentaciones.length > 0 ?
                                    <table className="table table-striped table-hover mt-5">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Hora de inicio</th>
                                                <th>Hora de fin</th>
                                                <th>Estado</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                presentaciones.map(presentacion => {
                                                    const estadoTotal = TURN_STATE_OBJECT[presentacion.estado];
                                                    return (
                                                        <tr key={presentacion.id}>
                                                            <td><b>#{presentacion.id}</b></td>
                                                            <td>{presentacion.horario_inicio}</td>
                                                            <td>{presentacion.horario_fin}</td>
                                                            <td>
                                                                <span className="infoTurno" data-color={estadoTotal.dataColor - 1}>{estadoTotal.display}</span>
                                                            </td>
                                                            <td>{estadoTotal.botones.finalizar ?
                                                                <button type="button" className="btnCancelar" onClick={() => finalizarPresentacion(presentacion.id)}>Finalizar</button> : ''}</td>
                                                        </tr>
                                                    )
                                                })

                                            }
                                        </tbody>
                                    </table>
                                    :
                                    <div div className={`alert alert-${message_type} my-5`} role="alert">
                                        <span>{message}</span>
                                    </div>
                            }
                        </div>
                    </main>
            }
        </>
    );
}