import { useState } from "react";
import agregarPresentacionEspontanea from "../../services/adp/presentaciones-espontaneas/agregar";
import Message from "../../utils/message";
import FormAgregarPresentacionEspontanea from "../Forms/AgregarPresentacionEspontanea";

export default function AgregarPresentacionEspontanea(props) {
    const { TOKEN, setLoading, cargarLista } = props;
    const [ver_modal, setModal] = useState(false);
    const [id_presentacion, setIdPresentacion] = useState(null);
    const [horary, setHorary] = useState('');

    const abrirModal = async () => {
        setLoading(true);
        const horary_actually = new Date();

        const hours = horary_actually.getHours() < 10 ? `0${horary_actually.getHours()}` : horary_actually.getHours();
        const minutes = horary_actually.getMinutes() < 10 ? `0${horary_actually.getMinutes()}` : horary_actually.getMinutes();
        const second = horary_actually.getSeconds() < 10 ? `0${horary_actually.getSeconds()}` : horary_actually.getSeconds();

        const horary = `${hours}:${minutes}:${second}`;
        setHorary(horary);
        agregarPresentacionEspontanea({
            params: { horario: horary, horario_completo: horary_actually }, token: TOKEN, callbacks: response => {
                setLoading(false);
                if (response.estado > 0) {
                    cargarLista();
                    setModal(true);
                    setIdPresentacion(response.resultado.idNitro4);
                } else {
                    setIdPresentacion(null);
                    Message({ message: response.mensaje, type: 'error' });
                }
            }
        });
    }
    return (
        <>
            {/* <div className="modal fade" id="modalAlla" tabIndex="-1" aria-labelledby="modalAllaLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-body h-cu-800">
                            <iframe className="h-100" src="https://n4s-dev.jphlions.com/front_custom_analytics/listadoCVs" frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
            </div> */}
            <button className="buttonModal2 mx-2 " onClick={abrirModal}>+ Entrega de CV</button>
            {/* <button className="buttonModal2 mx-2 " data-bs-toggle="modal" data-bs-target="#modalAlla">Carga de CV</button> */}
            <div className={`modal ${!ver_modal ? 'fade' : 'show d-block'}`} id="modalNuevaPresentacion" aria-labelledby="modalNuevaPresentacionLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalNuevaPresentacionLabel">Nueva presentacion espontanea</h5>
                            <button type="button" className="btn-close" id="closeModalAdd" onClick={() => setModal(false)}></button>
                        </div>
                        <div className="modal-body">
                            <FormAgregarPresentacionEspontanea
                                horary={horary.toString()}
                                TOKEN={TOKEN}
                                setLoading={setLoading}
                                setModal={setModal}
                                id_presentacion={id_presentacion}
                                setIdPresentacion={setIdPresentacion}
                                cargarLista={cargarLista}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}