import Loading from "../../components/Loading";
import Menu from "../../components/Menu";
import PERSON_TYPE from "../../config/person_type";
import { useAuth } from "../../hooks/auth";
import { useUser } from "../../hooks/user";
import ConfiguracionBoxSucursal from "../configuracionBoxSucursal";


export default function Home() {
    const { loading } = useAuth();
    const { user } = useUser();
    const render = PERSON_TYPE[user.person_type]?.render || <ConfiguracionBoxSucursal />;
    return (
        <>
            {
                loading ?
                    <>
                        <Loading />
                        <div className="alert alert-info">
                            <h6>Iniciando sesión</h6>
                        </div>
                    </> :
                    <>
                        <Menu />
                        {render}
                    </>
            }
        </>
    );
}