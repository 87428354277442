import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";


const metricasTiempoDeEsperaEnAtencion = async ({ params,token, callbacks }) => {
    const response = await connection({ method: 'POST', url: `${ENTITY_URL.SUPERVISOR.METRICAS.TIEMPO_EN_ATENCION}`, extraHeaders: { token: token }, params: params  });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default metricasTiempoDeEsperaEnAtencion;