import React, { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { addDisabled, isEmpty, removeDisabled, mostrarOtrosEstiloso } from '../../../utils/functions';
import Select from '../../Select/select.jsx';
import Button from '../../Button';
import HORARIOS from '../../../config/horarios';
import Multiselect from '../../Multiselect/Multiselect';
import derivarMasivaTurnoSucursal from '../../../services/adp/derivacion/masiva-sucursal';
import Message from '../../../utils/message';
import { Calendario } from '../../Calendar/calendar';
import ToastMesage from '../../../utils/toast';

export default function FormDerivacionMultipleSucursal(props) {

    const { turnos_finalizar, setLoading, motivos_derivacion, motivos_turno, sucursales, sucursal, cargarLista, cerrar_modal, TOKEN, restablecerCheckboxs } = props;
    const sucursales_filtered = sucursales.filter(item => parseInt(item.id) !== parseInt(sucursal.branchID));

    const [validated, setValidated] = useState(false);

    const [motivoTurnoSelected, setMotivoTurnoSelected] = useState([]);
    const [date_selected, setDateSelected] = useState(null);
    const [formValues, setValues] = useState({
        hora: '',
        sucursal_id: '',
        comentario: '',
        motivo_derivacion: '',
    });
    const [horarios_disponibles, setHorariosDisponibles] = useState(HORARIOS);

    const turn_reason_select = document.getElementById('turn_reason_id');
    const horary_select = document.getElementById('hora');
    const date_input = document.getElementById('fecha');

    const date_max = new Date();
    const months = 1;

    date_max.setMonth(date_max.getMonth() + months);

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    function handleSelectBranchChange(evt) {
        handleInputChange(evt);
        const { target } = evt;
        const { value } = target;
        if (!isEmpty(value)) {
            removeDisabled({ element: turn_reason_select });
            removeDisabled({ element: date_input });
        }
        else {
            addDisabled({ element: turn_reason_select });
        }
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        document.getElementById('addTurn').classList.add('d-none');
        document.getElementById('addTurnError').classList.add('d-none');
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            if (motivoTurnoSelected.length > 0) {
                if (date_selected === null) {
                    ToastMesage({ message: `Debes seleccionar una fecha`, type: 'error' });
                } else {
                    const params = {
                        ...formValues,
                        fecha: date_selected,
                        turnos_finalizar: turnos_finalizar,
                        motivos_turno: motivoTurnoSelected
                    }

                    setLoading(true);
                    derivarMasivaTurnoSucursal({
                        params: params, token: TOKEN, callbacks: async (response) => {
                            if (response.estado > 0) {
                                form.reset();
                                restablecerCheckboxs();
                                await cargarLista();
                                await cerrar_modal();
                            } else {
                                setLoading(false);
                                Message({ message: response.mensaje, type: 'error' });
                            }
                        }
                    });
                }
            }
        }
    };


    const cambiarMotivoDeDerivacionExterna = async (event) => {
        handleInputChange(event);
        const { target } = event;
        mostrarOtrosEstiloso(target, document.getElementById('motivo_derivacion_externa_otros'));
    }


    return (
        <>
            <Alert id="addTurn" variant="success" className='d-none my-3'></Alert>
            <Alert id="addTurnError" variant="danger" className='d-none my-3'></Alert>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <Form.Label className='m-0'>Sucursal</Form.Label>
                            <Select
                                value={sucursales_filtered}
                                name="sucursal_id"
                                id="sucursal_id"
                                placeholder="Seleccione la sucursal"
                                defaultValue={props.branch_id ?? ''}
                                onChange={handleSelectBranchChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group my-2">
                            <Form.Label className='m-0'>Motivo de turno</Form.Label>
                            <Multiselect
                                label="Seleccione motivos de turno"
                                value={motivoTurnoSelected}
                                onChange={setMotivoTurnoSelected}
                                options={motivos_turno}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                        <Alert key="success" variant="success" className="d-none my-3" id="listaAlert">
                            <ul id="list">
                            </ul>
                        </Alert>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <Form.Label className='m-0'>Fecha</Form.Label>
                            <Calendario
                                setDateSelected={setDateSelected}
                                horary_select={horary_select}
                                TOKEN={TOKEN}
                                horarios_disponibles={horarios_disponibles}
                                setHorariosDisponibles={setHorariosDisponibles}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Form.Label className='m-0'>Horario</Form.Label>
                            <Select
                                value={horarios_disponibles}
                                name="hora"
                                id="hora"
                                placeholder="Seleccione el horario"
                                onChange={handleInputChange}
                                className='form-control'
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>

                    <div className="form-group col-6">
                        <Form.Label className='m-0'>Motivo de derivacion</Form.Label>
                        <Select
                            value={motivos_derivacion}
                            name="motivo_derivacion"
                            id="motivo_derivacion"
                            placeholder="Seleccione un motivo de derivacion"
                            onChange={cambiarMotivoDeDerivacionExterna}
                            other="yes"
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>

                    <div className="form-group col-6 transitionThree notView" id="motivo_derivacion_externa_otros">
                        <Form.Label className='m-0'>Motivo de derivacion</Form.Label>
                        <Form.Control
                            className='my-2'
                            name="motivo_derivacion_externa_otros"
                            id="motivo_derivacion_externa_otros"
                            type="text"
                            onChange={handleInputChange}
                            placeholder="Indica el motivo de derivacion"
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <Form.Label>Observaciones</Form.Label>
                        <Form.Control required as="textarea" name="comentario" placeholder="Indique observaciones" className="customArea" onChange={handleInputChange} />
                    </div>
                </div>
                <Button type="submit" className="btn-greengg d-block mx-auto my-5" text="Agregar" />
            </Form>
        </>
    );
}