import { useCallback } from 'react'
import { /*useContext,*/ useEffect, useState } from 'react'
// import TurnosContext from '../context/TurnosContext'
import turnosEnAtencion from '../services/adp/turn/turnos-en-atencion'
import { isEmpty } from '../utils/functions'

export function useListaEnAtencion({ token, branch, fecha }) {
    // const { sucursal: branch } = useSucursal()
    // const { token } = useAuth();
    const [loading, setLoading] = useState(false)
    const [turnos, setTurnos] = useState([])
    // const { turnos, setTurnos } = useContext(TurnosContext)
    const cargarLista = useCallback(() => {
        turnosEnAtencion({
            branch_id: branch.branchID, fecha: fecha, token: token, callbacks: async (response) => {
                if (response.estado === 1 && response.resultados && response.resultados.length > 0) {
                    setTurnos(response.resultados ?? []);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setTurnos([]);
                }
            }
        })
    }, [token, branch, fecha, setTurnos]);

    useEffect(function () {
        if (!isEmpty(token) && !isEmpty(branch.branchID)) {
            setLoading(true)
            turnosEnAtencion({
                branch_id: branch.branchID, fecha: fecha, token: token, callbacks: async (response) => {
                    if (response.estado === 1 && response.resultados && response.resultados.length > 0) {
                        setTurnos(response.resultados ?? []);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setTurnos([]);
                    }
                }
            })
        }
    }, [setTurnos, branch, token, fecha]);

    return { loading, setLoading, turnos, setTurnos, cargarLista }
}