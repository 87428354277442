import { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Title from "../../components/Title";
import getListTurnReasons from '../../services/adp/turn_reason/list';
import { connection } from "../../utils/connection";
import { ENTITY_URL } from "../../config/config";
import { useCallback } from "react";
import { ModalMotivosTurno } from "../../components/Modals/MotivosTurno";
import { useAuth } from "../../hooks/auth";

export default function MotivosTurno(props) {
    const { token: TOKEN } = useAuth();
    const [MotivoTurno, setMotivoTurnoList] = useState([]);
    const [ver_modal, setAbrirModal] = useState(false);
    const [idSeleccionado, setIdSeleccionado] = useState(0);
    const [loading, setLoading] = useState(true);

    const obtenerListadoMotivosTurno = useCallback(() => {
        setLoading(true);
        getListTurnReasons({
            token: TOKEN, callbacks: response => {
                setMotivoTurnoList(response.resultados ?? []);
                setLoading(false);
            }
        });
    }, [TOKEN]);

    useEffect(() => {
        obtenerListadoMotivosTurno();
    }, [obtenerListadoMotivosTurno]);


    async function eliminarMotivoTurno(id) {
        const response = await connection({ method: 'DELETE', url: `${ENTITY_URL.MOTIVO_TURNO.DELETE}${id}`, extraHeaders: { token: TOKEN } });;
        if (response.estado > 0) {
            obtenerListadoMotivosTurno();
        }
    }

    function abrirModal(id) {
        setIdSeleccionado(id);
        setAbrirModal(true);
    }

    async function cerrarModal() {
        setAbrirModal(false);
        setIdSeleccionado(0);
    }

    return (
        <main>
            <div className="container-fluid ps-0">
                <div className="glassDatos">
                    <div className="pb-4 d-flex align-items-center justify-content-between">
                        <Title title="Motivos de turno" class="titlegray" />
                        <ModalMotivosTurno
                            TOKEN={TOKEN}
                            ver_modal={ver_modal}
                            setAbrirModal={setAbrirModal}
                            cerrarModal={cerrarModal}
                            idSeleccionado={idSeleccionado}
                            setIdSeleccionado={setIdSeleccionado}
                            obtenerListadoMotivosTurno={obtenerListadoMotivosTurno}
                        />
                    </div>
                    <div>
                        {
                            loading &&
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Cargando...</span>
                            </Spinner>
                        }
                        {
                            !loading &&
                            <table className="table table-stripped table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre</th>
                                        <th className="text-center">Turnos</th>
                                        <th className="text-center">Abreviatura</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        MotivoTurno.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td className="text-center text-uppercase">{item.cantidad_turnos || 1}</td>
                                                    <td className="text-center text-uppercase">{item.abbreviation}</td>
                                                    <td>
                                                        <Dropdown className="d-flex justify-content-center">
                                                            <Dropdown.Toggle variant="none" id="dropdown-basic" className="btnDropDownTables"></Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => abrirModal(item.id)}>Editar</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => eliminarMotivoTurno(item.id)}>Eliminar</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}