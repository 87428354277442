import { useEffect, useState } from "react";
import IframeYoutube from "../../components/Iframe";
import Title from "../../components/Title";
import { useListaEnAtencion } from "../../hooks/useListaTurnosEnAtencion";
import getUrlVideoYoutube from "../../services/adp/settings/get_video";
import { CURRENT_DATE } from "../../utils/config";
import { useAuth } from "../../hooks/auth";
import { useSucursal } from "../../hooks/sucursal";
import { useSocket } from "../../hooks/socket";
import Loading from "../../components/Loading";
import './_player.scss';

const ListaDeTurnos = (props) => {
    let turnos = props.turnos;
    return (
        <ul className="listaTurnos">
            {
                turnos.map((turno) => {
                    return (
                        <li key={turno.turno_id}>
                            <div className="containerInfo">
                                <b>{turno.colaborador_nombre} {turno.colaborador_apellido}</b>
                            </div>
                            <div className="containerInfo">
                                <div className="cointainerTurnData">
                                    <b>#{turno.turno_id} - {turno.motivo_turno_abreviacion}</b>
                                </div>
                                <div className="containerBoxData">BOX: <b>{turno.box}</b></div>
                            </div>
                        </li>
                    );
                })
            }
        </ul>
    );
}



function PantallaTurnos(props) {
    const { socket } = useSocket();
    const { token: TOKEN } = useAuth();
    const { sucursal } = useSucursal();
    const { turnos, cargarLista } = useListaEnAtencion({ token: TOKEN, branch: sucursal, fecha: CURRENT_DATE })
    const [URL, setURLVideo] = useState('');
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getUrlVideoYoutube({
            token: TOKEN, callbacks: async (result) => {
                setLoader(false);
                if (result.estado > 0 && result.resultados.length > 0) {
                    setURLVideo(result.resultados[0].video_youtube);
                }
            }
        })
        socket.on("TURNO_ESTADO_CHANGE", function (data) {
            try {
                cargarLista();
            } catch (e) {
                console.log("error:", e);
            }
        });
    }, [TOKEN, socket, cargarLista]);

    return (
        <>
            {
                loader ?
                    <Loading />
                    :
                    <main id="Player">
                        <div className="container">
                            <div className="glassDatos">
                                <div className="pb-4 d-flex align-items-center justify-content-between">
                                    <Title title="Player" class="titlegray" />
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div id="colYoutube" className={`${loader ? 'd-none' : 'd-block'}`}>
                                            <IframeYoutube src={URL} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <ListaDeTurnos turnos={turnos} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
            }
        </>
    );
}

export default PantallaTurnos;