import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";


const obtenerDetalleEncuesta = async ({ id, token, callbacks }) => {
    const response = await connection({ method: 'GET', url: `${ENTITY_URL.ADMIN.ENCUESTA.OBTENER}${id}`, extraHeaders: { token: token } });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default obtenerDetalleEncuesta;