import { useEffect, useState } from "react";
import FormAgregarEditarPersona from "../../components/Forms/AgregarEditarPersona";
import Title from "../../components/Title";
import PERSON_TYPE from "../../config/person_type";
import getListDocumentTypes from "../../services/adp/document_type/list";
import getListProfiles from "../../services/adp/profile/list";
import { useAuth } from "../../hooks/auth";
import { useUser } from "../../hooks/user";
import { Tab, Tabs } from "react-bootstrap";
import FormAgregarPersonaExistente from "../../components/Forms/AgregarEditarPersonaExistente";

export default function AgregarPersonas(props) {
    const { token: TOKEN } = useAuth();
    const { user } = useUser();
    const TYPE_PERSON = user.person_type;
    const ID_USER = user.id;

    const [documentTypeList, setDniType] = useState([]);
    const [profilesTypeList, setProfileType] = useState([]);

    useEffect(() => {
        getListDocumentTypes({
            token: TOKEN, callbacks: response => {
                setDniType(response.resultados ?? []);
            }
        });
        getListProfiles({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    let listita = response.resultados.filter(item => PERSON_TYPE[item.name].it_is_allowed_to_add);
                    if (TYPE_PERSON === 'NOT_REGISTRY') {
                        setProfileType(listita.filter(item => item.id !== 7));
                        return
                    }
                    setProfileType(listita);
                }

            }
        });
    }, [TOKEN, TYPE_PERSON]);

    return (
        <main>
            <div className="container-fluid ps-0 my-5">
                <div className="glassDatos">
                    <div className="pb-4 d-flex align-items-center justify-content-between">
                        <Title title="Agregar Personas" class="titlegray" />
                    </div>
                    <div className='col-12'>
                        <Tabs
                            defaultActiveKey="nuevo"
                            id="uncontrolled-usuario"
                            className="mb-3"
                        >
                            <Tab eventKey="nuevo" title="Nuevo usuario">
                                <div className="col-12">
                                    <FormAgregarEditarPersona
                                        document_type_list={documentTypeList}
                                        profile_type_list={profilesTypeList}
                                        TOKEN={TOKEN}
                                        TYPE_PERSON={TYPE_PERSON}
                                        ID_USER={ID_USER}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="existente" title="Usuario Existente">
                                <div className="col-12">
                                    <FormAgregarPersonaExistente
                                        document_type_list={documentTypeList}
                                        profile_type_list={profilesTypeList}
                                        TOKEN={TOKEN}
                                        TYPE_PERSON={TYPE_PERSON}
                                        ID_USER={ID_USER}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </main>
    )
}