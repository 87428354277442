import React from "react";
import { Form } from 'react-bootstrap';
import { mapObjectToArray } from '../../utils/functions';
import './_CheckRadios.scss';


const Checkradios = (props) => {
    let campo = props.campo;
    const setSelected = props.setSelected;
    let valores = JSON.parse(campo.valores);
    valores = mapObjectToArray(valores);

    const onChange = props.onChange
    const state = props.state

    const handleChange = (respuesta) => {
        let stateActual = [...state]
        let indexRepetido = stateActual.findIndex(element => element.name === respuesta.name)
        if (indexRepetido >= 0) {
            stateActual[indexRepetido] = respuesta
        } else {
            stateActual.push(respuesta)
        }
        onChange(stateActual);
        setSelected(true);
    }

    return (
        <>
            <div className="d-flex justify-content-center align-items-center my-5">
                {
                    valores.map((item, indice) => {
                        return (
                            <div key={`${props.indice}-${campo.id}-${indice}`} className="container__checkCustom">
                                {/* <input /* value={select}  onChange={(e) => handleChange({ name: campo.name, valor: indice }, indice)} id={`option_${campo.name ?? campo.id}_${item}`} name={campo.name ?? campo.id} type={campo.tipo ?? 'radio'} className={`${campo.clases ?? ''}`}  value={item}/>
                                <label htmlFor={`option_${campo.name ?? campo.id}_${item}`}>{item}</label> */}
                                <Form.Check /* value={select}*/ onChange={(e) => handleChange({ name: campo.name ?? campo.id, valor: item })} inline label={item} name={campo.name ?? campo.id} type={campo.tipo ?? 'radio'} className={`${campo.clases}`} value={item} />
                            </div>
                        )
                    })
                }
            </div>
        </>
    );
}

export default Checkradios;