import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import getListBranchs from '../../../services/adp/branch/list';
import Select from '../../Select/select';
import Button from '../../Button';
import modificarMiSucural from '../../../services/adp/person/update_branch_assigned';
import modificarMiBox from '../../../services/adp/person/update_box_assigned';
import Message from '../../../utils/message';
import { useAuth } from '../../../hooks/auth';


export default function FormEditarSucursalYBoxAsignados(props) {
    const { token: TOKEN } = useAuth();
    const [validated, setValidated] = useState(false);
    const [branchs, setBranchs] = useState([]);

    const [formValues, setValues] = useState({});

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            modificarMiSucural({
                params: formValues, token: TOKEN, callbacks: response => {
                    if (response.estado) {
                        modificarMiBox({
                            params: formValues, token: TOKEN, callbacks: response => {
                                if (response.estado) {
                                    setTimeout(() => {
                                        document.location.reload();
                                    }, 1000)
                                } else {
                                    Message({ message: response.mensaje, type: 'error' })
                                }
                            }
                        });
                        // setTimeout(() => {
                        //     document.location.reload();
                        // }, 1000)
                    } else {
                        Message({ message: response.mensaje, type: 'error' })
                    }
                }
            });
        }
    };

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }


    useEffect(() => {
        getListBranchs({
            token: TOKEN, callbacks: response => {
                setBranchs(response.resultados ?? []);
            }
        });
    }, [TOKEN]);

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="box" className="form-label m-0">Sucursal</label>
                        <Select
                            value={branchs}
                            name="Seleccione la sucursal"
                            id="sucursal_id"
                            placeholder="Seleccione la sucursal"
                            onChange={handleInputChange}
                        />
                        <div className="valid-feedback">
                            Campo correcto
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="form-group">
                        <label htmlFor="box" className="form-label m-0">Box</label>
                        <input
                            type="number"
                            id="box"
                            name="box"
                            className="form-control my-2"
                            placeholder="Indica el box de atencion"
                            onChange={handleInputChange} />
                        <div className="valid-feedback">
                            Campo correcto
                        </div>
                    </div>
                </div>
            </div>
            <Button type="submit" className="btn-greengg d-block mx-auto my-5" text="Actualizar" />
        </Form>
    );
}