import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { useUser } from "./user";
import PERSON_TYPE from "../config/person_type";
import getBranchAssigned from "../services/adp/person/get_branch_assigned";
import { isEmpty } from "../utils/functions";

const SucursalContext = createContext(null);
export const SucursalProvider = ({ children }) => {
    const { token } = useAuth();
    const { user } = useUser();
    const [loading_sucursal, setLoadingSucursal] = useState(false);
    const [sucursal, setSucursal] = useState({
        branchID: 0,
        branchName: 'Sin sucursal'
    });

    const removeSucursal = () => {
        setSucursal({
            branchID: 0,
            branchName: 'Sin sucursal'
        });
    }

    useEffect(() => {
        if (!isEmpty(token)) {
            const person_type_user = user?.person_type || '';
            if (PERSON_TYPE[person_type_user]) {
                if (PERSON_TYPE[person_type_user].add_branch) {
                    setLoadingSucursal(true);
                    getBranchAssigned({
                        token: token, callbacks: async (response) => {
                            setLoadingSucursal(false);
                            if (response.estado > 0 && response.resultados.length > 0) {
                                setSucursal(response.resultados[0]);
                            } else {
                                removeSucursal();
                            }
                        }
                    });
                } else {
                    removeSucursal();
                }
            } else {
                removeSucursal();
            }
        } else {
            removeSucursal();
        }
    }, [token, user]);

    return <SucursalContext.Provider value={{
        sucursal, setSucursal, loading_sucursal
    }}>{children}</SucursalContext.Provider>
}

export const useSucursal = () => {
    return useContext(SucursalContext);
}