import { createContext, useContext, useState, useCallback, useEffect } from "react";


import LoginService from "../services/auth/login";
import PERSON_TYPE from "../config/person_type";
import validateToken from "../services/auth/validate-token.js";
import { useUser } from "./user";
import { isEmpty } from "../utils/functions";
// import { useSucursal } from "./sucursal";
// import ToastMesage from "../utils/toast";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('auth_token'));
    const { saveUser, removeUser } = useUser();
    const [isLogin, setIsLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageError, setMessageError] = useState('');
    // const { removeSucursal } = useSucursal();



    const login = async ({ email, password, provider }) => {
        setMessageError('');
        setLoading(true);
        const result = await LoginService({ email, password, provider });
        return result;
    }


    const logout = () => {
        removeAll();
        // document.location.href = '/login';
    }

    const removeAll = useCallback(async () => {
        localStorage.removeItem('auth_token')
        removeUser();
        setToken(null);
        setIsLogin(false);
    }, [setToken, setIsLogin, removeUser]);

    const validateTokenAuth = useCallback(async ({ token }) => {
        if (!isEmpty(token)) {
            setLoading(true);
            await validateToken({
                token: token, callbacks: async (response) => {
                    setLoading(false);
                    if (response.estado > 0) {
                        if (response.estado && response.resultados.length > 0) {
                            const person_type_user = response.resultados[0].person_type;
                            if (!PERSON_TYPE[person_type_user]) {
                                setIsLogin(true);
                                removeAll();
                                saveUser(response.resultados[0]);
                            }
                            // } else if (response.userData.provider === 'ktna') {
                            //     setIsLogin(true);
                            //     saveUser({ name: response.userData.name, surname: '', person_type: 'NOT_REGISTRY', user_id: response.userData.id });
                            // }
                        } else {
                            setIsLogin(false);
                            removeAll();
                        }
                    } else {
                        setIsLogin(false);
                        removeAll();
                    }
                }
            });
        }
    }, [setIsLogin, saveUser, removeAll]);

    useEffect(() => {
        validateTokenAuth({ token });
    }, [token, validateTokenAuth])

    return <AuthContext.Provider value={{
        token, setToken, login, logout, messageError, isLogin, setIsLogin, loading, setLoading, validateTokenAuth, removeAll, setMessageError
    }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext);
}