import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { useAuth } from "../../hooks/auth";
import { Col, Container, Form, Row } from "react-bootstrap";
import Title from '../../components/Title';
import LogoGG from '../../images/logo-corporativo.svg'
import { isEmpty } from "../../utils/functions";
import forgotPasswordService from "../../services/auth/forgot-password";
export default function RestablecerPassword(props) {
    const { isLogin, loading } = useAuth();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    if (loading) {
        return <Loading />
    }
    if (isLogin) {
        return <Navigate to='/' />
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage('')
        setMessageType('');
        const response = await forgotPasswordService({ email });
        if (response.estado > 0) {
            setMessage(response.mensaje)
            setMessageType('success');
            setTimeout(() => {
                navigate("/login");
            }, 1000);
        } else {
            setMessage(response.mensaje);
            setMessageType('danger');
        }
    }

    return (
        <Container className='containerLogin'>
            <div className='col-12 containerGlassLogin'>
                <div className="col-6">
                    <img src={LogoGG} alt="logo grupo gestion" className='img-fluid resetWidth' />
                </div>
                <div className="col-6 containerPrintDatosLogin">
                    <div className='py-5 d-flex justify-content-center'>
                        <Title className='titleLogin w-100 text-center m-0' title="Recuperar password" />
                    </div>
                    {!isEmpty(message) &&
                        <div className={`alert alert-${messageType}`}>{message}</div>
                    }
                    <Form onSubmit={handleSubmit}>
                        <div className='containerEmail-Password'>
                            <Form.Group as={Row} className="mb-5" controlId="formHorizontalEmail">
                                <Form.Label className='textLabel'>
                                    E-mail:
                                </Form.Label>
                                <Col>
                                    <input className='inputCustom px-3 py-2' type="email" placeholder="Ingresar E-mail" onChange={e => setEmail(e.target.value)} />
                                </Col>
                            </Form.Group>
                        </div>

                        <Form.Group as={Row}>
                            <Col className='d-flex justify-content-center my-5'>
                                <button type="submit" className="btn-greengg" data-disabled="off">Restablecer</button>
                            </Col>
                            <Col className='d-flex justify-content-center my-5'>
                                <Link to="/login" className="btn-orange">Volver</Link>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </Container >
    );
}