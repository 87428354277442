import React from "react";
import { NavLink } from 'react-router-dom';
import './cardTurnos.scss';
import TURN_STATE from "../../config/estados";
import TURN_STATE_OBJECT from "../../utils/turn_state";
import Button from "../Button";
import useMediaQuery from "../MediaQueryJs/useMediaQuery";
import { isEmpty } from "../../utils/functions";


function Card(props) {
    const Cartas = props.items;

    const isDesktop = useMediaQuery('(min-width: 1024px)');


    return (
        <>
            <div className="glassDatos">
                <div className="col-12 my-5">
                    {
                        Cartas.map((item) => {
                            let routeItem = `/confirmar-turno/${item.id}`;
                            let cancelRoute = `/colaborador/cancelar-turno/${item.id}`;
                            let content = '';
                            let button = '';
                            if (item.id_estado === TURN_STATE.NOT_CONFIRMED) {
                                content = <div className="d-flex justify-content-center justify-content-lg-between">
                                    <div className='my-3'>
                                        <NavLink to={routeItem}><Button className="btnAtender" text="Confirmar" /></NavLink>
                                    </div>
                                    <div className='my-3'>
                                        <NavLink to={cancelRoute}><Button className="btnDerivar" text="Cancelar" /></NavLink>
                                    </div>
                                </div>;
                            } else {
                                if (item.id_estado === TURN_STATE.CONFIRMED || item.id_estado === TURN_STATE.PENDING_ATTENTION) {
                                    content = <div className="d-flex justify-content-center align-items-center justify-content-lg-between">
                                        <div className='my-3'>
                                            <span className={TURN_STATE_OBJECT[item.estado].color}>{TURN_STATE_OBJECT[item.estado].display}</span>
                                        </div>
                                        <div className='my-3'>
                                            <NavLink to={cancelRoute}><Button className="btnDerivar" text="Cancelar" /></NavLink>
                                        </div>
                                    </div>;
                                } else {
                                    content = <div className='my-3'><span className={TURN_STATE_OBJECT[item.estado].color}>{TURN_STATE_OBJECT[item.estado].display}</span></div>;
                                }
                            }
                            return (
                                <>
                                    {isDesktop &&
                                        <div key={`cardDesktop${item.id}`} className="">
                                            <div className="col-12 mt-3 mt-md-0 datos-card parrafoGray">
                                                <div className="text-center grid-card-colaborador">
                                                    {button}
                                                    <p>#{item.id}</p>
                                                    <p>Motivo: {item.motivo_turno_abreviacion}</p>
                                                    {/* <p>{item.tipoTurno}</p> */}
                                                    <p>Fecha: {item.fecha} - <b>{item.horario}</b></p>
                                                    <p>{item.sucursal}</p>
                                                    {content}
                                                    {!isEmpty(item.encuesta) &&
                                                        <a href={`/encuesta-calidad/${item.id}/${item.encuesta?.hash ?? ''}`} target="_blank" rel="noreferrer">Encuesta</a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !isDesktop &&
                                        <div key={`cardMobile${item.id}`} className="styleCard">
                                            <div className="col-12 mt-3 mt-md-0 datos-card parrafoGray">
                                                <div className="text-center">
                                                    {button}
                                                    <p>#{item.id}</p>
                                                    <p>Motivo: {item.motivo_turno_abreviacion}</p>
                                                    {/* <p>{item.tipoTurno}</p> */}
                                                    <p>Fecha: {item.fecha} - <b>{item.horario}</b></p>
                                                    <p>{item.sucursal}</p>
                                                    {content}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default Card;