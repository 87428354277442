import { useState } from "react";
import { Form } from "react-bootstrap";
import HORARIOS from "../../../config/horarios";
import reasignarListaTurnos from "../../../services/adp/turn/reasignar-lista-turnos";
import { formatarFecha, mostrarOtros } from "../../../utils/functions";
import TURN_STATE_OBJECT from "../../../utils/turn_state";
import Button from "../../Button";
import { Calendario } from "../../Calendar/calendar";
import Select from "../../Select/select";

export default function ModalEditarMultiple(props) {
    const { setLoading, ver_modal_editar, motivosReasignacion, turnos_finalizar, datosTurnosSeleccionados, cargarLista, closeModal, TOKEN, restablecerCheckboxs } = props;

    const [validated, setValidated] = useState(false);
    const [horarios_disponibles, setHorariosDisponibles] = useState(HORARIOS);
    const [date_selected, setDateSelected] = useState(null);
    const [formValues, setValues] = useState({
        horary_update_modal: '',
        motivo_reasignacion_id_update: '',
    });

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            setLoading(true);
            const params = {
                ...formValues,
                date_update: date_selected,
                horary_update: formValues.horary_update_modal,
                turnos_finalizar: turnos_finalizar,
            }
            reasignarListaTurnos({
                params: params, token: TOKEN, callbacks: async (response) => {
                    if (response.estado > 0) {
                        closeModal();
                        restablecerCheckboxs();
                    }
                    cargarLista();
                }
            })
        }
    };


    const cambiarMotivoDeReasignacion = async (event) => {
        handleInputChange(event);
        const { target } = event;
        mostrarOtros(target, document.getElementById('motivo_reasignacion_otros_update'));
    }


    const horary_select = document.getElementById('horary_update_modal');



    return (
        <div className={`modal ${!ver_modal_editar ? 'fade' : 'show d-block'}`} id="modalEditarMasivo" tabIndex="-1" aria-labelledby="modalEditarMasivoLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center" id="modalEditarMasivoLabel">Editar turnos masivamente</h5>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={closeModal} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-5">
                                {
                                    datosTurnosSeleccionados.map(turno => {
                                        const estado_total = TURN_STATE_OBJECT[turno.estado_turno];
                                        const color = estado_total !== undefined ? estado_total.dataColor - 1 : 1;
                                        const display = estado_total !== undefined ? estado_total.display : turno.estado_turno || 'Ocurrio un error';
                                        return (
                                            <div className={`card my-3 ${turno.estado === 0 ? 'color-danger text-danger' : ''}`} key={turno.turno_id}>
                                                <div className="card-header">
                                                    <h5 className="card-title">Turno #{turno.motivo_turno_abreviacion} - {turno.turno_id}</h5>
                                                </div>
                                                <div className="card-body">
                                                    <h6>{turno.persona.name} {turno.persona.surname}</h6>
                                                    <h6>{formatarFecha(turno.fecha)} - {turno.hora}</h6>
                                                </div>
                                                {
                                                    turno.estado === 0 &&
                                                    <div className="card-footer text-center">
                                                        <span className={`border-0 text-center font-weight ${color}`}>{`Este turno se encuentra ${display}`}</span>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-md-7">
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <div className="row mb-3 flex-column justify-content-center">
                                        <div className='col-md-12 my-3'>
                                            <div className="form-group">
                                                <Form.Label>Fecha</Form.Label>
                                                <Calendario
                                                    setDateSelected={setDateSelected}
                                                    horary_select={horary_select}
                                                    TOKEN={TOKEN}
                                                    horarios_disponibles={horarios_disponibles}
                                                    setHorariosDisponibles={setHorariosDisponibles}
                                                />
                                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className='col-md-12 my-3'>
                                            <div className="form-group">
                                                <Form.Label>Horario</Form.Label>
                                                <Select
                                                    value={horarios_disponibles}
                                                    name="horary_update_modal"
                                                    id="horary_update_modal"
                                                    placeholder="Seleccione el horario"
                                                    onChange={handleInputChange}
                                                />
                                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className='col-md-12 my-3'>
                                            <div className="form-group">
                                                <Form.Label>Motivo de reasignacion</Form.Label>
                                                <Select
                                                    value={motivosReasignacion}
                                                    name="motivo_reasignacion_id_update"
                                                    id="motivo_reasignacion_id_update"
                                                    placeholder="Seleccione el motivo de reasignación"
                                                    onChange={cambiarMotivoDeReasignacion}
                                                    other="yes"
                                                />
                                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                            </div>
                                            <div className="form-group d-none" id="motivo_reasignacion_otros_update">
                                                <Form.Label>Motivo de reasignación</Form.Label>
                                                <Form.Control
                                                    name="motivo_reasignacion_otros_update"
                                                    id="motivo_reasignacion_otros_update"
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    placeholder="Indica el motivo de reasignacion"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Button type="submit" className="btn-greengg d-block mx-auto my-5" text="Editar" id="buttonEditar" disabled />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}