import React from 'react';
import Title from "../../components/Title";
import FormAgregarPregunta from '../../components/Forms/AgregarEditarPreguntas/agregarEditarPreguntas';


export default function AgregarMotivoTurno() {
    return (
        <>
            <div className="container-fluid ps-0 pb-5">
                <div className="glassDatos">
                    <div className="pb-5 d-flex align-items-center justify-content-between">
                        <Title title="Agregar Encuesta" class="titlegray" />

                    </div>

                    <FormAgregarPregunta/>
                </div>
            </div>
        </>
    )
}

