import FormDerivacionInterna from "../../Forms/DerivacionInterna";
import FormDerivacionPorSucursal from "../../Forms/DerivacionSucursal";

export default function ModalDerivacionTurno(props) {
    const { turno_id, setLoading, setAbrirModalDerivar, ver_modal_derivar, motivos_derivacion, motivos_turno, sucursales, personas, sucursal, cargarLista, closeModalPersona, datosTurno, TOKEN, restablecerCheckboxs } = props;
    const DerivacionInterna = document.getElementById('DerivacionInterna');
    const containDerivacionPorSucursaler = document.getElementById('DerivacionPorSucursal');

    const abrirDerivacionInterna = () => {
        DerivacionInterna.classList.remove('d-none');
        containDerivacionPorSucursaler.classList.add('d-none');
    }

    const abrirDerivacionPorSucursal = () => {
        containDerivacionPorSucursaler.classList.remove('d-none');
        DerivacionInterna.classList.add('d-none');

    }
    return (
        <div className={`modal ${!ver_modal_derivar ? 'fade' : 'show d-block'}`} id="modalDerivacion" tabIndex="-1" aria-labelledby="modalDerivacionLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center" id="modalDerivacionLabel">Derivar turno</h5>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={() => { setAbrirModalDerivar(false) }} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btn-dangergg d-block mx-auto" onClick={abrirDerivacionInterna}>Interna</button>
                            </div>
                            <div className="col-md-6">
                                <button className="btn-greengg d-block mx-auto" onClick={abrirDerivacionPorSucursal}>A sucursal</button>
                            </div>
                        </div>
                        <div className="my-5">
                            <div id="DerivacionPorSucursal" className="d-none">
                                <div className="card">
                                    <div className="card-header">Derivar a nueva sucursal</div>
                                    <div className="card-body">
                                        <FormDerivacionPorSucursal
                                            id={turno_id}
                                            setLoading={setLoading}
                                            datosTurno={datosTurno}
                                            persona_id={datosTurno.persona_id}
                                            motivos_derivacion={motivos_derivacion}
                                            motivos_turno={motivos_turno}
                                            sucursales={sucursales}
                                            sucursal={sucursal}
                                            cargarLista={cargarLista}
                                            cerrar_modal={() => { setAbrirModalDerivar(false) }}
                                            closeModalPersona={closeModalPersona}
                                            TOKEN={TOKEN}
                                            restablecerCheckboxs={restablecerCheckboxs}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div id="DerivacionInterna" className="d-none">
                                <div className="card">
                                    <div className="card-header">Derivar a otro ADP</div>
                                    <div className="card-body">
                                        <FormDerivacionInterna
                                            id={turno_id}
                                            setLoading={setLoading}
                                            datosTurno={datosTurno}
                                            cargarLista={cargarLista}

                                            motivos_derivacion={motivos_derivacion}
                                            personas={personas}

                                            cerrar_modal={() => { setAbrirModalDerivar(false) }}
                                            closeModalPersona={closeModalPersona}

                                            TOKEN={TOKEN}
                                            restablecerCheckboxs={restablecerCheckboxs}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}