import { useEffect, useState } from "react";
import Title from "../../components/Title";
import getListBranchs from '../../services/adp/branch/list';
import { removeDisabled } from '../../utils/functions';
import { Alert, Form } from "react-bootstrap";
import Select from "../../components/Select/select";
import Button from "../../components/Button";
import { MAIN_URL } from "../../utils/config";
import asignarSucursalBox from "../../services/adp/person/assign_branch_box";
import { useAuth } from "../../hooks/auth";
import Loading from "../../components/Loading";
import { useSucursal } from "../../hooks/sucursal";

function ConfiguracionBoxSucursal() {
    const { token: TOKEN, loading } = useAuth();
    const { sucursal: sucursalAsignada } = useSucursal();
    const [validated, setValidated] = useState(false);
    const [branchs, setBranchs] = useState([]);
    const [formValues, setValues] = useState({
        'sucursal_id_config': '',
        'box_config': ''
    });


    const sucursal = document.getElementById('sucursal_id_config');
    const box = document.getElementById('box_config');

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    useEffect(() => {
        getListBranchs({
            token: TOKEN, callbacks: response => {
                setBranchs(response.resultados ?? []);
                removeDisabled({ element: sucursal })
                removeDisabled({ element: box })
                if (response.estado > 0) {
                    if (sucursal) {
                        sucursal.value = sucursalAsignada.branchID;
                    }
                    setValues({
                        sucursal_id_config: sucursalAsignada.branchID,
                        box_config: ''
                    })
                    removeDisabled({ element: sucursal })
                    removeDisabled({ element: box })
                }
            }
        });
    }, [TOKEN, sucursal, box, sucursalAsignada]);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            asignarSucursalBox({
                params: {
                    sucursal_id: formValues.sucursal_id_config,
                    box: formValues.box_config
                }, token: TOKEN, callbacks: response => {
                    if (response.estado) {
                        document.getElementById('alert').classList.remove('d-none');
                        document.getElementById('alert').innerHTML = 'Se modifico correctamente';
                        setTimeout(() => {
                            document.location.href = `${MAIN_URL}/turnos-en-espera`;
                        }, 1000)
                    } else {
                        document.getElementById('alertError').classList.remove('d-none');
                        document.getElementById('alertError').innerHTML = response.mensaje ?? '';

                    }
                }
            });
        }
    };

    return (

        <>
            {
                loading ?
                    <Loading /> :
                    <main>
                        <div className="container-fluid ps-0">
                            <div className='col-12 glassDatos'>
                                <div className="pb-4 d-flex align-items-center justify-content-between">
                                    <Title title="Configurar box y sucursal" class="titlegray" />
                                </div>
                                <Alert id="alert" variant="success" className='d-none my-3'></Alert>
                                <Alert id="alertError" variant="danger" className='d-none my-3'></Alert>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="sucursal_id" className="form-label">Sucursal</label>
                                                <Select
                                                    className="form-control my-2 disabled"
                                                    value={branchs}
                                                    name="sucursal_id_config"
                                                    id="sucursal_id_config"
                                                    placeholder="Seleccione tu sucursal"
                                                    onChange={handleInputChange}
                                                    disabled
                                                />
                                                <div className="valid-feedback">
                                                    Campo correcto
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="box" className="form-label">Box</label>
                                                <input
                                                    type="number"
                                                    id="box_config"
                                                    name="box_config"
                                                    className="form-control my-2 disabled"
                                                    placeholder="Indica el box de atencion"
                                                    onChange={handleInputChange}
                                                    disabled
                                                />
                                                <div className="valid-feedback">
                                                    Campo correcto
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Button type="submit" className="d-block btn-greengg mx-auto mt-5" text="Actualizar" />
                                </Form>
                            </div>
                        </div>
                    </main>
            }
        </>

    );
}

export default ConfiguracionBoxSucursal;