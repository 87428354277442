
import Title from "../../components/Title";
import { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import listaMotivosReasignacion from "../../services/adp/motivo_reasignacion/list";
import eliminarMotivoReasignacion from "../../services/adp/motivo_reasignacion/delete";
import { useCallback } from "react";
import { ModalMotivosReasignacion } from "../../components/Modals/MotivoReasignacion";
import { useAuth } from "../../hooks/auth";

export default function MotivosReasignacion(props) {
    const { token: TOKEN } = useAuth();
    const [motivoReasignacion, setMotivoReasignacionList] = useState([]);

    const [ver_modal, setAbrirModal] = useState(false);
    const [idSeleccionado, setIdSeleccionado] = useState(0);
    const [loading, setLoading] = useState(true);

    const obtenerListadoMotivos = useCallback(() => {
        setLoading(true);
        listaMotivosReasignacion({
            token: TOKEN, callbacks: response => {
                setMotivoReasignacionList(response.resultados ?? [])
                setLoading(false);
            }
        });
    }, [TOKEN]);

    useEffect(() => {
        obtenerListadoMotivos();
    }, [obtenerListadoMotivos]);


    async function eliminarMotivo(id) {
        eliminarMotivoReasignacion({
            id: id, token: TOKEN, callbacks: response => {
                if (response.estado > 0) {
                    obtenerListadoMotivos();
                }
            }
        });
    }


    function abrirModal(id) {
        setIdSeleccionado(id);
        setAbrirModal(true);
    }

    async function cerrarModal() {
        setAbrirModal(false);
        setIdSeleccionado(0);
    }

    return (
        <main>
            <div className="container-fluid ps-0">
                <div className="glassDatos">
                    <div className="pb-4 d-flex align-items-center justify-content-between">
                        <Title title="Motivos de cancelacion" class="titlegray" />
                        <ModalMotivosReasignacion
                            TOKEN={TOKEN}
                            ver_modal={ver_modal}
                            setAbrirModal={setAbrirModal}
                            cerrarModal={cerrarModal}
                            idSeleccionado={idSeleccionado}
                            setIdSeleccionado={setIdSeleccionado}
                            obtenerListadoMotivos={obtenerListadoMotivos}
                        />
                    </div>
                    {
                        loading &&
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </Spinner>
                    }
                    {
                        !loading &&
                        <table className="table table-stripped table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nombre</th>
                                    <th>Detalles</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    motivoReasignacion.map((item) => {
                                        return (
                                            <tr key={item.idNitro4}>
                                                <td>{item.idNitro4}</td>
                                                <td>{item.Nombre}</td>
                                                <td>{item.Detalles}</td>
                                                <td>
                                                    <Dropdown className="d-flex justify-content-center">
                                                        <Dropdown.Toggle variant="none" id="dropdown-basic" className="btnDropDownTables"></Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => abrirModal(item.idNitro4)}>Editar</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => eliminarMotivo(item.idNitro4)}>Eliminar</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </main>
    )
}