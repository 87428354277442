// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCsuALz9dY3XyIt7Ey8SbABthHpUJyxmSI",
    authDomain: "central-de-turnos.firebaseapp.com",
    projectId: "central-de-turnos",
    storageBucket: "central-de-turnos.appspot.com",
    messagingSenderId: "1009808206496",
    appId: "1:1009808206496:web:e58de1dcb1ee9f14b2f7c5",
    measurementId: "G-BVLRPGL5P8"
};

// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenFirebase = async () => {
    return await getToken(messaging, { vapidKey: 'BP7rx8NItg9d-K9z5EA_aOWdHjEMyHQZaXVfMYIqKRBIC-075kmqLYt-4GVN-w37oHmnAU_eiP-2au0JGU_1vbo' });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });