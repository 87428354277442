import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";


export async function obtenerListadoEmpresas({ token, callbacks }) {
    const response = await connection({ method: 'GET', url: `${ENTITY_URL.ADP.EMPRESAS.LISTADO}`, extraHeaders: { token: token } });;
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export async function obtenerListadoEmpresasktna({ params, token, callbacks }) {
    const response = await connection({ method: 'GET', url: `${ENTITY_URL.ADP.EMPRESAS.LISTADO_KTNA}?empresa=${params}`, extraHeaders: { token: token } });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}