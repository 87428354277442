import { /*useContext,*/ useEffect, useState } from 'react'
// import TurnosContext from '../context/TurnosContext'
import listaTurnos from '../services/adp/turn/lista-turnos'
import { isEmpty } from '../utils/functions'

export function useListaTurnos({ token, branch, fecha }) {
    const [loading, setLoading] = useState(false)
    const [turnos, setTurnos] = useState([])
    // const { turnos, setTurnos } = useContext(TurnosContext);

    useEffect(function () {
        if (!isEmpty(token) && !isEmpty(branch.branchID)) {
            setLoading(true)
            listaTurnos({
                branch_id: branch.branchID, fecha: fecha, token: token, callbacks: async (response) => {
                    if (response.estado === 1 && response.resultados && response.resultados.length > 0) {
                        setTurnos(response.resultados ?? []);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setTurnos([]);
                    }
                }
            })
        }
    }, [setTurnos, branch, token, fecha]);

    return { loading, setLoading, turnos, setTurnos }
}