import React, { useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from "../../../components/Select/select";
import listaMotivosCancelacion from "../../../services/adp/motivo_cancelacion/list";
import cancelTurn from "../../../services/adp/turn/cancel";
import getTurn from "../../../services/adp/turn/get";
import { MAIN_URL } from "../../../utils/config";
import { addDisabled, removeDisabled } from "../../../utils/functions";
import TURN_STATE_OBJECT from "../../../utils/turn_state";
import Button from "../../Button";
import { useAuth } from "../../../hooks/auth";


function FormCancelarTurnodivaborador(props) {
    const id = props.id;

    const [motivos_cancelacion_lista, setValueListaCancelacion] = useState([]);
    const { token: TOKEN } = useAuth();
    const [validated, setValidated] = useState(false);
    const [formValues, setValues] = useState({
        motivo_cancelacion: '',
    });
    const [id_estado, setTurnState] = useState({});


    const motivo_cancelacion = document.getElementById('motivo_cancelacion');

    useEffect(() => {
        getTurn({
            token: TOKEN, id: id, callbacks: (result) => {
                if (result.resultados) {
                    if (TURN_STATE_OBJECT[result.resultados.estado_turno]) {
                        setTurnState(TURN_STATE_OBJECT[result.resultados.estado_turno].id);
                    }
                }
            }
        });
        listaMotivosCancelacion({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setValueListaCancelacion(response.resultados);
                    removeDisabled({ element: motivo_cancelacion });
                } else {
                    addDisabled({ element: motivo_cancelacion });
                }
            }
        });
    }, [TOKEN, id, motivo_cancelacion]);

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            const newValue = {
                ...formValues,
                id_estado: id_estado,
            }
            cancelTurn({
                id: id, params: newValue, token: TOKEN, callbacks: response => {
                    if (response.estado > 0) {
                        setTimeout(() => {
                            document.location.href = `${MAIN_URL}/mis-turnos`;
                        }, 1000)
                    }
                }
            });
        }
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
            <div className="row my-3">
                <div className="col-md-12">
                    <div className="form-group">
                        <Form.Label>Motivo de cancelacion</Form.Label>
                        <Select
                            value={motivos_cancelacion_lista}
                            name="motivo_cancelacion"
                            id="motivo_cancelacion"
                            placeholder="Seleccione el motivo por el que cancela el turno"
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <FloatingLabel
                            controlId="detalles"
                            label="Observaciones"
                            className="mb-3"
                        >
                            <Form.Control required as="textarea" placeholder="Indique observaciones"
                                style={{ height: '200px' }} />
                        </FloatingLabel>
                    </div>
                </div>
            </div>
            <Button type="submit" className="btn-orange d-block mx-auto my-5" text="Finalizar" />
        </Form>
    );
}

export default FormCancelarTurnodivaborador;