import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../../components/Button";
import IframeYoutube from "../../components/Iframe";
import Title from "../../components/Title";
import getUrlVideoYoutube from "../../services/adp/settings/get_video";
import updateUrlVideo from "../../services/adp/settings/update_video";
import Message from "../../utils/message";
import { useAuth } from "../../hooks/auth";

function Configuracion() {
    const [URL, setURLVideo] = useState('');
    const { token: TOKEN } = useAuth();
    const [validated, setValidated] = useState(false);



    const [formValues, setValues] = useState({
        video_youtube: URL
    });

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });

        setURLVideo(value);
    }

    useEffect(() => {
        getUrlVideoYoutube({
            token: TOKEN, callbacks: async (result) => {
                if (result.estado > 0 && result.resultados.length > 0) {
                    setURLVideo(result.resultados[0].video_youtube);
                }
                document.getElementById('colYoutube').classList.remove('d-none');
            }
        })
    }, [TOKEN]);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            updateUrlVideo({
                params: formValues, token: TOKEN, callbacks: response => {
                    if (response.estado === 0) {
                        Message({ message: response.mensaje, type: 'error' })
                    }
                }
            });
        }
    };

    return (
        <main>
            <div className="container-fluid ps-0">
                <div className="glassDatos">
                    <div className="pb-5 d-flex align-items-center justify-content-between">
                        <Title title="Configuración de player" class="titlegray" />   
                    </div>

                    <Row>
                        <Col>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>URL DE YOUTUBE</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="video_youtube"
                                        id="video_youtube"
                                        onChange={handleInputChange}
                                        defaultValue={URL}
                                    />
                                    <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                </Form.Group>
                                <Button type="submit" className="btn-greengg d-block mx-auto my-5" text="Editar" />
                            </Form>
                        </Col>
                        <Col>
                            <div id="colYoutube" className="d-none">
                                <IframeYoutube src={URL} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </main>

    );
}

export default Configuracion;