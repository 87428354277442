import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";


const listaPresentacionEspontanea = async ({ sucursal_id, fecha, token, callbacks }) => {
    const response = await connection({ method: 'GET', url: `${ENTITY_URL.PRESENTACION_ESPONTANEA.LIST}${sucursal_id}?fecha=${fecha}`, extraHeaders: { token: token } });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default listaPresentacionEspontanea;