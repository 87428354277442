import Turnos from "../pages/misTurnos";
import ActualizarCantidadDeTurnos from "../pages/actualizarCantidadDeTurnos";
import ConfiguracionBoxSucursal from "../pages/configuracionBoxSucursal";

const PERSON_TYPE = {
    COLABORADOR: {
        id: parseInt(process.env.REACT_APP_TIPO_PERSONA_COLABORADOR),
        name: "COLABORADOR",
        add_branch: false,
        is_collaborator: true,
        it_is_allowed_to_add: false,
        profiles_you_can_add: [],
        redireccion_login: 'mis-turnos',
        color: {
            'color': 'spanFinalizado',
            'dataColor': 2,
        },
        render: <Turnos />
    },
    ADP: {
        id: parseInt(process.env.REACT_APP_TIPO_PERSONA_ADP),
        name: "ATENCION",
        add_branch: true,
        it_is_allowed_to_add: true,
        profiles_you_can_add: [parseInt(process.env.REACT_APP_TIPO_PERSONA_ADP), parseInt(process.env.REACT_APP_TIPO_PERSONA_SELECTOR), parseInt(process.env.REACT_APP_TIPO_PERSONA_RECEPCIONISTA), parseInt(process.env.REACT_APP_TIPO_PERSONA_RECEPCIONISTA)],
        is_collaborator: false,
        redireccion_login: 'asignar-box-sucursal',
        color: {
            'color': 'spanAtendido',
            'dataColor': 3,
        },
        render: <ConfiguracionBoxSucursal />
    },
    SELECTOR: {
        id: parseInt(process.env.REACT_APP_TIPO_PERSONA_SELECTOR),
        name: "SELECCION",
        add_branch: true,
        is_collaborator: false,
        it_is_allowed_to_add: true,
        profiles_you_can_add: [parseInt(process.env.REACT_APP_TIPO_PERSONA_SELECTOR)],
        redireccion_login: 'asignar-box-sucursal',
        color: {
            'color': 'spanAtendido',
            'dataColor': 3,
        },
        render: <ConfiguracionBoxSucursal />
    },
    RECEPCIONISTA: {
        id: parseInt(process.env.REACT_APP_TIPO_PERSONA_RECEPCIONISTA),
        profiles_you_can_add: [parseInt(process.env.REACT_APP_TIPO_PERSONA_RECEPCIONISTA)],
        name: "RECEPCIONISTA",
        add_branch: true,
        is_collaborator: false,
        it_is_allowed_to_add: true,
        redireccion_login: 'asignar-box-sucursal',
        color: {
            'color': 'spanAtendido',
            'dataColor': 3,
        },
        render: <ConfiguracionBoxSucursal />
    },
    SUPERVISOR: {
        id: parseInt(process.env.REACT_APP_TIPO_PERSONA_SUPERVISOR),
        name: "SUPERVISOR",
        add_branch: true,
        is_collaborator: false,
        it_is_allowed_to_add: true,
        profiles_you_can_add: [parseInt(process.env.REACT_APP_TIPO_PERSONA_ADP), parseInt(process.env.REACT_APP_TIPO_PERSONA_SELECTOR), parseInt(process.env.REACT_APP_TIPO_PERSONA_RECEPCIONISTA), parseInt(process.env.REACT_APP_TIPO_PERSONA_SUPERVISOR)],
        redireccion_login: 'asignar-box-sucursal',
        color: {
            'color': 'spanPendienteAtencion',
            'dataColor': 2,
        },
        render: <ConfiguracionBoxSucursal />
    },
    ADMIN: {
        id: parseInt(process.env.REACT_APP_TIPO_PERSONA_ADMIN),
        name: "ADMIN",
        add_branch: false,
        is_collaborator: false,
        it_is_allowed_to_add: true,
        profiles_you_can_add: [parseInt(process.env.REACT_APP_TIPO_PERSONA_ADP), parseInt(process.env.REACT_APP_TIPO_PERSONA_SELECTOR), parseInt(process.env.REACT_APP_TIPO_PERSONA_RECEPCIONISTA), parseInt(process.env.REACT_APP_TIPO_PERSONA_SUPERVISOR), parseInt(process.env.REACT_APP_TIPO_PERSONA_ADMIN)],
        redireccion_login: 'actualizar-cantidad-de-turnos-por-sucursal',
        color: {
            'color': 'spanCancelado',
            'dataColor': 1,
        },
        render: <ActualizarCantidadDeTurnos />
    },
    NOT_REGISTRY_PERSON: {
        id: parseInt(process.env.REACT_APP_TIPO_PERSONA_ADP),
        name: "ATENCION",
        add_branch: true,
        it_is_allowed_to_add: true,
        profiles_you_can_add: [parseInt(process.env.REACT_APP_TIPO_PERSONA_ADP)],
        is_collaborator: false,
        redireccion_login: 'agregar-persona',
        color: {
            'color': 'spanAtendido',
            'dataColor': 3,
        },
        render: <ConfiguracionBoxSucursal />
    }
};

export default PERSON_TYPE