import React, { useState } from 'react';
import { Col, Form, Row, Container } from 'react-bootstrap';
import agregarMotivoDerivacion from '../../../services/adp/motivo_derivacion/add';
import editarMotivoDerivacion from '../../../services/adp/motivo_derivacion/update';
import Button from '../../Button';


export default function FormAgregarEditarMotivoDerivacion(props) {
    const { TOKEN, motivoSeleccionado, obtenerListadoMotivos, restablecerModal } = props;
    const [validated, setValidated] = useState(false);
    const [formValues, setValues] = useState(motivoSeleccionado === null ? {
        Nombre: '',
        Descripcion: '',
    } : {
        idNitro4: motivoSeleccionado.idNitro4,
        Nombre: motivoSeleccionado.Nombre,
        Descripcion: motivoSeleccionado.Descripcion,
    });
    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            if (motivoSeleccionado === null) {
                agregarMotivoDerivacion({
                    params: formValues, token: TOKEN, callbacks: async (response) => {
                        if (response.estado > 0) {
                            await obtenerListadoMotivos();
                            await restablecerModal();
                        }
                    }
                });
            } else {
                editarMotivoDerivacion({
                    params: formValues, token: TOKEN, callbacks: async (response) => {
                        if (response.estado > 0) {
                            await obtenerListadoMotivos();
                            await restablecerModal();
                        }
                    }
                });
            }
        }
    };

    return (
        <Container className='py-5'>
            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <Row className="mb-3 flex-column">
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                type="text"
                                name="Nombre"
                                id="Nombre"
                                placeholder="Motivo de derivacion"
                                className='input-gg'
                                defaultValue={formValues.Nombre ?? ''}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                type="text"
                                name="Descripcion"
                                id="Descripcion"
                                placeholder="Descripcion"
                                className='input-gg'
                                defaultValue={formValues.Descripcion ?? ''}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center'>
                    {
                        (motivoSeleccionado === null) ?
                            <Button type="submit" className="btn-greengg d-block mx-auto" text="Agregar" />
                            :
                            <Button type="submit" className="btn-orange d-block mx-auto" text="Editar" />
                    }
                </div>
            </Form>
        </Container>
    );
}