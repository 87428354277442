import { useState } from "react";
import { Form } from "react-bootstrap";
import agregarFeriado from "../../../services/admin/feriados/add";
import ToastMesage from "../../../utils/toast";

export default function ModalAgregarFeriado(props) {
    const { ver_modal_agregar_feriado, setAbrirModalAgregarFeriado, cargarLista, TOKEN, setLoading } = props;
    const [validated, setValidated] = useState(false);
    const [formValues, setValues] = useState({
        fecha: '',
        motivo_no_laboral: ''
    });

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    function handleSubmit(evt) {
        const form = evt.currentTarget;
        evt.preventDefault();
        if (form.checkValidity() === false) {
            evt.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            agregarFeriado({
                params: formValues, token: TOKEN, callbacks: async (response) => {
                    if (response.estado > 0) {
                        setAbrirModalAgregarFeriado(false);
                        form.reset();
                        ToastMesage({ message: 'Se agrego correctamente el feriado', type: 'success' });
                        await cargarLista();
                    } else {
                        ToastMesage({ message: response.mensaje, type: 'error' });
                    }
                }
            })
        }
    }

    return (
        <div className={`modal ${!ver_modal_agregar_feriado ? 'fade' : 'show d-block'}`} id="modalReasignacion" tabIndex="-1" aria-labelledby="modalReasignacionLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center" id="modalReasignacionLabel">Agregar un feriado</h5>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={() => { setAbrirModalAgregarFeriado(false) }} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className="form-group my-4">
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control
                                    name="fecha"
                                    id="fecha"
                                    type="date"
                                    onChange={handleInputChange}
                                />
                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                            </div>
                            <div className="form-group my-4">
                                <Form.Label>Motivo</Form.Label>
                                <Form.Control
                                    name="motivo_no_laboral"
                                    id="motivo_no_laboral"
                                    type="text"
                                    onChange={handleInputChange}
                                    placeholder="Indica el motivo del feriado"
                                />
                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                            </div>
                            <button type="submit" className="buttonModal px-5 my-5 d-block mx-auto">Agregar</button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}