import { useEffect, useState } from "react";
import CardColaborador from "../../components/CardColaborador/cardTurnos";
import NotResult from "../../components/notResults";
import Title from "../../components/Title";
import imagenUno from '../../images/imagen-card1.png';
import imagenDos from '../../images/imagen-card2.png';
import imagenTres from '../../images/imagen-card3.png';
import FormAgregarTurnoColaborador from "../../components/Forms/AgregarTurnoColaborador";

import myTurns from "../../services/collaborator/turn/my-turns";
import { formatarFecha, random } from "../../utils/functions";
import { useAuth } from "../../hooks/auth";

const images = [{ src: imagenUno }, { src: imagenDos }, { src: imagenTres }];

export default function Turnos() {
    const [turnos, setTurnos] = useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [postsPerPage] = useState(5);
    // const [currentPosts, setCurrentPosts] = useState([]);

    // const inicio = (currentPage-1) * postsPerPage;
    // const fin = currentPage * postsPerPage;

    const { token: TOKEN } = useAuth();
    let turns = [];

    useEffect(() => {
        myTurns({
            token: TOKEN, callbacks: response => {
                if (response.estado && response.resultados.length > 0) {
                    document.getElementById('notResultMyTurns').style.display = 'none';
                    setTurnos(response.resultados);
                    // setCurrentPosts(response.resultados.slice(inicio, fin));
                } else {
                    document.getElementById('notResultMyTurns').style.display = 'block';
                }
            }

        });
    }, [TOKEN]);

    // const paginaAdelante = () => {
    //     if((currentPage+1) * postsPerPage < turnos.length)
    //     {
    //         setCurrentPage(currentPage+1);
    //         setCurrentPosts(turnos.slice(inicio, fin));
    //     }
    // }

    // const paginaAtras = () => {
    //     if(currentPage > 0 && (currentPage-1) * postsPerPage < turnos.length)
    //     {
    //         setCurrentPage(currentPage-1);
    //         setCurrentPosts(turnos.slice(inicio, fin));
    //     }
    // }


    return (
        <main className="pb-4">
            <div className="container-fluid ps-lg-0">
                <div className="d-flex justify-content-end m-3">
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <FormAgregarTurnoColaborador />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="pb-4 d-flex align-items-center justify-content-between">
                        <Title title="Mis turnos" class="titlegray" />
                        <button type="button" className="btnAgregarTurno" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            Agregar Turno +
                        </button>
                    </div>
                    <div className="mt-4 container-fluid d-flex justify-content-end">
                        <button className="btnPaginationAtras">
                            <i className="fas fa-chevron-left"></i>
                        </button>

                        <button className="btnPaginationAdelante">
                            <i className="fas fa-chevron-right"></i>
                        </button>
                    </div>
                    <NotResult title="No hay resultados" id="notResultMyTurns" />
                    {
                        turnos.forEach(item => {
                            const fecha = formatarFecha(item.date);

                            const imageUnique = images[random(0, 2)];
                            const color = random(0, 2);
                            turns.push({
                                id: item.id,
                                sucursal: item.branchName,
                                id_estado: item.turnStatusId,
                                estado: item.turnStatusName,
                                imagen: imageUnique.src,
                                tipoTurno: item.turn_reason,
                                nombre: `${item.userName} ${item.userSurname}`,
                                fecha: fecha,
                                horario: item.horary,
                                motivo_turno_abreviacion: item.motivo_turno_abreviacion,
                                dataColor: color,
                                encuesta: item.encuesta
                            })
                        })
                    }
                    <CardColaborador items={turns} />
                </div>
            </div>
        </main>
    )
}