import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../Button';
import Message from '../../../utils/message';
import { obtenerListadoEmpresasktna } from '../../../services/adp/empresas/lista';
import Multiselect from '../../Multiselect/Multiselect';
import { useAuth } from '../../../hooks/auth';
import updateMultiplePerson from '../../../services/adp/person/update_person_multiple';

function FormEditarPersonaMultiple(props) {
    const { TOKEN, personSelected, setAbrir, setPersonSelected, setMessage } = props;
    const [empresaSelected, setempresaSelected] = useState([]);
    const [resultadoBusqueda, setResultadoBusqueda] = useState("Ingrese 3 carácteres");

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (empresaSelected.length > 0) {
            if (personSelected.length > 0) {
                const empresa = empresaSelected[empresaSelected.length - 1].value;
                const params = {
                    Personas: personSelected,
                    Empresa: empresa
                }
                updateMultiplePerson({
                    params: params, token: TOKEN, callbacks: response => {
                        if (response.estado > 0) {
                            setAbrir(false);
                            setPersonSelected(personSelected.map(item => {
                                const array_item = item.label.split('-');
                                const empresa_actual = array_item[2];
                                let nueva_empresa = item.label.replaceAll(empresa_actual, empresa);
                                item.label = nueva_empresa;
                                return item;
                            }))
                            setMessage('Los colaboradores fueron modificados correctamente.');
                        } else {
                            setMessage('');
                            Message({ message: response.mensaje, type: 'error' });
                        }
                    }
                })
            } else {
                Message({ message: 'No hay colaboradores seleccionados', type: 'error' });
            }
        } else {
            Message({ message: 'Debes elegir al menos una empresa', type: 'error' });
        }
    };

    return (
        <Form noValidate onSubmit={handleFormSubmit}>
            <div className="container">
                <div className="row my-3">
                    <div className="col-md-12">
                        <h3 className="mb-3 text-center">Colaboradores seleccionados</h3>
                        <ul className="list-group">
                            {personSelected.map((persona) => {
                                return (
                                    <li className="list-group-item" key={persona.value}><b>{persona.label}</b></li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="col-md-12">
                        <div className="alert alert-info my-2">
                            Una vez terminada la edicion de estos colaboradores la lista seleccionada se restablecera y deberas buscar y seleccionar nuevamente los colaboradores
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group my-3">
                            <h6>Seleccione la empresa</h6>
                            <Multiselect
                                label="Seleccione la empresa"
                                value={empresaSelected}
                                onChange={setempresaSelected}
                                options={[]}
                                noOptions={resultadoBusqueda}
                                closeOnChangedValue={true}
                                filterOptions={async (options, filter) => {
                                    options = [];
                                    if (!filter) {
                                        return options;
                                    }
                                    if (filter.length >= 3) {
                                        await obtenerListadoEmpresasktna({
                                            params: filter, token: TOKEN, callbacks: async (response) => {
                                                if (response.estado > 0) {
                                                    const EMPRESAS = response.respuesta;
                                                    if (EMPRESAS.length > 0) {
                                                        options = EMPRESAS.map((empresa) => ({
                                                            label: `${empresa.Empresa.toUpperCase()}`,
                                                            value: `${empresa.Empresa}`
                                                        }))
                                                    } else {
                                                        setResultadoBusqueda("No hay resultados");
                                                    }
                                                }
                                                else {
                                                    setResultadoBusqueda("No hay resultados");
                                                }
                                            }
                                        });
                                    } else {
                                        setResultadoBusqueda("Ingrese 3 carácteres");
                                    }
                                    return options;
                                }}
                            />
                            {empresaSelected.length > 1 &&
                                <div className="alert alert-danger my-5">
                                    <b>Solo se tomara la ultima empresa seleccionada.<br />
                                        En este caso {empresaSelected[empresaSelected.length - 1].label}</b>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <Button type="submit" className="btn-orange d-block mx-auto my-5" text="Editar" />
                </div>
            </div>
        </Form>
    );
}


export default function ModalEditarPersonaMultiple(props) {
    const { token: TOKEN } = useAuth();
    const { ver_modal, setAbrir, personSelected, setPersonSelected, setMessage } = props;

    return (
        <div className={`modal ${!ver_modal ? 'fade' : 'show d-block'}`} id="modalEditarPersonas" tabIndex="-1" aria-labelledby="modalEditarPersonasLabel" aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title w-100 text-center" id="modalEditarPersonasLabel">Editar lista de colaboradores</h2>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={() => { setAbrir(false) }} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <FormEditarPersonaMultiple
                            setMessage={setMessage}
                            setPersonSelected={setPersonSelected}
                            personSelected={personSelected}
                            TOKEN={TOKEN}
                            setAbrir={setAbrir}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}