
import './_Metricas.scss';
import Title from '../../components/Title';
import { useEffect, useMemo } from 'react';
import getListBranchs from '../../services/adp/branch/list';
import { useState } from 'react';
import Select from '../../components/Select/select';
import { downloadExcelfromClient, filterObjectKeysFromArray, formatarFecha, getCurrentDate, isEmpty, restablecerFechaDeDB } from '../../utils/functions';
import metricasTurnosCancelados from '../../services/adp/metricas/turnos-cancelados';
import TURN_STATE_OBJECT from '../../utils/turn_state';
import LineChart from '../../components/Charts/LineChart';
import PieChart from '../../components/Charts/PieChart';
import { MESES } from '../../utils/config';
import { Pinwheel, RaceBy } from '@uiball/loaders';
import graficoTurnosCancelados from '../../services/admin/graficos/turnos-cancelados';
import graficoTurnosCanceladosTotal from '../../services/admin/graficos/turnos-cancelados-total';
import { obtenerExcel } from '../../services/adp/download-excel/get-file';
import { useAuth } from '../../hooks/auth';

export default function MetricasTurnosPorOperador(props) {
    const { token: TOKEN } = useAuth();
    const [filter, setFilter] = useState(false);
    const [sucursales, setSucursales] = useState([]);

    const hoy = new Date();
    const ayer = new Date();
    ayer.setMonth(hoy.getMonth() - 1);
    const [desde, setDesde] = useState(ayer.toISOString().split('T')[0]);
    const [hasta, setHasta] = useState(hoy.toISOString().split('T')[0]);
    const [sucursal_id, setSucursalID] = useState(null);
    const [turnos, setTurnos] = useState([]);
    const formValues = useMemo(() => expensiveCalculation(desde, hasta, sucursal_id), [desde, hasta, sucursal_id]);

    const [mensaje, setMensaje] = useState('Buscando resultados...');
    const [tipo_mensaje, setMensajeType] = useState('info');
    const [loading_table, setLoadingTable] = useState(true);

    const [datosGrafico, setDatosGrafico] = useState([]);
    const [datosTotalGrafico, setDatosGraficoTotal] = useState([]);
    const [filterParameters, setFilterParameters] = useState([
        "fecha_alta",
        "estado_turno",
        "motivo_turno",
        "motivo_cancelacion",
        "empresa",
        "DNI",
        "Nombre_completo",
        "sucursal_nombre"
    ])
    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        document.getElementById("desde").setAttribute("max", today);
        getListBranchs({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setSucursales(response.resultados);
                }
            }
        });
        graficoTurnosCancelados({
            params: formValues, token: TOKEN, callbacks: response => {
                if (response.estado && response.resultados.length > 0) {
                    setDatosGrafico(response.resultados.sort(function (a, b) { return b.anio - a.anio }));
                } else {
                    setDatosGrafico([]);
                }
            }
        });
        const newValues = filter ? {
            ...formValues, mesess: MESES
        } : { mesess: MESES };

        graficoTurnosCanceladosTotal({
            params: newValues, token: TOKEN, callbacks: response => {
                if (response.estado && response.resultados.length > 0) {
                    setDatosGraficoTotal(response.resultados.sort(function (a, b) { return b.anio - a.anio }));
                } else {
                    setDatosGraficoTotal([]);
                }
            }
        });
        metricasTurnosCancelados({
            params: formValues, token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setTurnos(response.resultados.sort(function (a, b) { return b.turno_id - a.turno_id }));
                    setMensaje(null);
                    setMensajeType(null);
                    setLoadingTable(false)
                } else {
                    setTurnos([]);
                    setMensaje('No hay resultados');
                    setMensajeType('danger');
                    setLoadingTable(false)
                }
            }
        });
    }, [TOKEN, formValues, filter])


    const handleDesde = async ({ target }) => {
        const { value } = target;
        if (value) {
            setDesde(value);

            const fechaDesde = new Date(value);
            const fechaActual = new Date();

            if (fechaDesde > fechaActual) {
                document.getElementById('desde').value = '';
                setDesde(null);
                setMensaje('La fecha "Desde" no puede ser mayor que la fecha actual');
                setMensajeType('warning');
                return;
            }

            if (hasta && fechaDesde > new Date(hasta)) {
                document.getElementById('desde').value = '';
                setDesde(null);
                setMensaje('La fecha "Desde" no puede ser mayor que la fecha "Hasta"');
                setMensajeType('warning');
                return;
            }

            if (!hasta) {
                const fecha = new Date(value);
                fecha.setDate(fecha.getDate() + 2);
                const fecha_string = await getCurrentDate();
                setHasta(fecha_string);
                //document.getElementById('hasta').value = fecha_string;
            }
            setFilter(true);
            setLoadingTable(true);
        } else {
            setHasta(null)
        }

    }

    const handleHasta = ({ target }) => {
        const { value } = target;
        if (value) {
            const fechaHasta = new Date(value);
            if (desde) {
                if (fechaHasta < new Date(desde)) {
                    setMensaje('La fecha "Hasta" no puede ser menor que la fecha "Desde"');
                    setMensajeType('warning');
                    document.getElementById('hasta').value = '';
                    setHasta(null);
                    return;
                } else {
                    setHasta(value);
                    setFilter(true);
                    setLoadingTable(true);
                }
            } else {
                setMensaje('Debes escoger una fecha inicial primero');
                setMensajeType('warning');
                setHasta(null);
                document.getElementById('desde').value = '';
                document.getElementById('hasta').value = '';
            }
        } else {
            setHasta(null);
        }
    }


    const handleSucursal = ({ target }) => {
        setFilter(true);
        setLoadingTable(true)
        const { value } = target;
        setMensaje('Buscando resultados...');
        setMensajeType('warning');
        setTurnos([]);
        if (!isEmpty(value)) {
            setSucursalID(value);
        } else {
            setSucursalID(null);
        }
    }
    async function handleDownloadExcel() {

        const descargarBtn = document.querySelector(".descargar");

        if (descargarBtn) {
            descargarBtn.classList.add("fa", "fa-spinner", "fa-spin");
        }

        const nombre = "Metricas cancelación turnos"

        const cabecera = [
            "Fecha Cancelacion",
            "Estado Turno",
            "Sucursal",
            "Motivo Turno",
            "Empresa",
            "Motivo Cancelación",
            "Dni",
            "Colaborador",
        ]

        const datosExcel = [cabecera, ...await filterObjectKeysFromArray(turnos, filterParameters, filterParameters)];

        const file = await obtenerExcel({ token: TOKEN, params: datosExcel })

        const response = await downloadExcelfromClient(file, nombre)

        if (response) descargarBtn.classList.remove("fa", "fa-spinner", "fa-spin");

    }
    return (
        <main>
            <div className="container-fluid ps-0">
                <div className="glassDatos row">
                    <div className="my-5 col-9">
                        <Title title='Turnos cancelados' class="titlegray" />
                    </div>
                    <div className="col-2 my-5">
                        <button onClick={handleDownloadExcel} className="btn-greengg d-block"><i className='descargar'></i>Exportar datos</button>
                    </div>
                    <div className="row divTotalTurnos my-3 mb-5">
                        <div className='col-8'>
                            <div className="selectViewTurnos">
                                <div className="divSelect">
                                    <div className="row">
                                        <div className="col-4">
                                            <p className="m-0 me-1">Desde:</p>
                                            <input className="formatInputs me-4" type="date" onChange={handleDesde} name="desde" id="desde" defaultValue={desde} />
                                        </div>
                                        <div className="col-4">
                                            <p className="m-0 me-1">Hasta:</p>
                                            <input className="formatInputs me-4" type="date" onChange={handleHasta} name="hasta" id="hasta" defaultValue={hasta} min={desde} />
                                        </div>
                                        <div className="col-4">
                                            <p className="m-0 me-1">Sucursal:</p>
                                            <Select
                                                id="sucursal_id"
                                                name="sucursal_id"
                                                placeholder="Seleccione una sucursal"
                                                onChange={handleSucursal}
                                                value={sucursales}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-4'>

                            <div className="containerNumberTurnos">
                                {
                                    !loading_table ?
                                        <>
                                            <h2 className="m-0">Total de turnos</h2>
                                            <p>{turnos.length}<span>turnos</span></p>
                                        </>
                                        :
                                        <div className="mt-4">
                                            <RaceBy
                                                size={150}
                                                lineWeight={5}
                                                speed={1.7}
                                                color="orange"
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={`alert alert-${tipo_mensaje}`}>
                                {mensaje}
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            loading_table ?
                                <div className="loadingTableMetricas">
                                    <Pinwheel
                                        size={100}
                                        lineWeight={5.5}
                                        speed={1}
                                        color="orange"
                                    />
                                </div> :
                                <>
                                    {
                                        turnos.length > 0 &&
                                        <>
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="mb-5">
                                                        <LineChart
                                                            chartData={{
                                                                labels: datosTotalGrafico.map(data => data.name),
                                                                datasets: [{
                                                                    label: 'Turnos cancelados durante el año',
                                                                    data: datosTotalGrafico.map(data => data.total),
                                                                    backgroundColor: [
                                                                        "#8fb435",
                                                                    ],
                                                                    borderColor: "#88868B",
                                                                    borderWidth: 1,
                                                                }]
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="mb-5">
                                                        <PieChart
                                                            chartData={{
                                                                labels: datosGrafico.map(data => data.name),
                                                                datasets: [
                                                                    {
                                                                        label: 'Meses en en los que se cancelaron turnos',
                                                                        data: datosGrafico.map(data => data.total),
                                                                        backgroundColor: [
                                                                            'rgba(255, 99, 132, 0.2)',
                                                                            'rgba(54, 162, 235, 0.2)',
                                                                            'rgba(255, 206, 86, 0.2)',
                                                                            'rgba(75, 192, 192, 0.2)',
                                                                            'rgba(153, 102, 255, 0.2)',
                                                                            'rgba(255, 159, 64, 0.2)',
                                                                        ],
                                                                        borderColor: [
                                                                            'rgba(255, 99, 132, 1)',
                                                                            'rgba(54, 162, 235, 1)',
                                                                            'rgba(255, 206, 86, 1)',
                                                                            'rgba(75, 192, 192, 1)',
                                                                            'rgba(153, 102, 255, 1)',
                                                                            'rgba(255, 159, 64, 1)',
                                                                        ],
                                                                        borderWidth: 1,
                                                                    },
                                                                ],
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5">
                                                <table className="table table-hover table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Colaborador</th>
                                                            <th>DNI</th>
                                                            <th>Fecha de turno</th>
                                                            <th>Hora de turno</th>
                                                            <th>Sucursal</th>
                                                            <th>Motivo de turno</th>
                                                            <th>Empresa</th>
                                                            <th>Motivo de cancelacion</th>
                                                            <th>Fecha de cancelacion</th>
                                                            <th>Estado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            turnos.map(item => {
                                                                let fecha_total = item.fecha_alta.split('T');
                                                                const fecha = formatarFecha(fecha_total[0]);
                                                                const hora = restablecerFechaDeDB({ hora: fecha_total[1].split('.') });
                                                                return (
                                                                    <tr key={item.turno_id}>
                                                                        <td>#{item.abreviacion}-{item.turno_id}</td>
                                                                        <td>{item.Nombre_completo}</td>
                                                                        <td>{item.DNI}</td>
                                                                        <td>{formatarFecha(item.fecha)}</td>
                                                                        <td>{item.hora}</td>
                                                                        <td>{item.sucursal_nombre}</td>
                                                                        <td>{item.motivo_turno}</td>
                                                                        <td>{item.empresa ?? 'SD'}</td>
                                                                        <td><span className="infoTurno" data-color={TURN_STATE_OBJECT[item.estado_turno].dataColor + 1}>{item.motivo_cancelacion ?? 'SD'}</span></td>
                                                                        <td>{`${fecha} ${hora}`}</td>
                                                                        <td><span className="infoTurno" data-color={TURN_STATE_OBJECT[item.estado_turno].dataColor - 1}>{TURN_STATE_OBJECT[item.estado_turno].display}</span></td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>

                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        </main>
    );
}


const expensiveCalculation = (desde, hasta, sucursal_id) => {
    return {
        "rango_fechas": {
            "inicio": desde,
            "fin": hasta
        },
        "sucursal_id": sucursal_id,
    };
}