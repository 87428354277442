import React, { useState } from 'react';
import { Col, Form, Row, Container, FloatingLabel } from 'react-bootstrap';
import addMotivoTurno from '../../../services/adp/turn_reason/add';
import updateMotivoTurno from '../../../services/adp/turn_reason/update';
import Button from '../../Button';
import { isEmpty } from '../../../utils/functions';
// import Multiselect from '../../Multiselect/Multiselect';


export default function FormAgregarMotivoTurno(props) {
    const { TOKEN, motivoTurnoSeleccionado, obtenerListadoMotivosTurno, restablecerModal, encuestas } = props;
    const [validated, setValidated] = useState(false);
    const [formValues, setValues] = useState(motivoTurnoSeleccionado === null ? {
        id: '',
        name: '',
        description: '',
        abbreviation: '',
        documentacion: '',
        cantidad_turnos: '',
        Encuesta_formulario_id: ''
    } : {
        id: motivoTurnoSeleccionado.id,
        name: motivoTurnoSeleccionado.name,
        description: motivoTurnoSeleccionado.description,
        abbreviation: motivoTurnoSeleccionado.abbreviation,
        documentacion: motivoTurnoSeleccionado.documentation,
        cantidad_turnos: motivoTurnoSeleccionado.cantidad_turnos,
        Encuesta_formulario_id: motivoTurnoSeleccionado.encuesta_formulario_id,
    });

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            if (motivoTurnoSeleccionado === null) {
                addMotivoTurno({
                    params: formValues, token: TOKEN, callbacks: async (response) => {
                        if (response.estado > 0) {
                            await restablecerModal();
                            await obtenerListadoMotivosTurno();
                        }
                    }
                });
            } else {
                updateMotivoTurno({
                    params: formValues, token: TOKEN, callbacks: async (response) => {
                        if (response.estado > 0) {
                            await restablecerModal();
                            await obtenerListadoMotivosTurno();
                        }
                    }
                });
            }
        }
    };


    return (
        <Container>
            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <Row className="mb-3 flex-column">
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Motivo del turno"
                                className='input-gg'
                                defaultValue={formValues.name ?? ''}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                type="text"
                                name="description"
                                id="description"
                                placeholder="Descripcion"
                                className='input-gg'
                                defaultValue={formValues.description ?? ''}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                type="text"
                                name="abbreviation"
                                id="abbreviation"
                                placeholder="Abreviacion"
                                className='input-gg'
                                defaultValue={formValues.abbreviation ?? ''}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                type="number"
                                name="cantidad_turnos"
                                id="cantidad_turnos"
                                placeholder="Cantidad de turnos"
                                className='input-gg'
                                defaultValue={formValues.cantidad_turnos ?? 1}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <label>Encuesta</label>
                        <select id="Encuesta_formulario_id" name="Encuesta_formulario_id" className="form-control input-gg" required={true} onChange={handleInputChange} defaultValue={formValues.Encuesta_formulario_id}>
                            <option value="">Seleccione una encuesta</option>
                            {encuestas.map(item => {
                                return <option value={item.form_id} selected={formValues.Encuesta_formulario_id === item.form_id}>{item.form_nombre}</option>
                            })}
                        </select>

                        {motivoTurnoSeleccionado !== null && isEmpty(motivoTurnoSeleccionado.encuesta_formulario_id) &&
                            <div className='alert alert-danger'>
                                Debes seleccionar una encuesta para el motivo de turno
                            </div>
                        }
                    </Col>
                    <Col>
                        <Form.Group>
                            <FloatingLabel
                                controlId="documentation"
                                label="Documentacion requerida"
                                className="mb-3"
                            >
                                <Form.Control
                                    as="textarea"
                                    placeholder="Indique documentacion requerida para solicitar este turno"
                                    name="documentation"
                                    className='input-gg'
                                    onChange={handleInputChange}
                                    defaultValue={formValues.documentacion ?? ''}
                                    style={{ height: '200px' }} />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center'>
                    {
                        (motivoTurnoSeleccionado === null) ?
                            <Button type="submit" className="btn-greengg d-block mx-auto" text="Agregar" />
                            :
                            <Button type="submit" className="btn-orange d-block mx-auto" text="Editar" />
                    }
                </div>
            </Form>
        </Container>
    );
}