
import './_Metricas.scss';
import Title from '../../components/Title';
import { useEffect, useMemo } from 'react';
import getListBranchs from '../../services/adp/branch/list';
import { useState } from 'react';
import Select from '../../components/Select/select';
import { downloadExcelfromClient, filterObjectKeysFromArray, formatarFecha, getCurrentDate, isEmpty, restablecerFechaDeDB } from '../../utils/functions';
import metricasPorOperador from '../../services/adp/metricas/turnos-por-operador';
// import getListPersonsByBranch from '../../services/adp/person/list-by-branch';
import TURN_STATE_OBJECT from '../../utils/turn_state';
import { Pinwheel, RaceBy } from '@uiball/loaders';
import { obtenerExcel } from '../../services/adp/download-excel/get-file';
import { useAuth } from '../../hooks/auth';
import getFilteredPersonsByBranch from '../../services/admin/personas/listado-por-sucursal';
// import { ESTADOS_TURNO } from '../../config/estados_turno';

export default function MetricasTurnosPorOperador(props) {
    const { token: TOKEN } = useAuth();

    const [filter, setFilter] = useState(true);
    const [sucursales, setSucursales] = useState([]);
    const [adps, setAdps] = useState([]);

    const hoy = new Date();
    const ayer = new Date();
    ayer.setMonth(hoy.getMonth() - 1);
    const [desde, setDesde] = useState(ayer.toISOString().split('T')[0]);
    const [hasta, setHasta] = useState(hoy.toISOString().split('T')[0]);
    const [persona_id, setPersonaID] = useState(null);
    const [sucursal_id, setSucursalID] = useState(null);
    // const [estado_id, setEstadoID] = useState(null);
    const [disabledADP, setDisabledADP] = useState(true);
    const [turnos, setTurnos] = useState([]);

    const [loading, setLoading] = useState(true);
    const [loading_table, setLoadingTable] = useState(true);
    const [loading_operadores, setLoadingOperadores] = useState(true);


    const [mensaje, setMensaje] = useState('Debes indicar un ADP/Selector');
    const [tipo_mensaje, setMensajeType] = useState('warning');
    const [filterParameters, setFilterParameters] = useState([
        "estado_turno",
        "id",
        "fecha_alta",
        "motivo_turno",
        "sucursal_nombre",
        "empresa",
        "DNI",
        "Nombre_completo",
        "Nombre_completo_operador"
    ])
    // const formValues = useMemo(() => expensiveCalculation(desde, hasta, persona_id, estado_id), [desde, hasta, persona_id, estado_id]);
    const formValues = useMemo(() => expensiveCalculation(desde, hasta, sucursal_id, persona_id), [desde, hasta, sucursal_id, persona_id]);

    useEffect(() => {

        const today = new Date().toISOString().split('T')[0];
        document.getElementById("desde").setAttribute("max", today);

        getListBranchs({
            token: TOKEN, callbacks: response => {
                setLoading(false);
                if (response.estado > 0 && response.resultados.length > 0) {
                    setSucursales(response.resultados);
                }
            }
        });
        if (filter) {
            metricasPorOperador({
                params: formValues, token: TOKEN, callbacks: response => {
                    if (response.estado > 0 && response.resultados.length > 0) {
                        setTurnos(response.resultados.sort(function (a, b) { return b.turno_id - a.turno_id }));
                        setMensaje(null);
                        setMensajeType(null);
                        setLoadingTable(false)
                    } else {
                        setTurnos([]);
                        setMensaje(response.mensaje ?? response.mensaje);
                        setMensajeType('danger');
                        setLoadingTable(false)
                    }
                }
            });
        }

    }, [TOKEN, formValues, filter])
    useEffect(() => {
        getFilteredPersonsByBranch({
            desde, hasta, sucursal_id: 0, token: TOKEN, callbacks: response => {
                setLoadingTable(false)
                setLoadingOperadores(false);
                if (response.estado > 0 && response.resultados.length > 0) {
                    let personasSelect = [];
                    response.resultados.forEach(item => {
                        if (item.id !== 0) {
                            personasSelect.push({
                                id: item.id,
                                name: `${item.name} ${item.surname} - ${item.email}`
                            });
                        }
                    });
                    setAdps([...new Map(personasSelect.map((m) => [m.id, m])).values()]);
                    setDisabledADP(false);
                }
            }
        });
    }, [TOKEN, desde, hasta])


    const handleDesde = async ({ target }) => {
        const { value } = target;
        if (value) {
            setDesde(value);

            const fechaDesde = new Date(value);
            const fechaActual = new Date();

            if (fechaDesde > fechaActual) {
                document.getElementById('desde').value = '';
                setDesde(null);
                setMensaje('La fecha "Desde" no puede ser mayor que la fecha actual');
                setMensajeType('warning');
                return;
            }

            if (hasta && fechaDesde > new Date(hasta)) {
                document.getElementById('desde').value = '';
                setDesde(null);
                setMensaje('La fecha "Desde" no puede ser mayor que la fecha "Hasta"');
                setMensajeType('warning');
                return;
            }

            if (!hasta) {
                const fecha = new Date(value);
                fecha.setDate(fecha.getDate() + 2);
                const fecha_string = await getCurrentDate();
                setHasta(fecha_string);
            }
            setFilter(true);
            setLoadingTable(true);
        } else {
            setHasta(null)
        }

    }

    const handleHasta = ({ target }) => {
        const { value } = target;
        if (value) {
            const fechaHasta = new Date(value);
            if (desde) {
                if (fechaHasta < new Date(desde)) {
                    setMensaje('La fecha "Hasta" no puede ser menor que la fecha "Desde"');
                    setMensajeType('warning');
                    document.getElementById('hasta').value = '';
                    setHasta(null);
                    return;
                } else {
                    setHasta(value);
                    setFilter(true);
                    setLoadingTable(true);
                }
            } else {
                setMensaje('Debes escoger una fecha inicial primero');
                setMensajeType('warning');
                setHasta(null);
                document.getElementById('desde').value = '';
                document.getElementById('hasta').value = '';
            }
        } else {
            setHasta(null);
        }
    }


    const handleSucursal = ({ target }) => {
        setFilter(false);
        setLoadingTable(true)
        setLoadingOperadores(true);
        setPersonaID(null);
        setAdps([]);
        setDisabledADP(true);
        const { value } = target;
        const valueAux = !isEmpty(value) ? value : 0;
        setSucursalID(valueAux);
        getFilteredPersonsByBranch({
            desde, hasta, sucursal_id: valueAux, token: TOKEN, callbacks: response => {
                setLoadingTable(false)
                setLoadingOperadores(false);
                if (response.estado > 0 && response.resultados.length > 0) {
                    let personasSelect = [];
                    response.resultados.forEach(item => {
                        if (item.id !== 0) {
                            personasSelect.push({
                                id: item.id,
                                name: `${item.name} ${item.surname} - ${item.email}`
                            });
                        }
                    });
                    setAdps([...new Map(personasSelect.map((m) => [m.id, m])).values()]);
                    setDisabledADP(false);
                }
            }
        });
        setFilter(true);
    }


    const handleADP = ({ target }) => {
        setFilter(false);
        setLoadingTable(true)
        setLoading(true)
        const { value } = target;
        if (!isEmpty(value)) {
            setMensaje('Buscando...');
            setMensajeType('info');
            setPersonaID(value);
            setLoadingTable(false)
            setLoading(false);
        } else {
            setPersonaID(0);
            setLoadingTable(false)
            setLoading(false);
        }
        setFilter(true);
    }

    async function handleDownloadExcel() {

        const descargarBtn = document.querySelector(".descargar");

        if (descargarBtn) {
            descargarBtn.classList.add("fa", "fa-spinner", "fa-spin");
        }

        const nombre = "Metricas turnos por operador"

        const cabecera = [
            "Estado Turno",
            "Sucursal",
            "Motivo Turno",
            "Fecha Alta",
            "Empresa",
            "Dni",
            "Colaborador",
            "Operador",
            "ID",
        ]

        const datosExcel = [cabecera, ...await filterObjectKeysFromArray(turnos, filterParameters, filterParameters)];

        const file = await obtenerExcel({ token: TOKEN, params: datosExcel })

        const response = await downloadExcelfromClient(file, nombre)

        if (response) descargarBtn.classList.remove("fa", "fa-spinner", "fa-spin");

    }
    return (
        <main>
            <div className="container-fluid ps-0">
                <div className="glassDatos row">
                    <div className="my-5 col-9">
                        <Title title='Turnos por operador' class="titlegray" />
                    </div>
                    <div className="col-2 my-5">
                        <button onClick={handleDownloadExcel} className="btn-greengg d-block"><i className='descargar'></i>Exportar datos</button>
                    </div>
                    <div className="row divTotalTurnos my-3 mb-5">
                        <div className='col-9'>
                            <div className="selectViewTurnos">
                                <div className="divSelect">
                                    <div className="row">
                                        <div className="col-3">
                                            <p className="m-0 me-1">Desde:</p>
                                            <input className="formatInputs me-4" type="date" onChange={handleDesde} name="desde" id="desde" defaultValue={desde} />
                                        </div>
                                        <div className="col-3">
                                            <p className="m-0 me-1">Hasta:</p>
                                            <input className="formatInputs me-4" type="date" onChange={handleHasta} name="hasta" id="hasta" defaultValue={hasta} min={desde} />
                                        </div>
                                        <div className="col-3">
                                            <p className="m-0 me-1">Sucursal:</p>
                                            {
                                                !loading ?
                                                    <Select
                                                        id="sucursal_id"
                                                        name="sucursal_id"
                                                        placeholder="Seleccione una sucursal"
                                                        onChange={handleSucursal}
                                                        value={sucursales}
                                                    /> :
                                                    <div className="mt-4">
                                                        <RaceBy
                                                            size={150}
                                                            lineWeight={5}
                                                            speed={1.7}
                                                            color="orange"
                                                        />
                                                    </div>
                                            }
                                        </div>
                                        <div className="col-3">
                                            <p className="m-0 me-1">Operador:</p>
                                            {
                                                !loading_operadores ?
                                                    <Select
                                                        id="persona_id"
                                                        name="persona_id"
                                                        placeholder="Seleccione un adp"
                                                        onChange={handleADP}
                                                        value={adps}
                                                        defaultValue={persona_id}
                                                        className={`form-control my-2 ${disabledADP ? 'disabled' : ''}`}
                                                        {...`${disabledADP ? 'disabled' : ''}`}
                                                    /> :

                                                    <div className="mt-4">
                                                        <RaceBy
                                                            size={150}
                                                            lineWeight={5}
                                                            speed={1.7}
                                                            color="orange"
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-3'>
                            {
                                turnos.length > 0 &&
                                <div className="containerNumberTurnos">
                                    {
                                        !loading_table ?
                                            <>
                                                <h2 className="m-0">Total de turnos</h2>
                                                <p>{turnos.length ?? '...'}<span>turnos</span></p>
                                            </>
                                            :
                                            <div className="mt-4">
                                                <RaceBy
                                                    size={150}
                                                    lineWeight={5}
                                                    speed={1.7}
                                                    color="orange"
                                                />
                                            </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className="col-12 mt-5">
                            <div className={`alert alert-${tipo_mensaje}`}>
                                {mensaje}
                            </div>
                        </div>
                    </div>
                    {
                        turnos.length > 0 &&
                        <div>
                            {
                                loading_table ?
                                    <div className="loadingTableMetricas">
                                        <Pinwheel
                                            size={100}
                                            lineWeight={5.5}
                                            speed={1}
                                            color="orange"
                                        />
                                    </div> :
                                    <table className="table table-hover table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>DNI</th>
                                                <th>Colaborador</th>
                                                <th>Operador</th>
                                                <th>Fecha de turno</th>
                                                <th>Hora de turno</th>
                                                <th>Sucursal</th>
                                                <th>Motivo de turno</th>
                                                <th>Empresa</th>
                                                <th>Fecha de operacion</th>
                                                <th>Estado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                turnos.map(item => {
                                                    let fecha_total = item.fecha_alta.split('T');
                                                    const fecha = formatarFecha(fecha_total[0]);
                                                    const hora = restablecerFechaDeDB({ hora: fecha_total[1].split('.') });
                                                    return (
                                                        <tr key={item.turno_id}>
                                                            <td>#{item.abreviacion}-{item.turno_id}</td>
                                                            <td>{item.DNI}</td>
                                                            <td>{item.Nombre_completo}</td>
                                                            <td>{item.Nombre_completo_operador}</td>
                                                            <td>{formatarFecha(item.fecha)}</td>
                                                            <td>{item.hora}</td>
                                                            <td>{item.sucursal_nombre}</td>
                                                            <td>{item.motivo_turno}</td>
                                                            <td>{item.empresa ?? 'SD'}</td>
                                                            <td>{`${fecha} ${hora}`}</td>
                                                            <td><span className="infoTurno" data-color={TURN_STATE_OBJECT[item.estado_turno].dataColor - 1}>{TURN_STATE_OBJECT[item.estado_turno].display}</span></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>

                            }
                        </div>
                    }
                </div>
            </div>
        </main>
    );
}


const expensiveCalculation = (desde, hasta, sucursal_id, persona_id) => {
    return {
        "rango_fechas": {
            "inicio": desde,
            "fin": hasta
        },
        "persona_id": persona_id,
        "sucursal_id": sucursal_id,
        // "estado_id": estado_id
    };
}