import React from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/Title";
import confirmTurn from "../../services/collaborator/turn/confirm";
import { useEffect, useState } from 'react';
import { MAIN_URL } from "../../utils/config";
import './_ConfirmarTurno.scss';
import Message from "../../utils/message";
import { useAuth } from "../../hooks/auth";

function ConfirmarTurno() {
    const { id } = useParams();
    const [colaboradorCC, setColaboradorCC] = useState([]);
    const { token: TOKEN } = useAuth();

    const [URL_MAPA, setMapa] = useState('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3288.153654381508!2d-58.514639684162624!3d-34.49898865947256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb0feecdfb3dd%3A0xe0018c4f273df542!2sEdison%201191%2C%20B1640HQW%20Mart%C3%ADnez%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1657643296675!5m2!1ses!2sar');

    // const confirmButton = () => {
    //     confirmTurn({
    //         id: id, token: TOKEN, callbacks: () => {
    //             document.location.href = `${MAIN_URL}/mis-turnos`;
    //         }
    //     });
    // }



    const confirmButton = async () => {
        let comparteUbicacion = await handlePermission();
        if (comparteUbicacion === "granted") {
            if (colaboradorCC.length !== 0) {
                let latlng = {
                    latitude: colaboradorCC.latitude,
                    longitude: colaboradorCC.longitude
                }
                confirmTurn({
                    id: id, latlng: latlng, token: TOKEN, callbacks: (result) => {
                        if (result.estado === 1) {
                            document.location.href = `${MAIN_URL}/mis-turnos`;
                        }else if(result.estado === 0 && !result.distancia ){
                            Message({ message: result.error, type: 'error' })
                        }
                        else {
                            let distancia = ((result.distancia / 1000) > 1) ? `${parseFloat(result.distancia / 1000).toFixed(2)} kms` : `${parseFloat(result.distancia).toFixed(2)} mts`;
                            Message({ message: `Ud. se encuentra a ${distancia} de la sucursal, debe estar a 50mts para validar su llegada.`, type: 'error' })
                        }
                    }
                });
            } else {
                Message({ message: `Debe habilitar compartir su ubicación para hacer uso de esta funcionalidad. Si recientemente la habilito, debe recargar la pagina.`, type: 'error' })
            }
        } else {
            Message({ message: `Debe habilitar compartir su ubicación para hacer uso de esta funcionalidad`, type: 'error' })
        }
    }

    const revealPosition = () => { }
    const positionDenied = () => { }
    const geoSettings = () => { }

    async function handlePermission() {
        let state = await navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') {
                // report(result.state);
            } else if (result.state === 'prompt') {
                // report(result.state);
                navigator.geolocation.getCurrentPosition(revealPosition, positionDenied, geoSettings);
            } else if (result.state === 'denied') {
                // report(result.state);
            }
            result.addEventListener('change', () => {
                // report(result.state);
                document.location.reload()
            });
            return result.state;
        });
        return state;
    }

    // function report(state) {
    //     console.log(`Permission ${state}`);
    // }

    // handlePermission();

    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
        console.log(`geolocation permission state is ${permissionStatus.state}`);
        permissionStatus.onchange = () => {
            document.location.reload()
            console.log(`geolocation permission state has changed to ${permissionStatus.state}`);
        };
    });

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                // position.coords.longitude
                // position.coords.latitude
                setColaboradorCC(position.coords);
                document.getElementById('botonConfirmar').removeAttribute('disabled');
                document.getElementById('botonConfirmar').classList.remove('disabled');

                let mapaNewUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.7584141261473!2d' + position.coords.longitude + '!3d' + position.coords.latitude + '!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x56a0e54815fa55ff!2zMjTCsDQ2JzEwLjEiUyA2NcKwMjYnNTMuMCJX!5e0!3m2!1ses-421!2sar!4v1664542231161!5m2!1ses-421!2sar';
                // console.log("mapaNewUrl:", mapaNewUrl)
                setMapa(mapaNewUrl);
            })
        } else {
            // I believe it may also mean geolocation isn't supported
            Message({ message: 'Se requiere que autorice el acceso a la geolocalización', type: 'error' })
        }



    }, [TOKEN, id]);

    return (
        <main className="container-fluid ps-0">
            <div className="glassDatos">
                <div className="pb-4 d-flex align-items-center justify-content-between">
                    <Title title="Confirmar turno" class="titlegray" />
                </div>

                <div className="container-fluid ps-0 container__map">
                    <iframe
                        title="Google Maps"
                        src={URL_MAPA}
                        loading="lazy"
                        height="450"
                    >
                    </iframe>
                </div>
                <div className="mt-4 d-flex justify-content-center">
                    <button id="botonConfirmar" className="btn-orange disabled" onClick={confirmButton}>Confirmar</button>
                </div>
            </div>
        </main>
    );
}

export default ConfirmarTurno;