import { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import cancelarListaTurnos from "../../../services/adp/turn/cancelar-lista-turnos";
import { formatarFecha, mostrarOtros } from "../../../utils/functions";
import Message from "../../../utils/message";
import ToastMesage from "../../../utils/toast";
import TURN_STATE_OBJECT from "../../../utils/turn_state";
import Select from "../../Select/select";

export default function ModalCancelarMultiple(props) {
    const { setLoading, ver_modal_cancelar, motivos_cancelacion, turnos_finalizar, datosTurnosSeleccionados, cargarLista, closeModal, TOKEN, restablecerCheckboxs } = props;



    const [validated, setValidated] = useState(false);
    const [formValues, setValues] = useState({
        detalles_update: '',
        motivo_cancelacion_id_update: ''
    });


    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            setValidated(false);
            const params = {
                ...formValues,
                turnos_finalizar: turnos_finalizar,
            }
            cancelarListaTurnos({
                params: params, token: TOKEN, callbacks: async (response) => {
                    if (response.estado > 0) {
                        response.resultados.forEach(message => {
                            if (message.estado > 0) {
                                ToastMesage({ message: message.mensaje, 'type': 'success' });
                            } else {
                                ToastMesage({ message: message.mensaje, 'type': 'error' });
                            }
                        });
                        restablecerCheckboxs();
                        closeModal();
                        cargarLista();
                    } else {
                        Message({ message: response.mensaje, 'type': 'error' });
                        setLoading(false);
                    }
                }
            })
        }
    };


    const cambiarMotivoDeCancelacion = async (event) => {
        handleInputChange(event);
        const { target } = event;
        mostrarOtros(target, document.getElementById('motivo_cancelacion_otros_update'));
    }

    return (
        <div className={`modal ${!ver_modal_cancelar ? 'fade' : 'show d-block'}`} id="modalCancelarMasivo" tabIndex="-1" aria-labelledby="modalCancelarMasivoLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center" id="modalCancelarMasivoLabel">Cancelar turnos masivamente</h5>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={closeModal} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-5">
                                {
                                    datosTurnosSeleccionados.map(turno => {
                                        const estado_total = TURN_STATE_OBJECT[turno.estado_turno];
                                        const color = estado_total !== undefined ? estado_total.dataColor - 1 : 1;
                                        const display = estado_total !== undefined ? estado_total.display : turno.estado_turno || 'Ocurrio un error';
                                        return (
                                            <div className={`card my-3 ${turno.estado === 0 ? 'color-danger text-danger' : ''}`} key={turno.turno_id}>
                                                <div className="card-header">
                                                    <h5 className="card-title">Turno #{turno.motivo_turno_abreviacion} - {turno.turno_id}</h5>
                                                </div>
                                                <div className="card-body">
                                                    <h6>{turno.persona.name} {turno.persona.surname}</h6>
                                                    <h6>{formatarFecha(turno.fecha)} - {turno.hora}</h6>
                                                </div>
                                                {
                                                    turno.estado === 0 &&
                                                    <div className="card-footer text-center">
                                                        <span className={`border-0 text-center font-weight ${color}`}>{`Este turno se encuentra ${display}`}</span>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-md-7">
                                <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                                    <div className="row my-3 flex-column align-items-center">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <Form.Label className="m-0">Motivo de cancelacion</Form.Label>
                                                <Select
                                                    value={motivos_cancelacion}
                                                    name="motivo_cancelacion_id_update"
                                                    id="motivo_cancelacion_id_update"
                                                    placeholder="Seleccione el motivo por el que cancela el turno"
                                                    onChange={cambiarMotivoDeCancelacion}
                                                    other="yes"
                                                />
                                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                            </div>
                                            <div className="form-group d-none" id="motivo_cancelacion_otros_update">
                                                <Form.Label>Motivo de cancelacion</Form.Label>
                                                <Form.Control
                                                    name="motivo_cancelacion_otros_update"
                                                    id="motivo_cancelacion_otros_update"
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    placeholder="Indica el motivo de cancelacion"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="form-group">
                                                <Form.Label>Observaciones</Form.Label>
                                                <FloatingLabel
                                                    controlId="detalles_update"
                                                    label="Observaciones"
                                                    className="mb-3"
                                                >
                                                    <Form.Control required as="textarea" placeholder="Indique observaciones"
                                                        style={{ height: '200px' }} name="detalles_update" onChange={handleInputChange} />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-5 d-flex justify-content-center">
                                        <button type="submit" className="btn-orange">Confirmar</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}