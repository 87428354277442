import { ENTITY_URL } from "../../config/config";
import { connection } from "../../utils/connection";


const obtenerListado = async ({ params, token, callbacks }) => {
    const response = await connection({ method: 'POST', url: `${ENTITY_URL.NOTIFICACIONES.OBTENER_LISTADO}`, extraHeaders: { token: token }, params: params });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default obtenerListado;