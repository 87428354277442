import { /*useContext,*/ useEffect, useState } from 'react'
// import PresentacionesContext from '../context/PresentacionesContext'
import listaPresentacionEspontanea from '../services/adp/presentaciones-espontaneas/lista';
import { isEmpty } from '../utils/functions';

export function useListaPresentacionesEspontaneas({ token, branch, fecha }) {
    const [loading, setLoading] = useState(false)
    // const { presentaciones, setPresentaciones } = useContext(PresentacionesContext);
    const [presentaciones, setPresentaciones] = useState([])
    const [message, setMessage] = useState('Buscando resultados');
    const [message_type, setMessageType] = useState('info');
    useEffect(function () {
        if (!isEmpty(token) && !isEmpty(branch.branchID)) {
            setLoading(true)
            listaPresentacionEspontanea({
                sucursal_id: branch.branchID, fecha: fecha, token: token, callbacks: async (response) => {
                    if (response.estado === 1 && response.resultados && response.resultados.length > 0) {
                        setPresentaciones(response.resultados ?? []);
                        setLoading(false);
                    } else {
                        setMessage('No hay resultados');
                        setMessageType('danger');
                        setLoading(false);
                        setPresentaciones([]);
                    }
                }
            })
        }
    }, [setPresentaciones, branch, token, fecha]);

    return { loading, setLoading, presentaciones, setPresentaciones, message, message_type }
}