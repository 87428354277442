import FormCancelarTurnoADP from "../../Forms/CancelarTurno";

export default function ModalCancelarTurno(props) {
    const { turno_id, setLoading, ver_modal_cancelar, setAbrirModalCancelar, closeModalPersona, datosTurno, cargarLista, TOKEN, motivos_cancelacion, restablecerCheckboxs } = props;
    return (
        <div className={`modal ${!ver_modal_cancelar ? 'fade' : 'show d-block'}`} id="modalCancelar" tabIndex="-1" aria-labelledby="modalCancelarLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center" id="modalCancelarLabel">Cancelar turno</h5>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={() => { setAbrirModalCancelar(false) }} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <FormCancelarTurnoADP
                            id={turno_id}
                            setLoading={setLoading}
                            cerrar_modal={() => { setAbrirModalCancelar(false) }}
                            closeModalPersona={closeModalPersona}
                            datosTurno={datosTurno}
                            cargarLista={cargarLista}
                            TOKEN={TOKEN}
                            motivos_cancelacion={motivos_cancelacion}
                            restablecerCheckboxs={restablecerCheckboxs}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}