
const TURN_STATE = {
    NOT_CONFIRMED: parseInt(process.env.REACT_APP_ESTADO_TURNO_SIN_CONFIRMAR),
    CONFIRMED: parseInt(process.env.REACT_APP_ESTADO_TURNO_CONFIRMADO),
    PENDING_ATTENTION: parseInt(process.env.REACT_APP_ESTADO_TURNO_PENDIENTE_DE_ATENCION),
    ATTENDED: parseInt(process.env.REACT_APP_ESTADO_TURNO_ATENDIDO),
    FINALIZED: parseInt(process.env.REACT_APP_ESTADO_TURNO_FINALIZADO),
    CANCELLED: parseInt(process.env.REACT_APP_ESTADO_TURNO_CANCELADO),
    TRANSFERRED: parseInt(process.env.REACT_APP_ESTADO_TURNO_TRANSFERIDO),
    NO_PRESENTADO: parseInt(process.env.REACT_APP_ESTADO_TURNO_NO_PRESENTADO),
    DERIVADO: parseInt(process.env.REACT_APP_ESTADO_TURNO_DERIVADO),
    REASIGNADO: parseInt(process.env.REACT_APP_ESTADO_TURNO_REASIGNADO)
};

export default TURN_STATE