import React, { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import Select from '../../Select/select';
import Button from '../../Button';
import { isEmpty, mostrarOtrosEstiloso } from '../../../utils/functions';
import derivarMasivaTurnoInterna from '../../../services/adp/derivacion/masiva-interna';
import Message from '../../../utils/message';
import { useUser } from '../../../hooks/user';


export default function FormDerivacionMultipleInterna(props) {
    const { turnos_finalizar, setLoading, cargarLista, personas, motivos_derivacion, cerrar_modal, TOKEN, restablecerCheckboxs} = props;
    const { user } = useUser();

    const [formValues, setValues] = useState({
        operador_id: '',
        observaciones: '',
        motivo_derivacion: ''
    });

    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            // setLoading(true);
            const params = {
                ...formValues,
                turnos_finalizar: turnos_finalizar,
            }

            setLoading(true);
            derivarMasivaTurnoInterna({
                params: params, token: TOKEN, callbacks: async (response) => {
                    if (response.estado > 0) {
                        restablecerCheckboxs();
                        form.reset();
                        await cargarLista();
                        await cerrar_modal();
                    } else {
                        setLoading(false);
                        Message({ message: response.mensaje, type: 'error' });
                    }
                }
            });
        }
    };



    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }



    const cambiarMotivoDeDerivacion = async (event) => {
        handleInputChange(event);
        const { target } = event;
        mostrarOtrosEstiloso(target, document.getElementById('motivo_derivacion_interna_otros'));
    }

    return (
        <>
            <Alert id="assignBranchAlert" variant="success" className='d-none my-3'></Alert>
            <Alert id="assignBranchAlertError" variant="danger" className='d-none my-3'></Alert>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <div className='col-md-12 my-3'>
                        <div className="form-group">
                            <Form.Label className='m-0'>ADP</Form.Label>
                            <Select
                                value={personas.filter(item => !isEmpty(item.id) && user.id !== item.id)}
                                name="operador_id"
                                id="operador_id"
                                placeholder="Seleccione el adp que va a tomar este turno"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>

                    <div className="form-group col-6">
                        <Form.Label className='m-0'>Motivo de derivacion</Form.Label>
                        <Select
                            value={motivos_derivacion}
                            name="motivo_derivacion"
                            id="motivo_derivacion"
                            placeholder="Seleccione un motivo de derivacion"
                            onChange={cambiarMotivoDeDerivacion}
                            other="yes"
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>

                    <div className="form-group col-6 transitionThree notView" id="motivo_derivacion_interna_otros">
                        <Form.Label>Otros</Form.Label>
                        <Form.Control
                            name="motivo_derivacion_interna_otros"
                            id="motivo_derivacion_interna_otros"
                            type="text"
                            onChange={handleInputChange}
                            placeholder="Indica un motivo por el que se esta derivando el turno"
                        />
                    </div>

                    <div className="col-md-12 my-3">
                        <Form.Label>Observaciones</Form.Label>
                        <Form.Control required as="textarea" placeholder="Indique observaciones" name="observaciones" className="customArea" onChange={handleInputChange} />
                    </div>
                </div>
                <Button type="submit" className="btn-greengg d-block mx-auto my-5" text="Finalizar" />
            </Form>
        </>
    );
}