import { formatarFecha } from "../../utils/functions";
import TURN_STATE_OBJECT from "../../utils/turn_state";
import NotResult from "../../components/notResults";
import atenderTurnoADP from "../../services/adp/turn/attend";
import confirmarTurnoADP from "../../services/adp/turn/confirm";
import confirmarListaTurno from "../../services/adp/turn/confirmar-lista-turnos";
import Message from "../../utils/message";
import ToastMesage from "../../utils/toast";
import Checkbox from "../Checkbox/Checkbox";

export default function ListadoTurnos(props) {
    const { openContainerDatosPersona, setLoading, closeContainerDatosPersona, setVerOpciones, cargarLista, TOKEN, lista: result, obtenerDatosTurno, setIdTurnoSeleccionado, cargarModalDerivar, cargarModalCancelar, abrirAtencionMultiple, setTurnosSeleccionados, abrirCancelarMultiple, abrirDerivarMultiple, abrirEditarMultiple, isAllChecked, setIsAllChecked } = props;
    let { turnos_seleccionados } = props;



    const verTurno = async (id) => {
        await obtenerDatosTurno(id);
        openContainerDatosPersona();
        setVerOpciones(false);
    }

    const confirmarTurno = (id) => {
        setLoading(true)
        setIdTurnoSeleccionado(id);
        setVerOpciones(false);
        confirmarTurnoADP({
            id: id, token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0) {
                    ToastMesage({ message: `El turno #${id} fue confirmado correctamente`, 'type': 'success' });
                    cargarLista();
                    closeContainerDatosPersona();
                } else {
                    setLoading(false)
                    Message({ message: response.mensaje, type: 'error' });
                    closeContainerDatosPersona();
                }
            }
        });
    }

    const atenderTurno = async (id) => {
        setVerOpciones(true);
        setLoading(true);
        await atenderTurnoADP({
            id: id, token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0) {
                    await obtenerDatosTurno(id);
                    cargarLista();
                    await openContainerDatosPersona();
                } else {
                    setVerOpciones(false);
                    await obtenerDatosTurno(id);
                    await cargarLista();
                    await Message({ message: response.mensaje, type: 'error' })
                    await openContainerDatosPersona();
                    setLoading(false);
                }
            }
        })
    }

    const cancelarTurno = async (id) => {
        setIdTurnoSeleccionado(id);
        setVerOpciones(false);
        closeContainerDatosPersona();
        await cargarModalCancelar(id);
    }

    const derivarTurno = async (id) => {
        await obtenerDatosTurno(id);
        await cargarModalDerivar(id);
        setVerOpciones(false);
        closeContainerDatosPersona();
    }


    const agregarTurnosMultiples = ({ target }) => {
        const { id: value, checked } = target;
        let updatedList = [...turnos_seleccionados];
        if (checked) {
            if (!updatedList.includes(Number(value))) {
                updatedList = [...turnos_seleccionados, Number(value)];
            }
        } else {
            updatedList.splice(turnos_seleccionados.indexOf(Number(value)), 1);
        }
        setTurnosSeleccionados(updatedList);
    };

    const confirmarListaTurnos = () => {
        if (turnos_seleccionados.length > 0) {
            setLoading(true);
            confirmarListaTurno({
                params: { lista: turnos_seleccionados }, token: TOKEN, callbacks: async (response) => {
                    if (response.estado > 0) {
                        response.resultados.forEach(message => {
                            if (message.estado > 0) {
                                ToastMesage({ message: message.message, 'type': 'success' });
                            } else {
                                ToastMesage({ message: message.mensaje, 'type': 'error' });
                            }
                        })
                        cargarLista();
                    } else {
                        Message({ message: response.mensaje, 'type': 'error' });
                        setLoading(false);
                    }
                }
            });
        } else {
            Message({ message: 'Debes seleccionar al menos un turno', type: 'error' })
        }
    }


    const handleSelectAll = ({ target }) => {
        if (target.checked) {
            setIsAllChecked(true);
            setTurnosSeleccionados(result.map(li => Number(li.Turno.id)));
        } else {
            setIsAllChecked(false);
            setTurnosSeleccionados([]);
        }
    };
    const comprobarCheck = (id) => turnos_seleccionados.includes(Number(id));

    return (
        <>
            <div className="">
                <div>
                    <Checkbox
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isAllChecked}
                    />
                </div>
                <div className={`d-flex justify-content-center containerAccionMasiva ${turnos_seleccionados.length < 2 ? 'notViewMasive' : ''}`}>
                    <button type="button" className="btnDe" onClick={abrirEditarMultiple}>Editar</button>
                    <button type="button" className="btnAtender" onClick={abrirAtencionMultiple}>Atender</button>
                    <button type="button" className="btnDerivar" onClick={abrirCancelarMultiple}>Cancelar</button>
                    <button type="button" className="btnDe" onClick={abrirDerivarMultiple}>Derivar</button>
                    <button type="button" className="btnConfirmar" onClick={confirmarListaTurnos}>Confirmar</button>
                </div>
            </div>
            <div>
                {result.length === 0 ?
                    <NotResult title="No hay resultados" id="notResultMyTurns" className="my-3" />
                    :
                    result.map(item => {
                        const { Turno, Persona } = item;
                        const { id, estado, fecha, hora, motivo, empresa } = Turno;
                        const { nombre, apellido, dni, legajo } = Persona;
                        const { nombre: motivoTurnoNombre, abreviacion } = motivo;
                        const { nro } = dni;
                        const fecha_formateada = formatarFecha(fecha);
                        const estadoTotal = TURN_STATE_OBJECT[estado];
                        return (
                            <div key={id} className="grid-custom-pendiente">
                                <div className="mt-md-0 datos-card parrafoGray">
                                    <Checkbox
                                        key={id}
                                        type="checkbox"
                                        name="radio_checkbox"
                                        id={id}
                                        handleClick={agregarTurnosMultiples}
                                        isChecked={comprobarCheck(id)}
                                    />
                                    {/* <input value={id} type="checkbox" onChange={agregarTurnosMultiples} /> */}

                                </div>

                                <div>
                                    <p className="m-0 boldP">#{abreviacion}-{id}</p>
                                </div>

                                <div>
                                    <p className="m-0 boldP">Horario: {hora}</p>
                                    <p className="m-0 boldP">Fecha: {fecha_formateada}</p>
                                    <p className="m-0 boldP mt-3">{`${motivoTurnoNombre}`}</p>
                                </div>

                                <div>
                                    {/* <p className="m-0 boldP">Colaborador: </p> */}
                                    <p className="m-0 boldP">DNI: {nro}</p>
                                    <p className="m-0 boldP">Legajo: {legajo}</p>
                                    <p className="m-0 my-2 boldP">Colaborador: {`${nombre} ${apellido}`}</p>
                                    <p className="m-0 my-2 boldP">Cliente: {`${empresa}`}</p>
                                </div>

                                <div className="d-flex align-items-center">
                                    {/* <span className="ballTurnoPendiente" data-color={estadoTotal.dataColor - 1}></span> */}
                                    <p className="infoTurno" data-color={estadoTotal.dataColor - 1}>{estadoTotal.display}</p>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <button className={`${estadoTotal.botones.ver ? '' : 'disabled'} btnDe`} onClick={() => { verTurno(id) }} disabled={`${estadoTotal.botones.ver ? '' : 'disabled'}`}>
                                        Ver
                                    </button>

                                    <button className={`${estadoTotal.botones.atender ? '' : 'disabled'} btnAtender`} onClick={() => { atenderTurno(id) }} disabled={`${estadoTotal.botones.atender ? '' : 'disabled'}`}>
                                        Atender
                                    </button>

                                    <button className={`${estadoTotal.botones.cancelar ? '' : 'disabled'} btnDerivar`} onClick={() => { cancelarTurno(id) }} disabled={`${estadoTotal.botones.cancelar ? '' : 'disabled'}`}>
                                        Cancelar
                                    </button>

                                    <button className={`${estadoTotal.botones.derivar ? '' : 'disabled'} btnDe`} onClick={() => { derivarTurno(id) }} disabled={`${estadoTotal.botones.derivar ? '' : 'disabled'}`}>
                                        Derivar
                                    </button>

                                    <button className={`${estadoTotal.botones.confirmar ? '' : 'disabled'} btnConfirmar`} onClick={() => { confirmarTurno(id) }} disabled={`${estadoTotal.botones.confirmar ? '' : 'disabled'}`}>
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    );
}