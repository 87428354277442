import Title from "../../components/Title";
import { Spinner } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import obtenerListadoPersonasInterno from "../../services/adp/person/list-internal";
import getPerson from '../../services/adp/person/get';
import PERSON_TYPE from "../../config/person_type";
import { useAuth } from "../../hooks/auth";
import { useUser } from "../../hooks/user";
import EditarPersonaModal from "../../components/Modals/EditarPersona";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import Message from "../../utils/message";
import eliminarPersonaService from "../../services/adp/person/delete";

export default function Personas(props) {
    const { token: TOKEN } = useAuth();
    const { user: USER } = useUser();
    const [persons, setPersons] = useState([]);

    const [loading, setLoading] = useState(true);
    const [loading_all, setLoadingAll] = useState(false);
    const persona_default = {
        idNitro4: "",
        name: "",
        surname: "",
        email: "",
        document_number: "",
        document_type_id: "",
        document_type: "",
        person_type_id: ""
    };
    const [persona, setPersona] = useState(persona_default);


    const [mostrarModal, setMostrarModal] = useState(false);

    useEffect(() => {
        obtenerListadoPersonasInterno({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    let list = response.resultados;
                    setPersons(list.filter(
                        person => USER.person_type === 'SUPERVISOR' ?
                            person.person_type !== PERSON_TYPE.COLABORADOR.name && person.person_type !== PERSON_TYPE.ADMIN.name :
                            person.person_type !== PERSON_TYPE.COLABORADOR.name
                    ));
                }
                setLoading(false);
            }
        });
    }, [TOKEN, USER]);
    async function editarPersona(e) {
        e.preventDefault();
        setLoadingAll(true);
        await getPerson({
            token: TOKEN, id: e.target.rel, callbacks: (response) => {
                setLoadingAll(false);
                if (response.estado > 0 && response.resultados.length > 0) {
                    setMostrarModal(true);
                    const data = response.resultados[0];
                    setPersona({
                        idNitro4: data.id,
                        name: data.name,
                        surname: data.surname,
                        email: data.email,
                        document_number: data.document_number,
                        document_type: data.document_type_id,
                        document_type_name: data.document_type,
                        type_profile: data.person_type_id
                    });
                }
            }
        })
    }

    async function eliminarPersona(e) {
        e.preventDefault();
        Swal.fire({
            title: 'Estas seguro que quieres eliminar este usuario?',
            showDenyButton: true,
            showCancelButton: false,
            icon: 'info',
            confirmButtonColor: "var(--color-orange)",
            confirmButtonText: 'Aceptar',
            denyButtonColor: "var(--color-danger)",
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                setLoadingAll(true);
                setLoading(true);
                eliminarPersonaService({
                    id: e.target.rel, token: TOKEN, callbacks: result => {
                        obtenerListadoPersonasInterno({
                            token: TOKEN, callbacks: response => {
                                if (response.estado > 0 && response.resultados.length > 0) {
                                    let list = response.resultados;
                                    setPersons(list.filter(
                                        person => USER.person_type === 'SUPERVISOR' ?
                                            person.person_type !== PERSON_TYPE.COLABORADOR.name && person.person_type !== PERSON_TYPE.ADMIN.name :
                                            person.person_type !== PERSON_TYPE.COLABORADOR.name
                                    ));
                                }
                                setLoadingAll(false);
                                setLoading(false);
                                Message({ message: result.mensaje, type: result.estado > 0 ? 'success' : 'error' });
                            }
                        });
                    }
                })
            }
        })

    }
    return (
        <main>
            {loading_all ?
                <Loading />
                :
                <>
                    <div className="container-fluid ps-0 my-5">
                        <div className="glassDatos">
                            <div className="pb-4 d-flex align-items-center justify-content-between">
                                <Title title="Listado de personas" class="titlegray" />
                            </div>
                            <div className="col-12">
                                {loading ?
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </Spinner>
                                    :
                                    <table className="table table-stripped table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre</th>
                                                <th>Apellido</th>
                                                <th>Email</th>
                                                <th>Documento</th>
                                                <th>Rol</th>
                                                {(USER.person_type === 'ADMIN' || USER.person_type === 'SUPERVISOR') && <th></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                persons.map((item) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.id}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.surname}</td>
                                                            <td>{item.email ?? 'S/D'}</td>
                                                            <td>{item.document_type}: {item.document_number}</td>
                                                            <td><span className="infoTurno" data-color={PERSON_TYPE[item.person_type].color.dataColor - 1}>{PERSON_TYPE[item.person_type].name}</span></td>
                                                            {(USER.person_type === 'ADMIN' || USER.person_type === 'SUPERVISOR') &&
                                                                <td>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn-sm">&nbsp;</Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item rel={item.id} onClick={editarPersona}>Editar</Dropdown.Item>
                                                                            <Dropdown.Item rel={item.id} onClick={eliminarPersona}>Eliminar</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>}
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                    <EditarPersonaModal
                        ver_modal={mostrarModal}
                        setAbrir={setMostrarModal}
                        persona={persona}
                        setPersona={setPersona}
                        setLoadingAll={setLoadingAll}
                        setPersons={setPersons}
                        persona_default={persona_default}
                    />
                </>
            }
        </main>
    )
}