import { useEffect, useState } from "react";
import AgregarMotivoTurnoATurno from "../Forms/agregarMotivoTurno_Turno";
import getListTurnReasons from "../../services/adp/turn_reason/list";
import MotivosTurnoLista from "./motivos_turno";
import { useAuth } from "../../hooks/auth";


export default function DatosMotivoTurno(props) {
    const [motivos_turno_turno_actual, setMotivosturnoTurnoActual] = useState([]);
    const [ver_agregar_motivo_turno, setAgregarMotivoTurno] = useState(false);
    const { token: TOKEN } = useAuth();
    const { datosTurno, obtenerDatosTurno, setLoader, cargarLista } = props;

    const [motivos_turno, setMotivoTurnoList] = useState([]);

    useEffect(() => {
        getListTurnReasons({
            token: TOKEN, callbacks: response => {
                setMotivoTurnoList(response.resultados ?? [])
            }
        });
        setMotivosturnoTurnoActual(datosTurno.motivos_turno);
    }, [TOKEN, datosTurno])
    return (
        <div className="row align-items-center">
            <div className="d-flex align-items-baseline">
                <div className="col-6">
                    <h5 className="m-0 fh5">Motivos de turno:</h5>
                </div>
                <div className="col-6 d-flex align-items-baseline justify-content-end">
                    <button className="btnAtender mb-4" onClick={() => { setAgregarMotivoTurno(true) }}>Agregar</button>
                </div>
            </div>
            <div className="col-12">
                <ul>
                    <MotivosTurnoLista
                        id={datosTurno.turno_id}
                        obtenerDatosTurno={obtenerDatosTurno}
                        setLoader={setLoader}
                        cargarLista={cargarLista}
                        motivos_actuales={motivos_turno_turno_actual}
                        setMotivosturno={setMotivosturnoTurnoActual}
                        TOKEN={TOKEN}
                    />
                </ul>
            </div>
            <div className="col-12">
                <AgregarMotivoTurnoATurno
                    id={datosTurno.turno_id}
                    obtenerDatosTurno={obtenerDatosTurno}
                    setLoader={setLoader}
                    cargarLista={cargarLista}
                    motivos_actuales={motivos_turno_turno_actual}
                    setMotivosturno={setMotivosturnoTurnoActual}
                    motivos_turno={motivos_turno}
                    ver_agregar_motivo_turno={ver_agregar_motivo_turno}
                    cerrarAgregarMotivoTurnoTurno={() => { setAgregarMotivoTurno(false) }}
                    TOKEN={TOKEN}
                />
            </div>
        </div>
    );
}