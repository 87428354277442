import React, { useState } from 'react';
import { Col, Form, Row, Container } from 'react-bootstrap';
import agregarMotivoCancelacion from '../../../services/adp/motivo_cancelacion/add';
import editarMotivoCancelacion from '../../../services/adp/motivo_cancelacion/update';
import Button from '../../Button';


export default function FormAgregarEditarMotivoCancelacion(props) {
    const { TOKEN, motivoSeleccionado, obtenerListadoMotivos, restablecerModal } = props;
    const [formValues, setValues] = useState(motivoSeleccionado === null ? {
        name: '',
        description: '',
    } : {
        id: motivoSeleccionado.id,
        name: motivoSeleccionado.name,
        description: motivoSeleccionado.description,
    });
    const [validated, setValidated] = useState(false);

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            if (motivoSeleccionado === null) {
                agregarMotivoCancelacion({
                    params: formValues, token: TOKEN, callbacks: async (response) => {
                        if (response.estado > 0) {
                            await obtenerListadoMotivos();
                            await restablecerModal();
                        }
                    }
                });
            } else {
                editarMotivoCancelacion({
                    params: formValues, token: TOKEN, callbacks: async (response) => {
                        if (response.estado > 0) {
                            await obtenerListadoMotivos();
                            await restablecerModal();
                        }
                    }
                });
            }
        }
    };

    return (
        <Container className='py-5'>
            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <Row className="mb-3 flex-column">
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Motivo de cancelacion"
                                className='input-gg'
                                defaultValue={formValues.name ?? ''}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                type="text"
                                name="description"
                                id="description"
                                placeholder="Descripcion"
                                className='input-gg'
                                defaultValue={formValues.description ?? ''}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center'>
                    {
                        (motivoSeleccionado === null) ?
                            <Button type="submit" className="btn-greengg d-block mx-auto" text="Agregar" />
                            :
                            <Button type="submit" className="btn-orange d-block mx-auto" text="Editar" />
                    }
                </div>
            </Form>
        </Container>
    );
}