import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";


const getmotivoTurnosData = async ({ id, token, callbacks }) => {
    const response = await connection({ method: 'GET', url: `${ENTITY_URL.MOTIVO_TURNO.GET}${id}`, extraHeaders: { token: token } });;
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default getmotivoTurnosData;