import React from "react";
import Title from "../../components/Title";
import './_Metricas.scss';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";

function Metricas()
{
    const options = {
        chart: {
            type: 'area',
            width: 500
        },
        accessibility: {
            description: ''
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            allowDecimals: false,
            labels: {
                formatter: function () {
                    return this.value;
                }
            },
            accessibility: {
                rangeDescription: 'Range: 1940 to 2017.'
            }
        },
        yAxis: {
            title: {
                text: false
            },
            labels: {
                formatter: function () {
                    return this.value / 1000 + ' Turnos';
                }
            }
        },
        tooltip: {
            pointFormat: '{series.name} tiene <b>{point.y:,.0f}</b><br/>turnos'
        },
        plotOptions: {
            area: {
                pointStart: 1940,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 5,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },
        series: [{
            name: 'Sucursal norte',
            data: [
                null, null, null, null, null, 2, 9, 13, 50, 170, 299, 438, 841,
                1169, 1703, 2422, 3692, 5543, 7345, 12298, 18638, 22229, 25540,
                28133, 29463, 31139, 31175, 31255, 29561, 27552, 26008, 25830,
                26516, 27835, 28537, 27519, 25914, 25542, 24418, 24138, 24104,
                23208, 22886, 23305, 23459, 23368, 23317, 23575, 23205, 22217,
                21392, 19008, 13708, 11511, 10979, 10904, 11011, 10903, 10732,
                10685, 10577, 10526, 10457, 10027, 8570, 8360, 7853, 5709, 5273,
                5113, 5066, 4897, 4881, 4804, 4717, 4571, 4018, 3822, 3785, 3805,
                3750, 3708, 3708
            ]
        }, {
            name: 'Sucursal sur',
            data: [null, null, null, null, null, null, null, null, null,
                1, 5, 25, 50, 120, 150, 200, 426, 660, 863, 1048, 1627, 2492,
                3346, 4259, 5242, 6144, 7091, 8400, 9490, 10671, 11736, 13279,
                14600, 15878, 17286, 19235, 22165, 24281, 26169, 28258, 30665,
                32146, 33486, 35130, 36825, 38582, 40159, 38107, 36538, 35078,
                32980, 29154, 26734, 24403, 21339, 18179, 15942, 15442, 14368,
                13188, 12188, 11152, 10114, 9076, 8038, 7000, 6643, 6286, 5929,
                5527, 5215, 4858, 4750, 4650, 4600, 4500, 4490, 4300, 4350, 4330,
                4310, 4495, 4477
            ]
        },
        {
            name: 'Sucursal oeste',
            data: [null, null, null,
                1, 5, 25, 50, 120, 150, 200, 426, 660, 863, 1048, 1627, 2492,
                3346, 4259, 5242, 6144, 7091, 8400, 9490, 10671, 11736, 13279,
                14600, 15878, 17286, 19235, 22165, 24281, 26169, 28258, 30665,
                32146, 33486, 35130, 36825, 38582, 40159, 38107, 36538, 35078,
                32980, 29154, 26734, 24403, 21339, 18179, 15942, 15442, 14368,
                13188, 12188, 11152, 10114, 9076, 8038, 7000, 6643, 6286, 5929,
                5527, 5215, 4858, 4750, 4650, 4600, 4500, 4490, 4300, 4350, 4330,
                4310, 4495, 4477
            ]
        }
        ]
      };

    return(
        <main>
        <div className="container-fluid ps-0">
            <div className="glassDatos">
                <div className="pb-4 d-flex align-items-center justify-content-between">
                    <Title title='Métricas' class="titlegray" />
                </div>

                <div className='col-12'>
                    <div className="col-12 my-5">
                        <Title title='Cantidad de turnos' class="titlegray" />
                        <div className="d-flex my-4">
                            <div className="col-6">
                                <div className="my-4 gridMetricas">
                                    <h3 className="h6 m-0">Sucursal norte</h3>
                                    <div className="progress">
                                        <div className="progress-bar w-25" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <h3 className="h6 m-0">50 turnos</h3>
                                </div>

                                <div className="my-4 gridMetricas">
                                    <h3 className="h6 m-0">Sucursal sur</h3>
                                    <div className="progress">
                                        <div className="progress-bar w-25" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <h3 className="h6 m-0">50 turnos</h3>
                                </div>

                                <div className="my-4 gridMetricas">
                                    <h3 className="h6 m-0">Sucursal oeste</h3>
                                    <div className="progress">
                                        <div className="progress-bar w-50" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <h3 className="h6 m-0">50 turnos</h3>
                                </div>

                                <div className="my-4 gridMetricas">
                                    <h3 className="h6 m-0">Sucursal rio</h3>
                                    <div className="progress">
                                        <div className="progress-bar w-75" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <h3 className="h6 m-0">50 turnos</h3>
                                </div>

                                <div className="my-4 gridMetricas">
                                    <h3 className="h6 m-0">Sucursal rosario</h3>
                                    <div className="progress">
                                        <div className="progress-bar w-100" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <h3 className="h6 m-0">50 turnos</h3>
                                </div>
                            </div>

                            <div className="col-6 divTotalTurnos">
                                <div className="selectViewTurnos">
                                    <div className="divSelect">
                                        <p className="m-0 me-1">Mostrar:</p> 
                                        <select name="" id="">
                                            <option value="">Mensual</option>
                                            <option value="">Anual</option>
                                            <option value="">Diario</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="containerNumberTurnos">
                                    <h2 className="m-0">Total de turnos</h2>
                                    <p>345<span>turnos</span></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 d-flex align-items-center">
                            <div className="col-6">
                                <div>
                                    <div className="divSelect">
                                        <p className="m-0 me-1">Mostrar:</p> 
                                        <select name="" id="">
                                            <option value="">Mensual</option>
                                            <option value="">Anual</option>
                                            <option value="">Diario</option> 
                                        </select> 
                                    </div>
                                </div>
                                <HighchartsReact highcharts={Highcharts} options={options} />
                            </div>

                            <div className="col-6">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    );
}

export default Metricas