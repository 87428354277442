import React from "react";
import { Container, } from "react-bootstrap";
import { useParams } from "react-router-dom";
import FormCancelarTurnoColaborador from "../../components/Forms/CancelarTurno/cancelarTurnoColaborador";
import Title from "../../components/Title";

function CancelarTurnoColaborador(props) {
    const { id } = useParams();

    return (
        <main className="pt-3">
            <Title title="Cancelar Turno" class="titlegray" />
            <Container className='pb-5'>
                <FormCancelarTurnoColaborador id={id} />
            </Container>
        </main>
    );
}

export default CancelarTurnoColaborador;