import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { useUser } from "./user";
import PERSON_TYPE from "../config/person_type";
import getBoxAssigned from "../services/adp/person/get_box_assigned";
import { isEmpty } from "../utils/functions";

const BoxContext = createContext(null);
export const BoxProvider = ({ children }) => {
    const { token, setLoading } = useAuth();
    const [loading_box, setLoadingBox] = useState(false);
    const { user } = useUser();
    const [box, setBox] = useState({
        idNitro4: 0,
        box: 'Sin box'
    });

    const removeBox = () => {
        setBox({
            idNitro4: 0,
            box: 'Sin box'
        });
    }

    useEffect(() => {
        if (!isEmpty(token)) {
            const person_type_user = user?.person_type || '';
            if (PERSON_TYPE[person_type_user]) {
                if (PERSON_TYPE[person_type_user].add_branch) {
                    setLoading(true);
                    setLoadingBox(true);
                    getBoxAssigned({
                        token: token, callbacks: response => {
                            setLoadingBox(false);
                            setLoading(false);
                            if (response.estado > 0 && response.resultados.length > 0) {
                                setBox(response.resultados[0]);
                            } else {
                                removeBox();
                            }
                        }
                    });
                } else {
                    removeBox();
                }
            } else {
                removeBox();
            }
        } else {
            removeBox();
        }
    }, [token, user, setLoading])
    return <BoxContext.Provider value={{
        box, setBox, loading_box
    }}>{children}</BoxContext.Provider>
}

export const useBox = () => {
    return useContext(BoxContext);
}