import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Container, Alert, Spinner } from 'react-bootstrap';
import getListBranchs from '../../../services/adp/branch/list';
import Select from '../../Select/select';
import Button from '../../Button';
// import getListPersons from '../../../services/adp/person/list';
import assignBranchToPerson from '../../../services/adp/person/assign_brach';
import PERSON_TYPE from '../../../config/person_type';
import { disabledButton, enabledButton, isEmpty } from '../../../utils/functions';
import getBranchToPerson from '../../../services/adp/person/get_branch_by_id';
import obtenerListadoPersonasInterno from '../../../services/adp/person/list-internal';
import { useAuth } from '../../../hooks/auth';


export default function FormAsignarSucursal(props) {
    const { token: TOKEN } = useAuth();
    const [validated, setValidated] = useState(false);

    const [branchs, setBranchs] = useState([]);
    const [persons, setPersons] = useState([]);
    const [loading, setLoading] = useState(true);

    const [person_id, setPersonID] = useState(0);
    const [branch_id, setBranchID] = useState(0);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            const formValues = {
                person_id: person_id,
                branch_id: branch_id
            }
            assignBranchToPerson({
                params: formValues, token: TOKEN, callbacks: async (response) => {
                    if (response.estado) {
                        document.getElementById('assignBranchAlertError').classList.add('d-none');
                        document.getElementById('assignBranchAlert').classList.remove('d-none');
                        document.getElementById('assignBranchAlert').innerHTML = 'Se asigno correctamente la sucursal';
                        document.location.reload();
                    } else {
                        document.getElementById('assignBranchAlert').classList.add('d-none');
                        document.getElementById('assignBranchAlertError').classList.remove('d-none');
                        document.getElementById('assignBranchAlertError').innerHTML = response.mensaje ?? '';

                    }
                }
            });
        }
    };

    const elementSucursalID = document.getElementById('branch_id');
    const selectPersonBranch = async (evt) => {
        const { target } = evt;
        const { value } = target;
        disabledButton({ element: elementSucursalID });
        if (!isEmpty(value)) {
            setPersonID(value);
            await getBranchToPerson({
                id: value, token: TOKEN, callbacks: async (response) => {
                    if (response.estado && response.resultados.length > 0) {
                        let branchID = response.resultados[0].branchID;
                        elementSucursalID.value = branchID;
                        setBranchID(branchID);
                        enabledButton({ element: elementSucursalID });
                    } else {
                        enabledButton({ element: elementSucursalID });
                        // elementSucursalID.value = '';
                        setBranchID(0);
                    }
                }
            })
        }
    }

    const selectBranch = async (evt) => {
        const { target } = evt;
        const { value } = target;
        setBranchID(value);
    }

    useEffect(() => {
        getListBranchs({
            token: TOKEN, callbacks: response => {
                setBranchs(response.resultados ?? []);
            }
        });

        obtenerListadoPersonasInterno({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    let list = response.resultados;
                    setPersons(list.filter(person => person.person_type !== PERSON_TYPE.COLABORADOR.name && person.person_type !== PERSON_TYPE.ADMIN.name).map((person) => {
                        let item = {
                            id: person.id,
                            name: `${person.name.toUpperCase()} ${person.surname.toUpperCase()} - ${person.person_type.toUpperCase()}`
                        }
                        return item;
                    }));
                }
                setLoading(false);
            }
        });
    }, [TOKEN]);

    return (
        <Container className='col-12 col-md-6 glassDatos'>
            <Alert id="assignBranchAlert" variant="success" className='d-none my-3'></Alert>
            <Alert id="assignBranchAlertError" variant="danger" className='d-none my-3'></Alert>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 flex-column justify-content-center">
                    <Col className='my-3'>
                        {
                            loading ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Cargando...</span>
                                </Spinner>
                                :
                                <Form.Group>
                                    <Form.Label>Persona</Form.Label>
                                    <Select
                                        value={persons}
                                        name="Seleccione la persona"
                                        id="person_id"
                                        placeholder="Seleccione la persona"
                                        onChange={selectPersonBranch}
                                    />
                                    <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                </Form.Group>
                        }
                    </Col>
                    <Col className='my-3'>
                        {
                            loading ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Cargando...</span>
                                </Spinner>
                                :
                                <Form.Group>
                                    <Form.Label>Sucursal</Form.Label>
                                    <Select
                                        value={branchs}
                                        name="Seleccione la sucursal"
                                        id="branch_id"
                                        placeholder="Seleccione la sucursal"
                                        onChange={selectBranch}
                                        disabled
                                    />
                                    <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                </Form.Group>
                        }
                    </Col>
                </Row>
                <Button type="submit" className="btn-greengg d-block mx-auto my-5" text="Actualizar" />
            </Form>
        </Container>
    );
}