import { RaceBy } from "@uiball/loaders";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import obtenerMotivoDerivacion from "../../../services/adp/motivo_derivacion/get";
import { isEmpty } from "../../../utils/functions";
import FormAgregarEditarMotivoDerivacion from "../../Forms/AgregarEditarMotivoDerivacion";

export function ModalMotivosDerivacion(props) {
    const { idSeleccionado, TOKEN, ver_modal, cerrarModal, setAbrirModal, setIdSeleccionado, obtenerListadoMotivos } = props;
    const [motivoSeleccionado, setMotivoSeleccionado] = useState(null);
    const [loader, setLoader] = useState(true)

    const obtenerDatosMotivo = useCallback(async () => {
        await obtenerMotivoDerivacion({
            id: idSeleccionado, token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setMotivoSeleccionado(response.resultados[0]);
                    setLoader(false);
                }
            }
        });
    }, [TOKEN, idSeleccionado]);

    useEffect(() => {
        if (!isEmpty(idSeleccionado)) {
            obtenerDatosMotivo();
        }
    }, [obtenerDatosMotivo, idSeleccionado]);

    async function restablecerModal() {
        await setMotivoSeleccionado(null)
        await setLoader(true);
        await cerrarModal();
    }


    async function abrirModalAgregar() {
        await setLoader(true);
        await setMotivoSeleccionado(null)
        await setAbrirModal(true)
        await setIdSeleccionado(0);
        await setLoader(false);
    }
    return (
        <>
            <button className="buttonModal" onClick={abrirModalAgregar}>+ Agregar</button>
            <div className={`modal ${!ver_modal ? 'fade' : 'show d-block'}`} id="modalAgregarMotivoDerivacion" tabIndex="-1" aria-labelledby="modalAgregarMotivoDerivacionLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center" id="modalAgregarMotivoDerivacionLabel">{`${motivoSeleccionado === null ? 'Agregar motivo derivacion' : `Editar motivo derivacion #${idSeleccionado}`}`}</h5>
                            <button type="button" className="btn-close" id="modalDerivacionClose" onClick={restablecerModal} aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <div className="modal-body">
                                {
                                    loader &&
                                    <RaceBy
                                        size={280}
                                        lineWeight={15}
                                        speed={1.4}
                                        color="black"
                                        className="d-flex"
                                    />
                                }
                                {
                                    !loader &&
                                    <FormAgregarEditarMotivoDerivacion
                                        TOKEN={TOKEN}
                                        motivoSeleccionado={motivoSeleccionado}
                                        obtenerListadoMotivos={obtenerListadoMotivos}
                                        restablecerModal={restablecerModal}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}