import Title from "../../components/Title";
import { Container, Dropdown, Table } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { connection } from "../../utils/connection";
import { ENTITY_URL } from "../../config/config";
import getListBranchs from "../../services/adp/branch/list";
import { useAuth } from "../../hooks/auth";

export default function Sucursales() {
    const [branchs, setSucursales] = useState([]);
    const { token: TOKEN } = useAuth();

    useEffect(() => {
        getListBranchs({
            token: TOKEN, callbacks: response => {
                setSucursales(response.resultados ?? []);
            }
        });
    }, [TOKEN]);


    async function deleteSucursal(e) {
        const id = e.target.getAttribute('data-id');
        const response = await connection({ method: 'DELETE', url: `${ENTITY_URL.SUCURSAL.DELETE}${id}`, extraHeaders: { token: TOKEN } });;
        if (response.estado > 0) {
            getListBranchs({
                token: TOKEN, callbacks: response => {
                    setSucursales(response.resultados ?? []);
                }
            });
        }
    }

    return (
        <>
            <Container>
            <Title title="Sucursales" class="title" />
                <Table responsive="lg">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Direccion</th>
                            <th>Provincia</th>
                            <th>Localidad</th>
                            <th>Coordenadas</th>
                            <th>Empleados</th>
                            <th>Turnos</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            branchs.map((item) => {
                                const editSucursal = `/editar-sucursal/${item.id}`;
                                return (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.address}</td>
                                        <td>{item.province}</td>
                                        <td>{item.locality}</td>
                                        <td>Lat: {item.latitude} Long: {item.longitude}</td>
                                        <td>{item.employees}</td>
                                        <td>{item.quantity}</td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn-sm">&nbsp;</Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href={editSucursal}>Editar</Dropdown.Item>
                                                    <Dropdown.Item data-id={item.id} href="#" onClick={deleteSucursal}>Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Container>
        </>
    )
}