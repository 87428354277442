import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormAgregarPersona from '../../components/Forms/AgregarEditarPersona';
import Title from "../../components/Title";
import PERSON_TYPE from '../../config/person_type';
import getListDocumentTypes from '../../services/adp/document_type/list';
import getPerson from '../../services/adp/person/get';
import getListProfiles from '../../services/adp/profile/list';
import { useAuth } from '../../hooks/auth';
import { useUser } from '../../hooks/user';
export default function EditarPersonas(props) {
    const { token: TOKEN } = useAuth();
    const { id } = useParams();
    const { user } = useUser();
    const TYPE_PERSON = user.person_type;
    const ID_USER = user.id;

    const [persona, setPersonaData] = useState([]);
    const [documentTypeList, setDniType] = useState([]);
    const [profilesTypeList, setProfileType] = useState([]);

    useEffect(() => {
        getPerson({
            token: TOKEN, id: id, callbacks: (response) => {
                setPersonaData(response.resultados[0]);
            }
        });
        getListDocumentTypes({
            token: TOKEN, callbacks: response => {
                setDniType(response.resultados ?? []);
            }
        });
        getListProfiles({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    let listita = response.resultados.filter(item => TYPE_PERSON === 'ADMIN' ? PERSON_TYPE[item.name].it_is_allowed_to_add : PERSON_TYPE[item.name].it_is_allowed_to_add && item.name !== 'ADMIN');
                    if (TYPE_PERSON === 'NOT_REGISTRY') {
                        setProfileType(listita.filter(item => item.id !== 7));
                        return
                    }
                    setProfileType(listita);
                }

            }
        });
    }, [TOKEN, id, TYPE_PERSON]);

    return (
        <>
            <Title title="Editar una persona" class="title" />

            <FormAgregarPersona name={persona.name} surname={persona.surname} email={persona.email} dni={persona.document_number} document_type={persona.document_type} document_type_list={documentTypeList} ID_USER={ID_USER} TYPE_PERSON={TYPE_PERSON} profile_type_list={profilesTypeList} TOKEN={TOKEN} id={id} update="yes" />
        </>
    )
}