import { createContext, useContext, useState } from "react";

const UserContext = createContext(null);
export const UserProvider = ({ children }) => {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) ?? null);
    const saveUser = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
    }
    const removeUser = () => {
        setUser(null);
        localStorage.removeItem('user')
    }
    return <UserContext.Provider value={{
        user, setUser, saveUser, removeUser
    }}>{children}</UserContext.Provider>
}

export const useUser = () => {
    return useContext(UserContext);
}