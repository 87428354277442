import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Message from '../../../utils/message';
import cambiarPassword from '../../../services/auth/change-password';
import { isEmpty } from '../../../utils/functions';
import { useAuth } from '../../../hooks/auth';

function FormCambiarPassword(props) {
    const { token: TOKEN } = useAuth();
    const MENSAJE_INICIAL = 'La contraseña debe tener al menos 8 caracteres. La contraseña debe tener al menos un caracter en minusculas. La contraseña debe tener al menos un caracter en mayusculas. La contraseña debe tener al menos un caracter especial.';
    const [validated, setValidated] = useState(false);
    const [message, setMessage] = useState(MENSAJE_INICIAL);
    const [message_type, setMessageType] = useState('info');

    const [formValues, setValues] = useState({
        password: "",
        repeatPassword: "",
    });

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            cambiarPassword({
                params: formValues, token: TOKEN, callbacks: response => {
                    if (response.estado > 0) {
                        form.reset();
                        setValues({
                            password: "",
                            repeatPassword: "",
                        });
                        Message({ message: 'La contraseña se actualizo correctamente', type: 'success' })
                        setMessage('La contraseña se actualizo correctamente');
                        setMessageType('success');
                    } else {
                        Message({ message: response.mensaje ?? 'Ocurrion un error al cambiar la password', type: 'error' });
                        setMessage(response.mensaje ?? 'Ocurrion un error al cambiar la password');
                        setMessageType('danger');
                    }

                    setTimeout(() => {
                        setMessage(MENSAJE_INICIAL);
                        setMessageType('info');
                    }, 5000)
                }
            });
        }
    };


    const viewPasswordInput = (inputName, ojitoName) => {
        const input = document.getElementById(inputName);
        const eye = document.getElementById(ojitoName);
        if (input.type === "text") {
            input.type = 'password';
            eye.classList.add('fa-eye-slash');
            eye.classList.remove('fa-eye');
            return;
        }
        input.type = 'text';
        eye.classList.remove('fa-eye-slash');
        eye.classList.add('fa-eye');
    }

    return (
        <>
            <div className={`alert alert-${message_type}`}>
                {message}
            </div>
            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <div className="row my-3">
                    <div className="col-md-6">
                        <div className="form-group my-3">
                            <label htmlFor="password" className='m-0 parrafoGray'>Password</label>
                            <div className='viewPassword'>
                                <input
                                    className='inputTypePassword input-gg'
                                    required
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="*********"
                                    onChange={handleInputChange}
                                />
                                <i className="ojito fas fa-eye-slash" id="ojito"
                                    onClick={e => viewPasswordInput('password', 'ojito')}></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group my-3">
                            <label htmlFor="repeatPassword" className='m-0 parrafoGray'>Repetir password</label>
                            <div className='viewPassword'>
                                <input
                                    className='inputTypePassword input-gg'
                                    required
                                    type="password"
                                    name="repeatPassword"
                                    id="repeatPassword"
                                    placeholder="*********"
                                    onChange={handleInputChange}
                                />
                                <i className="ojito fas fa-eye-slash" id="ojitorepeat"
                                    onClick={e => viewPasswordInput('repeatPassword', 'ojitorepeat')}></i>
                            </div>
                        </div>
                    </div>

                    <div className='col-12'>
                        <button type="submit" className={`btn-greengg d-block mx-auto my-5 ${isEmpty(formValues.password) || isEmpty(formValues.repeatPassword) ? 'disabled' : ''}`} {...`${isEmpty(formValues.password) || isEmpty(formValues.repeatPassword) ? 'disabled' : ''}`}>Actualizar</button>
                    </div>
                </div>
            </Form>
        </>
    );
}

export default FormCambiarPassword;