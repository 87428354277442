export const removeDisabled = ({ element }) => {
    if (element) {
        element.removeAttribute('disabled');
        element.classList.remove('disabled');
    }
}

export const addDisabled = ({ element }) => {
    if (element) {
        element.setAttribute('disabled', 'disabled');
        element.classList.add('disabled');
    }
}


export const generateRandomString = (num) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1 = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
        result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result1;
}


export const disabledButton = ({ element }) => {
    if (element) {
        element.dataset.disabled = 'on';
        element.setAttribute('disabled', 'disabled');
        element.classList.add('disabled');
        element.style.cursor = 'not-allowed';
        element.style.pointerEvents = 'none';
    }
}

export const enabledButton = ({ element }) => {
    if (element) {
        element.dataset.disabled = 'off';
        element.removeAttribute('disabled');
        element.classList.remove('disabled');
        element.style.cursor = 'pointer';
        element.style.pointerEvents = 'all';
    }
}

export const isEmpty = (string) => {
    return (!string || 0 === string.length || string === undefined || string === '0' || string === 0);
}

export function random(min, max) {
    return Math.floor((Math.random() * (max - min + 1)) + min);
}

export function mapObjectToArray(obj) {
    let result = [];
    let fields = Object.keys(obj);
    for (var i = 0; i < fields.length; i++) {
        result.push(obj[fields[i]]);
    }
    return result;
}

export const formatarFecha = (fecha) => {
    const [year, month, day] = fecha.split('-');
    return `${day}/${month}/${year}`;
}

export const mostrarOtros = (target, otros) => {
    const { value } = target;
    if (parseInt(value) === 0) {
        otros.classList.remove('d-none');
    } else {
        otros.classList.add('d-none');
    }
}

export const mostrarOtrosEstiloso = (target, otros) => {
    const { value } = target;
    if (parseInt(value) === 0) {
        otros.classList.remove('notView');
    } else {
        otros.classList.add('notView');
    }
}

export const comprobarFechaMayor = ({ valor, horarios, setValue }) => {
    let fecha_hoy = new Date();
    let fecha_seleccionada = new Date(valor);
    fecha_seleccionada.setDate(fecha_seleccionada.getDate() + 1);
    const comprobar_dias = fecha_hoy.getDate() === fecha_seleccionada.getDate();
    const comprobar_mes = fecha_hoy.getMonth() === fecha_seleccionada.getMonth();
    const comprobar_anio = fecha_hoy.getFullYear() === fecha_seleccionada.getFullYear();
    if (comprobar_dias && comprobar_mes && comprobar_anio) {
        let horarios_quitar = [];
        for (let i = 0; i < horarios.length; i++) {
            let tiempo = horarios[i].name;
            tiempo = tiempo.split(':');
            fecha_seleccionada.setHours(tiempo[0]);
            fecha_seleccionada.setMinutes(tiempo[1]);
            if (fecha_hoy.getTime() >= fecha_seleccionada.getTime()) {
                horarios_quitar.push(horarios[i]);
            }
        }
        for (let j = 0; j < horarios_quitar.length; j++) {
            const buscado = horarios_quitar[j];
            horarios = horarios.filter(item => item.id !== buscado.id)
        }
        setValue(horarios);
    }
}

export const filterObjectKeysFromArray = async (array, filterParameters, sortOrder) => {
    try {
        const filteredArray = array
            .map((value, index) => {
                if (value.hasOwnProperty("turno_id") && value.hasOwnProperty("abreviacion")) {
                    value.id = `#${value.abreviacion}-${value.turno_id}`;
                }
                return Object.keys(value)
                    .filter(key => filterParameters.includes(key))
                    .reduce((arr, key) => {
                        arr.push(value[key]);
                        return arr;
                    }, []);
            })
        return filteredArray
    } catch (error) {
        return error;
    }
}



/**
 * 
 * Función para descargar un excel en cliente
 * 
 * @param {base64} file - File en base 64 
 * @param {string} name - Nombre del archivo a descargar
 */

export const downloadExcelfromClient = async (file, name) => {
    try {
        const link = document.createElement("a");
        link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(file);
        link.style = "visibility:hidden";
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true
    } catch (error) {
        return error
    }
}

export const getCurrentDate = async () => {
    try {
        const date = new Date();
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);

        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate; // returns "2023-05-02"

    } catch (error) {
        console.log(error)
    }
}


export const debounce = (func, delay) => {
    let timerId;

    return function (...args) {
        const context = this;

        clearTimeout(timerId);

        timerId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}


export const restablecerFechaDeDB = ({ hora }) => {
    const [hours, minutes, seconds] = hora[0].split(':').map(Number);

    // Crear un objeto Date con la hora proporcionada
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    // Restar las horas
    date.setHours(date.getHours() - 3);

    // Formatear de nuevo a string en formato HH:MM:SS
    return date.toTimeString().split(' ')[0];
}