import FormActualizarCantidadDeTurnos from "../../components/Forms/CantidadDeTurnos";
import Title from "../../components/Title";
export default function ActualizarCantidadDeTurnos() {
    return (
        <>
            <div className="container-fluid ps-0">
                <div className="glassDatos">
                    <div className="pb-5 d-flex align-items-center justify-content-between">
                        <Title title="Actualizar cantidad de turnos" class="titlegray" />
                    </div>

                    <FormActualizarCantidadDeTurnos />
                </div>
            </div>
        </>
    )
}