import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Title from "../../components/Title";
import activateAccount from "../../services/auth/activate-account";
import { LeapFrog } from '@uiball/loaders'
import { useAuth } from "../../hooks/auth";

export default function ActivateAccount(props) {
    const { isLogin } = useAuth();
    const { clientID, hash } = useParams();
    const [message, setMessage] = useState('Estamos verificando tu cuenta. Seras redireccionado al finalizar...');
    const [message_type, setMessageType] = useState('info');
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(true);

    const navigate = useNavigate();
    useEffect(() => {
        activateAccount({
            params: { clientID: clientID, hash: hash }, callbacks: response => {
                if (response.estado > 0) {
                    setSuccess(true);
                    setMessage('Tu cuenta fue verificada correctamente');
                    setMessageType('success');
                    setTimeout(() => {
                        navigate("/login");
                    }, 1000)
                } else {
                    setSuccess(false);
                    setLoading(false);
                    setMessage(response.mensaje ?? 'Ocurrio un error al verificar tu cuenta');
                    setMessageType('danger');
                }
            }
        })
    }, [isLogin, navigate, clientID, hash]);
    return (
        <main className="w-100">
            <div className="container-fluid my-5">
                <div className="glassDatos">
                    <div className="pb-4 d-flex align-items-center justify-content-between">
                        <Title title="Activa tu cuenta en Central de Turnos" class="titlegray" />
                    </div>
                    <div className="col-12">
                        {isLogin ?
                            <div className="alert alert-success">Ya te encuentras logueado</div>
                            :
                            <>
                                <div className={`alert alert-${message_type}`}>{message}</div>
                                {
                                    loading &&
                                    <div className="d-flex align-items-center justify-content-center mt-5">
                                        <LeapFrog
                                            size={70}
                                            speed={2.5}
                                            color="black"
                                        />
                                    </div>
                                }

                                {
                                    !success &&
                                    <div className='d-flex justify-content-center'>
                                        <button type="button" className="btn-dangergg d-block mx-auto my-5" onClick={() => navigate("/")} >Volver</button>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </main>
    );
}