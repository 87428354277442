import { RaceBy } from "@uiball/loaders";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import getmotivoTurnosData from "../../../services/adp/turn_reason/get";
import { isEmpty } from "../../../utils/functions";
import FormAgregarMotivoTurno from "../../Forms/AgregarEditarMotivoTurno";
import listarEncuestas from "../../../services/admin/encuestas/lista";

export function ModalMotivosTurno(props) {
    const { idSeleccionado, TOKEN, ver_modal, cerrarModal, setAbrirModal, setIdSeleccionado, obtenerListadoMotivosTurno } = props;
    const [motivoTurnoSeleccionado, setMotivoTurnoSeleccionado] = useState(null);
    const [loader, setLoader] = useState(true)
    const [encuestas, setEncuestas] = useState([]);

    const obtenerDatosMotivoTurno = useCallback(async () => {
        await getmotivoTurnosData({
            id: idSeleccionado, token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setMotivoTurnoSeleccionado(response.resultados[0]);
                    setLoader(false);
                }
            }
        });
    }, [TOKEN, idSeleccionado]);

    useEffect(() => {
        if (!isEmpty(idSeleccionado)) {
            obtenerDatosMotivoTurno();
        }
        setLoader(true);
        listarEncuestas({
            token: TOKEN, callbacks: response => {
                setLoader(false);
                if (response.estado > 0) {
                    if (response.resultados.length > 0) {
                        setEncuestas(response.resultados);
                    } else {
                        setEncuestas([]);
                    }
                } else {
                    setEncuestas([]);
                }
            }
        })
    }, [obtenerDatosMotivoTurno, idSeleccionado, TOKEN]);

    async function restablecerModal() {
        await setMotivoTurnoSeleccionado(null)
        await setLoader(true);
        await cerrarModal();
    }


    async function abrirModalAgregar() {
        await setLoader(true);
        await setMotivoTurnoSeleccionado(null)
        await setAbrirModal(true)
        await setIdSeleccionado(0);
        await setLoader(false);
    }
    return (
        <>
            <button className="buttonModal" onClick={abrirModalAgregar}>+ Agregar</button>
            <div className={`modal ${!ver_modal ? 'fade' : 'show d-block'}`} id="modalAgregarMotivoTurno" tabIndex="-1" aria-labelledby="modalAgregarMotivoTurnoLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center" id="modalAgregarMotivoTurnoLabel">{`${motivoTurnoSeleccionado === null ? 'Agregar motivo turno' : `Editar motivo turno #${idSeleccionado}`}`}</h5>
                            <button type="button" className="btn-close" id="modalDerivacionClose" onClick={restablecerModal} aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <div className="modal-body">
                                {
                                    loader &&
                                    <RaceBy
                                        size={280}
                                        lineWeight={15}
                                        speed={1.4}
                                        color="black"
                                        className="d-flex"
                                    />
                                }
                                {
                                    !loader &&
                                    <FormAgregarMotivoTurno
                                        TOKEN={TOKEN}
                                        motivoTurnoSeleccionado={motivoTurnoSeleccionado}
                                        obtenerListadoMotivosTurno={obtenerListadoMotivosTurno}
                                        restablecerModal={restablecerModal}
                                        encuestas={encuestas}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}