import { formatarFecha } from "../../utils/functions";
import TURN_STATE_OBJECT from "../../utils/turn_state";
const Observaciones = (props) => {
    const historial = props.lista;
    return (
        <table className="table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Estado</th>
                    <th>Horario</th>
                    <th>Motivo</th>
                    <th>Observaciones</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    historial.map((item, index) => {
                        let fecha = formatarFecha(item.fecha);
                        return (
                            <tr key={index}>
                                <td><b>#{item.turno_id}</b></td>
                                <td><span className="infoTurno" data-color={TURN_STATE_OBJECT[item.estado_turno].dataColor - 1}>{TURN_STATE_OBJECT[item.estado_turno].display}</span></td>
                                <td>{fecha} - {item.hora}</td>
                                <td><b>{item.motivo_turno_abreviacion}</b> - {item.motivo_turno_nombre}</td>
                                <td>{item.observaciones}</td>
                            </tr>
                        )
                    })
                }</tbody>
        </table>
    )

}

export default Observaciones;