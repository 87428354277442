import React from 'react'
import Checkradios from '../../CheckRadio/Checkradios.jsx'

const FormEncuesta = (props) => {
    const setSelected = props.setSelected;
    const preguntas = props.preguntas
    return (
        <div id="EncuestaCalidad" className="d-flex">
            <div className="encuestaQuestion-block">
                <h3 className="parrafoGray text-center">{preguntas.nombre}</h3>
                <div>
                    <Checkradios onChange={props.onClickChange} state={props.state} campo={preguntas} setSelected={setSelected} />
                </div>
            </div>
        </div>

    )

}


export default FormEncuesta