import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Container } from 'react-bootstrap';
import addBranch from '../../../services/adp/branch/add';
import updateBranch from '../../../services/adp/branch/update';
import { MAIN_URL } from '../../../utils/config';
import Message from '../../../utils/message';
import Button from '../../Button';
import { useAuth } from '../../../hooks/auth';

export default function FormAgregarSucursal(props) {
    const { token: TOKEN } = useAuth();
    const [validated, setValidated] = useState(false);


    const [formValues, setValues] = useState({
        id: '',
        name: '',
        address: '',
        locality: '',
        province: '',
        longitude: '',
        latitude: '',
        employees: ''
    });

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    useEffect(() => {
        setValues({
            id: props.idNitro4 ?? '',
            name: props.name ?? '',
            address: props.address ?? '',
            locality: props.locality ?? '',
            province: props.province ?? '',
            longitude: props.longitude ?? '',
            latitude: props.latitude ?? '',
            employees: props.employees ?? ''
        });
    }, [props]);


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            const action = form.getAttribute("actionsubmit");
            if (action === 'add') {
                addBranch({
                    params: formValues, token: TOKEN, callbacks: response => {
                        if (response.estado > 0) {
                            document.location.href = `${MAIN_URL}/sucursales`;
                        } else {
                            Message({ message: response.mensaje, type: 'error' })
                        }
                    }
                });
            } if (action === 'edit') {
                updateBranch({
                    params: formValues, token: TOKEN, callbacks: response => {
                        if (response.estado > 0) {
                            document.location.href = `${MAIN_URL}/sucursales`;
                        } else {
                            Message({ message: response.mensaje, type: 'error' })
                        }
                    }
                });
            }
        }
    };


    return (
        <Container>
            <Form noValidate validated={validated} actionsubmit={(props.update && props.update === 'yes') ? 'edit' : 'add'} onSubmit={handleFormSubmit}>
                <Row className="mt-3 flex-column">
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                name="name"
                                id="name"
                                type="text"
                                className='input-gg'
                                defaultValue={props.name ?? ''}
                                placeholder="Ingresar nombre"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                name="address"
                                id="address"
                                type="text"
                                className='input-gg'
                                defaultValue={props.address ?? ''}
                                placeholder="Ingresar direccion"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='flex-column'>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                name="locality"
                                id="locality"
                                type="text"
                                className='input-gg'
                                defaultValue={props.locality ?? ''}
                                placeholder="Ingresar localidad"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                name="province"
                                id="province"
                                type="text"
                                className='input-gg'
                                defaultValue={props.province ?? ''}
                                placeholder="Ingresar provincia"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                name="longitude"
                                id="longitude"
                                type="text"
                                className='input-gg'
                                defaultValue={props.longitude ?? ''}
                                placeholder="Ingresar logitud"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                name="latitude"
                                id="latitude"
                                type="text"
                                className='input-gg'
                                defaultValue={props.latitude ?? ''}
                                placeholder="Ingresar latitud"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control
                                required
                                name="employees"
                                id="employees"
                                type="text"
                                className='input-gg'
                                defaultValue={props.employees ?? ''}
                                placeholder="Cantidad de empleados"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center'>
                    {
                        (props.update && props.update === 'yes') ?
                            <Button type="submit" className="btn-orange d-block mx-auto my-5" text="Editar" />
                            :
                            <Button type="submit" className="btn-greengg d-block mx-auto my-5" text="Agregar" />
                    }
                </div>
            </Form>
        </Container>
    );
}