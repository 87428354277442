import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';


import Message from '../../../utils/message';
import Select from '../../Select/select';
import { MAIN_URL } from '../../../utils/config';
import useUnload from '../../../hooks/beforUnload';
import fetchUsersService from '../../../services/auth/fetch-users';
import ReactSelect from 'react-select';
import obtenerPorUserID from '../../../services/adp/person/get_by_user_id';
import agregarUsuarioExistente from '../../../services/auth/signup-user-exist';


function FormAgregarPersonaExistente(props) {
    const { document_type_list, profile_type_list, TOKEN, TYPE_PERSON, ID_USER, id } = props;
    const [validated, setValidated] = useState(false);
    const [users, setUsers] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [userSelected, setUserSelected] = useState(0);

    const [formValues, setValues] = useState({
        document_number: "",
        type_profile: "",
        email: "",
        name: "",
        surname: "",
        document_type: "",
        previous_profile: TYPE_PERSON ?? "",
        user_id: ID_USER ?? "",
        idNitro4: id ?? ""
    });

    useEffect(() => {
        fetchUsersService({ token: TOKEN }).then((response) => {
            if (response.estado > 0 && response.resultados.length > 0) {
                let us = response.resultados.filter(item => item.id !== ID_USER && item.provider === 'local').map(item => ({ label: `${item.name} ${item.surname} - ${item.email} - ${item.provider === 'local' ? 'CDT' : item.provider.toUpperCase()}`, value: item.id }));
                setUsers(us);
            }
            setIsDisabled(false)
            setIsLoading(false)
        });
    }, [TOKEN, ID_USER]);



    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    const handleOnCancel = () => {
        setValues({
            document_number: "",
            type_profile: "",
            email: "",
            name: "",
            surname: "",
            document_type: "",
        });
        document.location.href = `${MAIN_URL}/lista-personas`

    }

    useUnload(e => {
        e.preventDefault();
        handleOnCancel()
    });


    async function handleFormSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            if (!error) {
                setIsLoading(true);
                setValidated(false);
                const response = await agregarUsuarioExistente({ params: { ...formValues, user_id: userSelected }, token: TOKEN });
                setIsLoading(false);
                if (response.estado > 0) {
                    Message({ message: 'Se dio de alta correctamente el usuario', type: 'success' });
                    // handleOnCancel();
                    form.reset();
                } else {
                    Message({ message: response.mensaje ?? 'Ocurrio un error al dar de alta este usuario', type: 'error' });
                }
            } else {
                Message({ message: 'No se puede completar este proceso con el usuario seleccionado', type: 'error' });
            }
        }
    };


    async function changeUserSelected(event) {
        const { value } = event;
        setError(false);
        setIsLoading(true);
        const response = await obtenerPorUserID({ user_id: value, token: TOKEN });
        if (response.estado > 0) {
            if (response.resultados.length > 0) {
                setUserSelected(value);
                setError(true);
                setIsLoading(false);
            } else {
                setUserSelected(value);
                setError(false);
                setIsLoading(false);
            }
        } else {
            setUserSelected(value);
            setError(false);
            setIsLoading(false);
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
            <div className="row my-3">
                <div className="col-md-6">
                    <div className="form-group my-3">
                        <label htmlFor="users" className='m-0 parrafoGray'>Usuario</label>
                        <ReactSelect
                            placeholder="Seleccionar un usuario existente"
                            className='mt-4'
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={true}
                            name="users"
                            options={users}
                            onChange={changeUserSelected}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>

                    {error &&
                        <div className={`alert alert-danger my-3`}>
                            Este usuario ya se encuentra registrado actualmente
                        </div>
                    }
                </div>

                <div className="col-md-6">
                    <div className="form-group my-3">
                        <label htmlFor="document_type" className='m-0 parrafoGray'>Tipo de documento</label>
                        <Select
                            required
                            value={document_type_list}
                            name="document_type"
                            id="document_type"
                            placeholder="Seleccione tipo de documento"
                            className='input-gg'
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group my-3">
                        <label htmlFor="document_number" className='m-0 parrafoGray'>Numero de documento</label>
                        <Form.Control
                            required
                            type="text"
                            name="document_number"
                            id="document_number"
                            className='input-gg'
                            defaultValue={props.dni ?? ''}
                            placeholder="Ingresar DNI"
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group my-3">
                        <label htmlFor="type_profile" className='m-0 parrafoGray'>Perfil</label>
                        <Select
                            required
                            value={profile_type_list}
                            name="type_profile"
                            id="type_profile"
                            placeholder="Seleccione el perfil"
                            defaultValue={props.TYPE_PERSON ?? 'Seleccione el perfil'}
                            className='input-gg'
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                    </div>
                </div>
            </div>
            <div className='mt-5 d-flex justify-content-center'>
                <button type="submit" className={`btn-greengg d-block mx-5 ${error || isLoading ? 'disabled' : 'active'}`} text="Agregar" isDisabled={error || isLoading} disabled={`${error || isLoading ? 'disabled' : ''}`}> Agregar </button>
                <button type="button" className="btn-dangergg d-block mx-5" text="Cancelar" onClick={handleOnCancel}> Cancelar </button>
            </div>
        </Form >
    );
}

export default FormAgregarPersonaExistente;