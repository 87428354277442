let DURACION_DE_TURNO = 15;
let HORA_ENTRADA = 9;
let HORA_SALIDA = 18;
let MINUTOS_POR_HORA = 60;

let HORARIOS = [];

for (let i = HORA_ENTRADA; i < HORA_SALIDA; i++) {
    let hora = `${i}`;
    for (let j = 0; j < (MINUTOS_POR_HORA / DURACION_DE_TURNO); j++) {
        let minutos = DURACION_DE_TURNO * j;
        minutos = minutos === 0 ? `${minutos}0` : `${minutos}`;
        HORARIOS.push({
            id: `${hora}:${minutos}`,
            name: `${hora}:${minutos}`,
        })
    }
}

export default HORARIOS;