import { CURRENT_DATE } from "../../utils/config";

export default function FiltrosLista(props) {
    const { paginaActual, ultimaPagina, setPaginaActual, setFecha, setFiltroEstadoTurno, estadosTurno, search, setSearch, cargarLista, setLoading, restablecerCheckboxs } = props;

    const paginaAdelante = () => {
        if (paginaActual !== ultimaPagina) {
            restablecerCheckboxs();
            setPaginaActual(paginaActual + 1);
        }

    }

    const paginaAtras = () => {
        if (paginaActual !== 1) {
            restablecerCheckboxs();
            setPaginaActual(paginaActual - 1);
        }
    }

    async function filtrarPorFecha(evt) {
        const { target } = evt;
        const { value } = target;
        setLoading(true);
        setFecha(value)
        setPaginaActual(1);
        restablecerCheckboxs(false);
    }

    function filtrarPorEstado(evt) {
        const { target } = evt;
        const { value } = target;
        restablecerCheckboxs(false);
        const estadoSeleccionado = buscarEstado(value);
        setFiltroEstadoTurno(estadoSeleccionado.name);
        setPaginaActual(1);
    }

    function buscarEstado(id) {
        return estadosTurno.find(element => parseInt(element.id) === parseInt(id));
    }

    function buscarTurnos(evt) {
        const { target } = evt;
        const { value } = target;
        setSearch(value);
        setPaginaActual(1);
        restablecerCheckboxs();
    }

    function refresh() {
        cargarLista();
        setPaginaActual(paginaActual)
    }


    return (
        <>
            <div className="mt-4 container-fluid d-flex justify-content-between align-items-center p-0">
                <div className="col-5 d-flex">
                    <div>
                        <input className="formatInputs me-4" type="date" defaultValue={CURRENT_DATE} onChange={filtrarPorFecha} />
                    </div>
                    <div>
                        <select aria-label="" id="estado_turno" name="estado_turno" onChange={filtrarPorEstado} className="formatInputs me-4">
                            {
                                estadosTurno.map((item) => {
                                    return <option value={item.id} key={item.id}>{item.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="col-3 text-center">
                    <div>
                        <input className="searchInput" type="search" placeholder="Buscar..." onChange={buscarTurnos} defaultValue={search} />
                    </div>
                </div>

                <div className="col-4 d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <button className="btnPaginationAtras" onClick={paginaAtras} data-disabled="off">
                            <i className="fas fa-chevron-left"></i>
                        </button>
                        <span className="actualPage">Pagina: {paginaActual} / {ultimaPagina}</span>
                        <button className="btnPaginationAdelante" onClick={paginaAdelante} data-disabled="off">
                            <i className="fas fa-chevron-right"></i>
                        </button>
                    </div>
                    <button className="m-0 btnPaginationAtras" onClick={refresh}>
                        <i className="fas fa-sync-alt"></i>
                    </button>
                </div>
            </div>
        </>

    );
}