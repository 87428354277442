import React from "react";
import './_Select.scss'

function Select(props) {
    let disabled;
    let className;
    let classNameDisabled;

    if (props.disabled) {
        disabled = 'disabled'
        classNameDisabled = 'disabled'
    }
    else {
        disabled = ''
        classNameDisabled = ''
    }
    if (props.className) {
        className = props.className
    }
    else {
        className = 'form-control my-2'
    }
    return (
        <select aria-label="" id={props.id} name={props.id} onChange={props.onChange} required disabled={disabled} className={`${className} ${classNameDisabled}`}>
            <option value=''>{props.placeholder}</option>
            {
                props.value.map((item) => {
                    return <option value={item.id} key={item.id}>{item.name}</option>
                })
            }
            {props.other === "yes" &&
                <option value="0">Otros</option>
            }
        </select>
    );
}

export default Select;
