import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";


const getListTurnReasons = async ({ token, setValue, callbacks }) => {
    const response = await connection({ method: 'GET', url: `${ENTITY_URL.MOTIVO_TURNO.LIST}`, extraHeaders: { token: token } });;
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default getListTurnReasons;