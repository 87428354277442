import { useState, useEffect } from "react";
import FormEditarTurno from "../../Forms/EditarTurnoSelectora";

export default function ModalEditarTurno(props) {
    const { turno_id, setLoading, ver_modal_editar, setAbrirModalEditar, datosTurno, closeModalPersona, motivosReasignacion, cargarLista, TOKEN, restablecerCheckboxs } = props;

    const [empresaSelected, setEmpresaSelected] = useState([{
        label: datosTurno.empresa,
        value: datosTurno.empresa
    }]);


    useEffect(() => {
        setEmpresaSelected([{
            label: datosTurno.empresa,
            value: datosTurno.empresa
        }]);
    }, [datosTurno])

    return (
        <div className={`modal ${!ver_modal_editar ? 'fade' : 'show d-block'}`} id="modalReasignacion" tabIndex="-1" aria-labelledby="modalReasignacionLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center" id="modalReasignacionLabel">Reasignacion de turno</h5>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={() => { setAbrirModalEditar(false) }} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <FormEditarTurno
                            id={turno_id}
                            setLoading={setLoading}
                            datosTurno={datosTurno}
                            cerrar_modal={() => { setAbrirModalEditar(false) }}
                            closeModalPersona={closeModalPersona}
                            motivosReasignacion={motivosReasignacion}
                            cargarLista={cargarLista}
                            TOKEN={TOKEN}
                            restablecerCheckboxs={restablecerCheckboxs}
                            empresaSelected={empresaSelected}
                            setEmpresaSelected={setEmpresaSelected}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}