import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { useUser } from "../hooks/user";

export const RequireAuth = ({ children }) => {
    const { token } = useAuth();
    const { user } = useUser();
    if (!token || (user === undefined || user === null)) {
        return <Navigate to='/login' />
    }
    return children
}