import React, { useState } from "react";
import { RaceBy, Waveform } from '@uiball/loaders'
import './_datosPersonaTurno.scss';
import profileImage from '../../images/imagen-card1.png';
import finalizeTurn from "../../services/adp/turn/finalize";
import { FloatingLabel, Form } from "react-bootstrap";
import Observaciones from "../Observaciones";
import historialDeAtencion from "../../services/adp/turn/obtener-historial-de-atencion";
import confirmarTurnoADP from "../../services/adp/turn/confirm";
import TURN_STATE_OBJECT from "../../utils/turn_state";
import { formatarFecha, isEmpty } from "../../utils/functions";
import DatosMotivoTurno from "../DatosMotivoTurno";
import { IoPencil } from "react-icons/io5";
import { ModalEditarObservacion } from "../Modals/Observacion/update";
import Message from "../../utils/message";
import { ModalEditarEmail } from "../Modals/EditarEmailColaborador";


function DatosPersonaTurno(props) {
    const { id, loader, obtenerDatosTurno, setLoader, setLoading, ver_opciones, closeModalPersona, volverAtras, cargarLista, datosTurno, TOKEN, cargarModalEditar, cargarModalDerivar, cargarModalCancelar, permitir_finalizar, setPermitirFinalizar } = props;
    const [historial_atencion, setHistorial] = useState(null);
    const [ver_historial, setAbrirHistorial] = useState(false);
    const estado_turno_turno_actual = TURN_STATE_OBJECT[datosTurno.estado_turno ?? 'Default'];
    const [loadingHistorial, setLoadingHistorial] = useState(true);
    const [ver_modificar_observacion, setModificarObservacion] = useState(false);

    const [observacion, setObservacion] = useState(datosTurno.observacion);
    const [openModalEmail, setOpenModalEmail] = useState(false);

    const finalizeOption = async () => {
        setLoader(true);
        setLoading(true);
        const formValues = {
            observaciones: document.getElementById('observaciones').value,
            permitir_finalizar: permitir_finalizar
        }
        finalizeTurn({
            token: TOKEN, id: id, params: formValues, callbacks: async (response) => {
                if (response.estado > 0) {
                    cargarLista();
                    setPermitirFinalizar(false);
                    await closeModalPersona();
                    await setLoader(false);
                } else {
                    await setLoader(false);
                    setLoading(false);
                    if (response.hasOwnProperty('email_empty')) {
                        if (response.email_empty) {
                            setOpenModalEmail(true);
                        }
                    }
                    Message({ message: response.mensaje, type: 'error' });
                }
            }
        });
    }

    const historialDeAtencionTurnos = () => {
        setLoadingHistorial(true);
        setAbrirHistorial(true);
        historialDeAtencion({
            token: TOKEN, id: id, callbacks: response => {
                setHistorial(response.resultados ?? []);
                setLoadingHistorial(false)
            }
        })
    }

    const reactivarOption = () => {
        setLoader(true);
        setLoading(true);
        confirmarTurnoADP({
            id: id, token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0) {
                    cargarLista();
                    await closeModalPersona();
                    await setLoader(false);
                }
            }
        });
    }

    const abrirModalObservaciones = async () => {
        setObservacion(datosTurno.observacion);
        setModificarObservacion(true);
    }

    return (
        <>
            <div className="shadowClose" data-close="off" onClick={ver_opciones ? volverAtras : closeModalPersona}></div>
            <div className="containerDatosPersonaCard" data-swap="off">
                {
                    loader &&
                    <RaceBy
                        size={280}
                        lineWeight={15}
                        speed={1.4}
                        color="black"
                        className="d-flex"
                    />
                }

                <div className="shadowClose" data-close="off" onClick={ver_opciones ? volverAtras : closeModalPersona}></div>
                {
                    !loader &&
                    <div className="h-100 container d-flex flex-column p-0" id="show">
                        <button className="btnPaginationAtras cerrarModal" onClick={ver_opciones ? volverAtras : closeModalPersona}>
                            <i className="fas fa-window-close"></i>
                        </button>
                        <div className="col-12 d-flex">
                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <img src={profileImage} alt="imagen perfil" className="img-fluid" />
                            </div>
                            <div className="col-9">
                                <h3 className="titlegray">{`${datosTurno.persona.name} ${datosTurno.persona.surname}`}</h3>
                                <div className="mt-5 container__main__datos">
                                    <div className="container__historial__card">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div>
                                                    <h6>#{datosTurno.motivo_turno_abreviacion} - {datosTurno.turno_id}</h6>
                                                </div>
                                                <div>
                                                    <p><b>Email:</b> <span>{datosTurno.persona.email}</span></p>
                                                </div>
                                                <div>
                                                    <p><b>Legajo:</b> <span>{datosTurno.persona.legajo}</span></p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div>
                                                    <p><b>Empresa:</b> <span>{datosTurno.persona.company}</span></p>
                                                </div>
                                                <div>
                                                    <p><b>{datosTurno.persona.document_type}:</b> <span>{datosTurno.persona.document_number}</span></p>
                                                </div>
                                                <div>
                                                    <p><b>Telefono:</b> <span>{datosTurno.persona.codigo_area} {datosTurno.persona.phone}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            Estado: <span className="infoTurno" data-color={TURN_STATE_OBJECT[datosTurno.estado_turno].dataColor - 1}>{TURN_STATE_OBJECT[datosTurno.estado_turno].display}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 container__main__datos">
                            <div className="container__historial__card bordersCustom">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div>
                                            <p><b>Sucursal:</b> <span>{datosTurno.sucursal.Nombre}</span></p>
                                        </div>

                                        <div>
                                            <p><b>Fecha:</b> <span>{`${formatarFecha(datosTurno.fecha)}`}</span></p>
                                        </div>

                                        <div>
                                            <p><b>Hora:</b> <span>{datosTurno.hora}</span></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-header">
                                                <b>Observaciones:</b>
                                            </div>
                                            <div className="card-body my-3">
                                                <span>{datosTurno.observacion}</span>
                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-primary" onClick={() => abrirModalObservaciones()}><IoPencil className="mr-5" /> Editar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 container__main__datos d-flex justify-content-between my-3 align-items-center">
                            <h5 className="m-0 fh5">Historial</h5>
                            <button className="btnAtender" onClick={historialDeAtencionTurnos}>Ver Historial</button>
                        </div>
                        <div className="col-12 container__main__datos">
                            <div className="container__historial__card">
                                <div>
                                    <DatosMotivoTurno
                                        obtenerDatosTurno={obtenerDatosTurno}
                                        datosTurno={datosTurno}
                                        TOKEN={TOKEN}
                                        cargarLista={cargarLista}
                                        setLoader={setLoader}
                                    />
                                </div>
                                <div className="my-5 bordersCustom py-5">
                                    <h5 className="text-center"><b>Creado por:</b></h5>
                                    <div>
                                        <p><b>Nombre y apellido:</b> <span>{datosTurno.ADP_nombre} {datosTurno.ADP_apellido}</span></p>
                                    </div>
                                    <div>
                                        <p><b>Email:</b> <span>{datosTurno.ADP_email}</span></p>
                                    </div>
                                </div>
                                {
                                    ver_opciones &&
                                    <>
                                        <div className="container__form__observaciones my-5">
                                            <FloatingLabel controlId="observaciones" label="Observaciones" className="mb-3" >
                                                <Form.Control as="textarea" placeholder="Indique observaciones" className="customArea" />
                                            </FloatingLabel>
                                        </div>

                                        {
                                            permitir_finalizar &&
                                            <div className="alert alert-info">
                                                Se va a finalizar el turno, pero al no agregar el email del colaborador no se va a enviar la encuesta
                                            </div>
                                        }
                                    </>
                                }

                                {
                                    ver_opciones &&
                                    <>
                                        <div className="d-flex justify-content-center">
                                            <div className="w-100">
                                                <div className="col-12 d-flex justify-content-between">
                                                    <button className={`${estado_turno_turno_actual.botones.editar ? '' : 'disabled'} btnAtender mx-4`} disabled={`${estado_turno_turno_actual.botones.editar ? '' : 'disabled'}`} onClick={() => { cargarModalEditar(id) }}>
                                                        Editar
                                                    </button>

                                                    <button className={`${estado_turno_turno_actual.botones.cancelar ? '' : 'disabled'} btnDerivar mx-4`} disabled={`${estado_turno_turno_actual.botones.cancelar ? '' : 'disabled'}`} onClick={() => { cargarModalCancelar(id) }}>
                                                        Cancelar
                                                    </button>

                                                    <button className={`${estado_turno_turno_actual.botones.derivar ? '' : 'disabled'} btnDe mx-4`} disabled={`${estado_turno_turno_actual.botones.derivar ? '' : 'disabled'}`} onClick={() => { cargarModalDerivar(id) }}>
                                                        Derivar
                                                    </button>
                                                </div>
                                                <div className="col-12 my-4 d-flex">
                                                    <button className={`${estado_turno_turno_actual.botones.finalizar ? '' : 'disabled'} btnCancelar w-100  mx-4`} onClick={finalizeOption} disabled={`${estado_turno_turno_actual.botones.finalizar ? '' : 'disabled'}`}>
                                                        Finalizar Atención
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    estado_turno_turno_actual.botones.reactivar &&
                                    <div className="d-flex justify-content-center">
                                        <div className="w-100">
                                            <div className="col-12 my-4 d-flex">
                                                <button className={`${estado_turno_turno_actual.botones.reactivar ? '' : 'disabled'} btnConfirmar w-100 mx-4`} onClick={reactivarOption} disabled={`${estado_turno_turno_actual.botones.reactivar ? '' : 'disabled'}`}>
                                                    Reactivar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    !ver_opciones && estado_turno_turno_actual.botones.editar &&
                                    <div className="d-flex justify-content-center">
                                        <div className="w-100">
                                            <div className="col-12 my-4 d-flex">
                                                <button className={`${estado_turno_turno_actual.botones.editar ? '' : 'disabled'} btnAtender w-100 mx-4`} disabled={`${estado_turno_turno_actual.botones.editar ? '' : 'disabled'}`} onClick={() => { cargarModalEditar(id) }}>
                                                    Editar turno
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={`modal ${!ver_historial ? 'fade' : 'show d-block'}`} id="modalHistorial" tabIndex="-1" aria-labelledby="modalHistorialLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title w-100 text-center" id="modalHistorialLabel">Historial</h5>
                                                <button type="button" className="btn-close" onClick={() => { setAbrirHistorial(false) }} aria-label="Close"></button>
                                            </div>

                                            <div className="modal-body">
                                                {loadingHistorial &&
                                                    <Waveform
                                                        size={40}
                                                        lineWeight={3.5}
                                                        speed={1}
                                                        color="black"
                                                    />
                                                }
                                                {!loadingHistorial &&
                                                    <>
                                                        {historial_atencion.length > 0 ? <Observaciones lista={historial_atencion} /> : <div className="alert alert-warning">No hay turnos previos</div>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                !isEmpty(id) &&
                <>
                    <ModalEditarObservacion
                        loader={loader}
                        setLoader={setLoader}
                        setLoading={setLoading}
                        obtenerDatosTurno={obtenerDatosTurno}
                        datosTurno={datosTurno}
                        TOKEN={TOKEN}
                        ver_modificar_observacion={ver_modificar_observacion}
                        setModificarObservacion={setModificarObservacion}
                        observacion={observacion}
                    />
                    <ModalEditarEmail
                        loader={loader}
                        setLoader={setLoader}
                        setLoading={setLoading}
                        datosTurno={datosTurno}
                        TOKEN={TOKEN}
                        openModalEmail={openModalEmail}
                        setOpenModalEmail={setOpenModalEmail}
                        obtenerDatosTurno={obtenerDatosTurno}
                        setPermitirFinalizar={setPermitirFinalizar}
                    />
                </>
            }
        </>
    );

}
export default DatosPersonaTurno;