import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/auth';
import { useUser } from '../../../hooks/user';
import getListDocumentTypes from '../../../services/adp/document_type/list';
import getListProfiles from '../../../services/adp/profile/list';
import PERSON_TYPE from '../../../config/person_type';
import updatePerson from '../../../services/adp/person/update_person';
import Message from '../../../utils/message';
import { Form } from 'react-bootstrap';
import Button from '../../Button';
import obtenerListadoPersonasInterno from '../../../services/adp/person/list-internal';


export default function EditarPersonaModal(props) {
    const { ver_modal, setAbrir, persona, setPersona, persona_default, setPersons, setLoadingAll } = props;
    const { user: USER } = useUser();
    const { token: TOKEN } = useAuth();
    const TYPE_PERSON = USER.person_type;
    const [documentTypeList, setDniType] = useState([]);
    const [profilesTypeList, setProfileType] = useState([]);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        getListDocumentTypes({
            token: TOKEN, callbacks: response => {
                setDniType(response.resultados ?? []);
            }
        });
        getListProfiles({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    let listita = response.resultados.filter(item => TYPE_PERSON === 'ADMIN' ? PERSON_TYPE[item.name].it_is_allowed_to_add : PERSON_TYPE[item.name].it_is_allowed_to_add && item.name !== 'ADMIN');
                    if (TYPE_PERSON === 'NOT_REGISTRY') {
                        setProfileType(listita.filter(item => item.id !== 7));
                        return
                    }
                    setProfileType(listita);
                }
            }
        });
    }, [TOKEN, TYPE_PERSON]);






    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setPersona({
            ...persona,
            [name]: value,
        });
    }

    const handleOnCancel = () => {
        setPersona(persona_default);
        setAbrir(false);
    }

    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            updatePerson({
                params: persona, token: TOKEN, callbacks: response => {
                    if (response.estado > 0) {
                        form.reset();
                        setPersona(persona_default);
                        Message({ message: response.mensaje, type: 'success' })
                        obtenerListadoPersonasInterno({
                            token: TOKEN, callbacks: response => {
                                if (response.estado > 0 && response.resultados.length > 0) {
                                    let list = response.resultados;
                                    setPersons(list.filter(
                                        person => USER.person_type === 'SUPERVISOR' ?
                                            person.person_type !== PERSON_TYPE.COLABORADOR.name && person.person_type !== PERSON_TYPE.ADMIN.name :
                                            person.person_type !== PERSON_TYPE.COLABORADOR.name
                                    ));
                                }
                                setLoadingAll(false);
                                setAbrir(false);
                            }
                        });
                    } else {
                        Message({ message: response.mensaje, type: 'error' })
                    }
                }
            });
        }
    };

    return (
        <div className={`modal ${!ver_modal ? 'fade' : 'show d-block'}`} id="modalEditarPersona" tabIndex="-1" aria-labelledby="modalEditarPersonaLabel" aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center" id="modalEditarPersonaLabel">Editar colaborador</h5>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={() => { setAbrir(false) }} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">

                        <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                            <div className="row my-3">
                                <div className="col-md-6">
                                    <div className="form-group my-3">
                                        <label htmlFor="name" className='m-0 parrafoGray'>Nombre</label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="name"
                                            id="name"
                                            className='input-gg'
                                            defaultValue={persona.name ?? ''}
                                            placeholder="Ingresar nombre"
                                            onChange={handleInputChange}
                                        />
                                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group my-3">
                                        <label htmlFor="surname" className='m-0 parrafoGray'>Apellido</label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="surname"
                                            className='input-gg'
                                            id="surname"
                                            defaultValue={persona.surname ?? ''}
                                            placeholder="Ingresar apellido"
                                            onChange={handleInputChange}
                                        />
                                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group my-3">
                                        <label htmlFor="email" className='m-0 parrafoGray'>Email</label>
                                        <Form.Control
                                            required
                                            type="email"
                                            name="email"
                                            className='input-gg'
                                            id="email"
                                            defaultValue={persona.email ?? ''}
                                            placeholder="Ingresar email"
                                            onChange={handleInputChange}
                                        />
                                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group my-3">
                                        <label htmlFor="document_type" className='m-0 parrafoGray'>Tipo de documento</label>
                                        <select
                                            value={persona.document_type}
                                            defaultValue={persona.document_type}
                                            onChange={handleInputChange}
                                            className='input-gg'
                                            name="document_type"
                                            id="document_type"
                                            required
                                        >
                                            {
                                                documentTypeList.map((item) => {
                                                    return <option value={item.id} key={item.id}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group my-3">
                                        <label htmlFor="document_number" className='m-0 parrafoGray'>Numero de documento</label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="document_number"
                                            id="document_number"
                                            className='input-gg'
                                            defaultValue={persona.document_number ?? ''}
                                            placeholder="Ingresar DNI"
                                            onChange={handleInputChange}
                                        />
                                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group my-3">
                                        <label htmlFor="type_profile" className='m-0 parrafoGray'>Perfil</label>
                                        <select
                                            value={persona.type_profile}
                                            defaultValue={persona.type_profile}
                                            onChange={handleInputChange}
                                            className='input-gg'
                                            name="type_profile"
                                            id="type_profile"
                                            required
                                        >
                                            {
                                                profilesTypeList.map((item) => {
                                                    return <option value={item.id} key={item.id}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                        <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 d-flex justify-content-center'>
                                <Button type="submit" className="btn-orange d-block mx-5" text="Editar" />
                                <button type="button" className="btn-dangergg d-block mx-5" text="Cancelar" onClick={handleOnCancel}> Cancelar </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}