
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastMesage = ({ message, type }) => {
    toast[type](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}

export default ToastMesage;