import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './menu.scss';
import { IoListCircleOutline, IoLogOutOutline, IoLogoWindows, IoChevronBackOutline, IoBarChartOutline, IoSettings, IoManOutline, IoLockClosedOutline, IoStorefront, IoClipboard, IoAttachOutline, IoListSharp } from "react-icons/io5";
import LogoGG from '../../images/logo-corporativo.svg';
import { isEmpty } from '../../utils/functions';
import FormEditarSucursalYBoxAsignados from '../Forms/EditarSucursalAsignada';
import obtenerListado from '../../services/notificaciones/obtenerListado';
import { maximoNotificaciones } from '../../config/config';
import { ToastContainer } from 'react-toastify';
import { useAuth } from '../../hooks/auth';
import { useUser } from '../../hooks/user';
import { useSucursal } from '../../hooks/sucursal';
import { useBox } from '../../hooks/box';
import { useSocket } from '../../hooks/socket';
import { version } from '../../utils/config';
import { RaceBy } from '@uiball/loaders';

function Menu() {
    const { token, logout, loading } = useAuth();
    const { user } = useUser();
    const { sucursal, loading_sucursal } = useSucursal();
    const { box, loading_box } = useBox();
    const { socket } = useSocket();
    const openAndCloseSideBar = () => {
        const burguer = document.querySelector('.openSidebar');
        const containerSideBar = document.querySelector('.sideBar');
        const shadowCloseSideBar = document.querySelector('.bgCloseSidebar');

        burguer.classList.toggle('open');
        containerSideBar.classList.toggle('w-3');
        shadowCloseSideBar.classList.toggle('w-3');
    }

    const openAndCloseSideBarShadow = () => {
        const burguer = document.querySelector('.openSidebar');
        const containerSideBar = document.querySelector('.sideBar');
        const shadowCloseSideBar = document.querySelector('.bgCloseSidebar');

        burguer.classList.toggle('open');
        containerSideBar.classList.toggle('w-3');
        shadowCloseSideBar.classList.toggle('w-3');
    }

    const sidebarDesktop = (e) => {
        const containerRoot = document.getElementById('root');
        const containerSidebar = document.querySelector('.sideBar');

        containerRoot.classList.toggle('moveRoot');
        containerSidebar.classList.toggle('moveSidebar');
        e.target.dataset.arrow = (e.target.dataset.arrow === "false") ? "true" : "false";
        containerSidebar.dataset.none = (containerSidebar.dataset.none === "false") ? "true" : "false";
    }

    const sideBarDesktopLoad = () => {
        const containerRoot = document.getElementById('root');
        containerRoot.classList.add('moveRoot');
    }

    const dropLi = (submenu, flechitaC) => {
        const contenedorOpciones = document.querySelector(`.${submenu}`);
        const flechita = document.querySelector(`.${flechitaC}`);

        contenedorOpciones.classList.toggle('subMenuOculto');
        contenedorOpciones.classList.toggle('h100MenuOculto');
        flechita.classList.toggle('rotateArrow');
    }


    const [listadoNotificaciones, setListadoNotificaciones] = useState([]);

    function handleClick() {
        logout();
    }

    useEffect(() => {
        obtenerListado({
            token: token, params: { cantidad: maximoNotificaciones }, callbacks: response => {
                if (response.estado > 0) {
                    setListadoNotificaciones(response.resultados);
                } else {
                    setListadoNotificaciones([]);
                }
            }
        });
        sideBarDesktopLoad();

        if (socket.conected) {
            socket.on("alerta_campanita", function (data) {
                alert("Nueva notificacion")
                console.log({ data });
                try {
                    const arrayNotificaciones = document.querySelectorAll("#containerNotif .menu-item");
                    const containerNotificaciones = document.getElementById("containerNotif");
                    const ballPushNotification = document.querySelector('.ballNotif');

                    let p = document.createElement("p");
                    p.innerHTML = data.fecha;
                    let spanHora = document.createElement("span");
                    spanHora.innerHTML = data.hora

                    let li = document.createElement("li");
                    let spanBall = document.createElement("span");
                    spanBall.classList.add('ballNotifHijo');
                    spanBall.dataset.notificacion = 'on';

                    let h3 = document.createElement("h3");
                    h3.innerHTML = data.title;

                    let span = document.createElement("span");
                    span.innerHTML = data.body;

                    let a = document.createElement("a");

                    p.appendChild(spanHora);
                    h3.appendChild(spanBall);
                    a.appendChild(h3);
                    a.appendChild(span);
                    a.appendChild(p);
                    li.appendChild(a);
                    li.classList.add("menu-item");

                    if (arrayNotificaciones.length === maximoNotificaciones) {
                        arrayNotificaciones[maximoNotificaciones - 1].remove();
                    }

                    ballPushNotification.dataset.notificacion = 'on';
                    spanBall.dataset.notificacion = 'on';
                    containerNotificaciones.insertBefore(li, arrayNotificaciones[0]);
                } catch (e) {
                    console.log("error:", e);
                }
            });
        }
    }, [token, socket])

    return (
        <>
            {user ?
                <>
                    <ToastContainer />
                    <div className='bgCloseSidebar' onClick={openAndCloseSideBarShadow}></div>
                    <div className="container-fluid ps-0 p-3">
                        <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                            <div className="container_user_data">
                                {!isEmpty(sucursal.branchID) && !isEmpty(box.idNitro4) &&
                                    <>
                                        <div className="container__sucursal__box">
                                            <p>
                                                Sucursal: <b>{sucursal.branchName}</b>
                                            </p>
                                        </div>
                                        <div className="container__sucursal__box mx-4">
                                            <p>
                                                Box: <b>{box.box}</b>
                                            </p>
                                            <button data-bs-toggle="modal" data-bs-target="#modalEditarSucursalBox" className="p-0"><i className="fas fa-pencil-alt"></i></button>
                                        </div>
                                    </>
                                }
                            </div>

                            <div className="col-3 container_user_data">
                                <nav className="user_notification menu">
                                    <ol className="p-0 m-0">
                                        <li className="menu-item">
                                            <span className='ballNotif' data-notificacion="off"></span>
                                            <a href="#!" onClick={(e) => { e.preventDefault() }}><i className="fa fa-bell"></i></a>
                                            <ol id="containerNotif" className="sub-menu">
                                                {
                                                    listadoNotificaciones.length > 0 &&

                                                    listadoNotificaciones.map((item) => {
                                                        return <li key={item.id} className="menu-item">
                                                            <a href="#!" onClick={(e) => { e.preventDefault() }}>
                                                                <h3>{item.title}</h3>
                                                                <span>{item.body}</span>
                                                                <p>{item.fecha}<span>{item.hora}</span></p>
                                                            </a>
                                                        </li>
                                                    })
                                                }
                                            </ol>
                                        </li>
                                    </ol>
                                </nav>
                                <h4 className='m-0'>{user.name} {user.surname}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="container__btns__sidebar">
                        <div className="menu-btn openSidebar" onClick={openAndCloseSideBar}>
                            <div className="menu-btn__burger"></div>
                        </div>
                    </div>

                    <nav className="sideBar col-12 padre_container_sidebar moveSidebar" data-none="false">
                        <div className='container__waves'>
                            <img src={LogoGG} alt="Logo grupo gestion" className='img-fluid imgLogoGG' />
                        </div>
                        {
                            (loading || loading_sucursal || loading_box) ?
                                <div className='container-loading'>
                                    <RaceBy
                                        size={150}
                                        lineWeight={5}
                                        speed={1.7}
                                        color="orange"
                                    />
                                </div>
                                :
                                <>
                                    {
                                        user.person_type === 'COLABORADOR' &&
                                        <>

                                            <ul>
                                                <li className='btnSidebar text-end'>
                                                    <IoChevronBackOutline onClick={sidebarDesktop} data-arrow="false" />
                                                </li>

                                                <li>
                                                    <NavLink to="/mis-turnos"><IoListCircleOutline /> <span>Mis turnos</span></NavLink>
                                                </li>

                                                <li>
                                                    <NavLink to="#" onClick={handleClick}><IoLogOutOutline /> <span>Salir</span></NavLink>
                                                </li>
                                            </ul>
                                            <span className="version">{version}</span>
                                        </>
                                    }

                                    {
                                        (user.person_type === 'ADP' || user.person_type === 'RECEPCIONISTA' || user.person_type === 'SELECTOR') &&
                                        <>
                                            <div className="container__btns__sidebar">
                                                <div className="menu-btn openSidebar" onClick={openAndCloseSideBar}>
                                                    <div className="menu-btn__burger"></div>
                                                </div>
                                            </div>

                                            <nav className="sideBar col-12 padre_container_sidebar moveSidebar" data-none="false">
                                                <div className='container__waves'>
                                                    <img src={LogoGG} alt="Logo grupo gestion" className='img-fluid imgLogoGG' />
                                                </div>
                                                {(!isEmpty(sucursal.branchID) && !isEmpty(box.idNitro4)) &&
                                                    <ul>
                                                        <li className='btnSidebar text-end'>
                                                            <IoChevronBackOutline onClick={sidebarDesktop} data-arrow="false" />
                                                        </li>

                                                        <li>
                                                            <NavLink to="/turnos-en-espera" ><IoListCircleOutline /> <span>Turnos En Espera</span></NavLink>
                                                        </li>

                                                        <li>
                                                            <NavLink to="/presentaciones-espontaneas" ><IoAttachOutline /><span>Entrega de CV</span> </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/historial" ><IoClipboard /><span>Historial</span> </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/colaboradores" ><IoManOutline /><span>Colaboradores</span></NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/cambiar-password"><IoLockClosedOutline /> <span>Cambiar password</span></NavLink>
                                                        </li>

                                                        <li>
                                                            <NavLink to="#" onClick={handleClick}><IoLogOutOutline /> <span>Salir</span></NavLink>
                                                        </li>
                                                    </ul>
                                                }

                                                {
                                                    (isEmpty(sucursal.branchID) || isEmpty(box.idNitro4)) &&
                                                    <ul>
                                                        <li className='btnSidebar text-end'>
                                                            <IoChevronBackOutline onClick={sidebarDesktop} data-arrow="false" />
                                                        </li>

                                                        <li>
                                                            <NavLink to="/asignar-box-sucursal" ><IoListCircleOutline /> <span>Sucursal y box</span></NavLink>
                                                        </li>

                                                        <li>
                                                            <NavLink to="#" onClick={handleClick}><IoLogOutOutline /> <span>Salir</span></NavLink>
                                                        </li>
                                                    </ul>
                                                }
                                                <span className="version">{version}</span>
                                            </nav>
                                        </>
                                    }

                                    {
                                        user.person_type === 'ADMIN' &&
                                        <>
                                            <div className="container__btns__sidebar">
                                                <div className="menu-btn openSidebar" onClick={openAndCloseSideBar}>
                                                    <div className="menu-btn__burger"></div>
                                                </div>
                                            </div>

                                            <nav className="sideBar col-12 padre_container_sidebar moveSidebar" data-none="false">
                                                <div className='container__waves'>
                                                    <img src={LogoGG} alt="Logo grupo gestion" className='img-fluid imgLogoGG' />
                                                </div>
                                                <ul>
                                                    <li className='btnSidebar text-end'>
                                                        <IoChevronBackOutline onClick={sidebarDesktop} data-arrow="false" />
                                                    </li>
                                                    <li onClick={() => dropLi('submenu1', 'flechita1')}>
                                                        <p className='btn-dropli'><IoSettings /> <span>Configuración</span><i className="flechitaSubMenu flechita1 fas fa-chevron-right"></i></p>
                                                    </li>

                                                    <div className="sub-menuAdmin subMenuOculto submenu1">
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/settings"><span>Player</span></NavLink>
                                                        </li>

                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/actualizar-cantidad-de-turnos-por-sucursal" ><span>Actualizar cant. de turnos</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/asignar-sucursal" ><span>Asignar sucursal</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/feriados" ><span>Feriados</span></NavLink>
                                                        </li>

                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/motivos-turno"><span>Motivos de turno</span></NavLink>
                                                        </li>

                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/motivos-derivacion"><span>Motivos de derivacion</span></NavLink>
                                                        </li>

                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/motivos-cancelacion"><span>Motivos de cancelación</span></NavLink>
                                                        </li>

                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/motivos-reasignacion"><span>Motivos de reasignación</span></NavLink>
                                                        </li>
                                                    </div>

                                                    <li onClick={() => dropLi('submenu8', 'flechita8')}>
                                                        <p className='btn-dropli'><IoListSharp /> <span>Encuestas</span><i className="flechitaSubMenu flechita8 fas fa-chevron-right"></i></p>
                                                    </li>

                                                    <div className="sub-menuAdmin subMenuOculto submenu8">
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/encuestas" ><span>Encuestas cargadas</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/agregar-encuesta" ><span>Nueva encuesta</span></NavLink>
                                                        </li>
                                                    </div>

                                                    <li onClick={() => dropLi('submenu2', 'flechita2')}>
                                                        <p className='btn-dropli'><IoBarChartOutline /> <span>Métricas</span><i className="flechitaSubMenu flechita2 fas fa-chevron-right"></i></p>
                                                    </li>

                                                    <div className="sub-menuAdmin subMenuOculto submenu2">

                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/metricas/tiempos-de-espera-en-atencion"><span>Tiempo de espera en atencion </span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/metricas/duracion-de-turnos" ><span>Duracion de turnos</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/metricas/cantidad-de-turnos" ><span>Cantidad de turnos</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/metricas/turnos-cancelados" ><span>Turnos cancelados</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/metricas/turnos-por-operador" ><span>Turnos por operador</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/metricas/presentaciones-espontaneas" ><span>Entregas de CV</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/metricas/motivos-turno" ><span>Motivos de turno</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/metricas/clientes" ><span>Clientes</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/metricas/motivos-turno-clientes" ><span>Motivos de turno y clientes</span></NavLink>
                                                        </li>
                                                    </div>
                                                    <li onClick={() => dropLi('submenu6', 'flechita6')}>
                                                        <p className='btn-dropli'><IoManOutline /> <span>Personas</span><i className="flechitaSubMenu flechita6 fas fa-chevron-right"></i></p>
                                                    </li>

                                                    <div className="sub-menuAdmin subMenuOculto submenu6">
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/colaboradores" ><span>Colaboradores</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/lista-personas" ><span>Lista personas</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/agregar-persona" ><span>Agregar personas</span></NavLink>
                                                        </li>
                                                    </div>
                                                    <li onClick={() => dropLi('submenu7', 'flechita7')}>
                                                        <p className='btn-dropli'><IoStorefront /> <span>Sucursales</span><i className="flechitaSubMenu flechita7 fas fa-chevron-right"></i></p>
                                                    </li>

                                                    <div className="sub-menuAdmin subMenuOculto submenu7">
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/sucursales" ><span>Lista sucursales</span></NavLink>
                                                        </li>
                                                        <li className='sub-menuAdminLi'>
                                                            <NavLink to="/agregar-sucursal" ><span>Agregar sucursal</span></NavLink>
                                                        </li>
                                                    </div>


                                                    <li>
                                                        <NavLink to="/cambiar-password"><IoLockClosedOutline /> <span>Cambiar password</span></NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink to="#" onClick={handleClick}><IoLogOutOutline /> <span>Salir</span></NavLink>
                                                    </li>
                                                </ul>
                                                <span className="version">{version}</span>
                                            </nav>
                                        </>
                                    }


                                    {
                                        user.person_type === 'SUPERVISOR' &&
                                        <>
                                            <div className="container__btns__sidebar">
                                                <div className="menu-btn openSidebar" onClick={openAndCloseSideBar}>
                                                    <div className="menu-btn__burger"></div>
                                                </div>
                                            </div>

                                            <nav className="sideBar col-12 padre_container_sidebar moveSidebar" data-none="false">
                                                <div className='container__waves'>
                                                    <img src={LogoGG} alt="Logo grupo gestion" className='img-fluid imgLogoGG' />
                                                </div>
                                                {(!isEmpty(sucursal.branchID) && !isEmpty(box.idNitro4)) &&
                                                    <ul>
                                                        <li className='btnSidebar text-end'>
                                                            <IoChevronBackOutline onClick={sidebarDesktop} data-arrow="false" />
                                                        </li>
                                                        <li>
                                                            <NavLink to="/turnos-en-espera" ><IoListCircleOutline /> <span>Turnos En Espera</span></NavLink>
                                                        </li>

                                                        <li>
                                                            <NavLink to="/presentaciones-espontaneas" ><IoAttachOutline /> <span>Entrega de CV</span></NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/historial" ><IoClipboard /><span>Historial</span> </NavLink>
                                                        </li>

                                                        <li>
                                                            <NavLink to="/pantalla-turnos" ><IoLogoWindows /> <span>Player</span></NavLink>
                                                        </li>

                                                        <li onClick={() => dropLi('submenu3', 'flechita3')}>
                                                            <p className='btn-dropli'><IoBarChartOutline /> <span>Métricas</span><i className="flechitaSubMenu flechita3 fas fa-chevron-right"></i></p>
                                                        </li>

                                                        <div className="sub-menuAdmin subMenuOculto submenu3">
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/metricas/tiempos-de-espera-en-atencion"><span>Tiempo de espera en atencion </span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/metricas/duracion-de-turnos" ><span>Duracion de turnos</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/metricas/cantidad-de-turnos" ><span>Cantidad de turnos</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/metricas/turnos-cancelados" ><span>Turnos cancelados</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/metricas/turnos-por-operador" ><span>Turnos por operador</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/metricas/presentaciones-espontaneas" ><span>Entregas de CV</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/metricas/motivos-turno" ><span>Motivos de turno</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/metricas/clientes" ><span>Clientes</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/metricas/motivos-turno-clientes" ><span>Motivos de turno y clientes</span></NavLink>
                                                            </li>
                                                        </div>

                                                        <li onClick={() => dropLi('submenu4', 'flechita4')}>
                                                            <p className='btn-dropli'><IoSettings /> <span>Configuración</span><i className="flechitaSubMenu flechita4 fas fa-chevron-right"></i></p>
                                                        </li>

                                                        <div className="sub-menuAdmin submenu4 subMenuOculto">

                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/settings"><span>Player</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/actualizar-cantidad-de-turnos-por-sucursal" ><span>Actualizar cant. de turnos</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/asignar-sucursal" ><span>Asignar sucursal</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/feriados" ><span>Feriados</span></NavLink>
                                                            </li>
                                                        </div>
                                                        <li onClick={() => dropLi('submenu5', 'flechita5')}>
                                                            <p className='btn-dropli'><IoManOutline /> <span>Personas</span><i className="flechitaSubMenu flechita5 fas fa-chevron-right"></i></p>
                                                        </li>

                                                        <div className="sub-menuAdmin subMenuOculto submenu5">
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/colaboradores" ><span>Colaboradores</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/lista-personas" ><span>Lista personas</span></NavLink>
                                                            </li>
                                                            <li className='sub-menuAdminLi'>
                                                                <NavLink to="/agregar-persona" ><span>Agregar personas</span></NavLink>
                                                            </li>
                                                        </div>

                                                        <li>
                                                            <NavLink to="/cambiar-password"><IoLockClosedOutline /> <span>Cambiar password</span></NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="#" onClick={handleClick}><IoLogOutOutline /> <span>Salir</span></NavLink>
                                                        </li>
                                                    </ul>
                                                }

                                                {
                                                    (isEmpty(sucursal.branchID) || isEmpty(box.idNitro4)) &&
                                                    <ul>
                                                        <li className='btnSidebar text-end'>
                                                            <IoChevronBackOutline onClick={sidebarDesktop} data-arrow="false" />
                                                        </li>

                                                        <li>
                                                            <NavLink to="/asignar-box-sucursal" ><IoListCircleOutline /> <span>Sucursal y box</span></NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="#" onClick={handleClick}><IoLogOutOutline /> <span>Salir</span></NavLink>
                                                        </li>
                                                    </ul>
                                                }
                                                <span className="version">{version}</span>
                                            </nav>
                                        </>
                                    }
                                </>
                        }
                    </nav>
                    {
                        !isEmpty(sucursal.branchID) && !isEmpty(box.idNitro4) &&
                        <>
                            <div className="modal fade" id="modalEditarSucursalBox" tabIndex="-1" aria-labelledby="modalEditarSucursalBoxLabel" aria-hidden="true">
                                <div className="modal-dialog modal-xs">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title w-100 text-center" id="modalEditarSucursalBoxLabel">Editar sucursal asignada</h5>
                                            <button type="button" className="btn-close" id="modalEditarSucursalBoxClose" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>

                                        <div className="modal-body">
                                            <FormEditarSucursalYBoxAsignados />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
                :
                <></>
            }
        </>
    )





}
export default Menu;