import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { formatarFecha, mostrarOtros } from '../../../utils/functions';
import Select from '../../Select/select.jsx';
import Button from '../../Button';
import HORARIOS from '../../../config/horarios';
import updateTurn from '../../../services/adp/turn/update';
import Message from '../../../utils/message';
import Multiselect from '../../Multiselect/Multiselect';
import { obtenerListadoEmpresasktna } from '../../../services/adp/empresas/lista';
import ToastMesage from '../../../utils/toast';
import { Calendario } from '../../Calendar/calendar';

export default function FormEditarTurno(props) {
    const { id, setLoading, cerrar_modal, closeModalPersona, TOKEN, datosTurno, motivosReasignacion, cargarLista, restablecerCheckboxs, empresaSelected, setEmpresaSelected } = props;

    const [validated, setValidated] = useState(false);
    const [horarios_disponibles, setHorariosDisponibles] = useState(HORARIOS);
    const [resultadoBusqueda, setResultadoBusqueda] = useState("Ingrese 3 carácteres");
    const [isLoading, setIsLoading] = useState(false);

    const [date_selected, setDateSelected] = useState(null);
    const [formValues, setValues] = useState({
        id: props.id,
        empresa: empresaSelected[0]
    });

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        document.getElementById('addTurn').classList.add('d-none');
        document.getElementById('addTurnError').classList.add('d-none');
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            if (date_selected === null) {
                ToastMesage({ message: `Debes seleccionar una fecha`, type: 'error' });
            } else {
                if (empresaSelected.length === 0) {
                    Message({ message: 'Debes seleccionar al menos una empresa', type: 'error' })
                } else {
                    if (empresaSelected.length === 1) {
                        setValidated(false);
                        setLoading(true);
                        const newValue = {
                            ...formValues,
                            date: date_selected,
                            horary: formValues.horary_edit,
                            empresa: empresaSelected[0]
                        }
                        updateTurn({
                            id: id, params: newValue, token: TOKEN, callbacks: async (response) => {
                                if (response.estado > 0) {
                                    restablecerCheckboxs();
                                    form.reset();
                                    cerrar_modal();
                                    await closeModalPersona();
                                    cargarLista();
                                } else {
                                    Message({ message: response.mensaje, type: 'error' })
                                    setLoading(false);
                                }
                            }
                        });
                    } else {
                        Message({ message: 'No puedes seleccionar más de una empresa', type: 'error' })
                    }
                }
            }
        }
    };


    const cambiarMotivoDeReasignacion = async (event) => {
        handleInputChange(event);
        const { target } = event;
        mostrarOtros(target, document.getElementById('motivo_reasignacion_otros'));
    }


    const horary_select = document.getElementById('horary_edit');


    return (
        <>
            <div className="container">
                <div className="alert alert-success d-none my-3" id="addTurn"></div>
                <div className="alert alert-danger d-none my-3" id="addTurnError"></div>
                <div className="alert alert-info my-3" id="infoTurn">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>Nombre y apellido</th>
                                <th>{datosTurno.persona.name} {datosTurno.persona.surname}</th>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <th>{datosTurno.persona.email}</th>
                            </tr>
                            <tr>
                                <th>{datosTurno.persona.document_type}</th>
                                <th>{datosTurno.persona.document_number}</th>
                            </tr>
                            <tr>
                                <th>Fecha del turno</th>
                                <th>{formatarFecha(datosTurno.fecha)}</th>
                            </tr>
                            <tr>
                                <th>Empresa</th>
                                <th>{datosTurno.empresa}</th>
                            </tr>
                            <tr>
                                <th>Hora del turno</th>
                                <th>{datosTurno.hora}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-md-7 my-3">
                            <div className="form-group">
                                <Form.Label>Fecha</Form.Label>
                                <Calendario
                                    setDateSelected={setDateSelected}
                                    horary_select={horary_select}
                                    TOKEN={TOKEN}
                                    horarios_disponibles={horarios_disponibles}
                                    setHorariosDisponibles={setHorariosDisponibles}
                                />
                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                            </div>
                        </div>
                        <div className='col-md-5 my-3'>
                            <div className="form-group">
                                <Form.Label>Horario</Form.Label>
                                <Select
                                    value={horarios_disponibles}
                                    name="horary"
                                    id="horary_edit"
                                    placeholder='Selecciona un horario'
                                    onChange={handleInputChange}
                                    defaultValue={datosTurno.hora}
                                />
                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                            </div>
                        </div>
                        <div className='col-md-12 my-3'>
                            <div className="form-group">
                                <Form.Label>Empresa</Form.Label>
                                <Multiselect
                                    label="Seleccione una empresa"
                                    value={empresaSelected}
                                    onChange={setEmpresaSelected}
                                    options={[]}
                                    noOptions={resultadoBusqueda}
                                    isLoading={isLoading}
                                    filterOptions={async (options, filter) => {
                                        options = [];
                                        if (!filter) {
                                            return options;
                                        }
                                        if (filter.length >= 3) {
                                            setIsLoading(true);
                                            await obtenerListadoEmpresasktna({
                                                params: filter, token: TOKEN, callbacks: async (response) => {
                                                    if (response.estado > 0) {
                                                        const resultados = response.respuesta;
                                                        options = resultados.map((empresa) => ({
                                                            label: `${empresa.Empresa.toUpperCase()}`,
                                                            value: empresa.Empresa
                                                        }));
                                                        setIsLoading(false);
                                                    } else {
                                                        setResultadoBusqueda("No hay resultados");
                                                        setIsLoading(false);
                                                    }
                                                }
                                            });
                                        } else {
                                            setResultadoBusqueda("Ingrese 3 carácteres");
                                            setIsLoading(false);
                                        }
                                        return options;
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-12 my-3'>
                            <div className="form-group">
                                <Form.Label>Motivo de reasignacion</Form.Label>
                                <Select
                                    value={motivosReasignacion}
                                    name="motivo_reasignacion_id"
                                    id="motivo_reasignacion_id"
                                    placeholder="Seleccione el motivo de reasignación"
                                    onChange={cambiarMotivoDeReasignacion}
                                    other="yes"
                                />
                                <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                            </div>
                            <div className="form-group d-none" id="motivo_reasignacion_otros">
                                <Form.Label>Motivo de reasignación</Form.Label>
                                <Form.Control
                                    name="motivo_reasignacion_otros"
                                    id="motivo_reasignacion_otros"
                                    type="text"
                                    onChange={handleInputChange}
                                    placeholder="Indica el motivo de reasignacion"
                                />
                            </div>
                        </div>
                    </div>
                    <Button type="submit" className="btn-greengg d-block mx-auto my-5" text="Editar" id="buttonEditar" disabled />
                </Form>
            </div>
        </>
    );
}