import './_EncuestaCalidadModal.scss';
import { RaceBy } from "@uiball/loaders";

export default function ModalVerEncuesta(props) {

    const { encuesta, ver_modal, setModal, loading } = props;
    return (
        <div className={`modal ${!ver_modal ? 'fade' : 'show d-block'}`} id="modalCancelar" tabIndex="-1" aria-labelledby="modalCancelarLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                {loading ?
                    <div className="position-absolute containerLoading">
                        <RaceBy
                            size={280}
                            lineWeight={15}
                            speed={1.4}
                            color="black"
                            className="d-flex"
                        />
                    </div>
                    :
                    <div className="modal-content">
                        {encuesta !== null ?
                            <>
                                <div className="modal-header">
                                    <h5 className="modal-title w-100 text-center" id="modalCancelarLabel">Ver Encuesta #{encuesta.form_id}</h5>
                                    <button type="button" className="btn-close" id="modalDerivacionClose" onClick={() => { setModal(false) }} aria-label="Close"></button>
                                </div>

                                <div className="modal-body">
                                    <div className="card">
                                        <div className="card-header">
                                            Informacion de la encuesta
                                        </div>
                                        <div className="card-body">
                                            <h4>{encuesta.form_nombre}</h4>
                                            <h6>{encuesta.form_descripcion}</h6>
                                        </div>
                                    </div>
                                    <div className="card my-5">
                                        <div className="card-header">
                                            Preguntas de la encuesta
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {encuesta.campos.length > 0 &&
                                                    encuesta.campos.map((pregunta,index) => {
                                                        let { valores } = pregunta;
                                                        valores = JSON.parse(valores);
                                                        return (
                                                            <div className="col-md-6 my-4" key={index}>
                                                                <div className="card">
                                                                    <div className="card-header">{pregunta.nombre}</div>
                                                                    <div className="card-body">
                                                                        <div className="response">
                                                                            {valores.map(respuesta => {
                                                                                return (
                                                                                    <div className="respuesta">
                                                                                        {respuesta}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <h1>Selecciona una encuesta</h1>
                        }
                    </div>
                }
            </div>
        </div>
    );
}