import { useEffect } from "react";
import { useState } from "react"
import { useAuth } from "../../hooks/auth";
import listarEncuestas from "../../services/admin/encuestas/lista";
import { RaceBy } from "@uiball/loaders";
import Title from "../../components/Title";
import { isEmpty } from "../../utils/functions";
import ModalVerEncuesta from "../../components/Modals/Encuestas/verEncuestas";
import obtenerDetalleEncuesta from "../../services/admin/encuestas/obtener";
import Message from "../../utils/message";

export default function ListaDeEncuestas(props) {
    const [encuestas, setEncuestas] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [loading, setLoading] = useState(true);
    const { token } = useAuth();
    const [ver_modal, setModal] = useState(false);
    const encuesta_default = {
        form_id: "",
        form_nombre: "",
        form_descripcion: "",
        campos: [],
    };
    const [encuesta, setEncuesta] = useState(encuesta_default);

    useEffect(() => {
        listarEncuestas({
            token, callbacks: response => {
                setLoading(false);
                if (response.estado > 0) {
                    if (response.resultados.length > 0) {
                        setEncuestas(response.resultados);
                    } else {
                        setMensaje('No hay encuestas cargadas');
                    }
                } else {
                    setMensaje(response.mensaje);
                }
            }
        })
    }, [token])

    const verDetallesEncuesta = ({ id }) => {
        setLoading(true);
        obtenerDetalleEncuesta({
            id: id, token, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setModal(true);
                    setEncuesta(response.resultados[0]);
                    setLoading(false);
                } else {
                    Message({ message: response.mensaje, type: 'error' });
                    setModal(false);
                    setEncuesta(null);
                    setLoading(false);
                }
            }
        })
    }

    return (
        <>
            <main>
                <div className="container-fluid ps-0">
                    <div className="glassDatos">
                        <div className="pb-4 d-flex align-items-center justify-content-between">
                            <Title title="Encuestas" class="titlegray" />
                        </div>
                        {loading ?
                            <div className="position-absolute containerLoading">
                                <RaceBy
                                    size={280}
                                    lineWeight={15}
                                    speed={1.4}
                                    color="black"
                                    className="d-flex"
                                />
                            </div>
                            :
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Encuesta</th>
                                        <th>Descripcion</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isEmpty(mensaje) ?
                                        encuestas.map(item => {
                                            return (
                                                <tr key={item.form_id}>
                                                    <td>#{item.form_id}</td>
                                                    <td>{item.form_nombre}</td>
                                                    <td>{item.form_descripcion}</td>
                                                    <td>
                                                        <button className="btnDerivar  px-3" onClick={() => { verDetallesEncuesta({ id: item.form_id }) }}>
                                                            Ver
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <th>
                                                <div className="alert alert-danger">{mensaje}</div>
                                            </th>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </main>
            <ModalVerEncuesta
                encuesta={encuesta}
                ver_modal={ver_modal}
                setModal={setModal}
                loading={loading}
            />
        </>
    )
}