import { RaceBy, Waveform } from "@uiball/loaders";
import { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import finalizarListaTurnos from "../../services/adp/turn/finalizar-lista-turnos";
import historialDeAtencion from "../../services/adp/turn/obtener-historial-de-atencion";
import { formatarFecha } from "../../utils/functions";
import TURN_STATE_OBJECT from "../../utils/turn_state";
import DatosMotivoTurno from "../DatosMotivoTurno";
import NotResult from "../notResults";
import Observaciones from "../Observaciones";

const AtencionMultiple = (props) => {
    const { closeModal, TOKEN, abrir_atencion_multiple, loader_atencion_multiple, datosTurnosSeleccionados, turnos_seleccionados, obtenerDatosTurno, cargarLista, setLoader, setLoaderAtencionMultiple, turnos_finalizar, restablecerCheckboxs } = props;
    const [historial_atencion, setHistorial] = useState(null);
    const [ver_historial, setAbrirHistorial] = useState(false);
    const [loadingHistorial, setLoadingHistorial] = useState(true);

    const finalizeOption = async () => {
        setLoader(true);
        setLoaderAtencionMultiple(true)
        const formValues = {
            observaciones: document.getElementById('observaciones').value,
            lista: turnos_seleccionados
        }
        finalizarListaTurnos({
            token: TOKEN, params: formValues, callbacks: async (response) => {
                if (response.estado > 0) {
                    restablecerCheckboxs();
                    cargarLista();
                    await closeModal();
                }
                await setLoader(false);
                await setLoaderAtencionMultiple(false)
            }
        });
    }

    function historialDeAtencionTurnos({ target }) {
        const id = target.getAttribute('rel');
        setLoadingHistorial(true);
        setAbrirHistorial(true);
        historialDeAtencion({
            token: TOKEN, id: id, callbacks: response => {
                setHistorial(response.resultados ?? []);
                setLoadingHistorial(false)
            }
        })
    }

    return (
        <>
            <div className="shadowClose" data-close={abrir_atencion_multiple ? 'on' : 'off'} data-swap={abrir_atencion_multiple ? 'on' : 'off'} onClick={closeModal}></div>
            <div className="containerDatosPersonaCard" data-swap={abrir_atencion_multiple ? 'on' : 'off'}>
                {
                    loader_atencion_multiple &&
                    <RaceBy
                        size={280}
                        lineWeight={15}
                        speed={1.4}
                        color="black"
                        className="d-flex"
                    />
                }
                {
                    !loader_atencion_multiple &&
                    <div className="h-100 container d-flex flex-column p-0" id="show">
                        <button className="btnPaginationAtras cerrarModal" onClick={closeModal}>
                            <i className="fas fa-window-close"></i>
                        </button>
                        {
                            datosTurnosSeleccionados.map(item => {
                                const estado_total = TURN_STATE_OBJECT[item.estado_turno];
                                const color = estado_total !== undefined ? estado_total.dataColor - 1 : 1;
                                const display = estado_total !== undefined ? estado_total.display : item.estado_turno || 'Ocurrio un error';
                                return (
                                    <div className="my-5 p-5" key={`${item.motivo_turno_abreviacio}-${item.turno_id}`} style={{ 'border': `1px solid ${item.estado === 1 ? '#AAA' : '#FE4545'}`, 'borderRadius': '10px' }}>
                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="titlegray">{`${item.persona.name} ${item.persona.surname}`}</h3>
                                            </div>
                                            <div className="col-5">
                                                <div className="mt-5 container__main__datos">
                                                    <div className="container__historial__card">
                                                        <div>
                                                            <h5 className="m-0 fh5">#{item.motivo_turno_abreviacion} - {item.turno_id}</h5>
                                                        </div>
                                                        <div>
                                                            <p><b>Email:</b> <span>{item.persona.email}</span></p>
                                                        </div>
                                                        <div>
                                                            <p><b>{item.persona.document_type}: </b> <span>{item.persona.document_number}</span></p>
                                                        </div>
                                                        <div>
                                                            <p><b>Legajo: </b> <span>{item.persona.legajo}</span></p>
                                                        </div>
                                                        <div>
                                                            <p><b>Empresa: </b> <span>{item.persona.company}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-7">
                                                <div className="container__main__datos mt-5">
                                                    <div className="container__historial__card d-flex justify-content-between align-items-center mb-3">
                                                        <h5 className="m-0 fh5">Historial</h5>
                                                        <button className="btnAtender" rel={item.turno_id} onClick={historialDeAtencionTurnos}>Ver Historial</button>
                                                    </div>
                                                    <div>
                                                        <p><b>Sucursal:</b> <span>{item.sucursal.Nombre}</span></p>
                                                    </div>

                                                    <div>
                                                        <p><b>Fecha:</b> <span>{`${formatarFecha(item.fecha)}`}</span></p>
                                                    </div>

                                                    <div>
                                                        <p className="m-0"><b>Hora:</b> <span>{item.hora}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mt-3 container__main__datos">
                                                    {
                                                        item.estado === 1 &&
                                                        <div className="mb-4">
                                                            <b>Estado:</b> <span className="infoTurno" data-color={color}>{display}</span>
                                                        </div>
                                                    }
                                                    <DatosMotivoTurno
                                                        obtenerDatosTurno={obtenerDatosTurno}
                                                        datosTurno={item}
                                                        TOKEN={TOKEN}
                                                        cargarLista={cargarLista}
                                                        setLoader={setLoader}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            item.estado === 1 &&
                                            <>
                                                <div className="row bordersCustom">
                                                    <div className="col-12">
                                                        <div className="container__main__datos mt-4 mb-5">
                                                            <label>Observaciones</label>
                                                            <div className="alert alert-info">{item.observacion}</div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-6">
                                                        <div className="container__main__datos my-5">
                                                            <div>
                                                                <p><b>Sucursal:</b> <span>{item.sucursal.name}</span></p>
                                                            </div>

                                                            <div>
                                                                <p><b>Fecha: </b><span>{`${formatarFecha(item.fecha)}`}</span></p>
                                                            </div>

                                                            <div>
                                                                <p className="m-0"><b>Hora:</b> <span>{item.hora}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="container__main__datos my-5">
                                                            <div>
                                                                <p><b>Creador por</b>: <span></span></p>
                                                            </div>

                                                            <div>
                                                                <p><b>Email</b>: <span>{item.ADP_email}</span></p>
                                                            </div>

                                                            <div>
                                                                <p><b>Nombre</b>: <span>{item.ADP_nombre} {item.ADP_apellido}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            item.estado === 0 &&
                                            <NotResult title={`Este turno se encuentra ${display}`} className={`my-3 border-0 py-4 text-center font-weight ${color}`} />
                                        }
                                    </div>
                                )
                            })
                        }
                        {
                            turnos_finalizar.length > 0 &&
                            <>
                                <div className="col-12 container__main__datos">
                                    <div className="container__historial__card">
                                        <div className="container__form__observaciones my-5">
                                            <FloatingLabel controlId="observaciones" label="Observaciones" className="mb-3" >
                                                <Form.Control as="textarea" placeholder="Indique observaciones" className="customArea" />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 container__main__datos">
                                    <div className="container__historial__card">
                                        <div className="d-flex justify-content-center">
                                            <div className="w-100">
                                                <div className="col-12 my-4 d-flex">
                                                    <button className="btnCancelar w-100  mx-4" onClick={finalizeOption}>
                                                        Finalizar Atención
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`modal ${!ver_historial ? 'fade' : 'show d-block'}`} id="modalHistorial" tabIndex="-1" aria-labelledby="modalHistorialLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title w-100 text-center" id="modalHistorialLabel">Historial</h5>
                                                <button type="button" className="btn-close" onClick={() => { setAbrirHistorial(false) }} aria-label="Close"></button>
                                            </div>

                                            <div className="modal-body">
                                                {loadingHistorial &&
                                                    <Waveform
                                                        size={40}
                                                        lineWeight={3.5}
                                                        speed={1}
                                                        color="black"
                                                    />
                                                }
                                                {!loadingHistorial &&
                                                    <>
                                                        {historial_atencion.length > 0 ? <Observaciones lista={historial_atencion} /> : <div className="alert alert-warning">No hay turnos previos</div>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
            </div>
        </>
    );
}

export default AtencionMultiple;