import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import finalizarPresentacionEspontanea from '../../../services/adp/presentaciones-espontaneas/finalizar';
import Message from '../../../utils/message';
import Title from '../../Title';

function FormAgregarPresentacionEspontanea(props) {
    const { TOKEN, horary, id_presentacion, setLoading, setModal, setIdPresentacion, cargarLista } = props;
    const [validated, setValidated] = useState(false);


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            setLoading(true);
            const new_horary = new Date();
            const hours = new_horary.getHours() < 10 ? `0${new_horary.getHours()}` : new_horary.getHours();
            const minutes = new_horary.getMinutes() < 10 ? `0${new_horary.getMinutes()}` : new_horary.getMinutes();
            const second = new_horary.getSeconds() < 10 ? `0${new_horary.getSeconds()}` : new_horary.getSeconds();

            const horary_finalize = `${hours}:${minutes}:${second}`;

            const params = {
                horario: horary_finalize,
                horario_completo: new_horary
            }
            finalizarPresentacionEspontanea({
                id: id_presentacion, params: params, token: TOKEN, callbacks: response => {
                    setLoading(false);
                    if (response.estado > 0) {
                        cargarLista();
                        setIdPresentacion(null);
                        setModal(false);
                    } else {
                        Message({ message: response.mensaje, type: 'error' });
                    }
                }
            });
        }
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <div className="row">
                    <div className='col-12'>
                        <Title title={`Tiempo: ${horary}`} class="titlegray" />
                    </div>
                    <div className='col-12'>
                        <button type="submit" className={`btn-greengg d-block mx-auto my-5`}>Guardar</button>
                    </div>
                </div>
            </Form>
        </>
    );
}

export default FormAgregarPresentacionEspontanea;