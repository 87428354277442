import Title from "../../components/Title";
import FormCambiarPassword from "../../components/Forms/CambiarPassword";
import './_CambiarPassword.scss';
import { useAuth } from "../../hooks/auth";
import Loading from "../../components/Loading";

export default function CambiarPassword() {
    const { token: TOKEN, loading } = useAuth();

    return (
        <>
            {
                loading ?
                    <Loading /> :
                    <main className="w-100">
                        <div className="container-fluid my-5">
                            <div className="glassDatos">
                                <div className="pb-4 d-flex align-items-center justify-content-between">
                                    <Title title="Cambia la contraseña de acceso a Central de Turnos" class="titlegray" />
                                </div>
                                <div className="col-12">
                                    <FormCambiarPassword
                                        TOKEN={TOKEN}
                                    />
                                </div>
                            </div>
                        </div>
                    </main>
            }
        </>
    );
}