import Title from "../../components/Title";
import { Spinner } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import obtenerListadoColaboradores from "../../services/adp/colaboradores/list-collaborators";
import getPerson from '../../services/adp/person/get';
import { useAuth } from "../../hooks/auth";
import { useUser } from "../../hooks/user";
import EditarColaboradorModal from "../../components/Modals/EditarColaborador";
import Loading from "../../components/Loading";

export default function Colaboradores(props) {
    const { token: TOKEN } = useAuth();
    const { user: USER } = useUser();
    const [collaborators, setCollaborators] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading_all, setLoadingAll] = useState(false);

    const [colaborador, setColaborador] = useState({
        idNitro4: '',
        Nombre: '',
        Apellido: '',
        Email: '',
        DNI: '',
        telefono: '',
        Empresa: '',
        Legajo: '',
        codigo_area: ''
    });


    const [mostrarModal, setMostrarModal] = useState(false);

    useEffect(() => {
        obtenerListadoColaboradores({
            token: TOKEN, callbacks: response => {
                setLoading(false);
                if (response.estado > 0 && response.resultados.length > 0) {
                    let list = response.resultados;
                    setCollaborators(list);
                }
            }
        });
    }, [TOKEN, USER]);

    function abrirModalEditar(e) {
        e.preventDefault();
        setLoadingAll(true);
        getPerson({
            token: TOKEN, id: e.target.rel, callbacks: (response) => {
                setLoadingAll(false);
                setMostrarModal(true);
                if (response.estado > 0 && response.resultados.length > 0) {
                    const data = response.resultados[0];
                    setColaborador({
                        idNitro4: data.id,
                        Nombre: data.name,
                        Apellido: data.surname,
                        Email: data.email || '',
                        DNI: data.document_number,
                        telefono: data.phone || '',
                        Empresa: data.company,
                        Legajo: data.legajo,
                        codigo_area: data.codigo_area || ''
                    })
                }
            }
        })
    }

    return (
        <main>
            {loading_all ?
                <Loading />
                :
                <>
                    <div className="container-fluid ps-0 my-5">
                        <div className="glassDatos">
                            <div className="pb-4 d-flex align-items-center justify-content-between">
                                <Title title="Listado de Colaboradores" class="titlegray" />
                            </div>
                            <div className="col-12">
                                {loading ?
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </Spinner>
                                    :
                                    <table className="table table-stripped table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre</th>
                                                <th>Apellido</th>
                                                <th>Documento</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                collaborators.map((item) => {
                                                    return (
                                                        <tr key={item.idNitro4}>
                                                            <td>{item.idNitro4}</td>
                                                            <td>{item.Nombre}</td>
                                                            <td>{item.Apellido}</td>
                                                            <td>{item.DNI}</td>
                                                            <td>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn-sm">&nbsp;</Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item rel={item.idNitro4} onClick={abrirModalEditar}>Editar</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                    <EditarColaboradorModal
                        ver_modal={mostrarModal}
                        setAbrir={setMostrarModal}
                        colaborador={colaborador}
                        setColaborador={setColaborador}
                        setLoadingAll={setLoadingAll}
                        setCollaborators={setCollaborators}
                    />
                </>
            }
        </main>
    )
}