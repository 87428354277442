import { USERNAME, PASSWORD } from './config.js';
const IGNORE_IN_URL = ['validation'];

const MESSAGE_ERROR = {
    estado: 0,
    mensaje: 'No se pudo realizar la peticion'
};


/**
 * Enviar peticion al servidor por fecth
 */
export const connection = async ({ method = 'POST', url = '', extraHeaders = {}, params = {}, file = false }) => {
    var headers = new Headers({
        'Accept-Charset': 'utf-8',
        'Authorization': 'Basic ' + btoa(USERNAME + ":" + PASSWORD),
        'Content-Type': 'application/json',
    });

    /*AGREGRO LOS HEADERS EXTRAS ENVIADOS*/

    if (Object.keys(extraHeaders).length !== 0) {
        for (var headers_key in extraHeaders) {
            headers.append(headers_key, extraHeaders[headers_key]);
        }
    }

    let options = {
        method: method,
        headers: headers,
        redirect: 'follow',
    };

    const comparation_method = method === 'GET' || method === 'DELETE';
    options = !comparation_method ? { ...options, body: !file ? JSON.stringify(params) : params } : options;

    let init = 0;
    /*AGREGRO A LA URL LOS PARAMETROS ENVIADOS*/
    if (comparation_method) {
        if (Object.keys(params).length !== 0) {
            for (var key in params) {
                if (!IGNORE_IN_URL.includes(key)) {
                    url = init === 0 ? `${url}?${key}=${params[key]}` : `${url}&${key}=${params[key]}`;
                    init++;
                }
            }
        }
    }
    let response;
    try {
        response = await fetch(url, options);
        if (!response.ok) {
            console.warn('Request failed I', response);
            let mensaje = response.description ?? response.statusText;
            if (response.status === 401) {
                mensaje = "No tiene permiso para utilizar este servicio";
            }
            response = MESSAGE_ERROR;
            response.mensaje = mensaje;
        } else {
            response = await response.json();
            if (response.hasOwnProperty('estado')) {
                if (response.estado === 0) {
                    if (response.hasOwnProperty('mensaje')) {
                        let message = response.mensaje.toLowerCase();
                        message = message.replaceAll('hubo un error. ', '');
                        response.mensaje = message.charAt(0).toUpperCase() + message.slice(1);
                    }
                }
            }
        }

    } catch (error) {
        console.error('Request failed II', error);
        response = MESSAGE_ERROR;
        response.mensaje = error;
    } finally {
        return response;
    }
}