import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormAgregarEditarSucursal from "../../components/Forms/AgregarEditarSucursal";
import Title from "../../components/Title";
import getSucursalData from '../../services/adp/branch/get';
import { useAuth } from '../../hooks/auth';
export default function EditarSucursales() {

    const { id } = useParams();

    const [sucursal, setSucursalData] = useState([]);
    const { token: TOKEN } = useAuth();

    useEffect(() => {
        getSucursalData({
            token: TOKEN, id: id, callbacks: (response) => {
                setSucursalData(response.resultados[0]);
            }
        });
    }, [TOKEN, id]);

    return (
        <>
            <Title title="Editar una sucursal" class="title" />

            <FormAgregarEditarSucursal name={sucursal.name} address={sucursal.address} locality={sucursal.locality} province={sucursal.province} longitude={sucursal.longitude} latitude={sucursal.latitude} employees={sucursal.employees} idNitro4={id} update="yes" />
        </>
    )
}