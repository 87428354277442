export const MAIN_URL = process.env.REACT_APP_MAIN_URL;
// let actually = new String(window.location);
// let url_array = actually.split("/");

// export const MAIN_URL = url_array[0] + '//' + url_array[2];



export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const API_URL = process.env.REACT_APP_API_URL;
export const API_URLBOT = process.env.REACT_APP_API_URLBOT;

export const USERNAME = process.env.REACT_APP_USER;
export const PASSWORD = process.env.REACT_APP_PASSWORD;
export const version = 'v1.5';

export const API_KEY = {
    GOOGLE_MAPS: 'AIzaSyDnpjjbeS4azogVG-USLY2A--27xKGehx0'
}

let date = new Date();
export const CURRENT_DATE = date.toISOString().split('T')[0];


export const ESTADO_TURNO = {
    SIN_CONFIRMAR: process.env.ESTADO_TURNO_SIN_CONFIRMAR || 2,
    CONFIRMADO: process.env.ESTADO_TURNO_CONFIRMADO || 3,
    PENDIENTE_DE_ATENCION: process.env.ESTADO_TURNO_PENDIENTE_DE_ATENCION || 4,
    ATENDIDO: process.env.ESTADO_TURNO_ATENDIDO || 5,
    FINALIZADO: process.env.ESTADO_TURNO_FINALIZADO || 6,
    CANCELADO: process.env.ESTADO_TURNO_CANCELADO || 7,
    TRANSFERIDO: process.env.ESTADO_TURNO_TRANSFERIDO || 11,
    NO_PRESENTADO: process.env.ESTADO_TURNO_NO_PRESENTADO || 12,
    DERIVADO: process.env.ESTADO_TURNO_DERIVADO || 13,
    REASIGNADO: process.env.ESTADO_TURNO_REASIGNADO || 14
}





export const MESES = [
    {
        id: '01',
        name: 'Enero'
    },
    {
        id: '02',
        name: 'Febrero'
    },
    {
        id: '03',
        name: 'Marzo'
    },
    {
        id: '04',
        name: 'Abril'
    },
    {
        id: '05',
        name: 'Mayo'
    },
    {
        id: '06',
        name: 'Junio'
    },
    {
        id: '07',
        name: 'Julio'
    },
    {
        id: '08',
        name: 'Agosto'
    },
    {
        id: '09',
        name: 'Septiembre'
    },
    {
        id: '10',
        name: 'Octubre'
    },
    {
        id: '11',
        name: 'Noviembre'
    },
    {
        id: '12',
        name: 'Diciembre'
    },
]


export const ANIO = [
    {
        id: '2022',
        name: '2022'
    },
    {
        id: '2023',
        name: '2023'
    },
    {
        id: '2024',
        name: '2024'
    },
]