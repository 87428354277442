import React, { useState } from 'react';
import { useAuth } from '../../../hooks/auth';
import { Form } from 'react-bootstrap';
import Button from '../../Button';
import modificarColaboradr from '../../../services/adp/person/update-colaborador';
import Message from '../../../utils/message';
import obtenerListadoColaboradores from '../../../services/adp/colaboradores/list-collaborators';


export default function EditarColaboradorModal(props) {
    const { token: TOKEN } = useAuth();
    const { ver_modal, setAbrir, colaborador, setColaborador, setLoadingAll, setCollaborators } = props;
    const [validated, setValidated] = useState(false);


    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setColaborador({
            ...colaborador,
            [name]: value,
        });
    }
    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            setLoadingAll(true);
            modificarColaboradr({
                token: TOKEN, params: colaborador, callbacks: response => {
                    if (response.estado > 0) {
                        obtenerListadoColaboradores({
                            token: TOKEN, callbacks: response => {
                                if (response.estado > 0 && response.resultados.length > 0) {
                                    let list = response.resultados;
                                    setCollaborators(list);
                                    setAbrir(false);
                                    setLoadingAll(false);
                                }
                            }
                        });
                    } else {
                        Message({ message: response.mensaje, type: 'error' });
                        setLoadingAll(false);
                    }
                }
            })
        }
    };

    return (
        <div className={`modal ${!ver_modal ? 'fade' : 'show d-block'}`} id="modalEditarColaborador" tabIndex="-1" aria-labelledby="modalEditarColaboradorLabel" aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center" id="modalEditarColaboradorLabel">Editar colaborador</h5>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={() => { setAbrir(false) }} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                            <div className="container">
                                <div className="row my-3">
                                    <div className="col-md-12">
                                        <Form.Group className="my-3" controlId="Nombre">
                                            <Form.Label>Nombre</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="Nombre"
                                                id="Nombre"
                                                className='input-gg my-0'
                                                placeholder="Ingresar el nombre del colaborador"
                                                onChange={handleInputChange}
                                                defaultValue={colaborador.Nombre}
                                            />
                                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="my-3" controlId="Apellido">
                                            <Form.Label>Apellido</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="Apellido"
                                                className='input-gg my-0'
                                                id="Apellido"
                                                placeholder="Ingresar apellido del colaborador"
                                                onChange={handleInputChange}
                                                defaultValue={colaborador.Apellido}
                                            />
                                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="my-3" controlId="Email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                name="Email"
                                                id="Email"
                                                className='input-gg my-0'
                                                placeholder="Ingresar email"
                                                onChange={handleInputChange}
                                                defaultValue={colaborador.Email}
                                            />
                                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="my-3" controlId="DNI">
                                            <Form.Label>DNI</Form.Label>
                                            <Form.Control
                                                required
                                                type="number"
                                                name="DNI"
                                                id="DNI"
                                                className='input-gg my-0'
                                                placeholder="Ingresar numero de documento"
                                                onChange={handleInputChange}
                                                defaultValue={colaborador.DNI}
                                            />
                                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="my-3" controlId="Empresa">
                                            <Form.Label>Empresa</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Empresa"
                                                id="Empresa"
                                                className='input-gg my-0'
                                                placeholder="Ingresar empresa del colaborador"
                                                onChange={handleInputChange}
                                                defaultValue={colaborador.Empresa}
                                            />
                                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <Form.Group className="my-3" controlId="codigo_area">
                                                    <Form.Label>Codigo Area</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="tel"
                                                        name="codigo_area"
                                                        id="codigo_area"
                                                        className='input-gg my-0'
                                                        placeholder="Ingresar el codigo de area del colaborador"
                                                        onChange={handleInputChange}
                                                        defaultValue={colaborador.codigo_area ?? ''}
                                                    />
                                                    <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-8">
                                                <Form.Group className="my-3" controlId="telefono">
                                                    <Form.Label>Telefono</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="tel"
                                                        name="telefono"
                                                        id="telefono"
                                                        className='input-gg my-0'
                                                        placeholder="Ingresar telefono del colaborador"
                                                        onChange={handleInputChange}
                                                        defaultValue={colaborador.telefono}
                                                    />
                                                    <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="my-3" controlId="Legajo">
                                            <Form.Label>Legajo</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Legajo"
                                                id="Legajo"
                                                className='input-gg my-0'
                                                placeholder="Ingresar legajo del colaborador"
                                                onChange={handleInputChange}
                                                defaultValue={colaborador.Legajo}
                                            />
                                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <Button type="submit" className="btn-greengg d-block mx-auto my-5" text="Agregar" />
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}