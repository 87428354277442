import React, { useEffect, useState, useRef } from "react";
import './_EncuestaCalidad.scss';

import { ENTITY_URL } from '../../config/config';
import { connection } from '../../utils/connection';

import Title from "../../components/Title";
import Spinner from 'react-bootstrap/Spinner';
import FormEncuesta from "../../components/Forms/FormEncuesta";
import { useAuth } from "../../hooks/auth";
import { useParams } from "react-router-dom";

function EncuestaCalidad() {
    const [encuestaCalidad, setEncuestaCalidad] = useState();
    const [encuestaSeleccionada, setEncuestaSeleccionada] = useState(0)
    const [loading, setLoading] = useState(true)
    const [arrRespuestas, setArrRespuestas] = useState([])
    const formulario = useRef()
    const { token: TOKEN } = useAuth();
    const [persona_id, setPersonaID] = useState();

    const { turnoID, hash } = useParams();
    const [selected, setSelected] = useState(false);

    const pasarEncuesta = (numero) => {
        const encuestaActual = (encuestaSeleccionada + numero) >= 0 ? encuestaSeleccionada + numero : 0
        if (encuestaActual === encuestaCalidad.campos.length) {
            enviarRespuesta(arrRespuestas, persona_id, hash, TOKEN)
            setEncuestaSeleccionada(encuestaActual);
        } else {
            setEncuestaSeleccionada(encuestaActual);
        }
    }

    const enviarRespuesta = (respuesta, personaID, hash, token) => {
        const data = {
            respuestas: JSON.stringify(respuesta),
            persona_id: personaID,
            encuesta_id: encuestaCalidad.form_id,
            hash
        }
        connection({ method: 'PUT', url: `${ENTITY_URL.ENCUESTA.CONFIRM}/${encuestaCalidad.form_id}`, extraHeaders: { token: token }, params: data }).then(response => {
            console.log({ response });
        });
    }


    useEffect(() => {
        let data = {
            persona_id: persona_id,
            turnoID: turnoID,
            hash: hash
        }
        connection({ method: 'POST', url: `${ENTITY_URL.ENCUESTA.GETFORM}`, extraHeaders: { token: TOKEN }, params: data }).then(response => {
            if (response.estado > 0 && response.resultados.length > 0) {
                setPersonaID(response.persona_id);
                setEncuestaCalidad(response.resultados[0]);
            }
            setLoading(false)

        });
    }, [TOKEN, persona_id, hash, turnoID]);

    const cambiarPregunta = () => {
        if (!selected) {
            alert('Selecciona una opcion')
        } else {
            pasarEncuesta(1);
            setSelected(false);
        }
    }

    const volverPregunta = () => {
        pasarEncuesta(-1)
        setSelected(false);

    }
    return (
        <main className="container container__EncuestaCalidad">
            <section className="container container__Encuesta">
                <Title title="Encuesta de calidad" class="titlegray"></Title>
                {
                    !loading &&
                    <div ref={formulario} className="d-flex flex-column justify-content-center align-items-center">
                        {
                            encuestaCalidad && encuestaSeleccionada !== encuestaCalidad?.campos.length &&
                            <FormEncuesta preguntas={encuestaCalidad?.campos[encuestaSeleccionada]} state={arrRespuestas} onClickChange={setArrRespuestas} setSelected={setSelected} />
                        }
                        {
                            encuestaSeleccionada === encuestaCalidad?.campos.length &&
                            <h1>Gracias por enviar sus datos</h1>
                        }

                        <div className="my-4 d-flex justify-content-center">
                            {
                                encuestaSeleccionada !== encuestaCalidad?.campos.length &&
                                (
                                    <>
                                        {
                                            encuestaSeleccionada !== 0 &&
                                            <button className={`btn-greengg mx-3`} onClick={volverPregunta}>Anterior</button>
                                        }

                                        <button className={`btn-orange mx-3 ${selected ? '' : 'disabled'}`} disabled={!selected} onClick={cambiarPregunta}>Siguiente</button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                }
                {
                    loading &&
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Cargando...</span>
                    </Spinner>
                }
            </section>
        </main>
    );
}


export default EncuestaCalidad;