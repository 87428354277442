import React, { useState, useEffect } from 'react';
import { Waveform } from "@uiball/loaders";
import PERSON_TYPE from "../../../config/person_type";
import getListDocumentTypes from "../../../services/adp/document_type/list";
import getListProfiles from "../../../services/adp/profile/list";
import { Form } from 'react-bootstrap';
import Button from '../../Button';
import Message from '../../../utils/message';
import Select from '../../Select/select';
import agregarColaborador from '../../../services/adp/person/agregar-colaborador';
import { obtenerListadoEmpresasktna } from '../../../services/adp/empresas/lista';
import Multiselect from '../../Multiselect/Multiselect';
import { useAuth } from '../../../hooks/auth';

function FormAgregarPersona(props) {
    const { document_type_list, TOKEN, setAbrir } = props;
    const [loading, setLoading] = useState(false);

    const [validated, setValidated] = useState(false);
    const [empresaSelected, setempresaSelected] = useState([]);
    const [resultadoBusqueda, setResultadoBusqueda] = useState("Ingrese 3 carácteres");

    const [formValues, setValues] = useState({
        Nombre: "",
        Apellido: "",
        Email: "",
        DNI: "",
        Tipo_dni_id: "",
        Legajo: ""
    });

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            setLoading(true);
            const newValue = {
                ...formValues,
                Empresa: empresaSelected[0].value
            }
            agregarColaborador({
                params: newValue, token: TOKEN, callbacks: response => {
                    setLoading(false);
                    if (response.estado > 0) {
                        form.reset();
                        setempresaSelected([])
                        setValues({
                            Nombre: "",
                            Apellido: "",
                            Email: "",
                            DNI: "",
                            Tipo_dni_id: "",
                            Legajo: ""
                        });
                        setAbrir(false);
                        Message({ message: 'Se agego correctamente la persona', type: 'success' });
                    } else {
                        Message({ message: response.mensaje ?? response.mensaje, type: 'error' })
                    }
                }
            });
        }
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
            <div className="container">
                <div className="row my-3">
                    <div className="col-md-12">
                        <div className="form-group my-3">
                            <Form.Control
                                required
                                type="text"
                                name="Nombre"
                                id="Nombre"
                                className='input-gg'
                                placeholder="Ingresar el nombre del colaborador"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group my-3">
                            <Form.Control
                                required
                                type="text"
                                name="Apellido"
                                className='input-gg'
                                id="Apellido"
                                placeholder="Ingresar apellido del colaborador"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group my-3">
                            <Multiselect
                                label="Seleccione la empresa"
                                value={empresaSelected}
                                className='input-gg'
                                onChange={setempresaSelected}
                                options={[]}
                                noOptions={resultadoBusqueda}
                                filterOptions={async (options, filter) => {
                                    options = [];
                                    if (!filter) {
                                        return options;
                                    }
                                    if (filter.length >= 3) {
                                        await obtenerListadoEmpresasktna({
                                            params: filter, token: TOKEN, callbacks: async (response) => {
                                                if (response.estado > 0) {
                                                    const EMPRESAS = response.respuesta;
                                                    if (EMPRESAS.length > 0) {
                                                        options = EMPRESAS.map((empresa) => ({
                                                            label: `${empresa.Empresa.toUpperCase()}`,
                                                            value: `${empresa.Empresa}`
                                                        }))
                                                    } else {
                                                        setResultadoBusqueda("No hay resultados");
                                                    }
                                                }
                                                else {
                                                    setResultadoBusqueda("No hay resultados");
                                                }
                                            }
                                        });
                                    } else {
                                        setResultadoBusqueda("Ingrese 3 carácteres");
                                    }
                                    return options;
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group my-3">
                            <Form.Control
                                type="text"
                                name="Legajo"
                                id="Legajo"
                                className='input-gg'
                                placeholder="Ingresar legajo del colaborador"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="form-group my-3">
                            <Form.Control
                                required
                                type="number"
                                name="DNI"
                                id="DNI"
                                className='input-gg'
                                placeholder="Ingresar numero de documento"
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group my-3">
                            <Select
                                required
                                value={document_type_list}
                                name="Tipo_dni_id"
                                id="Tipo_dni_id"
                                placeholder="Tipo de documento"
                                className='input-gg'
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <Button
                        type="submit"
                        className={`${loading ? 'btn-greengg text-center disabled' : 'btn-greengg'} d-block mx-auto my-5`}
                        text={loading ?
                            <Waveform
                                size={20}
                                lineWeight={3.5}
                                speed={1}
                                color="#FFF"
                                className="d-block mx-auto"
                            /> :
                            "Agregar"}
                        disabled={loading} />
                </div>
            </div>
        </Form>
    );
}


export default function ModalAgregarPersona(props) {
    const { token: TOKEN } = useAuth();
    const { ver_modal, setAbrir } = props;

    const [documentTypeList, setDniType] = useState([]);
    const [profilesTypeList, setProfileType] = useState([]);

    useEffect(() => {
        getListDocumentTypes({
            token: TOKEN, callbacks: response => {
                setDniType(response.resultados ?? []);
            }
        });
        getListProfiles({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setProfileType(response.resultados.filter(item => PERSON_TYPE[item.name].is_collaborator));
                }

            }
        });
    }, [TOKEN]);
    return (
        <div className={`modal ${!ver_modal ? 'fade' : 'show d-block'}`} id="modalAgregarPersonaCDI" tabIndex="-1" aria-labelledby="modalAgregarPersonaCDILabel" aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center" id="modalAgregarPersonaCDILabel">Agregar colaborador</h5>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={() => { setAbrir(false) }} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <FormAgregarPersona
                            document_type_list={documentTypeList}
                            profile_type_list={profilesTypeList}
                            TOKEN={TOKEN}
                            setAbrir={setAbrir}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}