import FormAgregarEditarSucursal from "../../components/Forms/AgregarEditarSucursal";
import Title from "../../components/Title";
export default function AgregarSucursales() {
    return (
        <>
        <main className="pt-3">
            <Title title="Agregar sucursales" class="title"/>
            <FormAgregarEditarSucursal/>
        </main>
        </>
    )
}