import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Container, Alert } from 'react-bootstrap';
import getListBranchs from '../../../services/collaborator/branch/list';
import checkAvaiableDays from '../../../services/collaborator/turn/check-availability-days';
import checkAvaiableHours from '../../../services/collaborator/turn/check-availability-hours';
import getListTurnReasons from '../../../services/collaborator/turn_reason/list';
import { CURRENT_DATE } from '../../../utils/config';
import { addDisabled, comprobarFechaMayor, isEmpty, removeDisabled } from '../../../utils/functions';
import Select from '../../Select/select.jsx';
import { MAIN_URL } from '../../../utils/config';
import addTurn from '../../../services/collaborator/turn/add';
import Button from '../../Button';
import NotResult from '../../notResults';
import Multiselect from '../../Multiselect/Multiselect';
import Message from '../../../utils/message';
import { useAuth } from '../../../hooks/auth';


export default function FormAgregarTurnoColaborador(props) {
    const { token: TOKEN } = useAuth();
    const [validated, setValidated] = useState(false);
    const [motivoTurnoSelected, setMotivoTurnoSelected] = useState([]);

    const [turnReasons, setTurnReasonsList] = useState([]);
    const [branchs, setBranchs] = useState([]);
    const [branch_selected, setBranchSelect] = useState({ nombre: 'Selecciona una sucursal', value: 0 });

    const [days_not_avaiable, setDaysNotAvaiable] = useState([]);
    const [schedules_available, setHorarysAvaiable] = useState([]);

    const [formValues, setValues] = useState({
        branch_id: '',
        date: '',
        horary: ''
    });

    const branch_select = document.getElementById('branch_id');
    const turn_reason_select = document.getElementById('turn_reason_id');
    const date_input = document.getElementById('date');
    const horary_select = document.getElementById('horary');

    const date_max = new Date();
    const months = 1;

    date_max.setMonth(date_max.getMonth() + months);


    useEffect(() => {
        getListBranchs({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setBranchs(response.resultados);
                    removeDisabled({ element: branch_select });
                    removeDisabled({ element: date_input });
                } else {
                    addDisabled({ element: branch_select });
                    addDisabled({ element: turn_reason_select });
                    addDisabled({ element: horary_select });
                }
            }
        });


        getListTurnReasons({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    let motivosTurnoArray = [];
                    response.resultados.forEach(item => {
                        let label = `${item.abbreviation} - ${item.name}`;
                        motivosTurnoArray.push({
                            value: item.id,
                            label: label.toUpperCase(),
                        });
                    });
                    setTurnReasonsList(motivosTurnoArray);
                } else {
                    addDisabled({ element: branch_select });
                    addDisabled({ element: turn_reason_select });
                    addDisabled({ element: horary_select });
                }
            }
        });
    }, [TOKEN, branch_select, turn_reason_select, date_input, horary_select]);

    function handleInputChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        setValues({
            ...formValues,
            [name]: value,
        });
    }

    function handleBranchSelectChange(evt) {
        handleInputChange(evt);
        setHorarysAvaiable([]);
        const { target } = evt;
        const { value } = target;
        setBranchSelect(value);
        date_input.value = '';
        horary_select.value = '';
        document.getElementById('notResultAddTurn').style.display = 'none';
        addDisabled({ element: turn_reason_select });
        addDisabled({ element: horary_select });
        if (!isEmpty(value)) {
            const params = {
                branch_id: value
            }
            checkAvaiableDays({
                params: params, token: TOKEN, callbacks: response => {
                    setDaysNotAvaiable(response.resultados ?? []);
                    date_input.setAttribute('min', CURRENT_DATE);
                    date_input.setAttribute('max', date_max.toISOString().split("T")[0]);
                    removeDisabled({ element: turn_reason_select });
                    removeDisabled({ element: date_input });

                }
            });
        } else {
            addDisabled({ element: turn_reason_select });
            addDisabled({ element: horary_select });
        }

    }


    function handleDatePickerChange(evt) {
        handleInputChange(evt);
        const { target } = evt;
        const { value } = target;
        addDisabled({ element: horary_select });
        document.getElementById('notResultAddTurn').style.display = 'none';
        horary_select.value = '';
        if (!isEmpty(value)) {
            // if (!days_not_avaiable.includes(value)) {
            const params = {
                branch_id: branch_selected,
                date: value
            }
            checkAvaiableHours({
                params: params, token: TOKEN, callbacks: response => {
                    let horarios_disponibles = response.resultados;
                    setHorarysAvaiable(horarios_disponibles);
                    comprobarFechaMayor({ valor: value, horarios: horarios_disponibles, setValue: setHorarysAvaiable });
                    removeDisabled({ element: horary_select });
                }
            });
            // } else {
            //     document.getElementById('notResultAddTurn').innerHTML = 'Fecha no disponible';
            //     document.getElementById('notResultAddTurn').style.display = 'block';
            //     target.value = '';
            //     addDisabled({ element: horary_select });
            // }
        }
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        document.getElementById('addTurnError').classList.add('d-none');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            const newValue = {
                ...formValues,
                turn_reasons: motivoTurnoSelected,
            }
            if (motivoTurnoSelected.length > 0) {
                document.querySelector('.btn-greengg').dataset.disabled = 'on';
                addTurn({
                    params: newValue, token: TOKEN, callbacks: response => {
                        if (response.estado > 0) {
                            document.location.href = `${MAIN_URL}/splash-confirm`;
                        } else {
                            document.querySelector('.btn-greengg').dataset.disabled = 'off';
                            Message({ message: response.mensaje, type: 'error' })
                        }
                    }
                });
            } else {
                document.getElementById('addTurnError').classList.remove('d-none');
                document.getElementById('addTurnError').innerHTML = 'Debes seleccionar al menos un motivo de turno';
            }
        }
    };

    return (
        <>
        <div className="modal fade" id="modalNoEncuentra" tabIndex="-1" aria-labelledby="modalNoEncuentraLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-body">
                        <h2 className='h5 text-center'>Comunicate con nosotros a través de nuestros medios: </h2>
                        <br />
                        WhatsApp: <a href="https://api.whatsapp.com/send?phone=541128012338&text=Hola%2C%20quisiera%20agendar%20un%20turno">+54 011 2801-2338</a>
                        <br />
                        Sucursal Virtual: <a href="https://sv.grupo-gestion.com.ar/sucursalvirtual.php/auth/validarusuario">App del Colaborador</a>
                    </div>
                </div>
            </div>
        </div>
        <Container className='col-12'>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Alert id="addTurnError" variant="danger" className='d-none my-3'></Alert>
                <Row className="mb-3 flex-column justify-content-center">
                    <Col className='resetInputs my-3'>
                        <Form.Group>
                            <Form.Label>Sucursal</Form.Label>
                            <Select
                                value={branchs}
                                name="branch_id"
                                id="branch_id"
                                placeholder="Seleccione la sucursal"
                                defaultValue={props.branch_id ?? ''}
                                onChange={handleBranchSelectChange}
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className='my-3'>
                        <Form.Group>
                            <Form.Label>Motivo de turno</Form.Label>
                            <Multiselect
                                label="Seleccione motivos de turno"
                                value={motivoTurnoSelected}
                                onChange={setMotivoTurnoSelected}
                                options={turnReasons}
                                disabled='disabled'
                                className='multiSelectStyles'
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                        <div className='mt-2'>
                            <p>
                                ¿No encuentras el motivo de turno? <a href="#" data-bs-toggle="modal" data-bs-target="#modalNoEncuentra">Haz click aqui</a>
                            </p>
                        </div>
                        <Alert key="success" variant="success" className="d-none my-3" id="listaAlert">
                            <ul id="list">
                            </ul>
                        </Alert>
                    </Col>
                    <Col className='resetInputs  my-3'>
                        <Form.Group>
                            <Form.Label>Fecha</Form.Label>
                            <Form.Control
                                required
                                name="date"
                                id="date"
                                type="date"
                                min={new Date().toISOString().split("T")[0]}
                                defaultValue={props.date ?? ''}
                                onChange={handleDatePickerChange}
                                disabled='disabled'
                                className='disabled'
                                placeholder="Seleccionar fecha"
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                        <NotResult title="" id="notResultAddTurn" className="my-3" />
                    </Col>
                    <Col className='resetInputs  my-3'>
                        <Form.Group>
                            <Form.Label>Horario</Form.Label>
                            <Select
                                value={schedules_available}
                                name="horary"
                                id="horary"
                                placeholder="Seleccione el horario"
                                defaultValue={props.horary ?? ''}
                                onChange={handleInputChange}
                                disabled
                            />
                            <Form.Control.Feedback>Campo correcto</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Button data-disabled="off" type="submit" className="btn-greengg d-block mx-auto my-5" text="Agregar" />
            </Form>
        </Container>
        </>
    );
}