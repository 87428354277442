import React from "react";
import 'swagger-ui-react/swagger-ui.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './App.scss'
import Login from "./pages/login";


// /**
//  * CONSTANTES
//  */
// import { isEmpty } from "./utils/functions.js";
// import PERSON_TYPE from "./config/person_type.js";



import Home from "./pages/home";
import MetricasTiempoDeEsperaEnAtencion from "./pages/metricas/tiempoEnAtencion.jsx";
import MetricasDuracionDeTurnos from "./pages/metricas/duracionDeTurnos.jsx";
import MetricasCantidadDeTurnos from "./pages/metricas/cantidadDeTurnos.jsx";
import ActivateAccount from "./pages/activateAccount/index.jsx";
import CambiarPassword from "./pages/cambiarPassword/index.jsx";
import NotFound from "./pages/NotFound";
import EncuestaCalidad from "./pages/Encuesta-calidad/EncuestaDeCalidad";
import PresentacionesEspontaneas from "./pages/presentacionEspontanea/index.jsx";
import MetricasTurnosCancelados from "./pages/metricas/turnosCancelados.jsx";
import MetricasTurnosPorOperador from "./pages/metricas/turnosPorOperador.jsx";
import MetricasTurnosPorMotivoTurno from "./pages/metricas/turnosPorMotivoTurno.jsx";
import MetricasTurnosPorClientes from "./pages/metricas/turnosPorClientes.jsx";
import MetricasPresentacionesEspontaneas from "./pages/metricas/presentacionesEspontaneas.jsx";
import MetricasMotivosTurnosPorClientes from "./pages/metricas/motivosTurnosPorClientes.jsx";
import EditarSucursales from "./pages/editarSucursales/index.jsx";
import EditarPersonas from "./pages/editarPersonas/index.jsx";
import HistorialPersona from "./pages/historial/index.jsx";
import Feriados from "./pages/Feriados/index.jsx";
import Personas from "./pages/personas";
import Sucursales from "./pages/sucursales";
import MotivosTurno from "./pages/motivosTurno";
import MotivosCancelacion from "./pages/motivosCancelacion";
import MotivosDerivacion from "./pages/motivosDerivacion/index.jsx";
import MotivosReasignacion from "./pages/motivosReasignacion/index.jsx";
import AgregarPreguntas from "./pages/agregarPreguntas/agregarPreguntas.jsx";
import AsignarSucursal from "./pages/asignarSucursal";
import AgregarPersonas from "./pages/agregarPersonas";
import AgregarSucursales from "./pages/agregarSucursales";
import ActualizarCantidadTurnos from "./pages/actualizarCantidadDeTurnos";
import Configuracion from "./pages/configuracion";
import Metricas from "./pages/metricas/Metricas.jsx";
import TurnosEnEspera from "./pages/turnosEspera/turnos-en-espera";
import ConfigurarLoginUser from "./pages/configuracionBoxSucursal/index.jsx";
import PantallaTurnos from "./pages/player";
import CancelarTurnoColaborador from "./pages/cancelarTurnoColaborador";
import Turnos from "./pages/misTurnos";
import SplashConfirm from "./pages/Splash-confirm/SplashConfirm";
import ConfirmaTurno from "./pages/confirmaTurno/ConfirmaTurno";

import { RequireAuth } from "./utils/requireAuth";
import { AuthProvider } from "./hooks/auth";
import { SocketProvider } from "./hooks/socket";
import { FirebaseProvider } from "./hooks/firebase";
import { UserProvider } from "./hooks/user";
import { SucursalProvider } from "./hooks/sucursal";
import { BoxProvider } from "./hooks/box";
// import { TurnosProvider } from "./hooks/turnos";
import Menu from "./components/Menu";
import Colaboradores from "./pages/colaboradores/listaColaboradores";
import ListaDeEncuestas from "./pages/ListaEncuestas";
import LimpiarLogin from "./pages/LimpiarLogin";
import RestablecerPassword from "./pages/RestablecerPassword";
import NuevaPassword from "./pages/NuevaPassword";

export default function App() {
    return (
        <UserProvider>
            <AuthProvider>
                <SucursalProvider>
                    <BoxProvider>
                        <FirebaseProvider>
                            <SocketProvider>
                                <Router>
                                    <Routes>
                                        {/* <Route path="/" caseSensitive={false} element={<RequireAuth><Menu /><Home /></RequireAuth>} /> */}
                                        <Route path="/" caseSensitive={false} element={<RequireAuth><Home /></RequireAuth>} />
                                        <Route path="/login" caseSensitive={false} element={<Login />} />
                                        <Route path="/restablecer-password" caseSensitive={false} element={<RestablecerPassword />} />
                                        <Route path="/password-reset/:clientID/:hash" caseSensitive={false} element={<NuevaPassword />} />




                                        <Route path="/encuesta-calidad/:turnoID/:hash" caseSensitive={false} element={<EncuestaCalidad />} />
                                        <Route path="/activate-account/:clientID/:hash" caseSensitive={false} element={<ActivateAccount />} />
                                        <Route path="/cambiar-password" caseSensitive={false} element={<RequireAuth><Menu /><CambiarPassword /></RequireAuth>} />
                                        <Route path="/asignar-box-sucursal" caseSensitive={false} element={<RequireAuth><Menu /><ConfigurarLoginUser /></RequireAuth>} />
                                        <Route path="/turnos-en-espera" caseSensitive={false} element={<RequireAuth><Menu /><TurnosEnEspera /></RequireAuth>} />
                                        <Route path="/presentaciones-espontaneas" caseSensitive={false} element={<RequireAuth><Menu /><PresentacionesEspontaneas /></RequireAuth>} />
                                        <Route path="/historial" caseSensitive={false} element={<RequireAuth><Menu /><HistorialPersona /></RequireAuth>} />

                                        <Route path="/pantalla-turnos" caseSensitive={false} element={<RequireAuth><Menu /><PantallaTurnos /></RequireAuth>} />
                                        <Route path="/actualizar-cantidad-de-turnos-por-sucursal" caseSensitive={false} element={<RequireAuth><Menu /><ActualizarCantidadTurnos /></RequireAuth>} />


                                        <Route path="/settings" caseSensitive={false} element={<RequireAuth><Menu /><Configuracion /></RequireAuth>} />
                                        <Route path="/lista-personas" caseSensitive={false} element={<RequireAuth><Menu /><Personas /></RequireAuth>} />
                                        <Route path="/agregar-persona" caseSensitive={false} element={<RequireAuth><Menu /><AgregarPersonas /></RequireAuth>} />
                                        <Route path="/feriados" caseSensitive={false} element={<RequireAuth><Menu /><Feriados /></RequireAuth>} />
                                        <Route path="/editar-persona/:id" caseSensitive={false} element={<RequireAuth><Menu /><EditarPersonas /></RequireAuth>} />
                                        <Route path="/mis-turnos" caseSensitive={false} element={<RequireAuth><Menu /><Turnos /></RequireAuth>} />
                                        <Route path="/splash-confirm" caseSensitive={false} element={<RequireAuth><Menu /><SplashConfirm /></RequireAuth>} />
                                        <Route path="/confirmar-turno/:id" caseSensitive={false} element={<RequireAuth><Menu /><ConfirmaTurno /></RequireAuth>} />
                                        <Route path="/colaborador/cancelar-turno/:id" caseSensitive={false} element={<RequireAuth><Menu /><CancelarTurnoColaborador /></RequireAuth>} />
                                        <Route path="/settings" caseSensitive={false} element={<RequireAuth><Menu /><Configuracion /></RequireAuth>} />

                                        <Route path="/asignar-sucursal" caseSensitive={false} element={<RequireAuth><Menu /><AsignarSucursal /></RequireAuth>} />
                                        <Route path="/actualizar-cantidad-de-turnos-por-sucursal" caseSensitive={false} element={<RequireAuth><Menu /><ActualizarCantidadTurnos /></RequireAuth>} />
                                        <Route path="/sucursales" caseSensitive={false} element={<RequireAuth><Menu /><Sucursales /></RequireAuth>} />
                                        <Route path="/agregar-sucursal" caseSensitive={false} element={<RequireAuth><Menu /><AgregarSucursales /></RequireAuth>} />
                                        <Route path="/editar-sucursal/:id" caseSensitive={false} element={<RequireAuth><Menu /><EditarSucursales /></RequireAuth>} />

                                        <Route path="/agregar-encuesta" caseSensitive={false} element={<RequireAuth><Menu /><AgregarPreguntas /></RequireAuth>} />
                                        <Route path="/encuestas" caseSensitive={false} element={<RequireAuth><Menu /><ListaDeEncuestas /></RequireAuth>} />


                                        <Route path="/motivos-turno" caseSensitive={false} element={<RequireAuth><Menu /><MotivosTurno /></RequireAuth>} />
                                        <Route path="/motivos-cancelacion" caseSensitive={false} element={<RequireAuth><Menu /><MotivosCancelacion /></RequireAuth>} />
                                        <Route path="/motivos-derivacion" caseSensitive={false} element={<RequireAuth><Menu /><MotivosDerivacion /></RequireAuth>} />
                                        <Route path="/motivos-reasignacion" caseSensitive={false} element={<RequireAuth><Menu /><MotivosReasignacion /></RequireAuth>} />


                                        <Route path="/metricas/defaults" caseSensitive={false} element={<RequireAuth><Menu /><Metricas /></RequireAuth>} />
                                        <Route path="/metricas/tiempos-de-espera-en-atencion" caseSensitive={false} element={<RequireAuth><Menu /><MetricasTiempoDeEsperaEnAtencion /></RequireAuth>} />
                                        <Route path="/metricas/duracion-de-turnos" caseSensitive={false} element={<RequireAuth><Menu /><MetricasDuracionDeTurnos /></RequireAuth>} />
                                        <Route path="/metricas/cantidad-de-turnos" caseSensitive={false} element={<RequireAuth><Menu /><MetricasCantidadDeTurnos /></RequireAuth>} />
                                        <Route path="/metricas/turnos-cancelados" caseSensitive={false} element={<RequireAuth><Menu /><MetricasTurnosCancelados /></RequireAuth>} />
                                        <Route path="/metricas/turnos-por-operador" caseSensitive={false} element={<RequireAuth><Menu /><MetricasTurnosPorOperador /></RequireAuth>} />
                                        <Route path="/metricas/motivos-turno" caseSensitive={false} element={<RequireAuth><Menu /><MetricasTurnosPorMotivoTurno /></RequireAuth>} />
                                        <Route path="/metricas/clientes" caseSensitive={false} element={<RequireAuth><Menu /><MetricasTurnosPorClientes /></RequireAuth>} />
                                        <Route path="/metricas/motivos-turno-clientes" caseSensitive={false} element={<RequireAuth><Menu /><MetricasMotivosTurnosPorClientes /></RequireAuth>} />
                                        <Route path="/metricas/presentaciones-espontaneas" caseSensitive={false} element={<RequireAuth><Menu /><MetricasPresentacionesEspontaneas /></RequireAuth>} />
                                        <Route path="/colaboradores" caseSensitive={false} element={<RequireAuth><Menu /><Colaboradores /></RequireAuth>} />




                                        <Route path="/logout" caseSensitive={false} element={<LimpiarLogin />} />

                                        <Route path="*" caseSensitive={false} element={<NotFound />} />
                                    </Routes>
                                </Router>
                            </SocketProvider>
                        </FirebaseProvider>
                    </BoxProvider>
                </SucursalProvider>
            </AuthProvider>
        </UserProvider>
    );
}