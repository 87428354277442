const IframeYoutube = (props) => {
    let url = props.src;
    url = url.split('=');
    const code = url[url.length - 1];
    return (
        <div className="iframe">
            <iframe
                id="IframeYoutube"
                src={`https://www.youtube.com/embed/${code}?controls=0&playlist=${code}&loop=1;rel=0&autoplay=1&controls=0&showinfo=0&mute=1`}
                title={props.title ?? "Video de youtube"}
            ></iframe>
        </div>
    );
}


export default IframeYoutube;