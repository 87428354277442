import { formatarFecha } from "../../../utils/functions";
import TURN_STATE_OBJECT from "../../../utils/turn_state";
import FormDerivacionMultipleInterna from "../../Forms/DerivacionMultipleInterna";
import FormDerivacionMultipleSucursal from "../../Forms/DerivacionMultipleSucursal";

export default function ModalDerivacionMultiple(props) {
    const { setLoading, ver_modal_derivar, motivos_derivacion, motivos_turno, sucursales, personas, sucursal, turnos_finalizar, datosTurnosSeleccionados, cargarLista, closeModal, TOKEN, restablecerCheckboxs } = props;
    const DerivacionInternaMultiple = document.getElementById('DerivacionInternaMultiple');
    const DerivacionPorSucursalMultiple = document.getElementById('DerivacionPorSucursalMultiple');

    const abrirDerivacionInternaMultiple = () => {
        DerivacionInternaMultiple.classList.remove('d-none');
        DerivacionPorSucursalMultiple.classList.add('d-none');
    }

    const abrirDerivacionPorSucursalMultiple = () => {
        DerivacionPorSucursalMultiple.classList.remove('d-none');
        DerivacionInternaMultiple.classList.add('d-none');

    }
    return (
        <div className={`modal ${!ver_modal_derivar ? 'fade' : 'show d-block'}`} id="modalDerivarMasivo" tabIndex="-1" aria-labelledby="modalDerivarMasivoLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center" id="modalDerivarMasivoLabel">Derivar turnos masivamente</h5>
                        <button type="button" className="btn-close" id="modalDerivacionClose" onClick={closeModal} aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-5">
                                {
                                    datosTurnosSeleccionados.map(turno => {
                                        const estado_total = TURN_STATE_OBJECT[turno.estado_turno];
                                        const color = estado_total !== undefined ? estado_total.dataColor - 1 : 1;
                                        const display = estado_total !== undefined ? estado_total.display : turno.estado_turno || 'Ocurrio un error';
                                        return (
                                            <div className={`card my-3 ${turno.estado === 0 ? 'color-danger text-danger' : ''}`} key={turno.turno_id}>
                                                <div className="card-header">
                                                    <h5 className="card-title">Turno #{turno.motivo_turno_abreviacion} - {turno.turno_id}</h5>
                                                </div>
                                                <div className="card-body">
                                                    <h6>{turno.persona.name} {turno.persona.surname}</h6>
                                                    <h6>{formatarFecha(turno.fecha)} - {turno.hora}</h6>
                                                </div>
                                                {
                                                    turno.estado === 0 &&
                                                    <div className="card-footer text-center">
                                                        <span className={`border-0 text-center font-weight ${color}`}>{`Este turno se encuentra ${display}`}</span>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-6">
                                        <button className="btn-dangergg d-block mx-auto" onClick={abrirDerivacionInternaMultiple}>Interna</button>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="btn-greengg d-block mx-auto" onClick={abrirDerivacionPorSucursalMultiple}>A sucursal</button>
                                    </div>
                                </div>
                                <div className="my-5">
                                    <div id="DerivacionInternaMultiple" className="d-none">
                                        <div className="card">
                                            <div className="card-header">Derivar a otro ADP</div>
                                            <div className="card-body">
                                                <FormDerivacionMultipleInterna
                                                    turnos_finalizar={turnos_finalizar}
                                                    setLoading={setLoading}
                                                    cargarLista={cargarLista}

                                                    motivos_derivacion={motivos_derivacion}
                                                    personas={personas}

                                                    cerrar_modal={closeModal}

                                                    restablecerCheckboxs={restablecerCheckboxs}

                                                    TOKEN={TOKEN}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="DerivacionPorSucursalMultiple" className="d-none">
                                        <div className="card">
                                            <div className="card-header">Derivar a nueva sucursal</div>
                                            <div className="card-body">
                                                <FormDerivacionMultipleSucursal
                                                    turnos_finalizar={turnos_finalizar}
                                                    setLoading={setLoading}
                                                    motivos_derivacion={motivos_derivacion}
                                                    motivos_turno={motivos_turno}
                                                    sucursales={sucursales}
                                                    sucursal={sucursal}
                                                    cargarLista={cargarLista}
                                                    cerrar_modal={closeModal}

                                                    restablecerCheckboxs={restablecerCheckboxs}
                                                    TOKEN={TOKEN}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}