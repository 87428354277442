import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";


const obtenerListaDeTurnoParaAtender = async ({ params, token, callbacks }) => {
    const response = await connection({ method: 'POST', url: `${ENTITY_URL.ADP.TURNOS.OBTENER_LISTA_PARA_ATENDER}`, extraHeaders: { token: token }, params: params });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default obtenerListaDeTurnoParaAtender;