import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import Loading from "../../components/Loading";
import { useEffect } from "react";

export default function LimpiarLogin() {
    const { loading, removeAll, setLoading } = useAuth();
    setLoading(true);
    useEffect(() => {
        removeAll();
        setLoading(false);
    }, [removeAll, loading, setLoading]);
    if (loading) {
        return <Loading />
    }
    return <Navigate to='/login' />

}