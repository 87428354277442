import { createContext, useContext, useState, useEffect } from "react";
import { io } from "socket.io-client";

import { useAuth } from "./auth";

import { USERNAME, PASSWORD, SERVER_URL } from "../utils/config.js";
import { useFirebase } from "./firebase";
import { isEmpty } from "../utils/functions";

const SocketContext = createContext(null);
export const SocketProvider = ({ children }) => {
    const { token } = useAuth();
    const { tokenFirebase } = useFirebase();
    const [socket, setSocket] = useState(io(SERVER_URL, {
        // const socket = io( {
        // path: "/services/notificaciones/",
        // path: "/services/",
        path: "/api/socketio/socket.io",
        withCredentials: true,
        // transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 10,
        extraHeaders: {
            Authorization: 'Basic ' + btoa(USERNAME + ":" + PASSWORD),
            Token: token
        }
    }));
    // useEffect(() => {
    //     try {
    //         const socketio = io(SERVER_URL, {
    //             // const socket = io( {
    //             // path: "/services/notificaciones/",
    //             // path: "/services/",
    //             path: "/api/socketio/socket.io",
    //             withCredentials: true,
    //             // transports: ['websocket'],
    //             reconnection: true,
    //             reconnectionDelay: 1000,
    //             reconnectionDelayMax: 5000,
    //             reconnectionAttempts: 10,
    //             extraHeaders: {
    //                 Authorization: 'Basic ' + btoa(USERNAME + ":" + PASSWORD),
    //                 Token: token
    //             }
    //         });
    //         setSocket(socketio);
    //         console.log({ socketio });
    //         if (socketio.conected) {
    //             console.log('bien')
    //             if (!isEmpty(tokenFirebase)) {
    //                 socketio.emit("firebase_registration", { device_id: tokenFirebase });
    //             }
    //             socketio.on('connection', (data) => {
    //                 try {
    //                     console.log("connection:", data)
    //                 } catch (e) {
    //                     console.log("error connection:", e);
    //                 }
    //             });
    //             socketio.on("connection_succed", function (data) {
    //                 try {
    //                     console.log("connection_succed:", data)
    //                 } catch (e) {
    //                     console.log("error firebase:", e);
    //                 }
    //             });
    //             socketio.on("connect_error", (err) => {
    //                 console.error(err);
    //             });

    //             socketio.on('disconnect', () => {
    //                 try {
    //                     console.log("disconnect");
    //                 } catch (e) {
    //                     console.log("error disconnect:", e);
    //                 }
    //             });
    //             socketio.on("messages", function (data) {
    //                 try {
    //                     console.log("messages:", data);
    //                 } catch (e) {
    //                     console.log("error messages:", e);
    //                 }
    //             });

    //             return () => {
    //                 socketio.off('connect');
    //                 socketio.off('disconnect');
    //             };
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }, [token, tokenFirebase])



    return <SocketContext.Provider value={{
        socket
    }}>{children}</SocketContext.Provider>
}

export const useSocket = () => {
    return useContext(SocketContext);
}