import { createContext, useContext, useState, useEffect } from "react";
import { getTokenFirebase } from "../config/firebase";
import asignarDispositivo from "../services/notificaciones/asignar-dispositivo";
import { useAuth } from "./auth";
import { isEmpty } from "../utils/functions";

const FirebaseContext = createContext(null);
export const FirebaseProvider = ({ children }) => {
    const [tokenFirebase, setTokenFirebase] = useState();
    const { token } = useAuth();

    useEffect(() => {
        if (!isEmpty(token)) {
            getTokenFirebase().then(response => {
                setTokenFirebase(response)
                asignarDispositivo({
                    token: token, params: { device_id: response }
                });
            })
        }

    }, [token]);

    return <FirebaseContext.Provider value={{
        tokenFirebase, setTokenFirebase
    }}>
        {
            !tokenFirebase &&
            <div className="alert alert-danger">
                No tienes las notificaciones activadas
            </div>
        }
        {
            tokenFirebase === '0' &&
            <div className="alert alert-info">
                Activa las notificaciones
            </div>
        }
        {children}
    </FirebaseContext.Provider>
}

export const useFirebase = () => {
    return useContext(FirebaseContext);
}