import { useAuth } from "../../hooks/auth";
import eliminarMotivoTurno from "../../services/adp/turn/eliminar-motivo-turno";
import Message from "../../utils/message";

export default function MotivosTurnoLista(props) {
    const { token: TOKEN } = useAuth();
    const { id: turno_id, motivos_actuales, setLoader, cargarLista, obtenerDatosTurno } = props;
    function borrarMotivoTurno(e) {
        if (motivos_actuales.length > 1) {
            const { target } = e;
            const rel = target.getAttribute('rel');
            setLoader(true);
            eliminarMotivoTurno({
                params: { motivo_id: rel, turno_id: turno_id }, token: TOKEN, callbacks: async (response) => {
                    if (response.estado > 0) {
                        target.parentNode.remove();
                        await obtenerDatosTurno(turno_id);
                        cargarLista();
                        await setLoader(false);
                    }
                }
            });
        } else {
            Message({ message: 'No puedes eliminar todos los motivos de turno', type: 'error' })
        }
    }

    return (
        <>
            {
                motivos_actuales.map(item => {
                    return <li key={item.idNitro4} id={`motivo_turno_${item.idNitro4}`}>
                        {item.Nombre}
                        <button className="btnCancelar" rel={item.idNitro4} onClick={(e) => borrarMotivoTurno(e)}>Borrar</button>
                    </li>
                })}
        </>
    )
}