
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { Spinner } from "react-bootstrap";
import ModalAgregarFeriado from "../../components/Modals/Feriados/agregar";
import Title from "../../components/Title";
import eliminarFeriado from "../../services/admin/feriados/delete";
import listaFeriados from "../../services/admin/feriados/list";
import { formatarFecha } from "../../utils/functions";
import ToastMesage from "../../utils/toast";
import { useSucursal } from "../../hooks/sucursal";
import { useAuth } from "../../hooks/auth";
export default function Feriados(props) {
    const { sucursal } = useSucursal();
    const { token: TOKEN } = useAuth();
    const [ver_modal_agregar_feriado, setAbrirModalAgregarFeriado] = useState(false);
    const [loading, setLoading] = useState(true);
    const [lista, setListado] = useState([]);
    const abrirModal = () => {
        setAbrirModalAgregarFeriado(true);
    }

    const cargarLista = useCallback(async () => {
        await listaFeriados({
            params: { branch_id: sucursal.branchID }, token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0) {
                    if (response.resultados.length > 0) {
                        setListado(response.resultados);
                    }
                }
                await setLoading(false);
            }
        })
    }, [TOKEN, sucursal]);

    useEffect(() => {
        cargarLista();
    }, [cargarLista]);


    async function eliminarFeriadoLista(id) {
        setLoading(true);
        eliminarFeriado({
            id: id, token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0) {
                    ToastMesage({ message: 'Se elimino correctamente el feriado', type: 'success' });
                    await cargarLista();
                } else {
                    setLoading(false);
                    ToastMesage({ message: response.mensaje, type: 'error' });
                }
            }
        })
    }
    return (
        <>
            <main>
                <div className="container-fluid ps-0 my-5">
                    <div className="glassDatos">
                        <div className="pb-4 d-flex align-items-center justify-content-between">
                            <Title title={`Feriados`} class="titlegray" />
                            <div className="d-flex">
                                <button className="buttonModal2 mx-2" onClick={abrirModal}>+ Agregar feriado</button>
                            </div>
                        </div>
                        <div className="col-12">
                            {
                                loading &&
                                <div className="containerLoader">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </Spinner>
                                </div>
                            }
                            {
                                !loading &&
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Motivo</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {lista.length > 0 &&
                                        <tbody>
                                            {
                                                lista.map((item, index) => {
                                                    const { idNitro4, fecha, motivo_no_laboral } = item;
                                                    return (
                                                        <tr id={`row_${idNitro4}`} key={`${index}_${idNitro4}`}>
                                                            <td><b>{formatarFecha(fecha).replace('T00:00:00.000Z', '')}</b></td>
                                                            <td>{motivo_no_laboral}</td>
                                                            <td>
                                                                <button className="btnDerivar px-3" onClick={() => eliminarFeriadoLista(idNitro4)}>
                                                                    Eliminar
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    }
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </main>
            <ModalAgregarFeriado
                ver_modal_agregar_feriado={ver_modal_agregar_feriado}
                setAbrirModalAgregarFeriado={setAbrirModalAgregarFeriado}
                TOKEN={TOKEN}
                cargarLista={cargarLista}
                setLoading={setLoading}
            />
        </>
    )
}