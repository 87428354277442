import { AUTH_URL } from "../../config/config";
import { connection } from "../../utils/connection";

async function validateToken({ token, callbacks }) {
    const response = await connection({ method: 'GET', url: `${AUTH_URL.VALIDATE_TOKEN}`, extraHeaders: { token: token } });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default validateToken;
