import { useState } from "react";
import getListBranchs from "../../services/collaborator/branch/list";
import getListTurnReasons from "../../services/collaborator/turn_reason/list";
import FormAgregarTurnoSelectora from "../Forms/AgregarTurnoSelectora";
import { useHotkeys } from 'react-hotkeys-hook';


function MyAwesomeComponent(props) {
    const { setLoading, setModalAgregar, TOKEN, setBranchs, setTurnReasonsList } = props;
    useHotkeys('shift+n, shift+t, alt+t, alt+n', async function (event) {
        event.preventDefault();
        setLoading(true);
        document.getElementById('formAgregarTurno').reset();
        document.querySelectorAll('.clear-selected-button').forEach(function (e) {
            e.click();
        })
        await getListBranchs({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setBranchs(response.resultados);
                }
            }
        });
        await getListTurnReasons({
            token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    let motivosTurnoArray = [];
                    response.resultados.forEach(item => {
                        let label = `${item.abbreviation} - ${item.name}`;
                        motivosTurnoArray.push({
                            value: item.id,
                            label: label.toUpperCase(),
                        });
                    });
                    setTurnReasonsList(motivosTurnoArray);
                }
                await setLoading(false);
            }
        });
        setModalAgregar(true);
    })

    const abrirModal = async () => {
        setLoading(true);
        document.getElementById('formAgregarTurno').reset();
        document.querySelectorAll('.clear-selected-button').forEach(function (e) {
            e.click();
        })
        await getListBranchs({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    setBranchs(response.resultados);
                }
            }
        });
        await getListTurnReasons({
            token: TOKEN, callbacks: async (response) => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    let motivosTurnoArray = [];
                    response.resultados.forEach(item => {
                        let label = `${item.abbreviation} - ${item.name}`;
                        motivosTurnoArray.push({
                            value: item.id,
                            label: label.toUpperCase(),
                        });
                    });
                    setTurnReasonsList(motivosTurnoArray);
                }
                await setLoading(false);
            }
        });
        setModalAgregar(true);
    }

    return (
        <button className="buttonModal mx-2" onClick={abrirModal}>+ Agregar Turno</button>
    )
}

export default function AgregarTurnoSelectora(props) {
    const { TOKEN, setLoading, cargarLista } = props;

    const [turnReasons, setTurnReasonsList] = useState([]);
    const [branchs, setBranchs] = useState([]);
    const [ver_modal, setModalAgregar] = useState(false);
    return (
        <>
            <MyAwesomeComponent
                setLoading={setLoading}
                setModalAgregar={setModalAgregar}
                TOKEN={TOKEN}
                setBranchs={setBranchs}
                setTurnReasonsList={setTurnReasonsList}
            />
            <div className={`modal ${!ver_modal ? 'fade' : 'show d-block'}`} id="modalNuevoTurno" aria-labelledby="modalNuevoTurnoLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalNuevoTurnoLabel">Nuevo Turno</h5>
                            <button type="button" className="btn-close" id="closeModalAdd" onClick={() => setModalAgregar(false)}></button>
                        </div>
                        <div className="modal-body">
                            <FormAgregarTurnoSelectora
                                TOKEN={TOKEN}
                                setLoading={setLoading}
                                turnReasons={turnReasons}
                                branchs={branchs}
                                closeModal='closeModalAdd'
                                cargarLista={cargarLista}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}