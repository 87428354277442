import { ENTITY_URL } from "../../../config/config";
import { connection } from "../../../utils/connection";

const eliminarMotivoTurno = async ({ params, token, callbacks }) => {
    const response = await connection({ method: 'POST', url: `${ENTITY_URL.TURNOS_MOTIVO.ELIMINAR}custom/eliminar`, extraHeaders: { token: token }, params: params });
    if (typeof callbacks === "function") {
        callbacks(response);
    }
}

export default eliminarMotivoTurno;